import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList, View} from 'react-native';
import {Spinner, Text} from '@ui-kitten/components';
import EmptyWithdrawalList from '../../../../assets/icons/EmptyRequests';
import {AppLink, OutlineButton} from '../../../../uikit/AppButtons';
import {StandardPointsWithdrawalResponse} from '../../../../api/standard-points/models/responses/withdrawal';

export const WithdrawalList = styled(
  FlatList<StandardPointsWithdrawalResponse>,
)`
  flex: 1;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.smallSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: ${props.theme.dimens.smallSpacer};
    `}
`;

export const WithdrawalListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const WithdrawalListLoading = styled(Spinner)``;

export const WithdrawalListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const WithdrawalListEmptyIcon = styled(EmptyWithdrawalList)``;

export const WithdrawalListEmptyInfo = styled(Text)``;

export const WithdrawalListEmptyCreateNewLink = styled(AppLink)`
  align-self: stretch;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
      min-width: 240px;
    `}
`;

export const WithdrawalListItemContainer = styled.View`
  display: flex;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const WithdrawalListItemInfoBlock = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const WithdrawalListItemInfoContainer = styled.View`
  display: flex;
  justify-content: space-between;
`;

export const WithdrawalListItemControlsContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const WithdrawalListItemControlsInfoContainer = styled.View`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const WithdrawalListItemInfoTitle = styled(Text)`
  font-size: 15px;
  font-weight: 500;
`;

export const WithdrawalListItemInfoText = styled(Text)`
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const WithdrawalListItemInfoPrice = styled(Text)`
  font-size: 20px;
  font-weight: 700;
`;

export const WithdrawalListItemInfoSign = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`;

export const WithdrawalListItemInfoReason = styled(View)`
  display: flex;
  border-top-width: 1;
  border-color: ${props => props.theme.colors['color-basic-200']};
  padding-top: ${props => props.theme.dimens.smallSpacer};
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const WithdrawalListItemInfoReasonTitle = styled.Text`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors['color-basic-900']};
`;

export const WithdrawalListItemInfoReasonText = styled.Text`
  font-weight: 400;
`;

export const WithdrawalListItemInfoStatus = styled(Text)`
  font-size: 12px;
  font-weight: bold;
`;

export const WithdrawalListItemCancelButton = styled(OutlineButton).attrs({
  status: 'danger',
})`
  background-color: transparent;
  margin-left: ${props =>
    props.theme.isDesktop
      ? props.theme.dimens.normalSpacer
      : props.theme.dimens.smallSpacer};
`;
