import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList} from 'react-native';
import {Text} from '@ui-kitten/components';
import {RequestCategory} from '../../../../../api/request/models/responses/categories';

export const NewRequestCategoriesRoutes = styled(FlatList<RequestCategory>)`
  flex: 1;
  margin-top: ${props => props.theme.dimens.smallSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  margin-left: ${props => props.theme.dimens.smallestSpacer};
  margin-right: ${props => props.theme.dimens.smallestSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: -${props.theme.dimens.smallSpacer};
      margin-right: -${props.theme.dimens.smallSpacer};
    `}
`;

export const NewRequestCategoriesRouteItemContainer = styled.View`
  display: flex;
  flex-basis: 50%;
  padding: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-basis: 33.33%;
    `}
`;

export const NewRequestCategoriesRouteItem = styled.TouchableOpacity`
  aspect-ratio: 1;
  border-radius: 4px;
  background-color: ${props => props.theme.colors['color-primary-500']}3D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NewRequestCategoriesRouteItemIcon = styled.Image`
  width: 80px;
  height: 80px;
`;

export const NewRequestCategoriesRouteItemName = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  text-align: center;
`;
