import React from 'react';
import {useStrings} from '../../../../utils/providers/strings';
import useRouter from '../../../../utils/use-router';

import {LinkItem, LinkItemText, LinksWrapper} from './styles/index.common';

/**
 * List of links for info pages
 * @kind Desktop
 */
export const InfoNavigationLinksDesktop = () => {
  const strings = useStrings();
  const router = useRouter();

  const links = [
    {label: strings.info_navigation_link_info, href: '/info'},
    // {
    //   label: strings.info_navigation_link_guidance_of_store_publication,
    //   href: '/guidance-of-store-publication',
    // },
    {
      label: strings.info_navigation_link_operating_company,
      href: '/operating-company',
    },
    {
      label: strings.info_navigation_link_privacy_policy,
      href: '/privacy-policy',
    },
    {label: strings.info_navigation_link_faq, href: '/faq'},
    {label: strings.info_navigation_link_contact_us, href: '/contact-us'},
  ];

  return (
    <LinksWrapper>
      {links.map(({label, href}) => (
        <LinkItem key={label} onPress={() => router.replace(href)}>
          <LinkItemText>{label}</LinkItemText>
        </LinkItem>
      ))}
    </LinksWrapper>
  );
};
