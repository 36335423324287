import {light} from '@eva-design/eva';

export const uiKittenColors = {
  ...light,

  // 'background-basic-color-1': '#F5EFE6',
  'color-primary-100': '#FDF4D4',
  'color-primary-200': '#FBE6AB',
  'color-primary-300': '#F4D27F',
  'color-primary-400': '#EABB5E',
  'color-primary-500': '#DC9B2D',
  'color-primary-600': '#BD7D20',
  'color-primary-700': '#9E6116',
  'color-primary-800': '#7F480E',
  'color-primary-900': '#693608',
  'color-success-100': '#D1EFFB',
  'color-success-200': '#A5DBF7',
  'color-success-300': '#74BBE7',
  'color-success-400': '#4E98D0',
  'color-success-500': '#1e6ab2',
  'color-success-600': '#155299',
  'color-success-700': '#0F3D80',
  'color-success-800': '#092B67',
  'color-success-900': '#051E55',
  'color-info-100': '#D7F0FF',
  'color-info-200': '#AFDDFF',
  'color-info-300': '#87C7FF',
  'color-info-400': '#69B3FF',
  'color-info-500': '#3891FF',
  'color-info-600': '#2870DB',
  'color-info-700': '#1C53B7',
  'color-info-800': '#113A93',
  'color-info-900': '#0A287A',
  'color-warning-100': '#FFF6DA',
  'color-warning-200': '#FFEBB6',
  'color-warning-300': '#FFDD91',
  'color-warning-400': '#FFCF76',
  'color-warning-500': '#FFB949',
  'color-warning-600': '#DB9435',
  'color-warning-700': '#B77324',
  'color-warning-800': '#935517',
  'color-warning-900': '#7A3F0E',
  'color-danger-100': '#FBE8D1',
  'color-danger-200': '#F7CCA5',
  'color-danger-300': '#E9A475',
  'color-danger-400': '#D47C4F',
  'color-danger-500': '#b8481f',
  'color-danger-600': '#9E3116',
  'color-danger-700': '#841E0F',
  'color-danger-800': '#6A0F09',
  'color-danger-900': '#580506',
  'color-primary-transparent-300': 'rgba(220, 155, 45, 0.24)',
  'color-basic-900': '#1F1B16',
  'color-basic-800': '#8F8880',
  'color-basic-700': 'rgba(31, 27, 22, 0.40)',
  'color-basic-600': 'rgba(31, 27, 22, 0.16)',
  'color-basic-500': '#AEA69E',
  'color-basic-400': '#CEC5BD',
  'color-basic-300': '#E9E0D8',
  'color-basic-200': '#E8E8E8',
  'color-basic-100': '#F5EFE6',

  'color-primary-focus': '$color-primary-700',
  'color-primary-hover': '$color-primary-400',
  'color-primary-default': '$color-primary-500',
  'color-primary-active': '$color-primary-600',
  'color-primary-disabled': '$color-primary-300',

  'background-basic-color-1': '$color-basic-100',
  'background-basic-color-2': '$color-basic-200',
  'background-basic-color-3': '$color-basic-300',
  'background-basic-color-4': '$color-basic-400',

  'border-basic-color-1': '$color-basic-100',
  'border-basic-color-2': '$color-basic-200',
  'border-basic-color-3': '$color-basic-300',
  'border-basic-color-4': '$color-basic-400',
  'border-basic-color-5': '$color-basic-500',

  'text-basic-color': '$color-basic-900',
  'text-alternate-color': '$color-basic-900',
  'text-control-color': '$color-basic-900',
  'text-disabled-color': '$color-basic-500',
  'text-hint-color': '$color-basic-700',
};
