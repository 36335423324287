import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const HeartOutline = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#000" {...props}>
    <Path d="M12 5.489c2.35-2.054 5.98-1.986 8.243.222a5.733 5.733 0 01.236 8.02L11.999 22 3.52 13.73a5.734 5.734 0 01.236-8.02c2.265-2.204 5.888-2.278 8.244-.221zm6.827 1.598c-1.5-1.463-3.92-1.522-5.49-.15l-1.335 1.167-1.336-1.165a4.077 4.077 0 00-5.494.15 3.82 3.82 0 00-.192 5.308L12 19.243l7.02-6.845a3.821 3.821 0 00-.193-5.311z" />
  </Svg>
);

export default HeartOutline;
