import jwtDecode from 'jwt-decode';
import {JwtUser, JwtUserResponse} from './models/responses/jwt-user';

export function getUserFromJwt(jwtToken: string): JwtUser {
  const user = jwtDecode<JwtUserResponse>(jwtToken);
  return {
    id: JSON.parse(user.user).userId,
    role: user.role,
  };
}
