import React, {ReactNode} from 'react';
import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import AppRouteView from '../../../uikit/AppRouteView';
import {useTheme} from 'styled-components/native';

export const ShopsContainerContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors['color-basic-100']};
  padding: ${props => (props.theme.isDesktop ? '0' : '16px 0')};
`;

export const ShopsContainerContent = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 128px;
    `}
`;

export const ShopsScrollView = styled.ScrollView`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding-bottom: 24px;
    `}
`;

export const ShopsRouteContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: 16px;
    `}
`;

export const CommonShopsRouteItemContainer = css`
  align-self: center;
  aspect-ratio: 1;
  border-radius: 4px;
  background-color: ${props => props.theme.colors['color-primary-500']}3D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ShopsRouteItemContainerDesktop = styled(AppRouteView)`
  ${CommonShopsRouteItemContainer};
  width: 100%;
  height: 100%;
`;

const ShopsRouteItemContainerMobile = styled(AppRouteView)`
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 4px;
  justify-content: flex-start;
  align-self: center;
  align-items: center;
  background-color: transparent;
`;

const ShopsRouteItemIconContainerMobile = styled.View`
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 4px;
  background-color: ${props => props.theme.colors['color-primary-500']}3D;
`;

const ShopsRouteItemName = styled(Text)`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  align-self: center;
  text-align: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

const ShopsRouteItemNameMobile = styled(ShopsRouteItemName)`
  width: 64px;
  text-align: center;
`;

export const ShopsRouteItem = ({
  name,
  route,
  lastItem,
  children,
}: {
  name: string;
  route: string;
  lastItem?: boolean;
  children?: ReactNode;
}) => {
  const theme = useTheme();

  const ShopsRouteItemContentContainerDesktop = styled.View`
    height: 104px;
    flex-basis: 33.33%;
    display: flex;
    padding-right: ${props => (lastItem ? 0 : props.theme.dimens.smallSpacer)};
  `;

  const ShopsRouteItemContentContainerMobile = styled.View`
    display: flex;
    height: 104px;
  `;

  if (theme.isDesktop) {
    return (
      <ShopsRouteItemContentContainerDesktop>
        <ShopsRouteItemContainerDesktop to={route}>
          {children}
          <ShopsRouteItemName>{name}</ShopsRouteItemName>
        </ShopsRouteItemContainerDesktop>
      </ShopsRouteItemContentContainerDesktop>
    );
  } else {
    return (
      <ShopsRouteItemContentContainerMobile>
        <ShopsRouteItemContainerMobile to={route}>
          <ShopsRouteItemIconContainerMobile>
            {children}
          </ShopsRouteItemIconContainerMobile>
          <ShopsRouteItemNameMobile>{name}</ShopsRouteItemNameMobile>
        </ShopsRouteItemContainerMobile>
      </ShopsRouteItemContentContainerMobile>
    );
  }
};
