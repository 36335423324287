import styled from 'styled-components/native';

export const ShopAccordionSection = styled.View`
  margin-top: 24px;
`;

export const ShopAccordionTextDescription = styled.Text`
  font-size: 14px;
  line-height: 20px;
  color: #8f8880;
`;

export const ShopProductsContainer = styled.View`
  margin-top: 8px;
`;

export const ShopProductItemContainer = styled.View<{
  isLastItem?: boolean;
}>`
  display: flex;
  flex-direction: row;
  border-bottom-width: ${props => (props.isLastItem ? '0' : '1px')};
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
  padding: 8px 0;
`;

export const ShopProductItemContentContainer = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ShopProductItemContentTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  margin-bottom: 8px;
`;

export const ShopProductItemContentDescription = styled.Text`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors['color-basic-900']};
`;

export const ShopProductItemImageContainer = styled.View`
  width: 104px;
  height: 104px;
  margin-right: 12px;
`;

export const ShopProductItemImage = styled.Image`
  flex: 1;
`;
