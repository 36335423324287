import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {
  ConciergePointsBuyByBtcRequest,
  ConciergePointsBuyByBankTransferRequest,
  ConciergePointsBuyRequest,
  ConciergePointsBuyByCybersourceRequest,
} from '../../../api/concierge-points/models/requests/buy';
import {
  ConciergePointsBuyByBtcResponse,
  ConciergePointsBuyByBankTransferResponse,
  ConciergePointsBuyByCybersourceResponse,
  ConciergePointsBuyResponse,
} from '../../../api/concierge-points/models/response/buy';
import {PaymentInfoResponse} from '../../../api/concierge-points/models/response/status';

export const buyPointsRequestAction = createAsyncAction<
  ConciergePointsBuyRequest,
  ConciergePointsBuyResponse,
  Error
>('BUY_POINTS_REQUEST');

export const buyPointsCybersourceRequestAction = createAsyncAction<
  ConciergePointsBuyByCybersourceRequest,
  ConciergePointsBuyByCybersourceResponse,
  Error
>('BUY_POINTS_CYBERSOURCE_REQUEST');

export const buyPointsByBtcRequestAction = createAsyncAction<
  ConciergePointsBuyByBtcRequest,
  ConciergePointsBuyByBtcResponse,
  Error
>('BUY_POINTS_BY_BTC_REQUEST');

export const paymentStatusRequestAction = createAsyncAction<
  string,
  PaymentInfoResponse,
  Error
>('PAYMENT_STATUS_REQUEST');

export const buyPointsByBankTransferRequestAction = createAsyncAction<
  ConciergePointsBuyByBankTransferRequest,
  ConciergePointsBuyByBankTransferResponse,
  Error
>('BUY_POINTS_BY_BANK_TRANSFER_REQUEST');
