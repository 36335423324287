import React from 'react';

import {useStrings} from '../../utils/providers/strings';
import type {User} from '../../api/user/models/responses/user';
import AppLogoutButton from '../AppLogoutButton';
import {
  UserIdContainerMobile,
  UserIdMobile,
  UserIdPrefixMobile,
  UserMobile,
  UserMobileContainer,
  UserMobileInfoContainer,
} from './styles';
import {ViewProps} from 'react-native';

type Props = {
  data: User;
} & ViewProps;

const UserMobileInfo = ({data, ...props}: Props) => {
  const strings = useStrings();
  return (
    <UserMobileContainer {...props}>
      <UserMobileInfoContainer>
        <UserMobile>{data.firstName}</UserMobile>
        <UserIdContainerMobile>
          <UserIdPrefixMobile>
            {strings.main_navigation_id_prefix}
          </UserIdPrefixMobile>
          <UserIdMobile>{data.clientId}</UserIdMobile>
        </UserIdContainerMobile>
      </UserMobileInfoContainer>
      <AppLogoutButton />
    </UserMobileContainer>
  );
};

export default UserMobileInfo;
