import React from 'react';
import {RequestCategoryTypeField} from '../../../../../../../api/request/models/responses/category-type';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldErrorText,
  NewRequestQuestionFieldTitle,
} from '../styles';
import {NewRequestQuestionFieldTextInput} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  NewRequestQuestionFieldTextState,
  NewRequestQuestionForm,
} from '../../../form';

export default function NewRequestQuestionFieldText({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  return (
    <NewRequestQuestionFieldContainer>
      <NewRequestQuestionFieldTitle>{field.title}</NewRequestQuestionFieldTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const textValue = value as NewRequestQuestionFieldTextState;
          return (
            <NewRequestQuestionFieldTextInput
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={textValue}
            />
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
    </NewRequestQuestionFieldContainer>
  );
}
