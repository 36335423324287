import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import {AppButton} from '../../AppButtons';

export const AppErrorContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.theme.dimens.smallSpacer};
`;

export const AppErrorText = styled(Text).attrs({
  category: 's1',
})`
  text-align: center;
`;

export const AppErrorRetryButton = styled(AppButton)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  align-self: stretch;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
    `}
`;
