import {combineReducers} from 'redux';
import {createReducer} from '@reduxjs/toolkit';
import {User} from '../../../api/user/models/responses/user';
import {
  deleteAccountRequestAction,
  getUserProfileRequestAction,
  logoutRequestAction,
  setJWTUserAction,
  updateUserProfileRequestAction,
  userRequestAction,
} from './action';
import createRequestReducer, {
  addRequestCase,
  data,
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {JwtUser} from '../../../api/user/models/responses/jwt-user';
import {UserProfileResponse} from '../../../api/user/models/responses/user-profile';

export const userReducer = combineReducers<{
  jwtUser: JwtUser | null;
  user: User | null;
  userRequest: RequestState<User> | null;
  getUserProfileRequest: RequestState<UserProfileResponse> | null;
  updateUserProfileRequest: RequestState<UserProfileResponse> | null;
  deleteAccountRequest: RequestState<void> | null;
  logoutRequest: RequestState<void> | null;
}>({
  jwtUser: createReducer<JwtUser | null>(null, builder => {
    builder.addCase(setJWTUserAction, (state, action) => action.payload);
  }),
  user: createReducer<User | null>(null, builder => {
    builder.addCase(
      userRequestAction.success,
      (state, action) => action.payload,
    );
    builder.addCase(setJWTUserAction, (state, action) =>
      action.payload === null ? null : state,
    );
  }),
  userRequest: createReducer<RequestState<User> | null>(null, builder => {
    addRequestCase(builder, userRequestAction);
    builder.addCase(setJWTUserAction, (state, action) =>
      action.payload === null ? null : state,
    );
  }),
  getUserProfileRequest:
    createReducer<RequestState<UserProfileResponse> | null>(null, builder => {
      addRequestCase(builder, getUserProfileRequestAction);
      builder.addCase(setJWTUserAction, (state, action) =>
        action.payload === null ? null : state,
      );
      builder.addCase(updateUserProfileRequestAction.success, (state, action) =>
        data(state, action.payload),
      );
    }),
  updateUserProfileRequest: createRequestReducer(
    updateUserProfileRequestAction,
  ),
  deleteAccountRequest: createRequestReducer(deleteAccountRequestAction),
  logoutRequest: createRequestReducer(logoutRequestAction),
});
