import {DateFnsService} from '@ui-kitten/date-fns';
import {Strings as StringsGenerated} from '../strings-generated';
import RNLocalize from 'react-native-localize';

export enum Locales {
  JA = 'JA',
  EN = 'EN',
}

export function getLocale(): Locales {
  return (
    RNLocalize.findBestAvailableLanguage(Object.values(Locales))?.languageTag ||
    Locales.EN
  );
}

export interface Strings extends StringsGenerated {
  countryData: {value: string; label: string}[];
  dateService: DateFnsService;
  //App
  app_date: (date: Date) => string;
  app_time: (date: Date) => string;
  app_date_string: (date: string) => string;
  app_date_time_string: (date: string) => string;
  app_number: (number: number) => string;
  app_minimal_value_fn: (number: number) => string;

  //New request question
  new_request_question_title_fn: (
    currentPage: number,
    allCount: number,
  ) => string;

  //New request confirm
  new_request_confirm_modal_title_fn: (requestName: string) => string;

  requests_calendar_date: (date: Date) => string;
  requests_calendar_events_counter_fn: (count: number) => string;

  requests_item_description_fn: (name: string, date: string) => string;

  detail_request_date_fixed_format_fn: (date: string) => string;

  detail_request_date_not_fixed_format_fn: (date: string) => string;
  register_steps_fn: (currentPage: number, allCount: number) => string;

  restore_timeout_fn: (minutes: number, seconds: number) => string;
  friend_list_id_prefix_fn: (id: string) => string;

  split_payment_detail_approved_modal_title_fn: (count: number) => string;
  split_payment_add_max_amount_error_fn: (count: number) => string;

  concierge_points_buy_bank_transfer_info_code_fn: (id: string) => string;
}
