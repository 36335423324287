import {CurrencyTypes} from '../../../user/models/responses/user-profile';
import {Strings} from '../../../../resources/strings';
import {TransferRequestStatus} from '../../../request/models/responses/transfer';
import {WithdrawalStatus} from '../../../standard-points/models/responses/withdrawal';
import {ExchangeStatus} from '../../../standard-points/models/responses/exchange';
import {ShortUser} from '../../../user/models/responses/short-user';

export interface Transaction {
  id: number;
  description?: string;
  type: TransactionType;
  createdAt: string;
  fromWallet?: Wallet;
  toWallet?: Wallet;
  amount: number;
  transferRequest?: TransactionTransfer;
  withdrawRequest?: TransactionWithdrawal;
  exchangeRequest?: TransactionExchange;
}

export interface Wallet {
  id: number;
  owner: ShortUser;
  currency: CurrencyTypes;
}

export interface TransactionTransfer {
  id: number;
  fromUserId: number;
  toUserId?: number;
  amount: number;
  status: TransferRequestStatus;
  createdAt: string;
  updatedAt: string;
}

export interface TransactionWithdrawal {
  id: number;
  userId: number;
  amount: number;
  status: WithdrawalStatus;
  declineReason?: string;
  createdAt: string;
  updatedAt: string;
}

export interface TransactionExchange {
  id: number;
  userId: number;
  amount: number;
  currency: CurrencyTypes;
  status: ExchangeStatus;
  declineReason?: string;
  createdAt: string;
  updatedAt: string;
}

export enum TransactionType {
  TRANSFER = 'TRANSFER',
  WITHDRAW = 'WITHDRAW',
  EXCHANGE = 'EXCHANGE',
  DEPOSIT = 'DEPOSIT',
}

export function getTransactionAmountSuffix(
  strings: Strings,
  transaction: Transaction,
): string {
  switch (transaction.type) {
    case TransactionType.TRANSFER:
      return 'CP';
    case TransactionType.WITHDRAW:
      return transaction.fromWallet?.currency || '';
    case TransactionType.EXCHANGE:
      return 'SP';
    case TransactionType.DEPOSIT:
      return transaction.toWallet?.currency || '';
  }
}

export function getTransactionType(
  strings: Strings,
  transaction: Transaction,
): string {
  switch (transaction.type) {
    case TransactionType.TRANSFER:
      return strings.transaction_type_transfer;
    case TransactionType.WITHDRAW:
      return strings.transaction_type_withdraw;
    case TransactionType.EXCHANGE:
      return strings.transaction_type_exchange;
    case TransactionType.DEPOSIT:
      return strings.transaction_type_deposit;
  }
}
