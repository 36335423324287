import styled from 'styled-components/native';
import {Spinner} from '@ui-kitten/components';

export const AppLoaderContainer = styled.View`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20px;
`;
export const AppLoaderSpinner = styled(Spinner).attrs(props => ({
  size: props.theme.isDesktop ? 'giant' : 'small',
}))`
  align-self: center;
  justify-self: center;
`;
