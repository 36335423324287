import React from 'react';
import styled, {useTheme} from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Calendar, Text} from '@ui-kitten/components';
import {PropsWithChildren} from 'react';
import {AppScrollView} from '../../../../uikit/AppScrollView';
import ArrowRight from '../../../../assets/icons/ArrowRight';
import {AppLoader} from '../../../../uikit/AppLoader';
import {AppError} from '../../../../uikit/AppError';
import AppRouteView from '../../../../uikit/AppRouteView';

const RequestsCalendarContainerDesktop = styled.View`
  flex: 1;
  align-items: flex-start;
  flex-direction: row-reverse;
  padding-right: 128px;
`;

export const RequestsCalendarContainer = ({children}: PropsWithChildren) => {
  const theme = useTheme();
  return theme.isDesktop ? (
    <RequestsCalendarContainerDesktop>
      {children}
    </RequestsCalendarContainerDesktop>
  ) : (
    <AppScrollView>{children}</AppScrollView>
  );
};

export const RequestsCalendar = styled(Calendar<Date>)`
  margin-bottom: 20px;
  background-color: ${props => props.theme.colors.white};

  ${props =>
    !props.theme.isDesktop &&
    css`
      width: 100%;
    `}
`;

export const RequestsCalendarDay = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
`;

export const RequestsCalendarDayText = styled(Text)``;

export const RequestsCalendarDayPin = styled.View<{
  selected: boolean;
  show: boolean;
}>`
  width: 8px;
  height: 8px;
  margin-top: 2px;
  border-radius: 4px;
  background: ${props =>
    props.show
      ? props.selected
        ? props.theme.colors.white
        : props.theme.colors['color-primary-500']
      : 'transparent'};
`;

const CommonRequestsCalendarInfo = css``;

const RequestsCalendarInfoMobile = styled.View`
  ${CommonRequestsCalendarInfo};
`;

const RequestsCalendarInfoDesktop = styled(AppScrollView)`
  ${CommonRequestsCalendarInfo};
  align-self: stretch;
  margin-right: 24px;
  margin-bottom: 48px;
`;

export const RequestsCalendarLoader = styled(AppLoader)`
  margin-top: 48px;
`;

export const RequestsCalendarError = styled(AppError)`
  margin-top: 48px;
`;

export const RequestsCalendarInfo = ({children}: PropsWithChildren) => {
  const theme = useTheme();

  return theme.isDesktop ? (
    <RequestsCalendarInfoDesktop>{children}</RequestsCalendarInfoDesktop>
  ) : (
    <RequestsCalendarInfoMobile>{children}</RequestsCalendarInfoMobile>
  );
};

export const RequestsCalendarInfoTitleContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;

export const RequestsCalendarInfoTitle = styled(Text)`
  font-weight: bold;
  font-size: 16px;
`;

export const RequestsCalendarInfoCounter = styled(Text)`
  margin-top: 2px;
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.theme.colors['color-primary-500']};
`;

export const RequestsCalendarInfoEmpty = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
`;

export const RequestsCalendarItem = styled(AppRouteView)<{last: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
  border-bottom-width: ${props => (props.last ? '0' : '1px')};
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;

export const RequestsCalendarItemInfo = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const RequestsCalendarItemInfoName = styled(Text)``;

export const RequestsCalendarItemInfoType = styled(Text)`
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const RequestsCalendarItemSelectIcon = styled(ArrowRight).attrs(
  props => ({
    fill: props.theme.colors['color-basic-500'],
  }),
)``;
