import {useMemo} from 'react';
import {SettingId} from '../api/settings/models/settingsResponse';
import {useRequestSelector} from '../store/store';

export default function useConvertRate(
  settingId: SettingId = SettingId.CP_TO_JPY_CONVERSION_RATE,
  defaultRate = 1,
): number {
  const settingsRequest = useRequestSelector(
    store => store.app.settingsRequest,
  );

  return useMemo<number>(() => {
    let convertRate: number;
    try {
      convertRate = Number(
        settingsRequest?.data?.find(({id}) => id === settingId)?.value,
      );
    } catch (e) {
      convertRate = defaultRate;
    }

    return convertRate;
  }, [defaultRate, settingId, settingsRequest?.data]);
}
