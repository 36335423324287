import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {QrNormalPayRequest} from '../../../api/transaction/models/requests/qr-normal-pay';
import {Transaction} from '../../../api/transaction/models/responses';
import {FriendRequest} from '../../../api/friend/models/requests/friend';
import {ListResponse} from '../../../api/models/response/list';
import {Friend} from '../../../api/friend/models/responses/friend';
import {CreateSplitPaymentRequest} from '../../../api/split-payments/models/requests/split-payment';
import {SplitPaymentResponse} from '../../../api/split-payments/models/responses/split-payment';

export const qrNormalPaymentRequestAction = createAsyncAction<
  QrNormalPayRequest,
  Transaction,
  Error
>('QR_NORMAL_PAYMENT_REQUEST');

export const qrSplitPaymentRequestAction = createAsyncAction<
  CreateSplitPaymentRequest,
  SplitPaymentResponse,
  Error
>('QR_SPLIT_PAYMENT_REQUEST');

export const qrSearchFriendListRequestAction = createAsyncAction<
  FriendRequest,
  [FriendRequest, ListResponse<Friend>],
  Error
>('QR_SEARCH_FRIEND_LIST_REQUEST');
