import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const Star = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox="0 0 40 40" fill="#DC9B2D" {...props}>
    <Path d="M20 30.433l-11.755 6.58L10.87 23.8.98 14.653l13.378-1.587L20 .833l5.643 12.233 13.379 1.587L29.13 23.8l2.625 13.213L20 30.433z" />
  </Svg>
);

export default Star;
