import {combineReducers} from 'redux';
import createRequestReducer, {
  addRequestCase,
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {
  createSplitPaymentRequestAction,
  getSplitPaymentDetailRequestAction,
  getSplitPaymentListRequestAction,
  rejectSplitPaymentRequestAction,
  acceptSplitPaymentRequestAction,
} from './action';
import {
  addPaginationRequestCase,
  PaginationRequest,
  PaginationRequestState,
} from '../../../utils/redux/create-pagination-request-reducer';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ListResponse} from '../../../api/models/response/list';
import {SplitPaymentResponse} from '../../../api/split-payments/models/responses/split-payment';
import {SplitPaymentDetailResponse} from '../../../api/split-payments/models/responses/detail';

export interface SplitPaymentList {
  items: SplitPaymentResponse[];
  totalCount: number;
  isLastPage: boolean;
}

export const splitPaymentsReducer = combineReducers<{
  splitPaymentListRequest: PaginationRequestState<SplitPaymentList> | null;
  splitPaymentRequest: RequestState<SplitPaymentDetailResponse> | null;
  splitPaymentAcceptRequest: RequestState<SplitPaymentDetailResponse> | null;
  splitPaymentRejectRequest: RequestState<SplitPaymentDetailResponse> | null;
  createSplitPaymentRequest: RequestState<SplitPaymentDetailResponse> | null;
}>({
  splitPaymentListRequest:
    createReducer<PaginationRequestState<SplitPaymentList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<SplitPaymentResponse>],
          SplitPaymentList
        >(builder, getSplitPaymentListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });

        builder.addCase(
          createSplitPaymentRequestAction.success,
          (
            state: PaginationRequestState<SplitPaymentList> | null,
            action: PayloadAction<SplitPaymentResponse>,
          ) => {
            if (!state || !state.data) return state;

            return {
              ...state,
              data: {
                ...state.data,
                items: [action.payload, ...state.data.items],
                totalCount: state.data.totalCount + 1,
              },
            };
          },
        );

        const updateSplitPayment = (
          state: PaginationRequestState<SplitPaymentList> | null,
          {
            payload: {friends, ...split},
          }: PayloadAction<SplitPaymentDetailResponse>,
        ) => {
          if (!state || !state.data) return state;

          const newItems = [...state.data.items];

          const splitPosition = newItems.findIndex(
            findSplit => findSplit.id === split.id,
          );

          if (splitPosition < 0) {
            return state;
          }

          newItems[splitPosition] = split;

          return {
            ...state,
            data: {
              ...state.data,
              items: newItems,
            },
          };
        };

        builder
          .addCase(acceptSplitPaymentRequestAction.success, updateSplitPayment)
          .addCase(rejectSplitPaymentRequestAction.success, updateSplitPayment);
      },
    ),
  splitPaymentRequest:
    createReducer<RequestState<SplitPaymentDetailResponse> | null>(
      null,
      builder => {
        addRequestCase(builder, getSplitPaymentDetailRequestAction);

        const updateSplitPayment = (
          state: RequestState<SplitPaymentDetailResponse> | null,
          action: PayloadAction<SplitPaymentDetailResponse>,
        ) => {
          if (!state || !state.data) return state;

          return {
            ...state,
            data: action.payload,
          };
        };

        builder
          .addCase(acceptSplitPaymentRequestAction.success, updateSplitPayment)
          .addCase(rejectSplitPaymentRequestAction.success, updateSplitPayment);
      },
    ),
  splitPaymentAcceptRequest: createRequestReducer(
    acceptSplitPaymentRequestAction,
  ),
  splitPaymentRejectRequest: createRequestReducer(
    rejectSplitPaymentRequestAction,
  ),
  createSplitPaymentRequest: createRequestReducer(
    createSplitPaymentRequestAction,
  ),
});
