import {all, call, put, takeLatest} from 'redux-saga/effects';
import api from '../../../api';
import {DefaultResponse} from '../../../api/models/response/default';
import {
  exchangePointsRequestAction,
  getExchangeListRequestAction,
  getWithdrawalListRequestAction,
  updateWithdrawalPointsRequestAction,
  withdrawalPointsRequestAction,
} from './action';
import {StandardPointsWithdrawalResponse} from '../../../api/standard-points/models/responses/withdrawal';
import {ListResponse} from '../../../api/models/response/list';
import {Exchange} from '../../../api/standard-points/models/responses/exchange';

export const WITHDRAWAL_LIST_LIMIT = 10;
export const EXCHANGE_LIST_LIMIT = 10;

const withdrawalPointsRequest = function* (
  action: ReturnType<typeof withdrawalPointsRequestAction.request>,
) {
  try {
    const response: DefaultResponse<StandardPointsWithdrawalResponse> =
      yield call([api, api.standardPoints.withdrawalPoints], action.payload);
    yield put(withdrawalPointsRequestAction.success(response.data));
  } catch (error) {
    yield put(withdrawalPointsRequestAction.failure(error as Error));
  }
};

const getWithdrawalListRequest = function* (
  action: ReturnType<typeof getWithdrawalListRequestAction.request>,
) {
  try {
    const response: ListResponse<StandardPointsWithdrawalResponse> = yield call(
      [api, api.standardPoints.getWithdrawalList],
      action.payload,
    );
    yield put(
      getWithdrawalListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getWithdrawalListRequestAction.failure(error as Error));
  }
};

const updateWithdrawalPointsRequest = function* (
  action: ReturnType<typeof updateWithdrawalPointsRequestAction.request>,
) {
  try {
    const response: DefaultResponse<StandardPointsWithdrawalResponse> =
      yield call(
        [api, api.standardPoints.updateWithdrawalPoints],
        action.payload,
      );
    yield put(updateWithdrawalPointsRequestAction.success(response.data));
  } catch (error) {
    yield put(updateWithdrawalPointsRequestAction.failure(error as Error));
  }
};

const exchangePointsRequest = function* (
  action: ReturnType<typeof exchangePointsRequestAction.request>,
) {
  try {
    const response: DefaultResponse<Exchange> = yield call(
      [api, api.standardPoints.exchangePoints],
      action.payload,
    );
    yield put(exchangePointsRequestAction.success(response.data));
  } catch (error) {
    yield put(exchangePointsRequestAction.failure(error as Error));
  }
};

const getExchangeListRequest = function* (
  action: ReturnType<typeof getExchangeListRequestAction.request>,
) {
  try {
    const response: ListResponse<Exchange> = yield call(
      [api, api.standardPoints.getExchangeList],
      action.payload,
    );
    yield put(getExchangeListRequestAction.success([action.payload, response]));
  } catch (error) {
    yield put(getExchangeListRequestAction.failure(error as Error));
  }
};

export const standardPointsSaga = function* () {
  yield all([
    takeLatest(withdrawalPointsRequestAction.request, withdrawalPointsRequest),
    takeLatest(
      getWithdrawalListRequestAction.request,
      getWithdrawalListRequest,
    ),
    takeLatest(
      updateWithdrawalPointsRequestAction.request,
      updateWithdrawalPointsRequest,
    ),
    takeLatest(exchangePointsRequestAction.request, exchangePointsRequest),
    takeLatest(getExchangeListRequestAction.request, getExchangeListRequest),
  ]);
};
