import React from 'react';
import styled from 'styled-components/native';
import {FAB} from './FAB';
import Call from '../assets/icons/Call';
import {onCallConcierge} from '../utils/on-call-concierge';

const CallFABIcon = styled(Call)``;

export default function CallFAB() {
  return (
    <FAB onClick={onCallConcierge}>
      <CallFABIcon />
    </FAB>
  );
}
