import React from 'react';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import {BottomTabBarProps, BottomTabView} from '@react-navigation/bottom-tabs';
import {useTheme} from 'styled-components/native';
import {MainNavigatorBottomNavigation, MainNavigatorContainer} from './styles';
import {
  BottomTabNavigationConfig,
  BottomTabNavigationEventMap,
  BottomTabNavigationOptions,
} from '@react-navigation/bottom-tabs/src/types';
import MainNavigationDesktop from '../navigation-desktop';
import MainHeader from '../header';
import {getActiveRouteState} from 'scl/src/react-navigation/get-active-route-state';
import {DefaultTheme} from 'styled-components';

function MainNavigatorComponent({
  id,
  initialRouteName,
  backBehavior,
  children,
  screenListeners,
  screenOptions,
  sceneContainerStyle,
  ...rest
}: DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  BottomTabNavigationOptions,
  BottomTabNavigationEventMap
> &
  TabRouterOptions &
  BottomTabNavigationConfig) {
  const theme = useTheme();

  const {state, descriptors, navigation, NavigationContent} =
    useNavigationBuilder<
      TabNavigationState<ParamListBase>,
      TabRouterOptions,
      TabActionHelpers<ParamListBase>,
      BottomTabNavigationOptions,
      BottomTabNavigationEventMap
    >(TabRouter, {
      id,
      initialRouteName,
      backBehavior,
      children,
      screenListeners,
      screenOptions: {...screenOptions, headerShown: false},
    });

  return (
    <NavigationContent>
      {theme.isDesktop && <MainHeader />}
      <MainNavigatorContainer>
        {theme.isDesktop && <MainNavigationDesktop state={state} />}
        <BottomTabView
          {...rest}
          state={state}
          navigation={navigation}
          descriptors={descriptors}
          sceneContainerStyle={sceneContainerStyle}
          tabBar={props => <BottomTabBar {...props} />}
        />
      </MainNavigatorContainer>
    </NavigationContent>
  );
}

const BottomTabBar = ({
  navigation,
  state,
  descriptors,
  insets,
}: BottomTabBarProps) => {
  const theme = useTheme();

  const isHide = isHideBar(theme, state);

  return (
    <MainNavigatorBottomNavigation
      style={{height: isHide ? 0 : undefined}}
      state={state}
      navigation={navigation}
      descriptors={descriptors}
      insets={insets}
    />
  );
};

export function isHideBar(theme: DefaultTheme, state): boolean {
  if (theme.isDesktop) return true;

  // TODO: Need change visible bottom bar, after change flow
  return false;

  const activeRouteState = getActiveRouteState(state);

  return (
    activeRouteState.name === 'DetailRequest' ||
    activeRouteState.name === 'NewRequest' ||
    activeRouteState.name === 'QrPaymentReader' ||
    activeRouteState.name === 'QrPayment' ||
    activeRouteState.name === 'ConciergePointsBuyByVirtualpay' ||
    activeRouteState.name === 'ConciergePointsBuyByBTC' ||
    activeRouteState.name === 'ConciergePointsBuySuccess'
  );
}

const createMainNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  BottomTabNavigationOptions,
  BottomTabNavigationEventMap,
  typeof MainNavigatorComponent
>(MainNavigatorComponent);

export default createMainNavigator;
