import {all, select} from 'redux-saga/effects';
import {settingsSaga} from '../features/app/store/saga';
import {loginSaga} from '../features/login/store/saga';
import {restoreConfirmSaga} from '../features/restore-confirm/store/saga';
import {userSaga} from '../features/user/store/saga';
import {newRequestSaga} from '../features/new-request/store/saga';
import {requestsSaga} from '../features/requests/store/saga';
import {RootState} from './store';
import {conciergePointsSaga} from '../features/concierge-points/store/saga';
import {standardPointsSaga} from '../features/standard-points/store/saga';
import {transactionSaga} from '../features/transaction-list/store/saga';
import {qrPaymentSaga} from '../features/qr-payment/store/saga';
import {registerSaga} from '../features/register/store/saga';
import {restoreSaga} from '../features/restore/store/saga';
import {friendListSaga} from '../features/friend-list/store/saga';
import {splitPaymentSaga} from '../features/split-payment-list/store/saga';
import {shopSaga} from '../features/shops/store/saga';
import {infoPostsSaga} from '../features/info/store/saga';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    userSaga(),
    newRequestSaga(),
    requestsSaga(),
    conciergePointsSaga(),
    standardPointsSaga(),
    transactionSaga(),
    qrPaymentSaga(),
    registerSaga(),
    restoreSaga(),
    restoreConfirmSaga(),
    friendListSaga(),
    splitPaymentSaga(),
    settingsSaga(),
    shopSaga(),
    infoPostsSaga(),
  ]);
}

export function* appSelect(selector: (state: RootState) => any) {
  return yield select(selector);
}
