import React, {useEffect} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {
  AddReviewButton,
  AddReviewBuyKeyboardAvoidingView,
  AddReviewFormContainer,
  AddReviewInputError,
  AddReviewInputWrapper,
  AddReviewSymbolsCounter,
  AddReviewSymbolsCounterWrapper,
  AddReviewTextField,
  AddReviewTextarea,
  AppTransparentHeader,
  ShopReviewsViewedContainerContent,
  ShopStarButton,
  ShopStarContainer,
  ShopStarIcon,
  ShopStarOutlineIcon,
} from './styles';
import {Route, useRoute} from '@react-navigation/native';
import {useStrings} from '../../../utils/providers/strings';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import AppStatusBar from '../../../uikit/AppStatusBar';
import {AppLayout} from '../../../uikit/AppLayout';
import {ShopsContainerContainer} from '../styles';
import {createShopReviewRequestAction} from '../store/action';
import {Controller, useForm} from 'react-hook-form';
import {AddReviewForm, addReviewInitialFormValues} from './lib/consts';
import {CreateReviewRequest} from '../../../api/shops';

const TEXT_CHARACTER_LIMIT = 480;

export default function AddReviewShopScreen() {
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  const createShopReviewRequest = useRequestSelector(
    store => store.shops.createShopReviewRequest,
  );

  const {control, handleSubmit, setValue} = useForm({
    shouldFocusError: true,
    defaultValues: addReviewInitialFormValues,
  });

  useEffect(() => {
    return function () {
      dispatch(createShopReviewRequestAction.clean());
      setValue('rate', 0);
      setValue('description', '');
    };
  }, []);

  useEffect(() => {
    if (createShopReviewRequest.error) {
      toast.show(createShopReviewRequest.error?.message);
    }
  }, [createShopReviewRequest]);

  useEffect(() => {
    if (createShopReviewRequest.data) {
      setValue('rate', 0);
      setValue('description', '');
      toast.show(strings.shop_detail_add_new_review_toast_message_success, {
        type: 'success',
        duration: 6000,
      });
    }
  }, [createShopReviewRequest]);

  const onSubmit = async (data: AddReviewForm) => {
    const request: CreateReviewRequest = {
      shopId: Number(requestId),
      rate: data.rate,
      description: data.description,
    };
    dispatch(createShopReviewRequestAction.request(request));
  };

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopReviewsViewedContainerContent>
          <AppLayout>
            <AppTransparentHeader
              title={strings.shop_detail_add_new_review_title}
              backNoHistoryRoute={`/shops/${requestId}/reviews`}
            />
            <AddReviewBuyKeyboardAvoidingView>
              <AddReviewFormContainer>
                <Controller
                  control={control}
                  name="rate"
                  rules={{
                    validate: value =>
                      value > 0 ||
                      strings.shop_detail_add_new_review_raiting_field,
                  }}
                  render={({field, fieldState: {error}}) => (
                    <AddReviewInputWrapper>
                      <AddReviewTextField>
                        {strings.shop_detail_add_new_review_raiting_field}
                      </AddReviewTextField>

                      <ShopStarContainer>
                        {[...Array(5)].map((star, index) => {
                          return (
                            <ShopStarButton
                              key={index}
                              onPress={() =>
                                setValue('rate', index + 1, {
                                  shouldValidate: true,
                                })
                              }>
                              {field.value >= index + 1 ? (
                                <ShopStarIcon />
                              ) : (
                                <ShopStarOutlineIcon />
                              )}
                            </ShopStarButton>
                          );
                        })}
                      </ShopStarContainer>
                      <AddReviewInputError>
                        {error?.message}
                      </AddReviewInputError>
                    </AddReviewInputWrapper>
                  )}
                />

                <Controller
                  control={control}
                  name="description"
                  rules={{required: strings.contact_us_form_input_required}}
                  render={({
                    field: {onChange, ...field},
                    fieldState: {error},
                  }) => (
                    <AddReviewInputWrapper isLastItem>
                      <AddReviewTextField>
                        {strings.shop_detail_add_new_review_comment_field}
                      </AddReviewTextField>
                      <AddReviewTextarea
                        {...field}
                        onChangeText={onChange}
                        multiline
                        placeholder={
                          strings.shop_detail_add_new_review_comment_placeholder
                        }
                        maxLength={TEXT_CHARACTER_LIMIT}
                      />
                      <AddReviewSymbolsCounterWrapper>
                        <AddReviewSymbolsCounter
                          limit={Boolean(
                            field.value.length >= TEXT_CHARACTER_LIMIT,
                          )}>
                          {field.value.length}/{TEXT_CHARACTER_LIMIT}
                        </AddReviewSymbolsCounter>
                        <AddReviewInputError>
                          {error?.message}
                        </AddReviewInputError>
                      </AddReviewSymbolsCounterWrapper>
                    </AddReviewInputWrapper>
                  )}
                />
              </AddReviewFormContainer>
              <AddReviewButton onPress={handleSubmit(onSubmit)}>
                {strings.shop_detail_add_new_review_confirm_button.toUpperCase()}
              </AddReviewButton>
            </AddReviewBuyKeyboardAvoidingView>
          </AppLayout>
        </ShopReviewsViewedContainerContent>
      </ShopsContainerContainer>
    </>
  );
}
