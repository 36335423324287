import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import Mic from '../../../../../../../../assets/icons/Mic';
import StopRecord from '../../../../../../../../assets/icons/StopRecord';

export const DetailRequestRecordAudioButton = styled.TouchableOpacity`
  margin-right: ${props => props.theme.dimens.smallSpacer};
  margin-top: 3px;
  padding: ${props => props.theme.dimens.smallestSpacer};
`;

export const DetailRequestChatMicIcon = styled(Mic).attrs(props => ({
  stroke: props.theme.colors['color-basic-500'],
}))``;

export const DetailRequestStopAudioButton = styled.TouchableOpacity`
  margin-right: ${props => props.theme.dimens.smallSpacer};
  margin-top: 3px;
  padding: ${props => props.theme.dimens.smallestSpacer};
  border-radius: 3px;
  background-color: #dc2626;
`;

export const DetailRequestChatStopIcon = styled(StopRecord).attrs(props => ({
  stroke: props.theme.colors.white,
}))``;
