import React, {useCallback, useEffect, useState} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../../uikit/ScreenLayout';
import {useTheme} from 'styled-components/native';
import AppHeader from '../../../../uikit/AppHeader';
import {useStrings} from '../../../../utils/providers/strings';
import {useForm} from 'react-hook-form';
import {useAppDispatch, useRequestSelector} from '../../../../store/store';
import {buyPointsRequestAction} from '../../store/action';
import {CurrencyTypes} from '../../../../api/user/models/responses/user-profile';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import useRouter from '../../../../utils/use-router';
import useFieldServerError from '../../../../utils/react-hook-form/use-field-server-error';
import {BackHandler} from 'react-native';
import ConciergePointsBuyVirtualpayInputs from '../components/inputs-virtualpay';
import {ConciergePointsBuyFieldSingleSelectionState} from '../components/inputs-common/form';
import ConciergePointsPaymentVirtualpay from '../components/payment-virtualpay';

export interface ConciergePointsVirtualpayBuyForm {
  amount: string;
  countryCode: ConciergePointsBuyFieldSingleSelectionState;
  city: string;
  postalCode: string;
  email: string;
  firstName: string;
  lastName: string;
}

export default function ConciergePointsBuyByVirtualpayScreen() {
  const theme = useTheme();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const profileRequest = useRequestSelector(
    store => store.user.getUserProfileRequest,
  );

  const buyRequest = useRequestSelector(
    store => store.conciergePoints.buyPointsRequest,
  );

  const [screenType, setScreenType] = useState(
    ConciergePointsBuyScreenType.INPUTS,
  );

  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    setValue,
    watch,
  } = useForm<ConciergePointsVirtualpayBuyForm>({
    defaultValues: {
      amount: '',
      countryCode: undefined,
      city: '',
      postalCode: '',
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  useEffect(() => {
    return function () {
      dispatch(buyPointsRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (profileRequest.data) {
      setValue('firstName', profileRequest.data.user.firstName);
      setValue('lastName', profileRequest.data.user.lastName);
      setValue('email', profileRequest.data.user.email);
      setValue('postalCode', profileRequest.data.user.postCode);
      if (profileRequest.data.user.country) {
        const findCountry = strings.countryData.find(
          country => country.value === profileRequest.data?.user.country,
        );
        if (findCountry) {
          setValue('countryCode', {
            id: findCountry.value,
            value: findCountry.label,
          });
        }
      }
      setValue('city', profileRequest.data.user.city);
    }
  }, [profileRequest.data, setValue, strings.countryData]);

  useEffect(() => {
    if (buyRequest.data) {
      setScreenType(ConciergePointsBuyScreenType.PAYMENT);
    }
  }, [buyRequest.data, router]);

  useFieldServerError(buyRequest, setError);

  const onBack = useCallback((): boolean => {
    switch (screenType) {
      case ConciergePointsBuyScreenType.INPUTS:
        router.back('/cp');
        return true;
      case ConciergePointsBuyScreenType.PAYMENT:
        dispatch(buyPointsRequestAction.clean());
        setScreenType(ConciergePointsBuyScreenType.INPUTS);
        return true;
    }

    // router.back('/cp');
    // return true;
  }, [dispatch, router, screenType]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBack,
    );

    return () => backHandler.remove();
  }, [onBack]);

  const onBuy = (form: ConciergePointsVirtualpayBuyForm) => {
    dispatch(
      buyPointsRequestAction.request({
        amount: Number(form.amount),
        currency: CurrencyTypes.CP,
        countryCode: form.countryCode?.id ?? '',
        city: form.city,
        postalCode: form.postalCode,
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
      }),
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.concierge_points_buy_credit_card_title}
              onBackPressed={onBack}
            />
            {(() => {
              switch (screenType) {
                case ConciergePointsBuyScreenType.INPUTS:
                  return (
                    <ConciergePointsBuyVirtualpayInputs
                      isLoading={buyRequest.isLoading}
                      control={control}
                      errors={errors}
                      onBuy={handleSubmit(onBuy)}
                      setFieldValue={setValue}
                      setError={setError}
                      watch={watch}
                    />
                  );
                case ConciergePointsBuyScreenType.PAYMENT:
                  if (!buyRequest.data) return null;
                  return (
                    <ConciergePointsPaymentVirtualpay
                      html={buyRequest.data.html}
                      redirectUrl={buyRequest.data.redirectUrl}
                    />
                  );
              }
            })()}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}

enum ConciergePointsBuyScreenType {
  INPUTS,
  PAYMENT,
}
