import * as React from 'react';
import Svg, {SvgProps, Rect, G, Path, Defs, ClipPath} from 'react-native-svg';

const ChatAvatarCommon = (props: SvgProps) => (
  <Svg width={32} height={32} viewBox="0 0 32 32" fill="#AEA69E" {...props}>
    <Rect width={32} height={32} rx={16} fillOpacity={0.2} />
    <G clipPath="url(#a)">
      <Path d="M23.5 12.667a1.667 1.667 0 0 1 1.666 1.667v3.333a1.667 1.667 0 0 1-1.666 1.667h-.885A6.668 6.668 0 0 1 16 25.167V23.5a5 5 0 0 0 5-5v-5a5 5 0 0 0-10 0v5.834H8.5a1.667 1.667 0 0 1-1.667-1.667v-3.333A1.666 1.666 0 0 1 8.5 12.667h.885a6.667 6.667 0 0 1 13.23 0h.885Zm-11.034 6.487.884-1.413c.794.498 1.713.76 2.65.76a4.976 4.976 0 0 0 2.65-.76l.883 1.413A6.635 6.635 0 0 1 16 20.167a6.636 6.636 0 0 1-3.534-1.013Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(6 6)" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ChatAvatarCommon;
