import React, {useEffect} from 'react';
import {ConciergePointsBuyByBankTransferResponse} from '../../../../../../api/concierge-points/models/response/buy';
import {
  ConciergePointsBuyByBankTransferPaymentContainer,
  ConciergePointsBuyByBankTransferPaymentHeader,
  ConciergePointsBuyByBankTransferPaymentInfo,
  ConciergePointsBuyByBankTransferPaymentInfoCode,
  ConciergePointsBuyByBankTransferPaymentTitle,
} from './styles';
import {useStrings} from '../../../../../../utils/providers/strings';
import {
  useAppDispatch,
  useRequestSelector,
} from '../../../../../../store/store';
import {useTheme} from 'styled-components/native';
import {userRequestAction} from '../../../../../user/store/action';

export default function ConciergePointsBuyByBankTransferPayment({
  transfer,
}: {
  transfer: ConciergePointsBuyByBankTransferResponse;
}) {
  const strings = useStrings();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userRequest = useRequestSelector(store => store.user.userRequest);

  const fetchUserInfo = () => dispatch(userRequestAction.request());

  useEffect(() => {
    if (
      !theme.isDesktop &&
      !userRequest.data &&
      !userRequest.error &&
      !userRequest.isLoading
    ) {
      fetchUserInfo();
    }
  }, [theme.isDesktop, userRequest]);

  return (
    <ConciergePointsBuyByBankTransferPaymentContainer>
      <ConciergePointsBuyByBankTransferPaymentHeader>
        {strings.concierge_points_buy_bank_transfer_payment_title}
      </ConciergePointsBuyByBankTransferPaymentHeader>

      {userRequest.data && (
        <ConciergePointsBuyByBankTransferPaymentInfo>
          {strings.concierge_points_buy_bank_transfer_info_prefix}
          <ConciergePointsBuyByBankTransferPaymentInfoCode>
            &nbsp;
            {strings.concierge_points_buy_bank_transfer_info_code_fn(
              userRequest.data.clientId,
            )}
          </ConciergePointsBuyByBankTransferPaymentInfoCode>
          {strings.concierge_points_buy_bank_transfer_info_postfix}
        </ConciergePointsBuyByBankTransferPaymentInfo>
      )}

      <ConciergePointsBuyByBankTransferPaymentTitle>
        {strings.concierge_points_buy_bank_transfer_payment_requisites_title}
      </ConciergePointsBuyByBankTransferPaymentTitle>
      <ConciergePointsBuyByBankTransferPaymentInfo>
        {transfer.requisites}
      </ConciergePointsBuyByBankTransferPaymentInfo>
      <ConciergePointsBuyByBankTransferPaymentTitle>
        {strings.concierge_points_buy_bank_transfer_payment_pay_amount_title}
      </ConciergePointsBuyByBankTransferPaymentTitle>
      <ConciergePointsBuyByBankTransferPaymentInfo>
        {strings.app_number(transfer.amount)} {transfer.currency}
      </ConciergePointsBuyByBankTransferPaymentInfo>
    </ConciergePointsBuyByBankTransferPaymentContainer>
  );
}
