import {Text} from 'react-native';
import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import EmptyListScreen from '../../../assets/icons/EmptyListScreen';

export const ScreenListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const ScreenListEmptyIcon = styled(EmptyListScreen)``;

export const ScreenListEmptyTitle = styled(Text)`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ScreenListEmptyInfo = styled(Text)``;
