import React from 'react';
import {
  AppModalCard,
  AppModal as AppModalComponent,
  AppModalProps,
} from './styles';
import {useTheme} from 'styled-components/native';
import recursiveAddChildProps from 'scl/src/react/recursive-add-child-props';

export default function AppModal({children, visible, ...props}: AppModalProps) {
  const theme = useTheme();
  return (
    <AppModalComponent {...props} visible={visible}>
      <AppModalCard theme={theme}>
        {visible &&
          recursiveAddChildProps(children, {
            theme,
          })}
      </AppModalCard>
    </AppModalComponent>
  );
}
