import React from 'react';
import {
  RequestCategoryType,
  RequestCategoryTypeField,
  RequestCategoryTypeFieldType,
  RequestCategoryTypeScreen,
} from '../../../../api/request/models/responses/category-type';
import {ListRenderItemInfo} from 'react-native';
import {
  NewRequestConfirmButtons,
  NewRequestConfirmButton,
  NewRequestConfirmContainer,
  NewRequestConfirmHeader,
  NewRequestConfirmInputsContainer,
  NewRequestConfirmList,
  NewRequestConfirmInputTitle,
  NewRequestConfirmTitleInput,
  NewRequestConfirmCommentInput,
  NewRequestConfirmInputErrorText,
  NewRequestConfirmFieldItemContainer,
  NewRequestConfirmFieldItemInfoContainer,
  NewRequestConfirmFieldItemTitle,
  NewRequestConfirmFieldItemAnswer,
  NewRequestConfirmFieldItemIcon,
  NewRequestConfirmImagePreviewContainer,
  NewRequestConfirmImagePreview,
} from './styles';
import {useStrings} from '../../../../utils/providers/strings';
import {useRequestSelector} from '../../../../store/store';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  getNewRequestQuestionAnswer,
  NewRequestQuestionFieldAttachmentState,
  NewRequestQuestionForm,
} from '../question/form';

export interface NewRequestConfirmForm {
  title: string;
  comment: string;
}

export interface NewRequestQuestionItem {
  screenPosition: number;
  screen: RequestCategoryTypeScreen;
  field: RequestCategoryTypeField;
  answer: NewRequestQuestionForm;
}

export default function NewRequestConfirm({
  type,
  onConfirm,
  answers,
  setSelectedField,
  control,
  formState,
}: {
  type: RequestCategoryType;
  onConfirm: () => void;
  answers: NewRequestQuestionForm[];
  setSelectedField: (field: NewRequestQuestionItem) => void;
  control: Control<NewRequestConfirmForm>;
  formState: FormState<NewRequestConfirmForm>;
}) {
  const strings = useStrings();
  const createRequest = useRequestSelector(
    store => store.newRequest.createRequestRequest,
  );

  function renderNewRequestQuestionItem({
    item,
  }: ListRenderItemInfo<NewRequestQuestionItem>) {
    return (
      <NewRequestConfirmFieldItemContainer
        onPress={() => setSelectedField(item)}>
        <NewRequestConfirmFieldItemInfoContainer>
          <NewRequestConfirmFieldItemTitle>
            {item.field.title}
          </NewRequestConfirmFieldItemTitle>
          {item.field.type === RequestCategoryTypeFieldType.ATTACHMENT_IMAGE ? (
            <NewRequestConfirmImagePreviewContainer>
              {(
                item.answer[
                  item.field.id
                ] as NewRequestQuestionFieldAttachmentState
              )
                .filter(field => field.asset !== null)
                .map(field => (
                  <NewRequestConfirmImagePreview
                    key={field.id}
                    source={{uri: field.asset?.uri}}
                  />
                ))}
            </NewRequestConfirmImagePreviewContainer>
          ) : (
            <NewRequestConfirmFieldItemAnswer>
              {getNewRequestQuestionAnswer(strings, item.field, item.answer)}
            </NewRequestConfirmFieldItemAnswer>
          )}
        </NewRequestConfirmFieldItemInfoContainer>
        <NewRequestConfirmFieldItemIcon />
      </NewRequestConfirmFieldItemContainer>
    );
  }

  return (
    <NewRequestConfirmContainer>
      <NewRequestConfirmInputsContainer>
        <NewRequestConfirmHeader>
          {strings.new_request_question_title_fn(
            type.screens.length + 1,
            type.screens.length + 1,
          )}
        </NewRequestConfirmHeader>
        <NewRequestConfirmInputTitle>
          {strings.new_request_confirm_title_input}
        </NewRequestConfirmInputTitle>
        <Controller
          control={control}
          rules={{
            required: strings.new_request_confirm_title_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
            return (
              <NewRequestConfirmTitleInput
                status={error ? 'danger' : 'basic'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                placeholder={strings.new_request_confirm_title_placeholder}
              />
            );
          }}
          name="title"
        />
        {formState.errors.title && (
          <NewRequestConfirmInputErrorText>
            {formState.errors.title.message}
          </NewRequestConfirmInputErrorText>
        )}
        <NewRequestConfirmInputTitle>
          {strings.new_request_confirm_comment_input}
        </NewRequestConfirmInputTitle>
        <Controller
          control={control}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
            return (
              <NewRequestConfirmCommentInput
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                placeholder={strings.new_request_confirm_comment_placeholder}
              />
            );
          }}
          name="comment"
        />
        <NewRequestConfirmInputTitle>
          {strings.new_request_confirm_fields_title}
        </NewRequestConfirmInputTitle>
        <NewRequestConfirmList
          data={type.screens.flatMap((screen, screenPosition) =>
            screen.fields.map(field => ({
              screen,
              field,
              screenPosition: screenPosition,
              answer: answers[screenPosition],
            })),
          )}
          renderItem={renderNewRequestQuestionItem}
          keyExtractor={item => item.field.id}
        />
      </NewRequestConfirmInputsContainer>
      <NewRequestConfirmButtons>
        <NewRequestConfirmButton
          onPress={onConfirm}
          disabled={createRequest.isLoading}>
          {strings.new_request_confirm_button.toUpperCase()}
        </NewRequestConfirmButton>
      </NewRequestConfirmButtons>
    </NewRequestConfirmContainer>
  );
}
