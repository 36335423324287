import React, {useCallback, useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../uikit/Points/container';
import {useTheme} from 'styled-components/native';
import {
  useAppDispatch,
  useAppSelector,
  usePaginationRequestSelector,
} from '../../../store/store';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import {
  WithdrawalListEmptyContainer,
  WithdrawalListEmptyCreateNewLink,
  WithdrawalListEmptyIcon,
  WithdrawalListEmptyInfo,
  WithdrawalList,
  WithdrawalListLoading,
  WithdrawalListItemContainer,
  WithdrawalListItemInfoTitle,
  WithdrawalListItemInfoStatus,
  WithdrawalListLoadingContainer,
  WithdrawalListItemCancelButton,
  WithdrawalListItemInfoContainer,
  WithdrawalListItemInfoText,
  WithdrawalListItemControlsContainer,
  WithdrawalListItemInfoPrice,
  WithdrawalListItemInfoBlock,
  WithdrawalListItemInfoReason,
  WithdrawalListItemControlsInfoContainer,
  WithdrawalListItemInfoReasonTitle,
  WithdrawalListItemInfoReasonText,
  WithdrawalListItemInfoSign,
} from './styles';
import {ListRenderItemInfo} from 'react-native';
import {useStrings} from '../../../utils/providers/strings';
import {useToast} from 'react-native-toast-notifications';
import AppRefreshControl from '../../../uikit/AppRefreshControl';
import {AppWhiteStatusBar} from '../../../uikit/AppStatusBar';
import {
  getWithdrawalListRequestAction,
  updateWithdrawalPointsRequestAction,
} from '../store/action';
import {WITHDRAWAL_LIST_LIMIT} from '../store/saga';
import {
  getWithdrawalStatusText,
  getWithdrawalStatusType,
  StandardPointsWithdrawalResponse,
  WithdrawalStatus,
} from '../../../api/standard-points/models/responses/withdrawal';
import AppHeader from '../../../uikit/AppHeader';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {useFocusEffect} from '@react-navigation/native';

export default function WithdrawalListScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const withdrawalListRequest = usePaginationRequestSelector(
    store => store.standardPoints.withdrawalListRequest,
  );
  const updateWithdrawalRequest = useAppSelector(
    store => store.standardPoints.updateWithdrawalPointsRequest,
  );

  const isEmptyList = !withdrawalListRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getWithdrawalListRequestAction.clean());
      dispatch(updateWithdrawalPointsRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      withdrawalListRequest.error &&
      withdrawalListRequest.data?.items.length
    ) {
      toast.show(withdrawalListRequest.error.message);
    }
  }, [withdrawalListRequest, toast]);

  const onFirstPage = useCallback(() => {
    dispatch(
      getWithdrawalListRequestAction.request({
        limit: WITHDRAWAL_LIST_LIMIT,
        offset: 0,
      }),
    );
  }, [dispatch]);

  useFocusEffect(onFirstPage);

  const onNextPage = () => {
    if (
      withdrawalListRequest.data?.isLastPage ||
      withdrawalListRequest.isLoading
    )
      return;

    dispatch(
      getWithdrawalListRequestAction.request({
        limit: WITHDRAWAL_LIST_LIMIT,
        offset: withdrawalListRequest.data?.items.length || 0,
      }),
    );
  };

  useEffect(() => {
    if (updateWithdrawalRequest?.error && !updateWithdrawalRequest?.data) {
      toast.show(updateWithdrawalRequest?.error.message);
    }
    if (updateWithdrawalRequest?.data) {
      onFirstPage();
    }
  }, [updateWithdrawalRequest, toast, onFirstPage]);

  const onDeclineRequest = (id: string) => {
    dispatch(
      updateWithdrawalPointsRequestAction.request({
        id,
        status: WithdrawalStatus.DECLINED,
      }),
    );
  };

  const renderWithdrawalItem = ({
    item,
  }: ListRenderItemInfo<StandardPointsWithdrawalResponse>) => {
    return (
      <WithdrawalListItemContainer>
        <WithdrawalListItemInfoBlock>
          <WithdrawalListItemInfoContainer>
            <WithdrawalListItemInfoTitle>
              №{item.id}
            </WithdrawalListItemInfoTitle>
            <WithdrawalListItemInfoText>
              {strings.app_date_time_string(item.createdAt)}
            </WithdrawalListItemInfoText>
          </WithdrawalListItemInfoContainer>
          <WithdrawalListItemControlsContainer>
            <WithdrawalListItemControlsInfoContainer>
              <WithdrawalListItemInfoStatus
                status={getWithdrawalStatusType(item.status)}>
                {getWithdrawalStatusText(strings, item.status)}
              </WithdrawalListItemInfoStatus>
              <WithdrawalListItemInfoPrice>
                {strings.app_number(item.amount)}{' '}
                <WithdrawalListItemInfoSign>
                  {strings.standard_points_withdrawal_list_sp}
                </WithdrawalListItemInfoSign>
              </WithdrawalListItemInfoPrice>
            </WithdrawalListItemControlsInfoContainer>

            {item.status === WithdrawalStatus.PENDING && (
              <WithdrawalListItemCancelButton
                size={theme.isDesktop ? 'medium' : 'small'}
                onPress={() => onDeclineRequest(String(item.id))}
                disabled={updateWithdrawalRequest?.isLoading}>
                {strings.standard_points_withdrawal_cancel_button}
              </WithdrawalListItemCancelButton>
            )}
          </WithdrawalListItemControlsContainer>
        </WithdrawalListItemInfoBlock>

        {item.declineReason ? (
          <WithdrawalListItemInfoReason>
            <WithdrawalListItemInfoReasonTitle>
              {strings.standard_points_withdrawal_decline_reason}
              {': '}
              <WithdrawalListItemInfoReasonText>
                {item.declineReason}
              </WithdrawalListItemInfoReasonText>
            </WithdrawalListItemInfoReasonTitle>
          </WithdrawalListItemInfoReason>
        ) : null}
      </WithdrawalListItemContainer>
    );
  };

  const renderWithdrawalListLoading = () => {
    return (
      <WithdrawalListLoadingContainer>
        <WithdrawalListLoading />
      </WithdrawalListLoadingContainer>
    );
  };

  const renderWithdrawalListEmpty = () => {
    return (
      <WithdrawalListEmptyContainer>
        <WithdrawalListEmptyIcon />
        <WithdrawalListEmptyInfo>
          {strings.standard_points_withdrawal_list_empty_title}
        </WithdrawalListEmptyInfo>
        <WithdrawalListEmptyCreateNewLink to="/withdrawal">
          {strings.standard_points_withdrawal_list_empty_new_button.toUpperCase()}
        </WithdrawalListEmptyCreateNewLink>
      </WithdrawalListEmptyContainer>
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.standard_points_withdrawal_list_title}
              backNoHistoryRoute="/"
            />
            {isEmptyList && withdrawalListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && withdrawalListRequest.error ? (
              <AppError
                error={withdrawalListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              withdrawalListRequest.data && (
                <WithdrawalList
                  refreshControl={
                    <AppRefreshControl
                      onRefresh={onFirstPage}
                      refreshing={withdrawalListRequest.isFirstLoading}
                    />
                  }
                  data={withdrawalListRequest.data.items}
                  renderItem={renderWithdrawalItem}
                  keyExtractor={item => String(item.id)}
                  onEndReached={onNextPage}
                  ListFooterComponent={
                    withdrawalListRequest.isLoading
                      ? renderWithdrawalListLoading
                      : null
                  }
                  ListEmptyComponent={renderWithdrawalListEmpty}
                />
              )
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
