import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const Friends = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      d="M16 14.667a6.667 6.667 0 016.667 6.666v8H9.333v-8A6.667 6.667 0 0116 14.667zm-8.95 4.008a9.31 9.31 0 00-.373 2.2l-.01.458v8h-4v-6a4.667 4.667 0 014.158-4.64l.226-.018zm17.9 0a4.668 4.668 0 014.383 4.658v6h-4v-8c0-.924-.133-1.816-.384-2.658zM7.332 10.667a3.333 3.333 0 110 6.667 3.333 3.333 0 010-6.667zm17.334 0a3.333 3.333 0 110 6.666 3.333 3.333 0 010-6.666zm-8.667-8a5.333 5.333 0 110 10.667 5.333 5.333 0 010-10.667z"
    />
  </Svg>
);

export default Friends;
