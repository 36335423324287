import React from 'react';
import {ListRenderItemInfo} from 'react-native';
import {RequestCategorySubtype} from '../../../../api/request/models/responses/categories';
import {
  NewRequestSubcategoriesRouteHeaderText,
  NewRequestSubcategoriesRouteItem,
  NewRequestSubcategoriesRoutes,
} from './styles';
import {useStrings} from '../../../../utils/providers/strings';

export default function NewRequestSubcategories({
  subcategories,
  onSelect,
  withoutList,
}: {
  subcategories: RequestCategorySubtype[];
  onSelect: (subcategory: RequestCategorySubtype) => void;
  withoutList?: boolean;
}) {
  const strings = useStrings();

  if (withoutList) {
    onSelect(subcategories?.[0]);
  }

  function renderNewRequestSubcategoriesRouteItem({
    item,
  }: ListRenderItemInfo<RequestCategorySubtype>) {
    return (
      <NewRequestSubcategoriesRouteItem
        onPress={() => onSelect(item)}
        name={item.name}
      />
    );
  }

  function renderNewRequestSubcategoriesRouteHeader() {
    return (
      <NewRequestSubcategoriesRouteHeaderText>
        {strings.new_request_subcategories_title}
      </NewRequestSubcategoriesRouteHeaderText>
    );
  }

  return (
    <NewRequestSubcategoriesRoutes
      contentContainerStyle={{height: 'auto'}}
      data={subcategories}
      renderItem={renderNewRequestSubcategoriesRouteItem}
      keyExtractor={item => String(item.id)}
      ListHeaderComponent={renderNewRequestSubcategoriesRouteHeader}
    />
  );
}
