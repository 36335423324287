import * as React from 'react';
import Svg, {SvgProps, Rect, G, Path, Defs, ClipPath} from 'react-native-svg';

const ChatAvatarMy = (props: SvgProps) => (
  <Svg width={32} height={32} viewBox="0 0 32 32" fill="#DC9B2D" {...props}>
    <Rect width={32} height={32} rx={16} fillOpacity={0.24} />
    <G clipPath="url(#a)">
      <Path d="M9.333 24.334a6.667 6.667 0 0 1 13.333 0H9.333Zm6.667-7.5c-2.763 0-5-2.238-5-5 0-2.763 2.237-5 5-5 2.762 0 5 2.237 5 5 0 2.762-2.238 5-5 5Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(6 6)" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ChatAvatarMy;
