import styled from 'styled-components/native';
import {css} from 'styled-components';
import {AppLink, GhostLink} from '../../../../../../uikit/AppButtons';
import Star from '../../../../../../assets/icons/Star';
import StarOutline from '../../../../../../assets/icons/StarOutline';
import EmptyListScreen from '../../../../../../assets/icons/EmptyListScreen';
import AppRouteView from '../../../../../../uikit/AppRouteView';

export const ShopReviewSection = styled.View`
  margin-top: 24px;
  display: flex;
  width: 100%;
`;

export const ShopReviewHeadContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 0;
    `};
`;

export const ShopReviewHeadTitle = styled.Text`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: ${props => props.theme.colors['color-basic-900']};

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 16px;
      line-height: 22px;
    `}
`;

export const ShopReviewHeadLink = styled(GhostLink).attrs({
  status: 'danger',
})`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  padding: 0;
`;

export const ShopAddReviewContainer = styled.View`
  display: flex;
  padding-bottom: 12px;
  align-items: center;
`;

export const ShopAddReviewEmptyIcon = styled(EmptyListScreen)``;

export const ShopAddReviewTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ShopAddReviewDescription = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  margin-bottom: 16px;
`;

export const ShopAddReviewButton = styled(AppLink)``;

export const ShopReviewContainer = styled.ScrollView`
  margin-top: 4px;
  padding: 6px 0;
`;

export const ShopReviewItemContainer = styled(AppRouteView)<{
  lastItem?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 276px;
  background: white;
  border-radius: 4px;
  padding: 16px;
  margin-left: 12px;
  margin-right: ${props => (props.lastItem ? '12px' : 0)};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 0;
      margin-right: ${props.lastItem ? 0 : '12px'};
    `}
`;

export const ShopReviewItemAuthor = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 8px;
`;

export const ShopReviewItemDescription = styled.Text`
  font-size: 14px;
  line-height: 20px;
  color: #8f8880;
  max-height: 60px;
  overflow: hidden;
  margin-top: 4px;
`;

export const ShopStarContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const ShopStarIcon = styled(Star)`
  max-width: 15px;
  max-height: 15px;
`;

export const ShopStarOutlineIcon = styled(StarOutline)`
  max-width: 15px;
  max-height: 15px;
`;
