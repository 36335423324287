import React from 'react';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import {AppTimePickerProps} from './common';
import {defaultNewRequestQuestionFieldTime} from '../../features/new-request/components/question/form';

export default function AppTimePicker({value, onChange}: AppTimePickerProps) {
  return (
    <TimePicker
      onChange={value =>
        onChange(
          value
            ? {hours: value.hours(), minutes: value.minutes()}
            : defaultNewRequestQuestionFieldTime(),
        )
      }
      value={value && moment({hours: value.hours, minutes: value.minutes})}
      format="HH:mm"
      showSecond={false}
    />
  );
}
