import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import ShopsScreen from './screen';
import DetailShopScreen from './detail/screen';
import RecentlyViewedShopScreen from './recently-viewed/screen';
import FavoritesShopScreen from './favorites/screen';
import AllReviewsShopScreen from './all-reviews/screen';
import ReviewsShopScreen from './shop-reviews/screen';
import ShopsByCategoryScreen from './by-category/screen';
import AddReviewShopScreen from './add-review/screen';
import DetailReviewShopScreen from './detail-review/screen';

const Stack = createNativeStackNavigator();

export default function ShopsNavigator() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="Shops" component={ShopsScreen} />
      <Stack.Screen name="DetailShop" component={DetailShopScreen} />
      <Stack.Screen
        name="RecentlyViewedShop"
        component={RecentlyViewedShopScreen}
      />
      <Stack.Screen name="FavoritesShop" component={FavoritesShopScreen} />
      <Stack.Screen name="AllReviewsShop" component={AllReviewsShopScreen} />
      <Stack.Screen name="ReviewsShop" component={ReviewsShopScreen} />
      <Stack.Screen
        name="DetailReviewShop"
        component={DetailReviewShopScreen}
      />
      <Stack.Screen name="ShopsByCategory" component={ShopsByCategoryScreen} />
      <Stack.Screen name="AddReviewShop" component={AddReviewShopScreen} />
    </Stack.Navigator>
  );
}
