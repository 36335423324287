import React, {useCallback, useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../../uikit/ScreenLayout';
import {useTheme} from 'styled-components/native';
import AppHeader from '../../../../uikit/AppHeader';
import {useStrings} from '../../../../utils/providers/strings';
import {useForm} from 'react-hook-form';
import {useAppDispatch, useRequestSelector} from '../../../../store/store';
import {buyPointsCybersourceRequestAction} from '../../store/action';
import {CurrencyTypes} from '../../../../api/user/models/responses/user-profile';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import useRouter from '../../../../utils/use-router';
import useFieldServerError from '../../../../utils/react-hook-form/use-field-server-error';
import {BackHandler} from 'react-native';
import ConciergePointsBuyCybersourceInputs from '../components/inputs-cybersource';
import {ConciergePointsBuyFieldSingleSelectionState} from '../components/inputs-common/form';

export interface ConciergePointsCybersourceBuyForm {
  cardNumber: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardSecurityCode: string;
  amount: string;
  countryCode: ConciergePointsBuyFieldSingleSelectionState;
  city: string;
  address: string;
  postalCode: string;
  email: string;
  firstName: string;
  lastName: string;
}

export default function ConciergePointsBuyByCybersourceScreen() {
  const theme = useTheme();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const profileRequest = useRequestSelector(
    store => store.user.getUserProfileRequest,
  );

  const buyRequest = useRequestSelector(
    store => store.conciergePoints.buyPointsByCybersourceRequest,
  );

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    setError,
    setValue,
    watch,
  } = useForm<ConciergePointsCybersourceBuyForm>({
    defaultValues: {
      amount: '',
      cardNumber: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      cardSecurityCode: '',
      countryCode: undefined,
      city: '',
      address: '',
      postalCode: '',
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  useEffect(() => {
    return function () {
      dispatch(buyPointsCybersourceRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (profileRequest.data) {
      setValue('firstName', profileRequest.data.user.firstName);
      setValue('lastName', profileRequest.data.user.lastName);
      setValue('email', profileRequest.data.user.email);
      setValue('address', profileRequest.data.user.streetAddress);
      setValue('postalCode', profileRequest.data.user.postCode);

      if (profileRequest.data.user.country) {
        const findCountry = strings.countryData.find(
          country => country.value === profileRequest.data?.user.country,
        );
        if (findCountry) {
          setValue('countryCode', {
            id: findCountry.value,
            value: findCountry.label,
          });
        }
      }
      setValue('city', profileRequest.data.user.city);
    }
  }, [profileRequest.data, setValue, strings.countryData]);

  useEffect(() => {
    if (buyRequest.data) {
      router.push(
        `/cybersource-success?success=${buyRequest.data.success ? '0' : '1'}`,
      );
    }
  }, [buyRequest.data]);

  useFieldServerError(buyRequest, setError);

  const onBack = useCallback((): boolean => {
    router.back('/cp');
    return true;
  }, [router]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBack,
    );

    return () => backHandler.remove();
  }, [onBack]);

  const onBuy = (form: ConciergePointsCybersourceBuyForm) => {
    dispatch(
      buyPointsCybersourceRequestAction.request({
        cardNumber: form.cardNumber,
        cardExpirationMonth: form.cardExpirationMonth,
        cardExpirationYear: form.cardExpirationYear,
        cardSecurityCode: form.cardSecurityCode,
        amount: Number(form.amount),
        currency: CurrencyTypes.CP,
        countryCode: form.countryCode?.id ?? '',
        city: form.city,
        address: form.address,
        postalCode: form.postalCode,
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
      }),
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.concierge_points_buy_credit_card_title}
              onBackPressed={onBack}
            />
            <ConciergePointsBuyCybersourceInputs
              isLoading={buyRequest.isLoading}
              control={control}
              errors={errors}
              onBuy={handleSubmit(onBuy)}
              setFieldValue={setValue}
              setError={setError}
              watch={watch}
            />
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
