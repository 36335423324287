import type {AxiosInstance} from 'axios';
import type {ContactUsForm} from '../../features/contact-us/lib/consts';
import type {DefaultResponse} from '../models/response/default';

export default class ContactUsApi {
  constructor(private axiosInstance: AxiosInstance) {}

  /** Send contact us form */
  sendContactUsForm(data: ContactUsForm): Promise<DefaultResponse<undefined>> {
    return this.axiosInstance.post('shop/contact-us', data);
  }
}
