import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {
  StandardPointsWithdrawalRequest,
  StandardPointsWithdrawalUpdateRequest,
} from '../../../api/standard-points/models/requests/withdrawal';
import {StandardPointsWithdrawalResponse} from '../../../api/standard-points/models/responses/withdrawal';
import {ListResponse} from '../../../api/models/response/list';
import {PaginationRequest} from '../../../utils/redux/create-pagination-request-reducer';
import {StandardPointsExchangeRequest} from '../../../api/standard-points/models/requests/exchange';
import {Exchange} from '../../../api/standard-points/models/responses/exchange';

export const withdrawalPointsRequestAction = createAsyncAction<
  StandardPointsWithdrawalRequest,
  StandardPointsWithdrawalResponse,
  Error
>('WITHDRAWAL_POINTS_REQUEST');

export const getWithdrawalListRequestAction = createAsyncAction<
  PaginationRequest,
  [PaginationRequest, ListResponse<StandardPointsWithdrawalResponse>],
  Error
>('GET_WITHDRAWAL_LIST_REQUEST');

export const updateWithdrawalPointsRequestAction = createAsyncAction<
  StandardPointsWithdrawalUpdateRequest,
  StandardPointsWithdrawalResponse,
  Error
>('UPDATE_WITHDRAWAL_POINTS_REQUEST');

export const exchangePointsRequestAction = createAsyncAction<
  StandardPointsExchangeRequest,
  Exchange,
  Error
>('EXCHANGE_POINTS_REQUEST');

export const getExchangeListRequestAction = createAsyncAction<
  PaginationRequest,
  [PaginationRequest, ListResponse<Exchange>],
  Error
>('GET_EXCHANGE_LIST_REQUEST');
