import React, {useCallback, useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../uikit/Points/container';
import {useTheme} from 'styled-components/native';
import {
  useAppDispatch,
  usePaginationRequestSelector,
} from '../../../store/store';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import {
  ExchangeListEmptyContainer,
  ExchangeListEmptyCreateNewLink,
  ExchangeListEmptyIcon,
  ExchangeListEmptyInfo,
  ExchangeList,
  ExchangeListLoading,
  ExchangeListItemContainer,
  ExchangeListItemInfoStatus,
  ExchangeListLoadingContainer,
  ExchangeListItemInfoContainer,
  ExchangeListItemInfoTitle,
  ExchangeListItemInfoText,
  ExchangeListItemControlsInfoContainer,
  ExchangeListItemInfoPrice,
  ExchangeListItemInfoSign,
} from './styles';
import {ListRenderItemInfo} from 'react-native';
import {useStrings} from '../../../utils/providers/strings';
import {useToast} from 'react-native-toast-notifications';
import AppRefreshControl from '../../../uikit/AppRefreshControl';
import {AppWhiteStatusBar} from '../../../uikit/AppStatusBar';
import {getExchangeListRequestAction} from '../store/action';
import {EXCHANGE_LIST_LIMIT} from '../store/saga';
import AppHeader from '../../../uikit/AppHeader';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {Exchange} from '../../../api/standard-points/models/responses/exchange';
import {useFocusEffect} from '@react-navigation/native';

export default function ExchangeListScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const exchangeListRequest = usePaginationRequestSelector(
    store => store.standardPoints.exchangeListRequest,
  );

  const isEmptyList = !exchangeListRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getExchangeListRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (exchangeListRequest.error && exchangeListRequest.data?.items.length) {
      toast.show(exchangeListRequest.error.message);
    }
  }, [exchangeListRequest, toast]);

  const onFirstPage = useCallback(() => {
    dispatch(
      getExchangeListRequestAction.request({
        limit: EXCHANGE_LIST_LIMIT,
        offset: 0,
      }),
    );
  }, [dispatch]);

  useFocusEffect(onFirstPage);

  const onNextPage = () => {
    if (exchangeListRequest.data?.isLastPage || exchangeListRequest.isLoading)
      return;

    dispatch(
      getExchangeListRequestAction.request({
        limit: EXCHANGE_LIST_LIMIT,
        offset: exchangeListRequest.data?.items.length || 0,
      }),
    );
  };

  const renderExchangeItem = ({item}: ListRenderItemInfo<Exchange>) => {
    return (
      <ExchangeListItemContainer>
        <ExchangeListItemInfoContainer>
          <ExchangeListItemInfoTitle>№{item.id}</ExchangeListItemInfoTitle>
          <ExchangeListItemInfoText>
            {strings.app_date_time_string(item.createdAt)}
          </ExchangeListItemInfoText>
        </ExchangeListItemInfoContainer>
        <ExchangeListItemControlsInfoContainer>
          <ExchangeListItemInfoStatus status="success">
            {strings.standard_points_exchange_completed}
          </ExchangeListItemInfoStatus>
          <ExchangeListItemInfoPrice>
            {strings.app_number(item.amount)}{' '}
            <ExchangeListItemInfoSign>
              {strings.standard_points_exchange_list_sp}
            </ExchangeListItemInfoSign>
          </ExchangeListItemInfoPrice>
        </ExchangeListItemControlsInfoContainer>
      </ExchangeListItemContainer>
    );
  };

  const renderExchangeListLoading = () => {
    return (
      <ExchangeListLoadingContainer>
        <ExchangeListLoading />
      </ExchangeListLoadingContainer>
    );
  };

  const renderExchangeListEmpty = () => {
    return (
      <ExchangeListEmptyContainer>
        <ExchangeListEmptyIcon />
        <ExchangeListEmptyInfo>
          {strings.standard_points_exchange_list_empty_title}
        </ExchangeListEmptyInfo>
        <ExchangeListEmptyCreateNewLink to="/exchange">
          {strings.standard_points_exchange_list_empty_new_button.toUpperCase()}
        </ExchangeListEmptyCreateNewLink>
      </ExchangeListEmptyContainer>
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.standard_points_exchange_list_title}
              backNoHistoryRoute="/"
            />
            {isEmptyList && exchangeListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && exchangeListRequest.error ? (
              <AppError
                error={exchangeListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              exchangeListRequest.data && (
                <ExchangeList
                  refreshControl={
                    <AppRefreshControl
                      onRefresh={onFirstPage}
                      refreshing={exchangeListRequest.isFirstLoading}
                    />
                  }
                  data={exchangeListRequest.data.items}
                  renderItem={renderExchangeItem}
                  keyExtractor={item => String(item.id)}
                  onEndReached={onNextPage}
                  ListFooterComponent={
                    exchangeListRequest.isLoading
                      ? renderExchangeListLoading
                      : null
                  }
                  ListEmptyComponent={renderExchangeListEmpty}
                />
              )
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
