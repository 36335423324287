import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Plus = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#fff" {...props}>
    <Path fill="transparent" d="M0 0h24v24H0z" />
    <Path d="M10.857 10.857V4h2.286v6.857H20v2.286h-6.857V20h-2.286v-6.857H4v-2.286h6.857Z" />
  </Svg>
);

export default Plus;
