import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {DetailRequestChatAttachIcon, DetailRequestUploadButton} from './styles';
import {useStrings} from '../../../../../../../utils/providers/strings';
import {TypeBloblUrl} from '../recorder';
import {useDispatch} from 'react-redux';
import {sendRequestMessageRequestAction} from '../../../../../store/action';
import api from '../../../../../../../api';

export default function DetailRequestImageUploader({
  filePath,
  requestId,
  message,
  isUploading,
  isStartUpload,
}: {
  filePath: (data: TypeBloblUrl) => void;
  requestId: string;
  message: string;
  isUploading: (data: boolean) => void;
  isStartUpload: boolean;
}) {
  const strings = useStrings();
  const toast = useToast();
  const dispatch = useDispatch();

  const inputRef = useRef<HTMLInputElement>(null);

  const [fileImage, setFileImage] = useState();

  useEffect(() => {
    if (isStartUpload && fileImage) {
      imageUploader(fileImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartUpload, fileImage]);

  // TODO: Need fix typisation

  const addFile = (e: ChangeEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const file = (e.target as HTMLInputElement).files[0];
    setFileImage(file);

    filePath({type: 'image', url: URL.createObjectURL(file)});
  };

  const onAddFile = () => {
    if (!inputRef) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    inputRef?.current.click();
  };

  const imageUploader = async (blob: Blob) => {
    isUploading(true);

    try {
      const response = await api.request.uploadFile({file: blob});

      dispatch(
        sendRequestMessageRequestAction.request({
          id: requestId,
          messageText: message.trim(),
          attachmentKeys: [response.data],
        }),
      );
    } catch (err) {
      toast.show(strings.detail_chat_error_save_image);
      console.error(err);
    } finally {
      setFileImage(undefined);
      isUploading(false);
    }
  };

  return (
    <DetailRequestUploadButton onPress={onAddFile}>
      <DetailRequestChatAttachIcon />
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={addFile}
        hidden
      />
    </DetailRequestUploadButton>
  );
}
