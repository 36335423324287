import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const QR = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      d="M20.445 2.667h8.888v7.407h-2.962V5.63h-5.926V2.667zm-8.89 0V5.63H5.63v4.444H2.667V2.667h8.889zm8.89 26.666V26.37h5.925v-4.444h2.963v7.407h-8.888zm-8.89 0H2.668v-7.407H5.63v4.444h5.926v2.963zM2.668 14.52h26.666v2.963H2.667V14.52z"
    />
  </Svg>
);

export default QR;
