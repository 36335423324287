import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Mic = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}>
    <Path d="M0 0h24v24H0z" stroke="none" />
    <Path d="M9 5a3 3 0 013-3h0a3 3 0 013 3v5a3 3 0 01-3 3h0a3 3 0 01-3-3zM5 10a7 7 0 0014 0M8 21h8M12 17v4" />
  </Svg>
);

export default Mic;
