import {Strings} from '../../../../resources/strings';
import {EvaStatus} from '@ui-kitten/components/devsupport';
import {ShortUser} from '../../../user/models/responses/short-user';

export interface SplitPaymentResponse {
  id: number;
  name: string;
  author: ShortUser;
  requestedAmount: number;
  totalAmount: number;
  status: SplitPaymentStatus;
  createdAt: string;
  updatedAt: string;
}

export enum SplitPaymentStatus {
  PENDING = 'PENDING',
  WAIT_YOUR_PAYMENT = 'WAIT_YOUR_PAYMENT',
  APPROVED = 'APPROVED',
  PAYED = 'PAYED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export function getSplitPaymentStatusText(
  strings: Strings,
  status: SplitPaymentStatus,
): string {
  switch (status) {
    case SplitPaymentStatus.PENDING:
      return strings.split_payment_status_pending;
    case SplitPaymentStatus.WAIT_YOUR_PAYMENT:
      return strings.split_payment_status_wait_your_payment;
    case SplitPaymentStatus.APPROVED:
      return strings.split_payment_status_approved;
    case SplitPaymentStatus.PAYED:
      return strings.split_payment_status_payed;
    case SplitPaymentStatus.REJECTED:
      return strings.split_payment_status_reject;
    case SplitPaymentStatus.CANCELLED:
      return strings.split_payment_status_cancelled;
  }
}

export function getSplitPaymentStatusType(
  status: SplitPaymentStatus,
): EvaStatus {
  switch (status) {
    case SplitPaymentStatus.PENDING:
      return 'warning';
    case SplitPaymentStatus.WAIT_YOUR_PAYMENT:
      return 'warning';
    case SplitPaymentStatus.APPROVED:
      return 'success';
    case SplitPaymentStatus.PAYED:
      return 'info';
    case SplitPaymentStatus.REJECTED:
      return 'danger';
    case SplitPaymentStatus.CANCELLED:
      return 'danger';
  }
}
