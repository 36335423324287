import React from 'react';
import {useDispatch} from 'react-redux';
import {useTheme} from 'styled-components/native';
import {Route, useRoute} from '@react-navigation/native';

import {useStrings} from '../../../../../utils/providers/strings';
import {searchShopsByCategoryRequestAction} from '../../../store/action';
import {useAppSelector} from '../../../../../store/store';

import ShopCard from '../ShopCard';
import {ShopsSearchByCategoryRequestParams} from '../../../../../api/shops/models/shop-search';
import {AppLoader} from '../../../../../uikit/AppLoader';

import {ShopsSearchResultsContent, ShopsSearchResultsShops} from './styles';
import {EmptyList} from '../../../../../uikit/EmptyList';

const SearchResultsByCategory = ({searchValue}: {searchValue?: string}) => {
  const shopsSearchByCategory = useAppSelector(
    store => store.shops.searchShopsByCategoryRequest,
  );
  const dispatch = useDispatch();
  const strings = useStrings();
  const theme = useTheme();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  const onNextPage = () => {
    if (
      !shopsSearchByCategory ||
      shopsSearchByCategory.data?.isLastPage ||
      shopsSearchByCategory.isLoading
    ) {
      return;
    }

    const request: ShopsSearchByCategoryRequestParams = {
      limit: 20,
      offset: shopsSearchByCategory.data?.items.length || 0,
      query: searchValue,
      categoryId: requestId,
    };

    dispatch(searchShopsByCategoryRequestAction.request(request));
  };

  const renderEmptyListMessage = () => (
    <EmptyList
      title={strings.shop_no_shops_title}
      description={strings.shop_recently_try_later_text}
    />
  );

  return (
    <>
      <ShopsSearchResultsContent>
        {shopsSearchByCategory?.data && (
          <ShopsSearchResultsShops
            numColumns={theme.isDesktop ? 4 : 1}
            data={shopsSearchByCategory?.data?.items || []}
            keyExtractor={item => String(item.id)}
            onEndReached={onNextPage}
            ListFooterComponent={
              shopsSearchByCategory.isLoading ? <AppLoader /> : null
            }
            ListEmptyComponent={renderEmptyListMessage}
            renderItem={render => <ShopCard shop={render.item} />}
          />
        )}
      </ShopsSearchResultsContent>
    </>
  );
};

export {SearchResultsByCategory};
