import {all, call, put, takeLatest} from 'redux-saga/effects';
import api from '../../../api';
import {
  createRequestRequestAction,
  getRequestCategoriesRequestAction,
  getRequestCategoryTypeRequestAction,
} from './action';
import {DefaultResponse} from '../../../api/models/response/default';
import {RequestCategory} from '../../../api/request/models/responses/categories';
import {RequestCategoryType} from '../../../api/request/models/responses/category-type';
import {RequestResponse} from '../../../api/request/models/responses';

const getRequestCategoriesRequest = function* (
  action: ReturnType<typeof getRequestCategoriesRequestAction.request>,
) {
  try {
    const response: DefaultResponse<RequestCategory[]> = yield call([
      api,
      api.request.getRequestCategories,
    ]);
    yield put(getRequestCategoriesRequestAction.success(response.data));
  } catch (error) {
    yield put(getRequestCategoriesRequestAction.failure(error as Error));
  }
};

const getRequestCategoryRequestType = function* (
  action: ReturnType<typeof getRequestCategoryTypeRequestAction.request>,
) {
  try {
    const response: DefaultResponse<RequestCategoryType> = yield call(
      [api, api.request.getRequestCategoryType],
      action.payload,
    );
    yield put(getRequestCategoryTypeRequestAction.success(response.data));
  } catch (error) {
    yield put(getRequestCategoryTypeRequestAction.failure(error as Error));
  }
};

const createRequestRequest = function* (
  action: ReturnType<typeof createRequestRequestAction.request>,
) {
  try {
    const response: DefaultResponse<RequestResponse> = yield call(
      [api, api.request.createRequest],
      action.payload,
    );
    yield put(createRequestRequestAction.success(response.data));
  } catch (error) {
    yield put(createRequestRequestAction.failure(error as Error));
  }
};

export const newRequestSaga = function* () {
  yield all([
    takeLatest(
      getRequestCategoriesRequestAction.request,
      getRequestCategoriesRequest,
    ),
    takeLatest(
      getRequestCategoryTypeRequestAction.request,
      getRequestCategoryRequestType,
    ),
    takeLatest(createRequestRequestAction.request, createRequestRequest),
  ]);
};
