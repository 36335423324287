import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, SvgProps} from 'react-native-svg';

const Shops = (props: SvgProps) => (
  <Svg width={24} height={24} fill="#AEA69E" {...props}>
    <G clipPath="url(#clip0_3343_3839)">
      <Path d="M20.889 3H3.11v2.25H20.89V3zM22 14.25V12l-1.111-5.625H3.11L2 12v2.25h1.111V21h11.111v-6.75h4.445V21h2.222v-6.75H22zm-10 4.5H5.333v-4.5H12v4.5z" />
    </G>
    <Defs>
      <ClipPath id="clip0_3343_3839">
        <Path fill="#fff" d="M0 0H24V24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Shops;
