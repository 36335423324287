import {all, call, put, takeLatest} from 'redux-saga/effects';
import api from '../../../api';
import {
  createSplitPaymentRequestAction,
  getSplitPaymentDetailRequestAction,
  getSplitPaymentListRequestAction,
  acceptSplitPaymentRequestAction,
  rejectSplitPaymentRequestAction,
} from './action';
import {ListResponse} from '../../../api/models/response/list';
import {
  SplitPaymentResponse,
  SplitPaymentStatus,
} from '../../../api/split-payments/models/responses/split-payment';
import {DefaultResponse} from '../../../api/models/response/default';
import {SplitPaymentDetailResponse} from '../../../api/split-payments/models/responses/detail';
import {getUserProfileRequestAction} from '../../user/store/action';

export const SPLIT_PAYMENT_LIST_LIMIT = 10;

const getSplitPaymentListRequest = function* (
  action: ReturnType<typeof getSplitPaymentListRequestAction.request>,
) {
  try {
    const response: ListResponse<SplitPaymentResponse> = yield call(
      [api, api.splitPayment.getSplitPaymentList],
      action.payload,
    );
    yield put(
      getSplitPaymentListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getSplitPaymentListRequestAction.failure(error as Error));
  }
};

const getSplitPaymentDetailRequest = function* (
  action: ReturnType<typeof getSplitPaymentDetailRequestAction.request>,
) {
  try {
    const response: DefaultResponse<SplitPaymentDetailResponse> = yield call(
      [api, api.splitPayment.getSplitPaymentDetailRequest],
      action.payload,
    );
    yield put(getSplitPaymentDetailRequestAction.success(response.data));
  } catch (error) {
    yield put(getSplitPaymentDetailRequestAction.failure(error as Error));
  }
};

const acceptSplitPaymentRequest = function* (
  action: ReturnType<typeof acceptSplitPaymentRequestAction.request>,
) {
  const [id, request] = action.payload;
  try {
    const response: DefaultResponse<SplitPaymentDetailResponse> = yield call(
      [api, api.splitPayment.updateSplitPaymentRequest],
      id,
      request,
    );
    if (response.data.status === SplitPaymentStatus.PAYED) {
      yield put(getUserProfileRequestAction.request());
    }

    yield put(acceptSplitPaymentRequestAction.success(response.data));
  } catch (error) {
    yield put(acceptSplitPaymentRequestAction.failure(error as Error));
  }
};

const rejectSplitPaymentRequest = function* (
  action: ReturnType<typeof rejectSplitPaymentRequestAction.request>,
) {
  const [id, request] = action.payload;
  try {
    const response: DefaultResponse<SplitPaymentDetailResponse> = yield call(
      [api, api.splitPayment.updateSplitPaymentRequest],
      id,
      request,
    );
    yield put(rejectSplitPaymentRequestAction.success(response.data));
  } catch (error) {
    yield put(rejectSplitPaymentRequestAction.failure(error as Error));
  }
};

const createSplitPaymentRequest = function* (
  action: ReturnType<typeof createSplitPaymentRequestAction.request>,
) {
  try {
    const response: DefaultResponse<SplitPaymentDetailResponse> = yield call(
      [api, api.splitPayment.createSplitPaymentRequest],
      action.payload,
    );
    yield put(createSplitPaymentRequestAction.success(response.data));
  } catch (error) {
    yield put(createSplitPaymentRequestAction.failure(error as Error));
  }
};

export const splitPaymentSaga = function* () {
  yield all([
    takeLatest(
      getSplitPaymentListRequestAction.request,
      getSplitPaymentListRequest,
    ),
    takeLatest(
      getSplitPaymentDetailRequestAction.request,
      getSplitPaymentDetailRequest,
    ),
    takeLatest(
      acceptSplitPaymentRequestAction.request,
      acceptSplitPaymentRequest,
    ),
    takeLatest(
      rejectSplitPaymentRequestAction.request,
      rejectSplitPaymentRequest,
    ),
    takeLatest(
      createSplitPaymentRequestAction.request,
      createSplitPaymentRequest,
    ),
  ]);
};
