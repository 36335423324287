export interface PaymentInfoResponse {
  status: PaymentInfoStatus;
  message: string;
}

export enum PaymentInfoStatus {
  Processing = 'PROCESSING',
  Failed = 'FAILED',
  Success = 'SUCCESS',
}
