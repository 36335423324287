import styled from 'styled-components/native';
import {css} from 'styled-components';
import Location from '../../../../../../assets/icons/Location';
import {OutlineButton} from '../../../../../../uikit/AppButtons';

export const ShopAdditionalSection = styled.View`
  margin-top: 24px;
  display: flex;
  width: 100%;
`;

export const ShopAdditionalHeadContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 0;
    `};
`;

export const ShopAdditionalHeadTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  margin-bottom: 16px;

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 16px;
      line-height: 22px;
    `}
`;

export const ShopAdditionalContainer = styled.View<{indented?: boolean}>`
  display: flex;
  background: white;
  padding: 8px 16px;
  border-color: #e8e8e8;
  border-bottom-width: 1px;
  margin-top: ${props => (props.indented ? '20px' : '0')};
`;

export const ShopAdditionalAdressContainer = styled.View`
  display: flex;
  flex-direction: row;
  background: white;
  padding: 8px 12px;
  border-color: #e8e8e8;
  border-bottom-width: 1px;
`;

export const ShopAdditionalAdressContentContainer = styled.View`
  flex: 1;
`;

export const ShopAdditionalHeading = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  margin-top: 2px;
`;

export const ShopAdditionalDescription = styled.Text`
  font-size: 14px;
  line-height: 20px;
  color: #8f8880;
`;

export const ShopAdditionalLink = styled.Text`
  font-size: 14px;
  line-height: 20px;
  color: #1e6ab2;
`;

export const ShopAdditionalLocationIcon = styled(Location)`
  max-width: 24px;
  max-height: 24px;
  margin-right: 8px;
  margin-top: 8px;
  flex: 1;
`;

export const ShopAdditionalMapLinkContainer = styled.View`
  display: flex;
  margin-left: 8px;
`;

export const ShopAdditionalMapDesktopLinkContainer = styled.View`
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const ShopAdditionalMapLink = styled(OutlineButton)`
  background: transparent;
  margin-left: 0;
  margin-right: 0;
`;
