import React from 'react';
import {
  ScreenListEmptyContainer,
  ScreenListEmptyIcon,
  ScreenListEmptyInfo,
  ScreenListEmptyTitle,
} from './styles';

export const EmptyList = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <ScreenListEmptyContainer>
    <ScreenListEmptyIcon />
    <ScreenListEmptyTitle>{title}</ScreenListEmptyTitle>
    <ScreenListEmptyInfo>{description}</ScreenListEmptyInfo>
  </ScreenListEmptyContainer>
);
