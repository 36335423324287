import React from 'react';

import {useStrings} from '../../../../../utils/providers/strings';

import {RequestsListEmptyInfo} from '../../../../requests/styles';

import {
  ScreenListEmptyContainer,
  ScreenListEmptyIcon,
  ScreenListEmptyTitle,
} from './styles';

const EmptyList = () => {
  const strings = useStrings();

  return (
    <ScreenListEmptyContainer>
      <ScreenListEmptyIcon />
      <ScreenListEmptyTitle>
        {strings.shop_no_reviews_title}
      </ScreenListEmptyTitle>
      <RequestsListEmptyInfo>
        {strings.shop_recently_try_later_text}
      </RequestsListEmptyInfo>
    </ScreenListEmptyContainer>
  );
};

export {EmptyList};
