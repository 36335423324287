import React, {useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../../uikit/ScreenLayout';
import {useTheme} from 'styled-components/native';
import AppHeader from '../../../../uikit/AppHeader';
import {useStrings} from '../../../../utils/providers/strings';
import {useForm} from 'react-hook-form';
import {useAppDispatch, useRequestSelector} from '../../../../store/store';
import {buyPointsByBankTransferRequestAction} from '../../store/action';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import useFieldServerError from '../../../../utils/react-hook-form/use-field-server-error';
import ConciergePointsBuyByBankTransferInputs from './components/inputs';
import ConciergePointsBuyByBankTransferPayment from './components/payment';

export interface ConciergePointsBuyByBankTransferForm {
  amount: string;
}

export default function ConciergePointsBuyByBankTransferScreen() {
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const buyPointsByBankTransferRequest = useRequestSelector(
    store => store.conciergePoints.buyPointsByBankTransferRequest,
  );

  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    watch,
    setValue,
  } = useForm<ConciergePointsBuyByBankTransferForm>({
    defaultValues: {
      amount: '',
    },
  });

  useEffect(() => {
    return function () {
      dispatch(buyPointsByBankTransferRequestAction.clean());
    };
  }, [dispatch]);

  useFieldServerError(buyPointsByBankTransferRequest, setError);

  const onBuy = (form: ConciergePointsBuyByBankTransferForm) => {
    dispatch(
      buyPointsByBankTransferRequestAction.request({
        amount: Number(form.amount),
      }),
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.concierge_points_buy_bank_transfer_title}
              backNoHistoryRoute="/cp"
            />
            {buyPointsByBankTransferRequest.data ? (
              <ConciergePointsBuyByBankTransferPayment
                transfer={buyPointsByBankTransferRequest.data}
              />
            ) : (
              <ConciergePointsBuyByBankTransferInputs
                isLoading={buyPointsByBankTransferRequest.isLoading}
                control={control}
                errors={errors}
                onBuy={handleSubmit(onBuy)}
                setFieldValue={setValue}
                setError={setError}
                watch={watch}
              />
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
