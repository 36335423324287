import React, {useEffect} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {
  AppTransparentHeader,
  ShopDetailReviewContainer,
  ShopDetailReviewContentContainer,
  ShopDetailReviewText,
  ShopDetailReviewTitle,
  ShopStarContainer,
  ShopStarIcon,
  ShopStarOutlineIcon,
} from './styles';
import {Route, useRoute} from '@react-navigation/native';
import {useStrings} from '../../../utils/providers/strings';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import AppStatusBar from '../../../uikit/AppStatusBar';
import {AppLayout} from '../../../uikit/AppLayout';
import {ShopsContainerContainer} from '../styles';
import {getDetailShopReviewRequestAction} from '../store/action';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';

export default function DetailReviewShopScreen() {
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  const shopDetailReviewRequest = useRequestSelector(
    store => store.shops.shopDetailReviewRequest,
  );

  useEffect(() => {
    dispatch(getDetailShopReviewRequestAction.request(requestId));
    return function () {
      dispatch(getDetailShopReviewRequestAction.clean());
    };
  }, [dispatch, requestId]);

  useEffect(() => {
    if (shopDetailReviewRequest.error) {
      toast.show(shopDetailReviewRequest.error?.message);
    }
  }, [shopDetailReviewRequest]);

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopDetailReviewContainer>
          <AppLayout>
            <AppTransparentHeader
              title={strings.shop_detail_review_screen_title}
              backNoHistoryRoute={`/shops/reviews`}
            />

            {shopDetailReviewRequest.isLoading ? (
              <AppLoader />
            ) : shopDetailReviewRequest.error ? (
              <AppError error={shopDetailReviewRequest.error.message} />
            ) : (
              <ShopDetailReviewContentContainer>
                <ShopStarContainer>
                  {[...Array(5)].map((_, index) => {
                    const rate = shopDetailReviewRequest.data?.rate;

                    return rate && rate >= index + 1 ? (
                      <ShopStarIcon key={index} />
                    ) : (
                      <ShopStarOutlineIcon key={index} />
                    );
                  })}
                </ShopStarContainer>
                <ShopDetailReviewTitle>
                  {`${shopDetailReviewRequest.data?.author?.firstName} ${shopDetailReviewRequest.data?.author?.lastName}`}
                </ShopDetailReviewTitle>
                <ShopDetailReviewText>
                  {shopDetailReviewRequest.data?.description}
                </ShopDetailReviewText>
              </ShopDetailReviewContentContainer>
            )}
          </AppLayout>
        </ShopDetailReviewContainer>
      </ShopsContainerContainer>
    </>
  );
}
