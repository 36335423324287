import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList, ImageStyle} from 'react-native';
import {RequestResponse} from '../../../api/request/models/responses';
import {Spinner, Text} from '@ui-kitten/components';
import EmptyRequests from '../../../assets/icons/EmptyRequests';
import {AppLink, AuxiliaryButton} from '../../../uikit/AppButtons';
import Call from '../../../assets/icons/Call';
import React from 'react';
import ArrowRight from '../../../assets/icons/ArrowRight';
import AppRouteView from '../../../uikit/AppRouteView';

export const RequestsList = styled(FlatList<RequestResponse>)`
  flex: 1;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.smallSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 20px;
      padding-right: ${props.theme.dimens.smallSpacer};
    `}
`;

export const RequestsListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const RequestsListLoading = styled(Spinner)``;

export const RequestsListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const RequestsListEmptyIcon = styled(EmptyRequests)``;

export const RequestsListEmptyInfo = styled(Text)``;

export const RequestsListEmptyCreateNewLink = styled(AppLink)`
  align-self: stretch;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
      min-width: 240px;
    `}
`;

export const RequestsListEmptyCallButton = styled(AuxiliaryButton).attrs({
  accessoryLeft: props => (
    <Call
      {...props}
      fill={(props?.style as ImageStyle)?.tintColor}
      width={(props?.style as ImageStyle)?.width}
      height={(props?.style as ImageStyle)?.height}
    />
  ),
})`
  align-self: stretch;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
      min-width: 240px;
    `}
`;

export const RequestsListItemContainer = styled(AppRouteView)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const RequestsListItemInfoContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const RequestsListItemInfoTitle = styled(Text)``;

export const RequestsListItemInfo = styled(Text).attrs({
  category: 'c1',
})`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const RequestsListItemInfoStatus = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-size: 12px;
  font-weight: bold;
`;

export const RequestsListItemIcon = styled(ArrowRight)``;
