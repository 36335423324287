import React from 'react';
import AppModal from '../../../../../uikit/AppModal';
import {
  AppModalCancel,
  AppModalRoute,
  AppModalTitle,
} from '../../../../../uikit/AppModal/styles';
import {useStrings} from '../../../../../utils/providers/strings';
import useRouter from '../../../../../utils/use-router';

interface IModal {
  title: string;
  route: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function SplitPaymentModal({
  title,
  isOpen,
  onClose,
  route,
}: IModal) {
  const strings = useStrings();
  const router = useRouter();

  return (
    <AppModal onBackdropPress={onClose} visible={isOpen}>
      <AppModalTitle>{title}</AppModalTitle>
      <AppModalRoute to={route} onPress={() => router.push(route)}>
        {strings.split_payment_friend_home_screen_button.toUpperCase()}
      </AppModalRoute>
      <AppModalCancel onPress={onClose}>
        {strings.qr_payment_error_modal_cancel_button}
      </AppModalCancel>
    </AppModal>
  );
}
