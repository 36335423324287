import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const SplitPay = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      fillRule="evenodd"
      d="M21.72 7.053L18.667 4h8v8l-3.054-3.053-3.84 3.84-1.893-1.894 3.84-3.84zM5.333 4h8L10.28 7.053l7.053 7.614H24v2.666h-6.667V20H24v2.667h-6.667V28h-2.666v-5.333H8V20h6.667v-2.667H8v-2.666h5.659l-5.272-5.72L5.333 12V4z"
      clipRule="evenodd"
    />
  </Svg>
);

export default SplitPay;
