import {Strings} from '../resources/strings';
import {ValidateResult} from 'react-hook-form';

export default function validateEmail(
  value: string,
  strings: Strings,
): ValidateResult {
  if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/))
    return strings.register_email_not_valid;

  return true;
}
