import styled from 'styled-components/native';

export const ExternalLinkContainer = styled.TouchableOpacity`
  cursor: pointer;
  user-select: auto;
`;

export const ExternalLinkText = styled.Text<{size?: number}>`
  font-size: ${props => (props.size ? `${props.size}px` : '14px')};
  font-weight: 300;
  color: ${props => props.theme.colors['color-success-500']};
`;
