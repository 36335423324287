import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {
  ListResponse,
  ListWithCategoryNameResponse,
} from '../../../api/models/response/list';
import {PaginationRequest} from '../../../utils/redux/create-pagination-request-reducer';
import {
  ShopCategoriesResponse,
  ShopCategory,
  ShopItem,
} from '../../../api/shops/models/responses/shop-categories';
import {ShopDetailResponse} from '../../../api/shops/models/responses/shop-detail';
import {
  CreateReviewRequest,
  ReviewsRequest,
  ShopIdRequest,
  ShopsFlatCategoriesRequest,
} from '../../../api/shops';
import {ShopProductsResponse} from '../../../api/shops/models/responses/shop-products';
import {ShopReviewsResponse} from '../../../api/shops/models/responses/shop-reviews';
import {ShopCommitmentsResponse} from '../../../api/shops/models/responses/shop-commitments';
import {
  ShopSearchItem,
  ShopsSearchByCategoryRequestParams,
  ShopsSearchRequestParams,
} from '../../../api/shops/models/shop-search';

export const getShopCategoriesListRequestAction = createAsyncAction<
  PaginationRequest,
  [PaginationRequest, ListResponse<ShopCategoriesResponse>],
  Error
>('GET_SHOP_CATEGORIES_LIST_REQUEST');

export const getShopsFlatCategoriesListAction = createAsyncAction<
  ShopsFlatCategoriesRequest,
  [ShopsFlatCategoriesRequest, ListResponse<ShopCategory>],
  Error
>('GET_SHOPS_FLAT_CATEGORIES_LIST_REQUEST');

export const searchShopsRequestAction = createAsyncAction<
  ShopsSearchRequestParams,
  [ShopsSearchRequestParams, ListResponse<ShopSearchItem>],
  Error
>('SEARCH_SHOPS');

export const getDetailShopRequestAction = createAsyncAction<
  string,
  ShopDetailResponse,
  Error
>('GET_SHOP_DETAIL_REQUEST');

export const getShopProductsListRequestAction = createAsyncAction<
  ShopIdRequest,
  ShopProductsResponse[],
  Error
>('GET_SHOP_PRODUCTS_LIST_REQUEST');

export const getShopCommitmentsListRequestAction = createAsyncAction<
  ShopIdRequest,
  ShopCommitmentsResponse[],
  Error
>('GET_SHOP_COMMITMENTS_LIST_REQUEST');

export const getAllReviewsListRequestAction = createAsyncAction<
  PaginationRequest,
  [ReviewsRequest, ListResponse<ShopReviewsResponse>],
  Error
>('GET_ALL_REVIEWS_LIST_REQUEST');

export const getShopReviewsListRequestAction = createAsyncAction<
  ReviewsRequest,
  [ReviewsRequest, ListResponse<ShopReviewsResponse>],
  Error
>('GET_SHOP_REVIEWS_LIST_REQUEST');

export const getDetailShopReviewRequestAction = createAsyncAction<
  string,
  ShopReviewsResponse,
  Error
>('GET_SHOP_DETAIL_REVIEW_REQUEST');

export const createShopReviewRequestAction = createAsyncAction<
  CreateReviewRequest,
  boolean,
  Error
>('CREATE_SHOP_REVIEW_REQUEST');

export const getShopRecentlyViewedListRequestAction = createAsyncAction<
  PaginationRequest,
  [PaginationRequest, ListResponse<ShopItem>],
  Error
>('GET_SHOP_RECENTLY_VIEWED_LIST_REQUEST');

export const deleteRecentlyViewedRequestAction = createAsyncAction<
  PaginationRequest,
  boolean,
  Error
>('DELETE_RECENTLY_VIEWED_REQUEST');

export const getShopFavoritesListRequestAction = createAsyncAction<
  PaginationRequest,
  [PaginationRequest, ListResponse<ShopItem>],
  Error
>('GET_SHOP_FAVORITES_LIST_REQUEST');

export const createShopFavoriteRequestAction = createAsyncAction<
  ShopIdRequest,
  boolean,
  Error
>('CREATE_SHOP_FAVORITE_REQUEST');

export const deleteShopFavoriteRequestAction = createAsyncAction<
  ShopIdRequest,
  boolean,
  Error
>('DELETE_SHOP_FAVORITE_REQUEST');

export const getShopByCategoryListRequestAction = createAsyncAction<
  ReviewsRequest,
  [ReviewsRequest, ListWithCategoryNameResponse<ShopItem>],
  Error
>('GET_SHOP_BY_CATEGORY_LIST_REQUEST');

export const searchShopsByCategoryRequestAction = createAsyncAction<
  ShopsSearchByCategoryRequestParams,
  [ShopsSearchByCategoryRequestParams, ListResponse<ShopSearchItem>],
  Error
>('SEARCH_SHOPS_BY_CATEGORY');
