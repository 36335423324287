import React from 'react';
import styled, {useTheme} from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Modal as ModalComponent, ModalProps, Text} from '@ui-kitten/components';
import {AppButton, AppLink, GhostButton, GhostLink} from '../../AppButtons';
import {ViewStyle} from 'react-native';

export type AppModalProps = ModalProps;

export const AppModal = ({style, backdropStyle, ...props}: AppModalProps) => {
  const theme = useTheme();
  const modalStyle: ViewStyle = {
    padding: 16,
  };
  if (theme.isDesktop) {
    modalStyle.maxWidth = '25%';
    modalStyle.minWidth = '264px';
  } else {
    modalStyle.width = '100%';
  }

  return (
    <ModalComponent
      {...props}
      style={[modalStyle, style]}
      backdropStyle={[
        {backgroundColor: `${theme.colors['color-basic-900']}66`},
        backdropStyle,
      ]}
    />
  );
};

export const AppModalCard = styled.View`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  box-shadow: 0 2px 8px ${props => props.theme.colors.white}26;
  border-radius: 4px;
`;

export const AppModalTitle = styled(Text)`
  flex: 1;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

const CommonAppModalButton = css`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

export const AppModalButton = styled(AppButton)`
  ${CommonAppModalButton};
`;

export const AppModalRoute = styled(AppLink)`
  ${CommonAppModalButton};
`;

export const AppModalCancel = styled(GhostButton)``;

export const AppModalCancelRoute = styled(GhostLink)`
  ${CommonAppModalButton};
`;
