import React from 'react';
import {AppLoaderContainer, AppLoaderSpinner} from './styles';
import {ViewStyle} from 'react-native';

export const AppLoader = ({style}: {style?: ViewStyle}) => {
  return (
    <AppLoaderContainer style={style}>
      <AppLoaderSpinner />
    </AppLoaderContainer>
  );
};
