import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';

export const NewRequestQuestionFieldStepperContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};
  background-color: ${props => props.theme.colors.white};
`;

export const NewRequestQuestionFieldStepperNumber = styled(Text)`
  font-weight: bold;
  font-size: 16px;
  flex: 1;
`;

const CommonNewRequestQuestionFieldStepperButton = css`
  width: 56px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: ${props => props.theme.colors['color-primary-500']};
`;

export const NewRequestQuestionFieldStepperMinusButton = styled.TouchableOpacity`
  ${CommonNewRequestQuestionFieldStepperButton};

  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`;

export const NewRequestQuestionFieldStepperPlusButton = styled.TouchableOpacity`
  ${CommonNewRequestQuestionFieldStepperButton};

  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
`;

export const NewRequestQuestionFieldStepperButtonText = styled(Text)`
  font-weight: bold;
  font-size: 24px;
  color: black;
`;
