import {css} from 'styled-components';
import styled from 'styled-components/native';
import EmptyListScreen from '../../../assets/icons/EmptyListScreen';
import {Text} from 'react-native';
import AppHeader from '../../../uikit/AppHeader';

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  margin-bottom: 20px;
`;

export const InfoScreenContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  background-color: ${props => props.theme.colors['color-basic-100']};
`;

export const InfoScreenContentWrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${props => (props.theme.isDesktop ? '48px 24px 0 40px' : '0')};
`;

export const InfoScreenContent = styled.ScrollView`
  padding: ${props => (props.theme.isDesktop ? '0' : '0 16px')};
  padding-bottom: 60px;
`;

export const InfoList = styled.ScrollView``;

export const InfoScreenEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const InfoScreenEmptyIcon = styled(EmptyListScreen)``;

export const InfoScreenEmptyTitle = styled(Text)`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const InfoScreenEmptyInfo = styled(Text)``;
