import React from 'react';
import {ShopDetailResponse} from '../../../../../api/shops/models/responses/shop-detail';
import {
  ShopHeaderBadgesContainer,
  ShopHeaderImage,
  ShopHeaderImageContainer,
  ShopHeaderLocationBadge,
  ShopHeaderRateBadge,
  ShopHeaderRateIcon,
  ShopHeaderRateText,
  ShopHeaderTagBadge,
} from './styles';

interface IProps {
  data: ShopDetailResponse | undefined;
}

export default function ShopHeader({data}: IProps) {
  const shopData = data?.public;

  return (
    <ShopHeaderImageContainer>
      <ShopHeaderBadgesContainer>
        {shopData?.prefecture && (
          <ShopHeaderLocationBadge numberOfLines={1}>
            {shopData?.prefecture}
          </ShopHeaderLocationBadge>
        )}
        {shopData?.tag && (
          <ShopHeaderTagBadge numberOfLines={1}>
            {shopData?.tag}
          </ShopHeaderTagBadge>
        )}
        {!!data?.averageRate && (
          <ShopHeaderRateBadge>
            <ShopHeaderRateIcon />
            <ShopHeaderRateText>{data?.averageRate}</ShopHeaderRateText>
          </ShopHeaderRateBadge>
        )}
      </ShopHeaderBadgesContainer>
      <ShopHeaderImage
        source={{
          uri: shopData?.images[0].imageUrl,
        }}
        accessibilityLabel={shopData?.images[0].description}
      />
    </ShopHeaderImageContainer>
  );
}
