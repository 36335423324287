import React from 'react';
import {
  DetailRequestChatMyContainer,
  DetailRequestChatMyIcon,
  DetailRequestChatMyImage,
  DetailRequestChatMyImageButton,
  DetailRequestChatMyInfoBubble,
  DetailRequestChatMyMessageContainer,
} from './styles';
import {
  RequestChatMessageContentType,
  RequestChatMessageNormal,
} from '../../../../../../../../api/request/models/responses/chat';
import DetailRequestSound from '../../sound';
import renderMessageWithUrls from '../message-with-url';

export default function DetailRequestChatMy({
  onAction,
  message,
}: {
  onAction: (imageUrl: string) => void;
  message: RequestChatMessageNormal;
}) {
  return (
    <DetailRequestChatMyContainer>
      <DetailRequestChatMyMessageContainer>
        {message.messageText ? (
          <DetailRequestChatMyInfoBubble>
            {renderMessageWithUrls(message.messageText)}
          </DetailRequestChatMyInfoBubble>
        ) : null}

        {message.messageContentType === RequestChatMessageContentType.IMAGE ? (
          <DetailRequestChatMyImageButton
            onPress={() => onAction(message.attachments?.[0]?.attachmentUrl)}>
            <DetailRequestChatMyImage
              source={{uri: message.attachments?.[0]?.attachmentUrl}}
            />
          </DetailRequestChatMyImageButton>
        ) : null}

        {message.messageContentType === RequestChatMessageContentType.AUDIO ? (
          <DetailRequestSound
            audioUrl={message.attachments?.[0]?.attachmentUrl}
            my
          />
        ) : null}
      </DetailRequestChatMyMessageContainer>

      <DetailRequestChatMyIcon />
    </DetailRequestChatMyContainer>
  );
}
