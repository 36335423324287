export default function getChangedObject<
  ObjectType extends Record<string, any>,
>(object: ObjectType, prevObject: ObjectType): Partial<ObjectType> {
  const newObject = {};

  Object.entries(object).forEach(([key, value]) => {
    if (
      typeof value === 'object' &&
      value !== null &&
      typeof prevObject[key] === 'object' &&
      prevObject[key] !== null
    ) {
      const changedChildObject = getChangedObject(value, prevObject[key]);
      if (Object.keys(changedChildObject).length) {
        newObject[key] = changedChildObject;
      }
    } else {
      if (value !== prevObject[key]) {
        newObject[key] = value;
      }
    }
  });

  return newObject;
}
