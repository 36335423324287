import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, SvgProps} from 'react-native-svg';

const Calendar = (props: SvgProps) => (
  <Svg width={24} height={24} fill="#AEA69E" {...props}>
    <G clipPath="url(#a)">
      <Path d="M17 4h5v18H2V4h5V2h2v2h6V2h2v2zM4 10v10h16V10H4zm2 2h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Calendar;
