import React from 'react';
import {RequestCategoryTypeFieldType} from '../../../../api/request/models/responses/category-type';
import {useStrings} from '../../../../utils/providers/strings';
import {useForm} from 'react-hook-form';
import {NewRequestQuestionItem} from '../confirm';
import {
  NewRequestQuestionForm,
  validateNewRequestQuestionFields,
} from '../question/form';
import NewRequestQuestionFieldText from '../question/components/fields/text';
import NewRequestQuestionFieldDate from '../question/components/fields/date';
import NewRequestQuestionFieldRadioGroup from '../question/components/fields/radio-group';
import NewRequestQuestionFieldSingleSelection from '../question/components/fields/single-selection';
import {
  NewRequestQuestionBackButton,
  NewRequestQuestionButtons,
  NewRequestQuestionContainer,
  NewRequestQuestionInputsContainer,
  NewRequestQuestionNextButton,
} from '../question/styles';
import {
  NewRequestChangeFieldQuestionContainer,
  NewRequestChangeFieldQuestionOutside,
} from './styles';
import NewRequestQuestionFieldNumber from '../question/components/fields/number';
import NewRequestQuestionFieldCheckBox from '../question/components/fields/check-box';
import NewRequestQuestionFieldStepper from '../question/components/fields/stepper';
import NewRequestQuestionFieldTime from '../question/components/fields/time';
import NewRequestQuestionFieldAttachImage from '../question/components/fields/attach-image';

export default function NewRequestChangeField({
  field,
  onAnswer,
  onBack,
}: {
  field: NewRequestQuestionItem;
  onAnswer: (answer: NewRequestQuestionForm, screenPosition: number) => void;
  onBack: () => void;
}) {
  const strings = useStrings();

  const {control, handleSubmit, formState} = useForm<NewRequestQuestionForm>({
    mode: 'onBlur',
    resolver: (values, context, options) => {
      const errors = validateNewRequestQuestionFields(
        strings,
        field.screen,
        values,
      );

      return {
        values: Object.keys(errors).length ? {} : values,
        errors,
      };
    },
    defaultValues: field.answer,
  });

  const onNext = handleSubmit(answers =>
    onAnswer(answers, field.screenPosition),
  );

  return (
    <NewRequestQuestionContainer>
      <NewRequestQuestionInputsContainer>
        <NewRequestChangeFieldQuestionOutside>
          <NewRequestChangeFieldQuestionContainer>
            {(() => {
              switch (field.field.type) {
                case RequestCategoryTypeFieldType.INPUT:
                  return (
                    <NewRequestQuestionFieldText
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.DATE:
                  return (
                    <NewRequestQuestionFieldDate
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.TIME:
                  return (
                    <NewRequestQuestionFieldTime
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.RADIO_GROUP:
                  return (
                    <NewRequestQuestionFieldRadioGroup
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.SINGLE_SELECTION:
                  return (
                    <NewRequestQuestionFieldSingleSelection
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.INPUT_NUMBER:
                  return (
                    <NewRequestQuestionFieldNumber
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.CHECKBOX:
                  return (
                    <NewRequestQuestionFieldCheckBox
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.STEPPER:
                  return (
                    <NewRequestQuestionFieldStepper
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
                case RequestCategoryTypeFieldType.ATTACHMENT_IMAGE:
                  return (
                    <NewRequestQuestionFieldAttachImage
                      control={control}
                      state={formState}
                      field={field.field}
                    />
                  );
              }
            })()}
          </NewRequestChangeFieldQuestionContainer>
        </NewRequestChangeFieldQuestionOutside>
      </NewRequestQuestionInputsContainer>
      <NewRequestQuestionButtons>
        <NewRequestQuestionBackButton onPress={onBack}>
          {strings.new_request_change_field_cancel_button.toUpperCase()}
        </NewRequestQuestionBackButton>
        <NewRequestQuestionNextButton onPress={onNext}>
          {strings.new_request_change_field_save_button.toUpperCase()}
        </NewRequestQuestionNextButton>
      </NewRequestQuestionButtons>
    </NewRequestQuestionContainer>
  );
}
