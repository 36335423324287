import React, {useCallback, useEffect, useState} from 'react';
import {useFocusEffect, useRoute} from '@react-navigation/native';
import {useToast} from 'react-native-toast-notifications';
import {Route} from '@react-navigation/routers';
import {useTheme} from 'styled-components';
import {BackHandler} from 'react-native';

import {useAppDispatch, useRequestSelector} from '../../../store/store';
import useRouter from '../../../utils/use-router';
import AppStatusBar from '../../../uikit/AppStatusBar';
import {
  createShopFavoriteRequestAction,
  deleteShopFavoriteRequestAction,
  getDetailShopRequestAction,
  getShopCommitmentsListRequestAction,
  getShopProductsListRequestAction,
  getShopReviewsListRequestAction,
} from '../store/action';

import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {AppTransparentHeader} from '../../../uikit/AppHeader';
import {AppError} from '../../../uikit/AppError';
import {AppLoader} from '../../../uikit/AppLoader';
import {ShopsContainerContainer} from '../styles';
import ShopAccordion from './components/accordion';
import ShopHeader from './components/header';
import ShopMainInfo from './components/main-info';
import ShopCommitment from './components/commitment';
import ShopReview from './components/review';
import ShopAdditionalInfo from './components/additional-info';

import {
  ShopContainerContent,
  ShopFavoritesButton,
  ShopFavoritesIcon,
  ShopFavoritesOutlineIcon,
  ShopScrollView,
} from './styles';

export default function DetailShopScreen() {
  const router = useRouter();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  const shopDetailRequest = useRequestSelector(
    ({shops}) => shops.shopDetailRequest,
  );
  const shopProductRequest = useRequestSelector(
    ({shops}) => shops.shopProductsRequest,
  );
  const shopCommitmentRequest = useRequestSelector(
    ({shops}) => shops.shopCommitmentsRequest,
  );
  const shopReviewRequest = useRequestSelector(
    ({shops}) => shops.shopReviewsListRequest,
  );
  const createShopFavoriteRequest = useRequestSelector(
    ({shops}) => shops.createShopFavoriteRequest,
  );
  const deleteShopFavoriteRequest = useRequestSelector(
    ({shops}) => shops.deleteShopFavoriteRequest,
  );

  const isEmptyList = !shopDetailRequest.data;

  const [isShopFavorite, setIsShopFavorite] = useState<boolean>(false);

  const onInit = useCallback(() => {
    dispatch(getDetailShopRequestAction.request(requestId));
    dispatch(
      getShopProductsListRequestAction.request({shopId: Number(requestId)}),
    );
    dispatch(
      getShopCommitmentsListRequestAction.request({shopId: Number(requestId)}),
    );
    dispatch(
      getShopReviewsListRequestAction.request({
        shopId: Number(requestId),
        limit: 10,
        offset: 0,
      }),
    );

    return function () {
      dispatch(getDetailShopRequestAction.clean());
      dispatch(getShopProductsListRequestAction.clean());
      dispatch(getShopCommitmentsListRequestAction.clean());
      dispatch(getShopReviewsListRequestAction.clean());
      dispatch(createShopFavoriteRequestAction.clean());
    };
  }, [dispatch, requestId]);

  useFocusEffect(onInit);

  const onBack = useCallback((): boolean => {
    router.back('/shops');
    return true;
  }, [router]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBack,
    );

    return () => backHandler.remove();
  }, [onBack]);

  useEffect(() => {
    if (createShopFavoriteRequest.error) {
      toast.show(createShopFavoriteRequest.error.message);
    }
    if (createShopFavoriteRequest.data) {
      setIsShopFavorite(true);
    }
  }, [createShopFavoriteRequest]);

  useEffect(() => {
    if (deleteShopFavoriteRequest.error) {
      toast.show(deleteShopFavoriteRequest.error.message);
    }
    if (deleteShopFavoriteRequest.data) {
      setIsShopFavorite(false);
    }
  }, [deleteShopFavoriteRequest]);

  useEffect(() => {
    if (!shopDetailRequest.data?.isFavorite) return;

    setIsShopFavorite(shopDetailRequest.data.isFavorite);
  }, [shopDetailRequest]);

  const handleFavorites = () => {
    if (isShopFavorite) {
      dispatch(
        deleteShopFavoriteRequestAction.request({
          shopId: Number(requestId),
        }),
      );
    }
    if (!isShopFavorite) {
      dispatch(
        createShopFavoriteRequestAction.request({
          shopId: Number(requestId),
        }),
      );
    }
  };

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopContainerContent>
          <ScreenLayoutWithHeader>
            <AppTransparentHeader
              title={shopDetailRequest.data?.public.name}
              backNoHistoryRoute="/shops"
              rightButton={
                !isEmptyList && (
                  <ShopFavoritesButton
                    isActive={isShopFavorite}
                    onPress={handleFavorites}>
                    {isShopFavorite ? (
                      <ShopFavoritesIcon
                        fill={theme.colors['color-danger-500']}
                      />
                    ) : (
                      <ShopFavoritesOutlineIcon />
                    )}
                  </ShopFavoritesButton>
                )
              }
            />

            {isEmptyList && shopDetailRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && shopDetailRequest.error ? (
              <AppError
                error={shopDetailRequest.error.message}
                retry={onInit}
              />
            ) : (
              <ShopScrollView>
                <ShopHeader data={shopDetailRequest.data} />
                <ShopMainInfo data={shopDetailRequest.data} />
                {!!shopCommitmentRequest.data?.length && (
                  <ShopCommitment commitments={shopCommitmentRequest.data} />
                )}
                <ShopAccordion
                  data={shopDetailRequest.data}
                  products={shopProductRequest.data}
                />
                <ShopReview data={shopReviewRequest.data} />
                <ShopAdditionalInfo data={shopDetailRequest.data} />
              </ShopScrollView>
            )}
          </ScreenLayoutWithHeader>
        </ShopContainerContent>
      </ShopsContainerContainer>
    </>
  );
}
