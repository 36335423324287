import {Strings} from '../../../../resources/strings';
import {EvaStatus} from '@ui-kitten/components/devsupport';
import {ShortUser} from '../../../user/models/responses/short-user';

export interface RequestResponse {
  id: number;
  author: ShortUser;
  concierge: ShortUser | null;
  createdAt: string;
  updatedAt: string;
  type: RequestType;
  status: RequestStatus;
  title: string;
  comment: string | null;
}

export interface CalendarRequestResponse {
  id: number;
  author: ShortUser;
  concierge: ShortUser | null;
  createdAt: string;
  updatedAt: string;
  type: RequestType;
  status: RequestStatus;
  title: string;
  comment: string | null;
  dateTimeFields: [string];
}

export interface RequestType {
  id: number;
  name: string;
}

export enum RequestStatus {
  TO_DO = 'TO_DO',
  WAIT_PAYMENT = 'WAIT_PAYMENT',
  PAID = 'PAID',
  DONE = 'DONE',
  DECLINED = 'DECLINED',
}

export function getStatusText(strings: Strings, status: RequestStatus): string {
  switch (status) {
    case RequestStatus.TO_DO:
      return strings.service_request_status_todo;
    case RequestStatus.WAIT_PAYMENT:
      return strings.service_request_status_wait_payment;
    case RequestStatus.PAID:
      return strings.service_request_status_paid;
    case RequestStatus.DONE:
      return strings.service_request_status_done;
    case RequestStatus.DECLINED:
      return strings.service_request_status_declined;
  }
}

export function getStatusType(status: RequestStatus): EvaStatus {
  switch (status) {
    case RequestStatus.DONE:
      return 'success';
    case RequestStatus.TO_DO:
    case RequestStatus.WAIT_PAYMENT:
    case RequestStatus.PAID:
      return 'warning';
    case RequestStatus.DECLINED:
      return 'danger';
  }
}
