import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import AppRouteView from '../../../../../uikit/AppRouteView';
import {GhostLink} from '../../../../../uikit/AppButtons';

export const ShopCategoryContainer = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: 28px;
      margin-left: 16px;
      margin-right: 16px;
    `}
`;

export const ShopCategoryHeadContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 2px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 0;
    `};
`;

export const ShopCategoryHeadTitle = styled.Text`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: ${props => props.theme.colors['color-basic-900']};

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 16px;
      line-height: 22px;
    `}
`;

export const ShopCategoryHeadLink = styled(GhostLink).attrs({
  status: 'danger',
})`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  padding: 0;
`;

export const ShopCategoryContentContainer = styled.ScrollView`
  padding: 6px 0;
`;

export const ShopCategoryCard = styled(AppRouteView)<{lastItem?: boolean}>`
  display: flex;
  justify-content: space-between;
  width: 205px;
  height: 128px;
  border-radius: 2px;
  overflow: hidden;
  margin-left: 12px;
  margin-bottom: 6px;
  margin-right: ${props => (props.lastItem ? '12px' : 0)};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 0;
      margin-right: ${props.lastItem ? 0 : '12px'};
      margin-bottom: 12px;
    `}
`;

export const ShopCategoryImage = styled.Image`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ShopCategoryCardTitleContainer = styled.View`
  z-index: 10;
  width: 100%;
  padding: 4px 8px;
  background: #000000b2;
  min-height: 40px;
`;

export const ShopCategoryCardTitle = styled.Text`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.white};
`;

export const ShopCardBadgesContainer = styled.View`
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-left: 8px;
`;

export const ShopCardLocationBadge = styled.Text`
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;
  border-radius: 8px;
  overflow: hidden;
  padding: 2px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: ${props => props.theme.colors['color-primary-500']};
  color: ${props => props.theme.colors['color-basic-900']};
`;

export const ShopCardTagBadge = styled(ShopCardLocationBadge)`
  background-color: ${props => props.theme.colors.white};
`;
