import {formatWithMask} from 'react-native-mask-input';

export default function usePhoneMask(value: string) {
  const mobilePhoneMask = [
    '0',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  const landingPhoneMask = [
    '0',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const phoneCodes = ['7', '8', '9'];
  const isMobilePhone = phoneCodes.includes(value?.[1]);
  const phoneLength = isMobilePhone
    ? mobilePhoneMask.length
    : landingPhoneMask.length;

  const {masked, unmasked} = formatWithMask({
    text: value,
    mask: isMobilePhone ? mobilePhoneMask : landingPhoneMask,
  });

  const updatedUnmasked = unmasked ? `0${unmasked}` : '';

  return {masked, unmasked: updatedUnmasked, phoneLength};
}
