import {createAction} from '@reduxjs/toolkit';
import {User} from '../../../api/user/models/responses/user';
import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {JwtUser} from '../../../api/user/models/responses/jwt-user';
import {UserProfileResponse} from '../../../api/user/models/responses/user-profile';
import {UpdateProfileRequest} from '../../../api/user/models/requests/update-profile';

export const setJWTUserAction = createAction<JwtUser | null>('SET_JWT_USER');

export const userRequestAction = createAsyncAction<void, User | null, Error>(
  'USER_REQUEST',
);

export const getUserProfileRequestAction = createAsyncAction<
  void,
  UserProfileResponse,
  Error
>('GET_USER_PROFILE_REQUEST');

export const updateUserProfileRequestAction = createAsyncAction<
  UpdateProfileRequest,
  UserProfileResponse,
  Error
>('UPDATE_USER_PROFILE_REQUEST');

export const deleteAccountRequestAction = createAsyncAction<void, void, Error>(
  'DELETE_ACCOUNT_REQUEST',
);

export const logoutRequestAction = createAsyncAction<void, void, Error>(
  'LOGOUT_REQUEST',
);
