import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const ExchangeHistory = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      d="M16 2.667c7.364 0 13.333 5.969 13.333 13.333S23.364 29.333 16 29.333 2.667 23.364 2.667 16h2.666c0 5.89 4.776 10.667 10.667 10.667 5.89 0 10.667-4.776 10.667-10.667 0-5.89-4.776-10.667-10.667-10.667a10.645 10.645 0 00-8.18 3.82L10.667 12h-8V4l3.262 3.261A13.306 13.306 0 0116 2.667z"
    />
    <Path
      fill="#DC9B2D"
      d="M19.238 15.111h-4.571V12.89h4.571v-2.222L22.667 14l-3.429 3.333v-2.222zm-6.476 4h4.571V16.89h-4.571v-2.222L9.333 18l3.429 3.333v-2.222z"
    />
  </Svg>
);

export default ExchangeHistory;
