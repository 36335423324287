import {all, call, put, takeLatest} from 'redux-saga/effects';
import {loginRequestAction} from './action';
import api from '../../../api';
import {saveTokens} from '../../../storage';
import {setJWTUserAction} from '../../user/store/action';
import {LoginResponse} from '../../../api/user/models/responses/login';
import {DefaultResponse} from '../../../api/models/response/default';
import {getUserFromJwt} from '../../../api/user/get-user-from-jwt';
import {UserRole} from '../../../api/user/models/responses/user';
import {appSelect} from '../../../store/saga';
import {Strings} from '../../../resources/strings';

const loginRequest = function* (
  action: ReturnType<typeof loginRequestAction.request>,
) {
  try {
    const strings: Strings | null = yield appSelect(store => store.app.strings);

    const response: DefaultResponse<LoginResponse> = yield call(
      [api, api.user.login],
      action.payload,
    );

    const user = getUserFromJwt(response.data.accessToken);
    if (user.role !== UserRole.USER && user.role !== UserRole.MEMBER) {
      throw new Error(strings?.app_error_invalid_user_role);
    }

    yield call(
      saveTokens,
      response.data.accessToken,
      response.data.refreshToken,
    );

    yield put(setJWTUserAction(user));

    yield put(loginRequestAction.success(response.data));
  } catch (error) {
    yield put(loginRequestAction.failure(error as Error));
  }
};

export const loginSaga = function* () {
  yield all([takeLatest(loginRequestAction.request, loginRequest)]);
};
