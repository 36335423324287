import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import Heart from '../../../../assets/icons/Heart';
import HeartOutline from '../../../../assets/icons/HeartOutline';

export const ShopContainerContent = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 24px;
      padding-right: 128px;
    `}
`;

export const ShopFavoritesButton = styled.TouchableOpacity<{
  isActive?: boolean;
}>`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 8px 18px;
      border-radius: 2px;
      background: ${props.isActive
        ? `${props.theme.colors['color-danger-500']}2D`
        : '#e9e0d8'};
    `}
`;

export const ShopFavoritesIcon = styled(Heart)`
  max-width: 24px;
  max-height: 24px;
`;

export const ShopFavoritesOutlineIcon = styled(HeartOutline)``;

export const ShopScrollView = styled.ScrollView`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding-bottom: 24px;
    `}
`;
