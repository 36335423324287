import React from 'react';
import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Spinner, Text} from '@ui-kitten/components';
import Empty from '../../../../assets/images/Empty.png';
import Done from '../../../../assets/icons/Done';
import Plus from '../../../../assets/icons/Plus';
import {AppInput} from '../../../../uikit/AppInput';
import Search from '../../../../assets/icons/Search';

export const FriendNewSearch = styled(AppInput).attrs({
  keyboardType: 'phone-pad',
  accessoryRight: props => {
    return <Search {...props} />;
  },
})`
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 0;
    `}
`;

export const FriendNewEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const FriendNewEmptyIcon = styled.Image.attrs({
  source: Empty,
})`
  width: 192px;
  height: 192px;
`;

export const FriendNewEmptyTitle = styled(Text)`
  font-weight: bold;
  font-size: 20px;
`;
export const FriendNewEmptyInfo = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  font-weight: 300;
  font-size: 16px;
`;

export const FriendNewItemContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 0;
    `}
`;

export const FriendNewItemInfo = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FriendNewItemInfoName = styled(Text)`
  font-weight: 300;
  font-size: 16px;
`;
export const FriendNewItemInfoId = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-weight: 300;
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const FriendNewItemInfoStatus = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-size: 12px;
  font-weight: bold;
`;

export const FriendNewItemAddButton = styled.TouchableOpacity``;
export const FriendNewItemAddButtonIcon = styled(Plus).attrs(props => ({
  fill: props.theme.colors['color-basic-500'],
}))``;

export const FriendNewItemAddedIcon = styled(Done)``;

export const FriendNewItemProgress = styled(Spinner)``;
