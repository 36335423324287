import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTheme} from 'styled-components/native';
import {BackHandler} from 'react-native';

import {settingsRequestAction} from '../../../app/store/action';
import useMinimalAmount from '../../../../utils/use-minimal-amount';
import useConvertRate from '../../../../utils/use-convert-rate';
import {SettingId} from '../../../../api/settings/models/settingsResponse';
import {useStrings} from '../../../../utils/providers/strings';
import {useAppDispatch, useRequestSelector} from '../../../../store/store';
import useRouter from '../../../../utils/use-router';
import useFieldServerError from '../../../../utils/react-hook-form/use-field-server-error';
import {onNumberChangeText} from 'scl/src/react-native/input/on-number-change-text';
import {
  buyPointsByBtcRequestAction,
  buyPointsRequestAction,
} from '../../store/action';

import {ScreenLayoutWithHeader} from '../../../../uikit/ScreenLayout';
import AppHeader from '../../../../uikit/AppHeader';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import {AccessoryLoadingIndicator} from '../../../../uikit/AccessoryLoadingIndicator';
import ConciergePointsPaymentByBtc from './components/payment-btc';
import {AppLoader} from '../../../../uikit/AppLoader';
import {AppError} from '../../../../uikit/AppError';

import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../../uikit/Points/container';

import {ConciergePointsBuyByBtcContainer} from '../by-virtualpay/styles';
import {
  ConciergePointsAddAmountButtons,
  ConciergePointsBuyButton,
  ConciergePointsBuyCourseTitle,
  ConciergePointsBuyHeader,
  ConciergePointsBuyInput,
  ConciergePointsBuyInputError,
  ConciergePointsBuyKeyboardAvoidingView,
} from '../components/inputs-virtualpay/styles';
import {AppButtonGroup} from '../../../../uikit/AppButtonGroup';
import {AppButton} from '../../../../uikit/AppButtons';
import {
  ConciergePointsAmountAfterChargeContainer,
  ConciergePointsAmountAfterChargePoints,
  ConciergePointsAmountAfterChargeSuffix,
  ConciergePointsAmountAfterChargeTitle,
} from '../styles';

import {CurrencyTypes} from '../../../../api/user/models/responses/user-profile';

export interface ConciergePointsBuyByBtcForm {
  amount: string;
}

export default function ConciergePointsBuyByBtcScreen() {
  const theme = useTheme();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const buyByBtcRequest = useRequestSelector(
    store => store.conciergePoints.buyPointsByBtcRequest,
  );
  const settingsRequest = useRequestSelector(
    store => store.app.settingsRequest,
  );

  const [screenType, setScreenType] = useState(
    ConciergePointsBuyScreenType.INPUTS,
  );

  const {
    control,
    handleSubmit,
    formState: {errors},
    setError,
    setValue,
    watch,
  } = useForm<ConciergePointsBuyByBtcForm>({
    defaultValues: {
      amount: '',
    },
  });

  const getUserProfileRequest = useRequestSelector(
    store => store.user.getUserProfileRequest,
  );

  const cpPoints = useMemo<number>(() => {
    return (
      getUserProfileRequest.data?.wallets?.find(
        wallet => wallet.currency === CurrencyTypes.CP,
      )?.amount || 0
    );
  }, [getUserProfileRequest.data]);

  const chargedCpPoints = watch('amount') || 0;

  const convertRate = useConvertRate();

  const minimalAmount = useMinimalAmount(
    SettingId.BTC_PAY_MINIMAL_BUY_CP_AMOUNT,
  );

  const onBack = () => {
    switch (screenType) {
      case ConciergePointsBuyScreenType.INPUTS:
        router.back('/cp');
        return true;
      case ConciergePointsBuyScreenType.PAYMENT:
        dispatch(buyPointsByBtcRequestAction.clean());
        dispatch(buyPointsRequestAction.clean());
        setScreenType(ConciergePointsBuyScreenType.INPUTS);
        return true;
    }
  };

  const onBuy = (form: ConciergePointsBuyByBtcForm) => {
    dispatch(
      buyPointsByBtcRequestAction.request({
        amount: Number(form.amount),
      }),
    );
  };

  /** Add value to CP`s amount on button click */
  const handleAddAmount = (value: number) => () => {
    const prevValue = watch('amount') || 0;
    setValue('amount', String(+prevValue + value));
    setError('amount', {message: ''});
  };

  useEffect(() => {
    return function () {
      dispatch(buyPointsByBtcRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (buyByBtcRequest.data) {
      setScreenType(ConciergePointsBuyScreenType.PAYMENT);
    }
  }, [buyByBtcRequest.data]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBack,
    );

    return () => backHandler.remove();
  }, []);

  useFieldServerError(buyByBtcRequest, setError);

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.concierge_points_buy_btc_title}
              onBackPressed={onBack}
            />
            {(() => {
              switch (screenType) {
                case ConciergePointsBuyScreenType.INPUTS:
                  if (settingsRequest.isLoading) {
                    return <AppLoader />;
                  }
                  if (settingsRequest.error) {
                    return (
                      <AppError
                        error={settingsRequest.error.message}
                        retry={() => dispatch(settingsRequestAction.request())}
                      />
                    );
                  }
                  if (settingsRequest.data) {
                    return (
                      <ConciergePointsBuyKeyboardAvoidingView>
                        <ConciergePointsBuyByBtcContainer>
                          <ConciergePointsBuyHeader>
                            {strings.concierge_points_buy_points_title}
                          </ConciergePointsBuyHeader>
                          <Controller
                            control={control}
                            rules={{
                              required:
                                strings.concierge_points_buy_points_required,
                              validate: value =>
                                Number(value) >= minimalAmount ||
                                strings.app_minimal_value_fn(minimalAmount),
                            }}
                            render={({
                              field: {onChange, onBlur, value},
                              fieldState: {error},
                            }) => (
                              <>
                                <ConciergePointsBuyInput
                                  status={error ? 'danger' : 'basic'}
                                  onBlur={onBlur}
                                  onChangeText={onNumberChangeText(onChange)}
                                  value={value}
                                  placeholder={
                                    strings.concierge_points_buy_points_placeholder
                                  }
                                />
                                <ConciergePointsBuyCourseTitle>
                                  {`${
                                    strings.app_number(Number(value)) || 0
                                  } CP = ${
                                    strings.app_number(
                                      convertRate * Number(value),
                                    ) || 0
                                  } JPY`}
                                </ConciergePointsBuyCourseTitle>
                              </>
                            )}
                            name="amount"
                          />
                          {errors.amount && (
                            <ConciergePointsBuyInputError>
                              {errors.amount.message}
                            </ConciergePointsBuyInputError>
                          )}
                          <ConciergePointsAddAmountButtons>
                            <AppButtonGroup>
                              <AppButton onPress={handleAddAmount(500)}>
                                {`+${strings.app_number(500)}`}
                              </AppButton>
                              <AppButton onPress={handleAddAmount(1000)}>
                                {`+${strings.app_number(1000)}`}
                              </AppButton>
                              <AppButton onPress={handleAddAmount(3000)}>
                                {`+${strings.app_number(3000)}`}
                              </AppButton>
                              <AppButton onPress={handleAddAmount(5000)}>
                                {`+${strings.app_number(5000)}`}
                              </AppButton>
                              <AppButton onPress={handleAddAmount(10000)}>
                                {`+${strings.app_number(10000)}`}
                              </AppButton>
                              <AppButton onPress={handleAddAmount(25000)}>
                                {`+${strings.app_number(25000)}`}
                              </AppButton>
                            </AppButtonGroup>
                          </ConciergePointsAddAmountButtons>

                          <ConciergePointsAmountAfterChargeContainer>
                            <ConciergePointsAmountAfterChargeTitle>
                              {strings.concierge_points_balance_after_charge}
                            </ConciergePointsAmountAfterChargeTitle>
                            <ConciergePointsAmountAfterChargePoints>
                              {strings.app_number(
                                cpPoints + Number(chargedCpPoints),
                              )}
                            </ConciergePointsAmountAfterChargePoints>
                            <ConciergePointsAmountAfterChargeSuffix>
                              {strings.points_suffix.toUpperCase()}
                            </ConciergePointsAmountAfterChargeSuffix>
                          </ConciergePointsAmountAfterChargeContainer>
                        </ConciergePointsBuyByBtcContainer>
                        <ConciergePointsBuyButton
                          disabled={buyByBtcRequest.isLoading}
                          accessoryRight={
                            buyByBtcRequest.isLoading
                              ? AccessoryLoadingIndicator
                              : undefined
                          }
                          onPress={handleSubmit(onBuy)}>
                          {strings.concierge_points_buy_button.toUpperCase()}
                        </ConciergePointsBuyButton>
                      </ConciergePointsBuyKeyboardAvoidingView>
                    );
                  }
                  return null;
                case ConciergePointsBuyScreenType.PAYMENT:
                  if (!buyByBtcRequest.data) return null;
                  return (
                    <ConciergePointsPaymentByBtc
                      checkoutUrl={buyByBtcRequest.data.checkoutUrl}
                      redirectUrl={buyByBtcRequest.data.redirectUrl}
                    />
                  );
              }
            })()}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}

enum ConciergePointsBuyScreenType {
  INPUTS,
  PAYMENT,
}
