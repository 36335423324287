//TODO Think about namings
export const dimens = {
  smallestSpacer: '4px',
  smallSpacer: '8px',
  normalSpacer: '16px',
  bigSpacer: '32px',
  biggestSpacer: '64px',
};

export type Dimens = typeof dimens;
