import styled from 'styled-components/native';
import {SafeAreaView} from 'react-native-safe-area-context';

export const AppSafeAreaView = styled(SafeAreaView).attrs({
  edges: ['left', 'right'],
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${props => props.theme.colors.white};
`;
