import styled from 'styled-components/native';
import {Text} from '@ui-kitten/components';
import {css} from 'scl/src/styled-components/css';
import {AppButton} from '../../../uikit/AppButtons';
import {AppInput} from '../../../uikit/AppInput';
import HideKeyboardContainer from '../../../uikit/HideKeyboardContainer';
import AppKeyboardAvoidingView from '../../../uikit/AppKeyboardAvoidingView';
import {ComponentProps, ComponentType} from 'react';
import {ImageProps, ImageStyle} from 'react-native';
import Eye from '../../../assets/icons/Eye';
import EyeOff from '../../../assets/icons/EyeOff';
import Logo from '../../../assets/icons/LoginLogo';

export const RestoreConfirmContainerOutside = styled(HideKeyboardContainer)`
  flex: 1;
`;

export const RestoreConfirmContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const RestoreConfirmContainerView = styled(AppKeyboardAvoidingView)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  justify-content: center;

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: stretch;
      align-items: center;
    `}
`;

export const RestoreConfirmContentView = styled.View`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors['color-basic-400']};
  border-radius: 4px;

  ${props => {
    return (
      props.theme.isDesktop &&
      css`
        flex: 1;
        margin-top: 10%;
        margin-bottom: 10%;
        padding: 40px;
      `
    );
  }}
`;

export const RestoreConfirmLogoContainer = styled.View`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const RestoreConfirmLogo = styled<
  ComponentType<ImageProps & ComponentProps<typeof Logo>>
>(Logo)``;

export const RestoreConfirmLogoText = styled(Text)`
  font-weight: 300;
  font-size: 24px;
  margin-left: ${props => props.theme.dimens.smallSpacer};
`;

export const RestoreConfirmTitleText = styled(Text)`
  margin-top: ${props => props.theme.dimens.bigSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
`;

export const RestoreConfirmFieldLabel = styled(Text)`
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
`;

export const RestoreConfirmInput = styled(AppInput)``;

export const RestoreConfirmEye = styled<
  ComponentType<ImageProps & ComponentProps<typeof Eye>>
>(Eye).attrs(props => ({
  fill: (props.style as ImageStyle)?.tintColor,
}))``;

export const RestoreConfirmEyeOff = styled<
  ComponentType<ImageProps & ComponentProps<typeof EyeOff>>
>(EyeOff).attrs(props => ({
  fill: (props.style as ImageStyle)?.tintColor,
}))``;

export const RestoreConfirmInputErrorText = styled(Text).attrs({
  status: 'danger',
})``;

export const RestoreConfirmButton = styled(AppButton).attrs({})`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

export const RestoreConfirmRules = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};
`;
