import styled from 'styled-components/native';

export const AppButtonGroupWrapper = styled.View`
  position: relative;
  overflow: hidden;
`;

export const AppButtonGroupContainer = styled.ScrollView.attrs({
  horizontal: true,
  showsHorizontalScrollIndicator: false,
})``;
