import {Asset} from 'react-native-image-picker';

export interface RequestCategoryType {
  screens: RequestCategoryTypeScreen[];
}

export interface RequestCategoryTypeScreen {
  title: string | null;
  fields: RequestCategoryTypeField[];
}

export interface ShortRequestCategoryTypeField {
  id: string;
  title: string;
  type: RequestCategoryTypeFieldType;
}

export interface RequestCategoryTypeField
  extends ShortRequestCategoryTypeField {
  required: boolean;
  emptyMessage?: string;
  extraData?:
    | RequestCategoryTypeFieldExtraDataDate
    | RequestCategoryTypeFieldExtraDataMinMaxValues
    | RequestCategoryTypeFieldExtraDataRadioGroup
    | RequestCategoryTypeFieldExtraDataSingleSelection;
}

export enum RequestCategoryTypeFieldType {
  INPUT = 'INPUT',
  DATE = 'DATE',
  TIME = 'TIME',
  RADIO_GROUP = 'RADIO_GROUP',
  SINGLE_SELECTION = 'SINGLE_SELECTION',
  INPUT_NUMBER = 'NUMBER',
  CHECKBOX = 'CHECKBOX',
  STEPPER = 'STEPPER',
  ATTACHMENT_IMAGE = 'ATTACHMENT_IMAGE',
}

export type RequestDateValueJson = {isFixed: boolean; date?: string};

export type RequestCategoryTypeFieldExtraDataDate = {
  minDate: string;
  maxDate: string;
};
export type RequestCategoryTypeFieldExtraDataMinMaxValues = {
  minVal: number;
  maxValue: number;
};
export interface RequestCategoryTypeFieldExtraDataRadioGroupItem {
  id: string;
  value: string;
}
export type RequestCategoryTypeFieldExtraDataRadioGroup =
  RequestCategoryTypeFieldExtraDataRadioGroupItem[];

export interface RequestCategoryTypeFieldExtraDataSingleSelectionItem {
  id: string;
  value: string;
}
export type RequestCategoryTypeFieldExtraDataSingleSelection =
  RequestCategoryTypeFieldExtraDataSingleSelectionItem[];

export interface RequestCategoryTypeFieldExtraDataCheckBoxItem {
  id: string;
  value: string;
}

export type RequestCategoryTypeFieldExtraDataCheckBox =
  RequestCategoryTypeFieldExtraDataCheckBoxItem[];

export interface RequestCategoryTypeFieldExtraDataAttachItem {
  id: number;
  asset: Asset | null;
  isLoading: boolean;
  key: string;
}

export type RequestCategoryTypeFieldExtraDataAttach =
  RequestCategoryTypeFieldExtraDataAttachItem[];
