import React, {useCallback, useEffect, useState} from 'react';
import AppHeader from '../../uikit/AppHeader';
import {useStrings} from '../../utils/providers/strings';
import {ScreenLayoutWithHeader} from '../../uikit/ScreenLayout';
import {useAppDispatch, useRequestSelector} from '../../store/store';
import {useTheme} from 'styled-components/native';
import {
  createRequestRequestAction,
  getRequestCategoriesRequestAction,
  getRequestCategoryTypeRequestAction,
} from './store/action';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../uikit/Points/container';
import NewRequestCategories from './components/categories';
import {
  RequestCategory,
  RequestCategorySubtype,
} from '../../api/request/models/responses/categories';
import useRouter from '../../utils/use-router';
import {AppLoader} from '../../uikit/AppLoader';
import {AppError} from '../../uikit/AppError';
import NewRequestSubcategories from './components/subcategories';
import NewRequestQuestion from './components/question';
import {useToast} from 'react-native-toast-notifications';
import NewRequestConfirm, {
  NewRequestConfirmForm,
  NewRequestQuestionItem,
} from './components/confirm';
import {getAnswer, NewRequestQuestionForm} from './components/question/form';
import NewRequestChangeField from './components/change-field';
import AppModal from '../../uikit/AppModal';
import {AppModalRoute, AppModalTitle} from '../../uikit/AppModal/styles';
import {BackHandler, Keyboard} from 'react-native';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {useForm} from 'react-hook-form';
import {useFocusEffect} from '@react-navigation/native';

export default function NewRequestScreen() {
  const router = useRouter();
  const strings = useStrings();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const categoriesRequest = useRequestSelector(
    store => store.newRequest.getRequestCategoriesRequest,
  );
  const categoryTypeRequest = useRequestSelector(
    store => store.newRequest.getRequestCategoryTypeRequest,
  );
  const createRequest = useRequestSelector(
    store => store.newRequest.createRequestRequest,
  );

  const [screenType, setScreenType] = useState(NewRequestScreenType.CATEGORIES);
  const [selectedCategory, setSelectedCategory] =
    useState<RequestCategory | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<RequestCategorySubtype | null>(null);
  const [answers, setAnswers] = useState<NewRequestQuestionForm[]>([]);
  const [currentScreenPosition, setCurrentScreenPosition] = useState(0);
  const [selectedField, setSelectedField] =
    useState<NewRequestQuestionItem | null>(null);

  const questionScreen =
    categoryTypeRequest.data?.screens?.[currentScreenPosition];

  const {control, handleSubmit, formState, reset} =
    useForm<NewRequestConfirmForm>({
      defaultValues: {
        title: '',
        comment: '',
      },
    });

  const onInit = useCallback(() => {
    dispatch(getRequestCategoriesRequestAction.request());

    return function () {
      dispatch(getRequestCategoriesRequestAction.clean());
      dispatch(getRequestCategoryTypeRequestAction.clean());
      dispatch(createRequestRequestAction.clean());
    };
  }, [dispatch]);
  useFocusEffect(onInit);

  useEffect(() => {
    if (createRequest.error) {
      toast.show(createRequest.error.message);
    }
  }, [createRequest.error, toast]);

  const onBackPressed = useCallback((): boolean => {
    switch (screenType) {
      case NewRequestScreenType.CATEGORIES:
        router.back('/requests');
        break;
      case NewRequestScreenType.SUBCATEGORIES:
        setScreenType(NewRequestScreenType.CATEGORIES);
        break;
      case NewRequestScreenType.QUESTIONS:
        // TODO: If changed backend need remove this condition
        if (selectedCategory?.name === 'Other') {
          setScreenType(NewRequestScreenType.CATEGORIES);
          break;
        }

        if (currentScreenPosition) {
          setCurrentScreenPosition(currentScreenPosition - 1);
        } else {
          setAnswers([]);
          reset();
          if (selectedCategory?.subtypes) {
            setScreenType(NewRequestScreenType.SUBCATEGORIES);
          } else {
            setScreenType(NewRequestScreenType.CATEGORIES);
          }
        }
        break;
      case NewRequestScreenType.CONFIRM:
        if (selectedField) {
          setSelectedField(null);
        } else {
          setScreenType(NewRequestScreenType.QUESTIONS);
        }
        break;
    }
    return true;
  }, [
    currentScreenPosition,
    reset,
    router,
    screenType,
    selectedCategory?.subtypes,
    selectedField,
  ]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBackPressed,
    );

    return () => backHandler.remove();
  }, [onBackPressed]);

  const onSelectCategory = (category: RequestCategory) => {
    setSelectedCategory(category);
    if (category.subtypes) {
      setScreenType(NewRequestScreenType.SUBCATEGORIES);
    } else {
      setScreenType(NewRequestScreenType.QUESTIONS);
      dispatch(
        getRequestCategoryTypeRequestAction.request(String(category.id)),
      );
    }
  };

  const onSelectSubcategory = (subcategory: RequestCategorySubtype) => {
    setSelectedSubcategory(subcategory);
    setScreenType(NewRequestScreenType.QUESTIONS);
    dispatch(
      getRequestCategoryTypeRequestAction.request(String(subcategory.id)),
    );
  };

  const changeAnswer = (
    answer: NewRequestQuestionForm,
    screenPosition: number,
  ) => {
    const newAnswers = [...answers];
    if (newAnswers.length <= screenPosition) {
      newAnswers.push(answer);
    } else {
      newAnswers[screenPosition] = answer;
    }
    setAnswers(newAnswers);
  };

  const onAnswer = (answer: NewRequestQuestionForm, screenPosition: number) => {
    changeAnswer(answer, screenPosition);
    const newScreenPosition = screenPosition + 1;
    if (categoryTypeRequest.data?.screens.length === newScreenPosition) {
      setScreenType(NewRequestScreenType.CONFIRM);
    } else {
      setCurrentScreenPosition(newScreenPosition);
    }
  };

  const onChangeAnswer = (
    answer: NewRequestQuestionForm,
    screenPosition: number,
  ) => {
    changeAnswer(answer, screenPosition);
    setSelectedField(null);
  };

  const onConfirm = (form: NewRequestConfirmForm) => {
    const typeId = selectedSubcategory
      ? selectedSubcategory.id
      : selectedCategory?.id;
    if (typeId === undefined) {
      return;
    }
    Keyboard.dismiss();

    dispatch(
      createRequestRequestAction.request({
        typeId,
        title: form.title,
        comment: form.comment || null,
        fields: answers.flatMap(answer =>
          Object.entries(answer).map(([fieldId, answer]) => {
            const value = getAnswer(answer);
            return {
              fieldId,
              value: value !== undefined ? value : null,
            };
          }),
        ),
      }),
    );
  };

  const onRetryCategories = () => {
    dispatch(getRequestCategoriesRequestAction.request());
  };

  const onRetryCategoryType = () => {
    if (selectedSubcategory) {
      dispatch(
        getRequestCategoryTypeRequestAction.request(
          String(selectedSubcategory.id),
        ),
      );
    } else if (selectedCategory) {
      dispatch(
        getRequestCategoryTypeRequestAction.request(
          String(selectedCategory.id),
        ),
      );
    }
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          {categoriesRequest.isLoading || categoryTypeRequest.isLoading ? (
            <AppLoader />
          ) : categoriesRequest.error || categoryTypeRequest.error ? (
            <AppError
              error={
                categoriesRequest.error?.message ||
                categoryTypeRequest.error?.message
              }
              retry={
                categoriesRequest.error
                  ? onRetryCategories
                  : onRetryCategoryType
              }
            />
          ) : (
            <ScreenLayoutWithHeader>
              <AppHeader
                title={(() => {
                  switch (screenType) {
                    case NewRequestScreenType.CATEGORIES:
                      return strings.new_request_categories_title;
                    case NewRequestScreenType.SUBCATEGORIES:
                      return selectedCategory?.name || '';
                    case NewRequestScreenType.QUESTIONS:
                      return (
                        selectedSubcategory?.name ||
                        selectedCategory?.name ||
                        ''
                      );
                    case NewRequestScreenType.CONFIRM:
                      if (selectedField) {
                        return strings.new_request_change_field_title;
                      }
                      return (
                        selectedSubcategory?.name ||
                        selectedCategory?.name ||
                        ''
                      );
                  }
                })()}
                onBackPressed={onBackPressed}
              />
              {(() => {
                switch (screenType) {
                  case NewRequestScreenType.CATEGORIES:
                    return (
                      categoriesRequest.data && (
                        <NewRequestCategories
                          categories={categoriesRequest.data}
                          onSelect={onSelectCategory}
                        />
                      )
                    );
                  case NewRequestScreenType.SUBCATEGORIES:
                    return (
                      selectedCategory?.subtypes && (
                        <NewRequestSubcategories
                          subcategories={selectedCategory.subtypes}
                          onSelect={onSelectSubcategory}
                          // TODO: If changed backend need remove withoutList props
                          withoutList={selectedCategory?.name === 'Other'}
                        />
                      )
                    );
                  case NewRequestScreenType.QUESTIONS:
                    return (
                      questionScreen && (
                        <NewRequestQuestion
                          key={currentScreenPosition}
                          screen={questionScreen}
                          screenPosition={currentScreenPosition}
                          questionsCount={
                            categoryTypeRequest.data?.screens.length || 0
                          }
                          onAnswer={onAnswer}
                          onBack={onBackPressed}
                          answers={answers[currentScreenPosition]}
                        />
                      )
                    );
                  case NewRequestScreenType.CONFIRM:
                    if (selectedField) {
                      return (
                        <NewRequestChangeField
                          field={selectedField}
                          onBack={onBackPressed}
                          onAnswer={onChangeAnswer}
                        />
                      );
                    }
                    return (
                      categoryTypeRequest.data && (
                        <NewRequestConfirm
                          control={control}
                          formState={formState}
                          onConfirm={handleSubmit(onConfirm)}
                          type={categoryTypeRequest.data}
                          answers={answers}
                          setSelectedField={setSelectedField}
                        />
                      )
                    );
                }
              })()}
            </ScreenLayoutWithHeader>
          )}
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>

      <AppModal visible={!!createRequest.data}>
        {createRequest.data && (
          <>
            <AppModalTitle>
              {strings.new_request_confirm_modal_title_fn(
                createRequest.data.title,
              )}
            </AppModalTitle>
            <AppModalRoute
              to="/requests"
              onPress={() => router.push('/requests')}>
              {strings.new_request_confirm_modal_button.toUpperCase()}
            </AppModalRoute>
          </>
        )}
      </AppModal>
    </>
  );
}

enum NewRequestScreenType {
  CATEGORIES,
  SUBCATEGORIES,
  QUESTIONS,
  CONFIRM,
}
