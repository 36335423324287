import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {
  ConciergePointsBuyByBtcResponse,
  ConciergePointsBuyByCybersourceResponse,
  ConciergePointsBuyByBankTransferResponse,
  ConciergePointsBuyResponse,
} from '../../../api/concierge-points/models/response/buy';
import {PaymentInfoResponse} from '../../../api/concierge-points/models/response/status';
import {
  buyPointsByBtcRequestAction,
  buyPointsCybersourceRequestAction,
  buyPointsByBankTransferRequestAction,
  buyPointsRequestAction,
  paymentStatusRequestAction,
} from './action';

export const conciergePointsReducer = combineReducers<{
  buyPointsRequest: RequestState<ConciergePointsBuyResponse> | null;
  buyPointsByBtcRequest: RequestState<ConciergePointsBuyByBtcResponse> | null;
  buyPointsByCybersourceRequest: RequestState<ConciergePointsBuyByCybersourceResponse> | null;
  paymentStatusRequest: RequestState<PaymentInfoResponse> | null;
  buyPointsByBankTransferRequest: RequestState<ConciergePointsBuyByBankTransferResponse> | null;
}>({
  buyPointsRequest: createRequestReducer(buyPointsRequestAction),
  buyPointsByBtcRequest: createRequestReducer(buyPointsByBtcRequestAction),
  buyPointsByCybersourceRequest: createRequestReducer(
    buyPointsCybersourceRequestAction,
  ),
  paymentStatusRequest: createRequestReducer(paymentStatusRequestAction),
  buyPointsByBankTransferRequest: createRequestReducer(
    buyPointsByBankTransferRequestAction,
  ),
});
