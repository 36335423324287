import React, {useEffect, useMemo, useState} from 'react';
import {
  Controller,
  Control,
  FieldErrors,
  UseFormWatch,
  UseFormSetValue,
  UseFormSetError,
} from 'react-hook-form';

import {useStrings} from '../../../../../utils/providers/strings';
import {ConciergePointsVirtualpayBuyForm} from '../../by-virtualpay/screen';
import {
  ConciergePointsBuyFieldExtraDataSingleSelection,
  ConciergePointsBuyFieldSingleSelectionState,
} from '../inputs-common/form';

import {onNumberChangeText} from 'scl/src/react-native/input/on-number-change-text';
import {AccessoryLoadingIndicator} from '../../../../../uikit/AccessoryLoadingIndicator';
import {useAppDispatch, useRequestSelector} from '../../../../../store/store';
import {AppLoader} from '../../../../../uikit/AppLoader';
import {AppError} from '../../../../../uikit/AppError';
import {settingsRequestAction} from '../../../../app/store/action';
import useConvertRate from '../../../../../utils/use-convert-rate';
import {AppButton} from '../../../../../uikit/AppButtons';
import {AppButtonGroup} from '../../../../../uikit/AppButtonGroup';

import {
  ConciergePointsBuyButton,
  ConciergePointsBuyContainer,
  ConciergePointsBuyCourseTitle,
  ConciergePointsBuyHeader,
  ConciergePointsBuyInput,
  ConciergePointsBuyInputError,
  ConciergePointsBuyInputTitle,
  ConciergePointsBuyKeyboardAvoidingView,
  ConciergePointsBuyAutocomplete,
  ConciergePointsBuyAutocompleteItem,
  ConciergePointsBuyTextInput,
  ConciergePointsAddAmountButtons,
} from './styles';
import {
  ConciergePointsAmountAfterChargeContainer,
  ConciergePointsAmountAfterChargePoints,
  ConciergePointsAmountAfterChargeSuffix,
  ConciergePointsAmountAfterChargeTitle,
} from '../../styles';

import {CurrencyTypes} from '../../../../../api/user/models/responses/user-profile';

interface Props {
  isLoading: boolean;
  control: Control<ConciergePointsVirtualpayBuyForm>;
  errors: FieldErrors<ConciergePointsVirtualpayBuyForm>;
  onBuy: () => void;
  watch: UseFormWatch<ConciergePointsVirtualpayBuyForm>;
  setFieldValue: UseFormSetValue<ConciergePointsVirtualpayBuyForm>;
  setError: UseFormSetError<ConciergePointsVirtualpayBuyForm>;
}

export default function ConciergePointsBuyVirtualpayInputs({
  isLoading,
  control,
  errors,
  onBuy,
  watch,
  setFieldValue,
  setError,
}: Props) {
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const countryCodeValue = watch('countryCode');

  const settingsRequest = useRequestSelector(
    store => store.app.settingsRequest,
  );

  const getUserProfileRequest = useRequestSelector(
    store => store.user.getUserProfileRequest,
  );

  const cpPoints = useMemo<number>(() => {
    return (
      getUserProfileRequest.data?.wallets?.find(
        wallet => wallet.currency === CurrencyTypes.CP,
      )?.amount || 0
    );
  }, [getUserProfileRequest.data]);

  const chargedCpPoints = watch('amount') || 0;

  const [countryQuery, setCountryQuery] = useState(
    countryCodeValue?.value || '',
  );

  const convertRate = useConvertRate();

  // const minimalAmount: number = useMinimalAmount(
  //   SettingId.VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT,
  // );

  const extraData: ConciergePointsBuyFieldExtraDataSingleSelection = useMemo(
    () =>
      strings.countryData.map(
        ({value, label}: {value: string; label: string}) => ({
          id: value,
          value: label,
        }),
      ),
    [strings.countryData],
  );

  const filteredExtraData = useMemo(
    () =>
      extraData.filter(({value}) =>
        value.toLowerCase().includes(countryQuery.toLowerCase()),
      ),
    [extraData, countryQuery],
  );

  /** Add value to CP`s amount on button click */
  const handleAddAmount = (value: number) => () => {
    const prevValue = watch('amount') || 0;
    setFieldValue('amount', String(+prevValue + value));
    setError('amount', {message: ''});
  };

  useEffect(() => {
    if (countryCodeValue) {
      setCountryQuery(countryCodeValue.value);
    }
  }, [countryCodeValue]);

  const onChangeCountryQuery = (
    onChange: (state: ConciergePointsBuyFieldSingleSelectionState) => void,
  ) => {
    return function (query) {
      setCountryQuery(query);
      const findCountry = extraData.find(country => country.value === query);
      if (findCountry) {
        onChange(findCountry);
      } else {
        onChange(undefined);
      }
    };
  };

  if (settingsRequest.isLoading) {
    return <AppLoader />;
  }

  if (settingsRequest.error || !settingsRequest.data) {
    return (
      <AppError
        error={settingsRequest.error?.message}
        retry={() => dispatch(settingsRequestAction.request())}
      />
    );
  }

  return (
    <ConciergePointsBuyKeyboardAvoidingView>
      <ConciergePointsBuyContainer>
        <ConciergePointsBuyHeader>
          {strings.concierge_points_buy_points_title}
        </ConciergePointsBuyHeader>
        <Controller
          control={control}
          rules={{
            required: strings.concierge_points_buy_points_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <>
              <ConciergePointsBuyInput
                status={error ? 'danger' : 'basic'}
                onBlur={onBlur}
                onChangeText={onNumberChangeText(onChange)}
                value={value}
                placeholder={strings.concierge_points_buy_points_placeholder}
              />
              <ConciergePointsBuyCourseTitle>
                {`${strings.app_number(Number(value)) || 0} CP = ${
                  strings.app_number(convertRate * Number(value)) || 0
                } JPY`}
              </ConciergePointsBuyCourseTitle>
            </>
          )}
          name="amount"
        />
        {errors.amount && (
          <ConciergePointsBuyInputError>
            {errors.amount.message}
          </ConciergePointsBuyInputError>
        )}

        <ConciergePointsAddAmountButtons>
          <AppButtonGroup>
            <AppButton onPress={handleAddAmount(500)}>
              {`+${strings.app_number(500)}`}
            </AppButton>
            <AppButton onPress={handleAddAmount(1000)}>
              {`+${strings.app_number(1000)}`}
            </AppButton>
            <AppButton onPress={handleAddAmount(3000)}>
              {`+${strings.app_number(3000)}`}
            </AppButton>
            <AppButton onPress={handleAddAmount(5000)}>
              {`+${strings.app_number(5000)}`}
            </AppButton>
            <AppButton onPress={handleAddAmount(10000)}>
              {`+${strings.app_number(10000)}`}
            </AppButton>
            <AppButton onPress={handleAddAmount(25000)}>
              {`+${strings.app_number(25000)}`}
            </AppButton>
          </AppButtonGroup>
        </ConciergePointsAddAmountButtons>

        <ConciergePointsAmountAfterChargeContainer>
          <ConciergePointsAmountAfterChargeTitle>
            {strings.concierge_points_balance_after_charge}
          </ConciergePointsAmountAfterChargeTitle>
          <ConciergePointsAmountAfterChargePoints>
            {strings.app_number(cpPoints + Number(chargedCpPoints))}
          </ConciergePointsAmountAfterChargePoints>
          <ConciergePointsAmountAfterChargeSuffix>
            {strings.points_suffix.toUpperCase()}
          </ConciergePointsAmountAfterChargeSuffix>
        </ConciergePointsAmountAfterChargeContainer>

        <ConciergePointsBuyInputTitle>
          {strings.concierge_points_buy_country_code_title}
        </ConciergePointsBuyInputTitle>
        <Controller
          control={control}
          rules={{
            required: strings.concierge_points_buy_country_code_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
            return (
              <ConciergePointsBuyAutocomplete
                status={error ? 'danger' : 'basic'}
                onBlur={onBlur}
                value={countryQuery}
                onChangeText={onChangeCountryQuery(onChange)}
                onSelect={index => {
                  onChange(filteredExtraData[index]);
                  setCountryQuery(filteredExtraData[index].value);
                }}
                placeholder={
                  strings.concierge_points_buy_country_code_placeholder
                }>
                {filteredExtraData?.map(item => {
                  return (
                    <ConciergePointsBuyAutocompleteItem
                      key={item.id}
                      title={item.value}
                    />
                  );
                })}
              </ConciergePointsBuyAutocomplete>
            );
          }}
          name="countryCode"
        />
        {errors.countryCode && (
          <ConciergePointsBuyInputError>
            {errors.countryCode.message}
          </ConciergePointsBuyInputError>
        )}
        <ConciergePointsBuyInputTitle>
          {strings.concierge_points_buy_city_title}
        </ConciergePointsBuyInputTitle>
        <Controller
          control={control}
          rules={{
            required: strings.concierge_points_buy_city_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <ConciergePointsBuyTextInput
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholder={strings.concierge_points_buy_city_placeholder}
            />
          )}
          name="city"
        />
        {errors.city && (
          <ConciergePointsBuyInputError>
            {errors.city.message}
          </ConciergePointsBuyInputError>
        )}
        <ConciergePointsBuyInputTitle>
          {strings.concierge_points_buy_postal_code_title}
        </ConciergePointsBuyInputTitle>
        <Controller
          control={control}
          rules={{
            required: strings.concierge_points_buy_postal_code_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <ConciergePointsBuyTextInput
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholder={strings.concierge_points_buy_postal_code_placeholder}
            />
          )}
          name="postalCode"
        />
        {errors.postalCode && (
          <ConciergePointsBuyInputError>
            {errors.postalCode.message}
          </ConciergePointsBuyInputError>
        )}
        <ConciergePointsBuyInputTitle>
          {strings.concierge_points_buy_email_title}
        </ConciergePointsBuyInputTitle>
        <Controller
          control={control}
          rules={{
            required: strings.concierge_points_buy_email_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <ConciergePointsBuyTextInput
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholder={strings.concierge_points_buy_email_placeholder}
            />
          )}
          name="email"
        />
        {errors.email && (
          <ConciergePointsBuyInputError>
            {errors.email.message}
          </ConciergePointsBuyInputError>
        )}
        <ConciergePointsBuyInputTitle>
          {strings.concierge_points_buy_first_name_title}
        </ConciergePointsBuyInputTitle>
        <Controller
          control={control}
          rules={{
            required: strings.concierge_points_buy_first_name_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <ConciergePointsBuyTextInput
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholder={strings.concierge_points_buy_first_name_placeholder}
            />
          )}
          name="firstName"
        />
        {errors.firstName && (
          <ConciergePointsBuyInputError>
            {errors.firstName.message}
          </ConciergePointsBuyInputError>
        )}
        <ConciergePointsBuyInputTitle>
          {strings.concierge_points_buy_last_name_title}
        </ConciergePointsBuyInputTitle>
        <Controller
          control={control}
          rules={{
            required: strings.concierge_points_buy_last_name_required,
          }}
          render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
            <ConciergePointsBuyTextInput
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              placeholder={strings.concierge_points_buy_last_name_placeholder}
              lastItem
            />
          )}
          name="lastName"
        />
        {errors.lastName && (
          <ConciergePointsBuyInputError>
            {errors.lastName.message}
          </ConciergePointsBuyInputError>
        )}
      </ConciergePointsBuyContainer>
      <ConciergePointsBuyButton
        disabled={isLoading}
        accessoryRight={isLoading ? AccessoryLoadingIndicator : undefined}
        onPress={onBuy}>
        {strings.concierge_points_buy_button.toUpperCase()}
      </ConciergePointsBuyButton>
    </ConciergePointsBuyKeyboardAvoidingView>
  );
}
