import React, {useCallback, useEffect} from 'react';
import {ListRenderItemInfo} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useTheme} from 'styled-components/native';
import {useFocusEffect} from '@react-navigation/native';

import {useStrings} from '../../../utils/providers/strings';
import {
  useAppDispatch,
  usePaginationRequestSelector,
} from '../../../store/store';
import {REQUESTS_SHOP_REVIEWS_LIMIT} from '../store/saga';
import {REQUESTS_LIMIT} from '../../requests/store/saga';
import {PaginationRequest} from '../../../utils/redux/create-pagination-request-reducer';
import {getAllReviewsListRequestAction} from '../store/action';
import {ShopReviewsResponse} from '../../../api/shops/models/responses/shop-reviews';

import AppStatusBar from '../../../uikit/AppStatusBar';
import {AppLayout} from '../../../uikit/AppLayout';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import AppRefreshControl from '../../../uikit/AppRefreshControl';

import {RequestsListEmptyInfo} from '../../requests/styles';
import {ShopsContainerContainer} from '../styles';
import {
  AppTransparentHeader,
  ScreenListEmptyContainer,
  ScreenListEmptyIcon,
  ScreenListEmptyTitle,
  ScreenListLoading,
  ScreenListLoadingContainer,
  ShopReviewsViewedContainerContent,
  ShopReviewsViewedList,
  ShopReviewsViewedListContainer,
  ShopReviewItemAuthor,
  ShopReviewItemContainer,
  ShopReviewItemDescription,
  ShopStarContainer,
  ShopStarIcon,
  ShopStarOutlineIcon,
  ShopHeadContainer,
  ShopHeadTitle,
} from './styles';

export default function AllReviewsShopScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const allReviewsListRequest = usePaginationRequestSelector(
    store => store.shops.allReviewsListRequest,
  );

  const isEmptyList = !allReviewsListRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getAllReviewsListRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      allReviewsListRequest.error &&
      allReviewsListRequest.data?.items.length
    ) {
      toast.show(allReviewsListRequest.error.message);
    }
  }, [allReviewsListRequest]);

  const onFirstPage = useCallback(() => {
    const request: PaginationRequest = {
      limit: REQUESTS_SHOP_REVIEWS_LIMIT,
      offset: 0,
    };

    dispatch(getAllReviewsListRequestAction.request(request));
  }, [dispatch]);

  const onInit = useCallback(() => {
    dispatch(getAllReviewsListRequestAction.clean());
    onFirstPage();
  }, [dispatch, onFirstPage]);

  useFocusEffect(onInit);

  const onNextPage = () => {
    if (
      allReviewsListRequest.data?.isLastPage ||
      allReviewsListRequest.isLoading
    )
      return;

    const request: PaginationRequest = {
      limit: REQUESTS_LIMIT,
      offset: allReviewsListRequest.data?.items.length || 0,
    };

    dispatch(getAllReviewsListRequestAction.request(request));
  };

  const renderShopCardItem = ({
    item,
  }: ListRenderItemInfo<ShopReviewsResponse>) => {
    return (
      <ShopReviewItemContainer to={`/shops/reviews/${item.id}`}>
        <ShopHeadContainer>
          <ShopStarContainer>
            {[...Array(5)].map((_, index) => {
              return index + 1 <= item.rate ? (
                <ShopStarIcon key={index} />
              ) : (
                <ShopStarOutlineIcon key={index} />
              );
            })}
          </ShopStarContainer>

          {!item.approved && (
            <ShopHeadTitle>
              {strings.shop_review_on_moderation_title}
            </ShopHeadTitle>
          )}
        </ShopHeadContainer>
        <ShopReviewItemAuthor>
          {`${item.author.firstName} ${item.author.lastName}`}
        </ShopReviewItemAuthor>
        <ShopReviewItemDescription>
          {item.description}
        </ShopReviewItemDescription>
      </ShopReviewItemContainer>
    );
  };

  const renderScreenListLoading = () => {
    return (
      <ScreenListLoadingContainer>
        <ScreenListLoading />
      </ScreenListLoadingContainer>
    );
  };

  const renderScreenListEmpty = () => {
    return (
      <ScreenListEmptyContainer>
        <ScreenListEmptyIcon />
        <ScreenListEmptyTitle>
          {strings.shop_no_reviews_title}
        </ScreenListEmptyTitle>
        <RequestsListEmptyInfo>
          {strings.shop_recently_try_later_text}
        </RequestsListEmptyInfo>
      </ScreenListEmptyContainer>
    );
  };

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopReviewsViewedContainerContent>
          <AppLayout>
            {isEmptyList && allReviewsListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && allReviewsListRequest.error ? (
              <AppError
                error={allReviewsListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              <>
                <AppTransparentHeader
                  title={strings.shop_all_reviews_title}
                  backNoHistoryRoute="/shops"
                />
                {allReviewsListRequest.data && (
                  <ShopReviewsViewedListContainer>
                    <ShopReviewsViewedList
                      numColumns={theme.isDesktop ? 3 : 1}
                      refreshControl={
                        <AppRefreshControl
                          onRefresh={onFirstPage}
                          refreshing={allReviewsListRequest.isFirstLoading}
                        />
                      }
                      data={allReviewsListRequest.data.items}
                      renderItem={renderShopCardItem}
                      keyExtractor={item => String(item.id)}
                      onEndReached={onNextPage}
                      ListFooterComponent={
                        allReviewsListRequest.isLoading
                          ? renderScreenListLoading
                          : null
                      }
                      ListEmptyComponent={renderScreenListEmpty}
                    />
                  </ShopReviewsViewedListContainer>
                )}
              </>
            )}
          </AppLayout>
        </ShopReviewsViewedContainerContent>
      </ShopsContainerContainer>
    </>
  );
}
