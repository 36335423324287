import React from 'react';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {AppLayout} from '../../uikit/AppLayout';
import {
  TermOfServiceContainer,
  TermOfServiceScrollView,
  TermOfServiceText,
} from './styles';
import AppHeader from '../../uikit/AppHeader';
import {useStrings} from '../../utils/providers/strings';
import AppMarkdownViewer from '../../uikit/AppMarkdownViewer';

export default function TermOfServiceScreen() {
  const strings = useStrings();

  return (
    <>
      <AppWhiteStatusBar />
      <AppLayout>
        <TermOfServiceContainer>
          <AppHeader
            title={strings.term_of_service_title}
            backNoHistoryRoute="/sign-up"
          />
          <TermOfServiceScrollView>
            <TermOfServiceText>
              <AppMarkdownViewer>
                {strings.term_of_service_text}
              </AppMarkdownViewer>
            </TermOfServiceText>
          </TermOfServiceScrollView>
        </TermOfServiceContainer>
      </AppLayout>
    </>
  );
}
