import React, {useState} from 'react';
import {Dimensions, Platform} from 'react-native';
import {
  DetailRequestInfoCommonBigImage,
  DetailRequestInfoCommonImageButton,
  DetailRequestInfoContainer,
  DetailRequestInfoImagePreview,
  DetailRequestInfoImagesContainer,
  DetailRequestInfoItemContainer,
  DetailRequestInfoItemStatus,
  DetailRequestInfoItemText,
  DetailRequestInfoItemTitle,
  DetailRequestInfoRequestInfoTitleMobile,
} from './styles';
import {useAppDispatch, useRequestSelector} from '../../../../../store/store';
import AppRequest from '../../../../../uikit/AppRequest';
import {getDetailRequestRequestAction} from '../../../store/action';
import {
  getStatusText,
  getStatusType,
} from '../../../../../api/request/models/responses';
import {useStrings} from '../../../../../utils/providers/strings';
import {useTheme} from 'styled-components/native';
import {
  DetailRequestAnswer,
  getAnswerString,
} from '../../../../../api/request/models/responses/detail';
import {RequestCategoryTypeFieldType} from '../../../../../api/request/models/responses/category-type';
import {Modal} from '@ui-kitten/components';

const DetailRequestAttachmentImages = ({
  answer,
  onAction,
}: {
  answer: DetailRequestAnswer;
  onAction: (imageUrl: string) => void;
}) => {
  const strings = useStrings();
  const images: string[] = answer.value ? JSON.parse(answer.value) : [];

  return (
    <DetailRequestInfoImagesContainer>
      {images.length ? (
        images.map(item => {
          return (
            <DetailRequestInfoCommonImageButton
              key={item}
              onPress={() => onAction(item)}>
              <DetailRequestInfoImagePreview source={{uri: item}} />
            </DetailRequestInfoCommonImageButton>
          );
        })
      ) : (
        <DetailRequestInfoItemText>
          {strings.detail_request_no_images}
        </DetailRequestInfoItemText>
      )}
    </DetailRequestInfoImagesContainer>
  );
};

export default function DetailRequestInfo({requestId}: {requestId: string}) {
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const request = useRequestSelector(store => store.requests.request);

  const screenHeight = Dimensions.get('window').height;
  const modalStyle = {
    width: Platform.OS === 'web' ? '640px' : '100%',
    height: screenHeight,
  };
  const imageStyle = {
    height: screenHeight,
    backgroundColor: Platform.OS === 'web' ? 'transparent' : 'black',
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const handleImageModal = (url: string) => {
    setImageUrl(url);
    setIsModalOpen(true);
  };

  return (
    <>
      <AppRequest
        request={request}
        retry={() =>
          dispatch(getDetailRequestRequestAction.request(requestId))
        }>
        {request.data && (
          <DetailRequestInfoContainer>
            <DetailRequestInfoItemContainer>
              <DetailRequestInfoItemTitle>
                {strings.detail_request_status_title}
              </DetailRequestInfoItemTitle>
              <DetailRequestInfoItemStatus
                status={getStatusType(request.data.status)}>
                {getStatusText(strings, request.data.status)}
              </DetailRequestInfoItemStatus>
            </DetailRequestInfoItemContainer>

            <DetailRequestInfoItemContainer>
              <DetailRequestInfoItemTitle>
                {strings.detail_request_created_date_title}
              </DetailRequestInfoItemTitle>
              <DetailRequestInfoItemText>
                {strings.app_date_string(request.data.createdAt)}
              </DetailRequestInfoItemText>
            </DetailRequestInfoItemContainer>

            {request.data.concierge && (
              <DetailRequestInfoItemContainer>
                <DetailRequestInfoItemTitle>
                  {strings.detail_request_concierge_title}
                </DetailRequestInfoItemTitle>
                <DetailRequestInfoItemText>
                  {request.data.concierge.firstName}
                </DetailRequestInfoItemText>
              </DetailRequestInfoItemContainer>
            )}

            {!theme.isDesktop && (
              <DetailRequestInfoRequestInfoTitleMobile>
                {strings.detail_request_request_info_title}
              </DetailRequestInfoRequestInfoTitleMobile>
            )}

            {request.data.fields.map(answer => (
              <DetailRequestInfoItemContainer key={answer.id}>
                <DetailRequestInfoItemTitle>
                  {answer.title}
                </DetailRequestInfoItemTitle>
                <DetailRequestInfoItemText>
                  {answer.type ===
                  RequestCategoryTypeFieldType.ATTACHMENT_IMAGE ? (
                    <DetailRequestAttachmentImages
                      onAction={handleImageModal}
                      answer={answer}
                    />
                  ) : (
                    getAnswerString(strings, answer)
                  )}
                </DetailRequestInfoItemText>
              </DetailRequestInfoItemContainer>
            ))}

            <DetailRequestInfoItemContainer>
              <DetailRequestInfoItemTitle>
                {strings.detail_request_comment_title}
              </DetailRequestInfoItemTitle>
              <DetailRequestInfoItemText>
                {request.data.comment || ''}
              </DetailRequestInfoItemText>
            </DetailRequestInfoItemContainer>
          </DetailRequestInfoContainer>
        )}
      </AppRequest>

      <Modal
        visible={isModalOpen}
        style={modalStyle}
        onBackdropPress={() => setIsModalOpen(false)}>
        <DetailRequestInfoCommonImageButton
          onPress={() => setIsModalOpen(false)}>
          <DetailRequestInfoCommonBigImage
            style={imageStyle}
            source={{uri: imageUrl}}
            resizeMode="contain"
          />
        </DetailRequestInfoCommonImageButton>
      </Modal>
    </>
  );
}
