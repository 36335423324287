import {ShortUser} from '../../../user/models/responses/short-user';
import {Strings} from '../../../../resources/strings';
import {EvaStatus} from '@ui-kitten/components/devsupport';

export interface TransferResponse {
  id: number;
  fromUser: ShortUser;
  toUser?: ShortUser;
  amount: number;
  status: TransferRequestStatus;
  createdAt: string;
  updatedAt: string;
}

//TODO Update logic
export enum TransferRequestStatus {
  PENDING = 'PENDING',
  APPROVED_BY_ADMIN = 'APPROVED_BY_ADMIN',
  APPROVED_BY_USER = 'APPROVED_BY_USER',
  REJECTED = 'REJECTED',
}

export function getRequestChatMessageTransferRequestStatusText(
  strings: Strings,
  status: TransferRequestStatus,
): string {
  switch (status) {
    case TransferRequestStatus.PENDING:
      return strings.detail_chat_transfer_request_status_pending;
    case TransferRequestStatus.APPROVED_BY_ADMIN:
      return strings.detail_chat_transfer_request_status_approved_by_admin;
    case TransferRequestStatus.APPROVED_BY_USER:
      return strings.detail_chat_transfer_request_status_approved_by_user;
    case TransferRequestStatus.REJECTED:
      return strings.detail_chat_transfer_request_status_rejected;
  }
}

export function getRequestChatMessageTransferRequestStatusEva(
  status: TransferRequestStatus,
): EvaStatus {
  switch (status) {
    case TransferRequestStatus.PENDING:
      return 'basic';
    case TransferRequestStatus.APPROVED_BY_ADMIN:
      return 'basic';
    case TransferRequestStatus.APPROVED_BY_USER:
      return 'success';
    case TransferRequestStatus.REJECTED:
      return 'danger';
  }
}
