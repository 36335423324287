import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import {AppLink} from '../../../../../../uikit/AppButtons';
import Success from '../../../../../../assets/images/Success.png';

export const SplitPaymentSuccessPngIcon = styled.Image.attrs({
  source: Success,
})`
  width: 192px;
  height: 192px;
`;

export const SplitPaymentMainContainer = styled.ScrollView`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SplitPaymentInfoContainer = styled.View`
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: ${props.theme.dimens.normalSpacer};
      padding-bottom: ${props.theme.dimens.normalSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;

export const SplitPaymentInfoItemContainer = styled.View<{last?: boolean}>`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
  border-bottom-width: ${props => (props.last ? 0 : '1px')};
`;

export const SplitPaymentInfoItemTitle = styled(Text)`
  font-size: 16px;
`;

export const SplitPaymentInfoItemText = styled(Text)`
  color: ${props => props.theme.colors['color-basic-800']};
  font-size: 14px;
`;

export const SplitPaymentInfoRequestInfoTitleMobile = styled(Text)`
  margin-bottom: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const SplitPaymentFriendsContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-bottom: ${props => props.theme.dimens.normalSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;

export const SplitPaymentFriendItemContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-bottom: ${props => props.theme.dimens.normalSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  background-color: ${props => props.theme.colors.white};
  border-radius: 4px;
`;

export const SplitPaymentButtonsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top-width: 1px;
  border-top-color: ${props => props.theme.colors['color-basic-200']};
  background-color: ${props => props.theme.colors.white};
  padding: 8px 4px;

  ${props =>
    props.theme.isDesktop &&
    css`
      background-color: transparent;
      border-top-width: 0;
    `}
`;

export const SplitPaymentHomeButton = styled(AppLink)`
  margin-left: 4px;
  margin-right: 4px;
  flex-grow: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-grow: normal;
    `}
`;

export const SplitPaymentSuccessIconContainer = styled.View`
  align-self: center;
  margin-top: ${props => props.theme.dimens.bigSpacer};
`;

export const SplitPaymentSuccessTitle = styled(Text).attrs({
  category: 'h5',
})`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  align-self: center;
  font-weight: bold;
`;

export const SplitPaymentSuccessDetailsTitle = styled(Text)`
  margin: ${props => props.theme.dimens.normalSpacer};
`;
