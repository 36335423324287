import {useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import useEndlessRepeater from 'scl/src/hooks/use-endless-repeater';
import {useTheme} from 'styled-components/native';
import {PaymentInfoStatus} from '../../../../api/concierge-points/models/response/status';
import {useAppDispatch, useRequestSelector} from '../../../../store/store';
import AppHeader from '../../../../uikit/AppHeader';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../../uikit/ScreenLayout';
import {useStrings} from '../../../../utils/providers/strings';
import useRouter from '../../../../utils/use-router';
import {getUserProfileRequestAction} from '../../../user/store/action';
import {paymentStatusRequestAction} from '../../store/action';
import {
  ConciergePointsBuyByBTCErrorButton,
  ConciergePointsBuyByBTCErrorIcon,
  ConciergePointsBuyByBTCSpinner,
  ConciergePointsBuyByBTCSuccessButton,
  ConciergePointsBuyByBTCSuccessContainer,
  ConciergePointsBuyByBTCSuccessIcon,
  ConciergePointsBuyByBTCSuccessMessage,
} from './styles';

interface ConciergePointsBuyByBTCSuccessParams {
  id: string;
}

export default function ConciergePointsBuyByBTCSuccess() {
  const theme = useTheme();
  const route = useRoute();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const params = route.params as
    | ConciergePointsBuyByBTCSuccessParams
    | undefined;
  const paymentStatusRequest = useRequestSelector(
    store => store.conciergePoints.paymentStatusRequest,
  );

  const [paymentInfoStatus, setPaymentInfoStatus] = useState(
    PaymentInfoStatus.Processing,
  );

  useEffect(() => {
    return function () {
      dispatch(paymentStatusRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (paymentStatusRequest.data) {
      setPaymentInfoStatus(
        paymentStatusRequest.data?.status ?? PaymentInfoStatus.Processing,
      );
    }
  }, [paymentStatusRequest.data, router]);

  useEndlessRepeater(10000, () => {
    if (params?.id) {
      dispatch(paymentStatusRequestAction.request(params.id));
    }
  });

  const onSuccessClick = () => {
    dispatch(getUserProfileRequestAction.request());
    router.push('/');
  };

  const onErrorClick = () => {
    router.back('/cp');
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={
                paymentInfoStatus === PaymentInfoStatus.Success
                  ? strings.concierge_points_buy_success_title
                  : paymentInfoStatus === PaymentInfoStatus.Processing
                  ? strings.concierge_points_buy_waiting_title
                  : strings.concierge_points_buy_error_title
              }
            />
            <ConciergePointsBuyByBTCSuccessContainer>
              {paymentInfoStatus === PaymentInfoStatus.Success ? (
                <ConciergePointsBuyByBTCSuccessIcon />
              ) : paymentInfoStatus === PaymentInfoStatus.Processing ? (
                <ConciergePointsBuyByBTCSpinner />
              ) : (
                <ConciergePointsBuyByBTCErrorIcon />
              )}
              <ConciergePointsBuyByBTCSuccessMessage>
                {paymentInfoStatus === PaymentInfoStatus.Success
                  ? strings.concierge_points_buy_success_message
                  : paymentInfoStatus === PaymentInfoStatus.Processing
                  ? strings.concierge_points_buy_waiting_transaction_complete_message
                  : strings.concierge_points_buy_error_message}
              </ConciergePointsBuyByBTCSuccessMessage>
              {paymentInfoStatus === PaymentInfoStatus.Success && (
                <ConciergePointsBuyByBTCSuccessButton onPress={onSuccessClick}>
                  {strings.concierge_points_buy_success_button}
                </ConciergePointsBuyByBTCSuccessButton>
              )}
              {paymentInfoStatus === PaymentInfoStatus.Failed && (
                <ConciergePointsBuyByBTCErrorButton onPress={onErrorClick}>
                  {strings.concierge_points_buy_error_button}
                </ConciergePointsBuyByBTCErrorButton>
              )}
            </ConciergePointsBuyByBTCSuccessContainer>
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
