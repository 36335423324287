import React, {useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {useTheme} from 'styled-components/native';
import AppHeader from '../../../uikit/AppHeader';
import {useStrings} from '../../../utils/providers/strings';
import {
  StandardPointsWithdrawalButton,
  StandardPointsWithdrawalContainer,
  StandardPointsWithdrawalHeader,
  StandardPointsWithdrawalInput,
  StandardPointsWithdrawalInputError,
  StandardPointsWithdrawalKeyboardAvoidingView,
  StandardPointsWithdrawalOutside,
} from './styles';
import {Controller, useForm} from 'react-hook-form';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import {onNumberChangeText} from 'scl/src/react-native/input/on-number-change-text';
import {AccessoryLoadingIndicator} from '../../../uikit/AccessoryLoadingIndicator';
import {useToast} from 'react-native-toast-notifications';
import {AppWhiteStatusBar} from '../../../uikit/AppStatusBar';
import {withdrawalPointsRequestAction} from '../store/action';
import useRouter from '../../../utils/use-router';
import {SettingId} from '../../../api/settings/models/settingsResponse';
import useMinimalAmount from '../../../utils/use-minimal-amount';

interface WithdrawalForm {
  amount: string;
}

export default function StandardPointsWithdrawalScreen() {
  const toast = useToast();
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const withdrawalPointsRequest = useRequestSelector(
    store => store.standardPoints.withdrawalPointsRequest,
  );

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<WithdrawalForm>({
    defaultValues: {
      amount: '',
    },
  });

  const minimalAmount: number = useMinimalAmount(
    SettingId.SP_MINIMAL_AMOUNT_TO_WITHDRAW,
  );

  useEffect(() => {
    return function () {
      dispatch(withdrawalPointsRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (withdrawalPointsRequest.data) {
      toast.show(strings.standard_points_withdrawal_success, {type: 'success'});
      router.push('/withdrawals');
    }
  }, [withdrawalPointsRequest.data, toast, strings, router]);

  useEffect(() => {
    if (withdrawalPointsRequest.error) {
      toast.show(withdrawalPointsRequest.error.message);
    }
  }, [withdrawalPointsRequest.error, toast]);

  const onWithdrawal = (form: WithdrawalForm) => {
    dispatch(
      withdrawalPointsRequestAction.request({
        amount: Number(form.amount),
      }),
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.standard_points_withdrawal_title}
              backNoHistoryRoute="/"
            />
            <StandardPointsWithdrawalOutside>
              <StandardPointsWithdrawalKeyboardAvoidingView>
                <StandardPointsWithdrawalContainer>
                  <StandardPointsWithdrawalHeader>
                    {strings.standard_points_withdrawal_input_title}
                  </StandardPointsWithdrawalHeader>
                  <Controller
                    control={control}
                    rules={{
                      required: strings.standard_points_withdrawal_required,
                      validate: value =>
                        Number(value) >= minimalAmount ||
                        strings.app_minimal_value_fn(minimalAmount),
                    }}
                    render={({
                      field: {onChange, onBlur, value},
                      fieldState: {error},
                    }) => (
                      <StandardPointsWithdrawalInput
                        status={error ? 'danger' : 'basic'}
                        onBlur={onBlur}
                        onChangeText={onNumberChangeText(onChange)}
                        value={value}
                        placeholder={
                          strings.standard_points_withdrawal_placeholder
                        }
                      />
                    )}
                    name="amount"
                  />
                  {errors.amount && (
                    <StandardPointsWithdrawalInputError>
                      {errors.amount.message}
                    </StandardPointsWithdrawalInputError>
                  )}
                </StandardPointsWithdrawalContainer>
                <StandardPointsWithdrawalButton
                  disabled={withdrawalPointsRequest.isLoading}
                  accessoryRight={
                    withdrawalPointsRequest.isLoading
                      ? AccessoryLoadingIndicator
                      : undefined
                  }
                  onPress={handleSubmit(onWithdrawal)}>
                  {strings.standard_points_withdrawal_button.toUpperCase()}
                </StandardPointsWithdrawalButton>
              </StandardPointsWithdrawalKeyboardAvoidingView>
            </StandardPointsWithdrawalOutside>
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
