import React, {useEffect} from 'react';
import type {TextStyle} from 'react-native';
import {useTheme} from 'styled-components/native';
import {Route, useRoute} from '@react-navigation/native';

import {useAppDispatch, useAppSelector} from '../../../../store/store';
import {infoActions} from '../../store/action';
import {formatInfoPostDate} from '../../lib';

import {PointsContainerPoints} from '../../../../uikit/Points/container';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import {AppLayout} from '../../../../uikit/AppLayout';
import {AppLoader} from '../../../../uikit/AppLoader';
import AppHeader from '../../../../uikit/AppHeader';
import {AppError} from '../../../../uikit/AppError';
import CallFAB from '../../../../uikit/CallFAB';

import {
  DetailedInfoScreenContainer,
  DetailedInfoScreenContentWrapper,
  DetailedInfoScreenContent,
  DetailedInfoScreenDate,
  DetailedInfoScreenTitle,
  DetailedInfoScreenTextContainer,
} from './styles';
import renderTextWithUrls from '../../../../utils/text-with-urls';

const DetailInfoScreen = () => {
  const route = useRoute<Route<string, {id: string}>>();
  const fetchDetailedInfoPost = useAppSelector(
    state => state.info.fetchDetailedInfoPost,
  );
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const requestId = route.params.id;

  useEffect(() => {
    if (requestId) dispatch(infoActions.fetchPost.request(Number(requestId)));
  }, [requestId]);

  const titleStyles: TextStyle = theme.isDesktop
    ? {
        fontWeight: '600',
        fontSize: 28,
        lineHeight: 36,
      }
    : {
        fontSize: 16,
      };

  return (
    <>
      <AppWhiteStatusBar />
      <DetailedInfoScreenContainer>
        <DetailedInfoScreenContentWrapper>
          <AppHeader
            backNoHistoryRoute="/info"
            style={{marginBottom: 24, backgroundColor: 'transparent'}}
            titleStyle={titleStyles}
          />
          {fetchDetailedInfoPost.data ? (
            <DetailedInfoScreenContent>
              <DetailedInfoScreenDate>
                {formatInfoPostDate(
                  new Date(fetchDetailedInfoPost.data.createdAt),
                )}
              </DetailedInfoScreenDate>
              <DetailedInfoScreenTitle>
                {fetchDetailedInfoPost.data.title}
              </DetailedInfoScreenTitle>
              <DetailedInfoScreenTextContainer>
                {renderTextWithUrls(fetchDetailedInfoPost.data.text)}
              </DetailedInfoScreenTextContainer>
            </DetailedInfoScreenContent>
          ) : fetchDetailedInfoPost.isLoading ? (
            <AppLoader />
          ) : (
            <AppError error={fetchDetailedInfoPost.error?.message} />
          )}
          <AppLayout>{!theme.isDesktop && <CallFAB />}</AppLayout>
        </DetailedInfoScreenContentWrapper>
        {theme.isDesktop && <PointsContainerPoints />}
      </DetailedInfoScreenContainer>
    </>
  );
};

export {DetailInfoScreen};
