import React, {useEffect} from 'react';
import {useTheme} from 'styled-components/native';

import {useAppDispatch, useRequestSelector} from '../../store/store';
import {userRequestAction} from '../user/store/action';

import {useStrings} from '../../utils/providers/strings';
import useRouter from '../../utils/use-router';

import TouchableStripe from '../../uikit/TouchableStripe';
import {AppLayout} from '../../uikit/AppLayout';
import CallFAB from '../../uikit/CallFAB';

import {
  MyPageContainer,
  StripesGroup,
  MyPageUserMobileInfo,
  MyPageUserInfoLoader,
  MyPageUserInfoError,
} from './styles';
import AppStatusBar from '../../uikit/AppStatusBar';

const MyPageScreen = () => {
  const userRequest = useRequestSelector(store => store.user.userRequest);
  const dispatch = useAppDispatch();
  const strings = useStrings();
  const router = useRouter();
  const theme = useTheme();

  const fetchUserInfo = () => dispatch(userRequestAction.request());

  useEffect(() => {
    if (theme.isDesktop) router.replace('/');
  }, [theme.isDesktop]);

  useEffect(() => {
    if (
      !theme.isDesktop &&
      !userRequest.data &&
      !userRequest.error &&
      !userRequest.isLoading
    ) {
      fetchUserInfo();
    }
  }, [theme.isDesktop, userRequest]);

  return (
    <>
      <AppStatusBar />
      <MyPageContainer>
        {userRequest.data ? (
          <MyPageUserMobileInfo data={userRequest.data} />
        ) : userRequest.isLoading ? (
          <MyPageUserInfoLoader />
        ) : (
          userRequest.error && (
            <MyPageUserInfoError
              error={userRequest.error.message}
              retry={fetchUserInfo}
            />
          )
        )}

        <StripesGroup>
          <TouchableStripe title={strings.info_navigation_link_faq} to="/faq" />
          <TouchableStripe
            title={strings.info_navigation_link_privacy_policy}
            to="/privacy-policy"
          />
          <TouchableStripe
            title={strings.info_navigation_link_contact_us}
            to="/contact-us"
          />
          {/* <TouchableStripe
            title={strings.info_navigation_link_guidance_of_store_publication}
            to="/faq"
          /> */}
        </StripesGroup>
        <StripesGroup>
          <TouchableStripe
            title={strings.info_navigation_link_info}
            to="/info"
          />
          <TouchableStripe
            title={strings.info_navigation_link_operating_company}
            to="/operating-company"
          />
        </StripesGroup>
        <AppLayout>{!theme.isDesktop && <CallFAB />}</AppLayout>
      </MyPageContainer>
    </>
  );
};

export default MyPageScreen;
