import {createReducer} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';

import {infoActions} from './action';
import {
  RequestState,
  addRequestCase,
  initState,
} from 'scl/src/redux/create-request-reducer';
import {IInfo} from '../../../api/info/models/info';

export const infoReducer = combineReducers({
  fetchInfoList: createReducer(null, builder =>
    addRequestCase(builder, infoActions.fetchList),
  ),
  fetchDetailedInfoPost: createReducer<RequestState<IInfo | null>>(
    initState(),
    builder => addRequestCase(builder, infoActions.fetchPost),
  ),
});
