import React, {useEffect} from 'react';
import {useTheme} from 'styled-components/native';
import type {TextStyle} from 'react-native';

import {useAppDispatch, useInfoSelector} from '../../store/store';
import {useStrings} from '../../utils/providers/strings';
import {infoActions} from './store/action';

import {AppLoader} from '../../uikit/AppLoader';
import {InfoPostCard} from './ui/InfoPostCard';
import {PointsContainerPoints} from '../../uikit/Points/container';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';

import {
  InfoList,
  InfoScreenContentWrapper,
  InfoScreenContent,
  InfoScreenContainer,
  InfoScreenEmptyContainer,
  InfoScreenEmptyIcon,
  InfoScreenEmptyTitle,
  InfoScreenEmptyInfo,
  AppTransparentHeader,
} from './style';
import {AppLayout} from '../../uikit/AppLayout';
import CallFAB from '../../uikit/CallFAB';

const InfoScreen = () => {
  const {isLoading, data: infoPostsList} = useInfoSelector(
    state => state.info.fetchInfoList,
  );
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const strings = useStrings();

  useEffect(() => {
    dispatch(infoActions.fetchList.request());
  }, []);

  const listEmpty = !isLoading && !infoPostsList?.length;
  const titleStyles: TextStyle = theme.isDesktop
    ? {
        fontWeight: '600',
        fontSize: 28,
        lineHeight: 36,
      }
    : {
        fontSize: 16,
      };

  return (
    <>
      <AppWhiteStatusBar />
      <InfoScreenContainer>
        <InfoScreenContentWrapper>
          <AppTransparentHeader
            title={strings.info_title}
            backNoHistoryRoute={theme.isDesktop ? '/' : '/my-page'}
            titleStyle={titleStyles}
          />
          <InfoScreenContent>
            {isLoading && !infoPostsList && (
              <AppLoader style={{marginTop: 12}} />
            )}
            {listEmpty && (
              <InfoScreenEmptyContainer>
                <InfoScreenEmptyIcon />
                <InfoScreenEmptyTitle>
                  {strings.info_empty_list_title}
                </InfoScreenEmptyTitle>
                <InfoScreenEmptyInfo>
                  {strings.info_empty_list_message}
                </InfoScreenEmptyInfo>
              </InfoScreenEmptyContainer>
            )}
            {infoPostsList && !isLoading && (
              <InfoList>
                {infoPostsList.map(infoPost => (
                  <InfoPostCard
                    postId={infoPost.id}
                    date={infoPost.createdAt}
                    title={infoPost.title}
                    key={infoPost.id}
                  />
                ))}
              </InfoList>
            )}
          </InfoScreenContent>
          <AppLayout>{!theme.isDesktop && <CallFAB />}</AppLayout>
        </InfoScreenContentWrapper>
        {theme.isDesktop && <PointsContainerPoints />}
      </InfoScreenContainer>
    </>
  );
};

export default InfoScreen;
