import React from 'react';
import {ScreenLayout} from '../../uikit/ScreenLayout';
import AppStatusBar from '../../uikit/AppStatusBar';

export default function SettingsScreen() {
  return (
    <>
      <AppStatusBar />
      <ScreenLayout />
    </>
  );
}
