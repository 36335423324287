import React, {useCallback, useEffect, useState} from 'react';
import {Linking, TouchableWithoutFeedback} from 'react-native';
import {useAppDispatch, useRequestSelector} from '../../store/store';
import {loginRequestAction} from './store/action';
import {LoginRequest} from '../../api/user/models/requests/login';
import {useForm, Controller} from 'react-hook-form';
import useRouter from '../../utils/use-router/index';
import {AppLayout} from '../../uikit/AppLayout';
import {
  LoginContainerView,
  LoginEye,
  LoginEyeOff,
  LoginLogo,
  LoginInput,
  LoginInputErrorText,
  LoginTitleText,
  LoginContentView,
  LoginButton,
  LoginSignUpButton,
  LoginButtonDivider,
  LoginRestoreButton,
  LoginContainerOutside,
  LoginLogoContainer,
} from './styles';
import {useStrings} from '../../utils/providers/strings';
import {AccessoryLoadingIndicator} from '../../uikit/AccessoryLoadingIndicator';
import AppStatusBar from '../../uikit/AppStatusBar';
import useFieldServerError from '../../utils/react-hook-form/use-field-server-error';

export default function LoginScreen() {
  const strings = useStrings();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const loginRequest = useRequestSelector(state => state.login.loginRequest);

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
    setError,
  } = useForm<LoginRequest>({
    defaultValues: {
      login: '',
      password: '',
    },
  });
  const [securePassEntry, setSecurePassEntry] = useState(true);

  const toggleSecurePassEntry = useCallback(() => {
    setSecurePassEntry(!securePassEntry);
  }, [securePassEntry]);

  useEffect(() => {
    if (loginRequest.data) {
      router.replace('/');
    }
  }, [loginRequest.data, router]);

  useFieldServerError(loginRequest, setError);

  useEffect(() => {
    return function () {
      dispatch(loginRequestAction.clean());
    };
  }, [dispatch]);

  const onLogin = (data: LoginRequest) => {
    const normalizedData: LoginRequest = {...data};
    Object.entries(normalizedData).forEach(([key, value]) => {
      normalizedData[key] = value.trim();

      if (key === 'login') {
        normalizedData[key] = normalizedData.login.toLowerCase();
      }
    });

    dispatch(loginRequestAction.request(normalizedData));
  };

  return (
    <>
      <AppStatusBar />
      <AppLayout>
        <LoginContainerOutside>
          <LoginContainerView>
            <LoginContentView>
              <LoginLogoContainer>
                <LoginLogo />
              </LoginLogoContainer>
              <LoginTitleText>{strings.login_title}</LoginTitleText>
              <Controller
                control={control}
                rules={{
                  required: strings.login_required,
                }}
                render={({
                  field: {onChange, onBlur, value},
                  fieldState: {error},
                }) => (
                  <LoginInput
                    status={error ? 'danger' : 'basic'}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    placeholder={strings.login_placeholder}
                    label={strings.login_label}
                  />
                )}
                name="login"
              />
              {errors.login && (
                <LoginInputErrorText key="login-error">
                  {errors.login.message}
                </LoginInputErrorText>
              )}

              <Controller
                control={control}
                rules={{
                  required: strings.login_password_required,
                }}
                render={({
                  field: {onChange, onBlur, value},
                  fieldState: {error},
                }) => (
                  <LoginInput
                    status={error ? 'danger' : 'basic'}
                    placeholder={strings.login_password_placeholder}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value}
                    label={strings.login_password_label}
                    accessoryRight={props => {
                      return (
                        <TouchableWithoutFeedback
                          onPress={toggleSecurePassEntry}>
                          {securePassEntry ? (
                            <LoginEye key="eye" {...props} />
                          ) : (
                            <LoginEyeOff key="eye-off" {...props} />
                          )}
                        </TouchableWithoutFeedback>
                      );
                    }}
                    secureTextEntry={securePassEntry}
                  />
                )}
                name="password"
              />
              {errors.password && (
                <LoginInputErrorText key="password-error">
                  {errors.password.message}
                </LoginInputErrorText>
              )}

              <LoginButton
                disabled={loginRequest.isLoading}
                accessoryRight={
                  loginRequest.isLoading ? AccessoryLoadingIndicator : undefined
                }
                onPress={handleSubmit(onLogin)}>
                {strings.login_button.toUpperCase()}
              </LoginButton>

              <LoginSignUpButton to="/sign-up">
                {strings.login_sign_up_button.toUpperCase()}
              </LoginSignUpButton>

              <LoginButtonDivider />
              <LoginRestoreButton to="/restore">
                {strings.login_restore_button}
              </LoginRestoreButton>
            </LoginContentView>
          </LoginContainerView>
        </LoginContainerOutside>
      </AppLayout>
    </>
  );
}
