import React from 'react';
import {
  RequestCategoryTypeField,
  RequestCategoryTypeFieldExtraDataSingleSelection,
} from '../../../../../../../api/request/models/responses/category-type';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldErrorText,
  NewRequestQuestionFieldTitle,
} from '../styles';
import {
  NewRequestQuestionFieldSingleSelectionSelect,
  NewRequestQuestionFieldSingleSelectionSelectItem,
} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  NewRequestQuestionFieldSingleSelectionState,
  NewRequestQuestionForm,
} from '../../../form';
import {IndexPath} from '@ui-kitten/components/devsupport';
import {useStrings} from '../../../../../../../utils/providers/strings';

export default function NewRequestQuestionFieldSingleSelection({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  const strings = useStrings();
  const extraData =
    field.extraData as RequestCategoryTypeFieldExtraDataSingleSelection;
  return (
    <NewRequestQuestionFieldContainer>
      <NewRequestQuestionFieldTitle>{field.title}</NewRequestQuestionFieldTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const singleSelectionValue =
            value as NewRequestQuestionFieldSingleSelectionState;
          return (
            <NewRequestQuestionFieldSingleSelectionSelect
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              selectedIndex={
                singleSelectionValue !== undefined && extraData
                  ? new IndexPath(
                      extraData.findIndex(
                        selection => selection.id === singleSelectionValue.id,
                      ),
                    )
                  : undefined
              }
              onSelect={index =>
                onChange(extraData?.[(index as IndexPath).row])
              }
              value={
                singleSelectionValue !== undefined
                  ? singleSelectionValue.value
                  : undefined
              }
              placeholder={
                strings.new_request_question_single_selection_placeholder
              }>
              {extraData?.map(item => {
                return (
                  <NewRequestQuestionFieldSingleSelectionSelectItem
                    key={item.id}
                    title={item.value}
                  />
                );
              })}
            </NewRequestQuestionFieldSingleSelectionSelect>
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
    </NewRequestQuestionFieldContainer>
  );
}
