import React, {useState} from 'react';
import {ShopDetailResponse} from '../../../../../api/shops/models/responses/shop-detail';
import {
  ShopMainInfoContainer,
  ShopMoreButton,
  ShopTextContainer,
  ShopTextDescription,
  ShopTextHeading,
} from './styles';
import {useStrings} from '../../../../../utils/providers/strings';

interface IProps {
  data: ShopDetailResponse | undefined;
}

export default function ShopMainInfo({data}: IProps) {
  const strings = useStrings();
  const shopData = data?.public;

  const [isShowfullDescription, setIsShowFullDescription] = useState(false);

  return (
    <ShopMainInfoContainer>
      <ShopTextContainer>
        <ShopTextHeading>{strings.shop_detail_number_field}</ShopTextHeading>
        <ShopTextDescription>{shopData?.number}</ShopTextDescription>
      </ShopTextContainer>

      {shopData?.shortDescription && (
        <ShopTextContainer>
          <ShopTextHeading>
            {strings.shop_detail_description_field}
          </ShopTextHeading>
          <ShopTextDescription>
            {isShowfullDescription
              ? `${shopData?.shortDescription} ${shopData?.description}`
              : `${shopData?.shortDescription}...`}
            <ShopMoreButton
              onPress={() => setIsShowFullDescription(!isShowfullDescription)}
              children={
                !isShowfullDescription
                  ? ` ${strings.shop_more_button}`
                  : ` ${strings.shop_less_button}`
              }
            />
          </ShopTextDescription>
        </ShopTextContainer>
      )}
    </ShopMainInfoContainer>
  );
}
