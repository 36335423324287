import {all, call, put, takeLatest} from 'redux-saga/effects';
import {RestoreConfirmResponse} from '../../../api/user/models/responses/restore-confirm';
import {restoreConfirmRequestAction} from './action';
import api from '../../../api';
import {DefaultResponse} from '../../../api/models/response/default';

const restoreConfirmRequest = function* (
  action: ReturnType<typeof restoreConfirmRequestAction.request>,
) {
  try {
    const response: DefaultResponse<RestoreConfirmResponse> = yield call(
      [api, api.user.restoreConfirm],
      action.payload,
    );

    yield put(restoreConfirmRequestAction.success({}));
  } catch (error) {
    yield put(restoreConfirmRequestAction.failure(error as Error));
  }
};

export const restoreConfirmSaga = function* () {
  yield all([
    takeLatest(restoreConfirmRequestAction.request, restoreConfirmRequest),
  ]);
};
