import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {LoginResponse} from '../../../api/user/models/responses/login';
import {loginRequestAction} from './action';

export const loginReducer = combineReducers<{
  loginRequest: RequestState<LoginResponse> | null;
}>({
  loginRequest: createRequestReducer(loginRequestAction),
});
