import React, {useCallback, useEffect} from 'react';
import CP from '../../assets/icons/CP';
import Exchange from '../../assets/icons/Exchange';
import ExchangeHistory from '../../assets/icons/ExchangeHistory';
import Profile from '../../assets/icons/Profile';
import QR from '../../assets/icons/QR';
import SP from '../../assets/icons/SP';
import SPHistory from '../../assets/icons/SPHistory';
import Transactions from '../../assets/icons/Transactions';
import {
  HomeLayout,
  HomeRouteContainer,
  HomeRouteItem,
  HomeUserErrorMobile,
  HomeUserSpinnerMobile,
} from './styles';
import {useStrings} from '../../utils/providers/strings';
import {
  useAppDispatch,
  useJwtUserSelector,
  useRequestSelector,
} from '../../store/store';
import {useTheme} from 'styled-components/native';
import Points from '../../uikit/Points';
import CallFAB from '../../uikit/CallFAB';
import {userRequestAction} from '../user/store/action';
import AppStatusBar from '../../uikit/AppStatusBar';
import {UserRole} from '../../api/user/models/responses/user';
import {Platform} from 'react-native';
import Friends from '../../assets/icons/Friends';
import SplitPay from '../../assets/icons/SplitPay';
import UserMobileInfo from '../../uikit/UserMobileInfo';

export default function HomeScreen() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const strings = useStrings();
  const userRequest = useRequestSelector(store => store.user.userRequest);
  const jwtUser = useJwtUserSelector();

  const onUserRequest = useCallback(() => {
    dispatch(userRequestAction.request());
  }, [dispatch]);

  useEffect(() => {
    if (
      !theme.isDesktop &&
      !userRequest.data &&
      !userRequest.error &&
      !userRequest.isLoading
    ) {
      onUserRequest();
    }
  }, [onUserRequest, theme.isDesktop, userRequest]);

  return (
    <>
      <AppStatusBar />
      <HomeLayout>
        {!theme.isDesktop &&
          (userRequest.data ? (
            <UserMobileInfo data={userRequest.data} />
          ) : userRequest.isLoading ? (
            <HomeUserSpinnerMobile />
          ) : userRequest.error ? (
            <HomeUserErrorMobile
              error={userRequest.error.message}
              retry={onUserRequest}
            />
          ) : null)}
        <Points />
        <HomeRouteContainer>
          <HomeRouteItem name={strings.home_cp_button} route="/cp">
            <CP />
          </HomeRouteItem>
          {jwtUser.role === UserRole.MEMBER && (
            <>
              <HomeRouteItem
                name={strings.home_withdrawal_button}
                route="/withdrawal">
                <SP />
              </HomeRouteItem>
              <HomeRouteItem
                name={strings.home_withdrawal_list_button}
                route="/withdrawals">
                <SPHistory />
              </HomeRouteItem>
              <HomeRouteItem
                name={strings.home_exchange_button}
                route="/exchange">
                <Exchange />
              </HomeRouteItem>
              <HomeRouteItem
                name={strings.home_exchange_list_button}
                route="/exchanges">
                <ExchangeHistory />
              </HomeRouteItem>
            </>
          )}
          {/*TODO: Uncomment after adding settings*/}
          {/*<HomeRouteItem*/}
          {/*  name={strings.home_settings_button}*/}
          {/*  route="/settings"*/}
          {/*/>*/}
          <HomeRouteItem name={strings.home_profile_button} route="/profile">
            <Profile />
          </HomeRouteItem>
          <HomeRouteItem name={strings.home_friends_button} route="/friends">
            <Friends />
          </HomeRouteItem>
          <HomeRouteItem
            name={strings.home_transaction_list_button}
            route="/transactions">
            <Transactions />
          </HomeRouteItem>
          <HomeRouteItem
            name={strings.home_split_payment_list_button}
            route="/split-payments">
            <SplitPay />
          </HomeRouteItem>
          {(Platform.OS === 'ios' || Platform.OS === 'android') && (
            <HomeRouteItem
              name={strings.home_qr_reader_button}
              route="/qr-reader">
              <QR />
            </HomeRouteItem>
          )}
        </HomeRouteContainer>
        {!theme.isDesktop && <CallFAB />}
      </HomeLayout>
    </>
  );
}
