import React, {useState} from 'react';
import {
  RequestsStatusTypeAll,
  RequestsStatusTypes,
} from '../../../../api/request/models/requests';
import {
  RequestsHeaderContainer,
  RequestsHeaderCreateLink,
  RequestsHeaderPicker,
  RequestsHeaderPickerContainer,
  RequestsHeaderPickerIcon,
  RequestsHeaderPickerItem,
  RequestsHeaderPickerTitle,
} from './styles';
import {useStrings} from '../../../../utils/providers/strings';
import {RequestStatus} from '../../../../api/request/models/responses';
import {IndexPath} from '@ui-kitten/components/devsupport/typings';

export default function RequestsHeader({
  selectedStatus,
  setSelectedStatus,
  allCounts,
}: {
  selectedStatus: RequestsStatusTypes;
  setSelectedStatus: (status: RequestsStatusTypes) => void;
  allCounts: number;
}) {
  const strings = useStrings();
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const statuses: RequestsStatusTypes[] = [
    ...Object.values(RequestStatus),
    RequestsStatusTypeAll,
  ];

  const getStatusText = (status: RequestsStatusTypes): string => {
    switch (status) {
      case RequestStatus.DONE:
        return strings.service_request_status_done;
      case RequestStatus.TO_DO:
        return strings.service_request_status_todo;
      case RequestStatus.WAIT_PAYMENT:
        return strings.service_request_status_wait_payment;
      case RequestStatus.PAID:
        return strings.service_request_status_paid;
      case RequestStatus.DECLINED:
        return strings.service_request_status_declined;
      case RequestsStatusTypeAll:
        return strings.requests_header_statuses_all;
    }
  };

  return (
    <RequestsHeaderContainer>
      <RequestsHeaderPicker
        visible={isPickerOpen}
        selectedIndex={new IndexPath(statuses.indexOf(selectedStatus))}
        onSelect={index => {
          setSelectedStatus(statuses[index.row]);
          setIsPickerOpen(false);
        }}
        onBackdropPress={() => setIsPickerOpen(false)}
        anchor={() => (
          <RequestsHeaderPickerContainer onPress={() => setIsPickerOpen(true)}>
            <RequestsHeaderPickerTitle>
              {getStatusText(selectedStatus)} ({allCounts})
            </RequestsHeaderPickerTitle>
            <RequestsHeaderPickerIcon />
          </RequestsHeaderPickerContainer>
        )}>
        {statuses.map(status => (
          <RequestsHeaderPickerItem
            key={status}
            title={getStatusText(status)}
          />
        ))}
      </RequestsHeaderPicker>
      <RequestsHeaderCreateLink to="/create-request">
        {strings.requests_header_new_button.toUpperCase()}
      </RequestsHeaderCreateLink>
    </RequestsHeaderContainer>
  );
}
