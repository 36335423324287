import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList} from 'react-native';
import {Text} from '@ui-kitten/components';
import {RequestCategoryTypeField} from '../../../../../api/request/models/responses/category-type';
import {AppButton, OutlineButton} from '../../../../../uikit/AppButtons';
import AppKeyboardAvoidingView from '../../../../../uikit/AppKeyboardAvoidingView';

export const NewRequestQuestionContainer = styled(AppKeyboardAvoidingView)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NewRequestQuestionInputsContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;

export const NewRequestQuestionList = styled(
  FlatList<RequestCategoryTypeField>,
)``;

export const NewRequestQuestionHeader = styled(Text).attrs({
  category: 'h6',
})`
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestQuestionButtons = styled.View`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.dimens.smallSpacer};
  border-top-width: 1px;
  border-top-color: ${props => props.theme.colors['color-basic-300']};

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: flex-end;
      margin-top: ${props.theme.dimens.bigSpacer};
      padding-right: 0;
      background-color: transparent;
      border-top-width: 0;
    `}
`;

export const NewRequestQuestionBackButton = styled(OutlineButton)`
  flex: 1;
  ${props =>
    props.theme.isDesktop &&
    css`
      flex: none;
    `}
`;

export const NewRequestQuestionNextButton = styled(AppButton)`
  flex: 1;
  margin-left: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      flex: none;
    `}
`;
