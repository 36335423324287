import {combineReducers} from 'redux';
import {loginReducer} from '../features/login/store/reducer';
import {restoreConfirmReducer} from '../features/restore-confirm/store/reducer';
import {userReducer} from '../features/user/store/reducer';
import {newRequestReducer} from '../features/new-request/store/reducer';
import {requestsReducer} from '../features/requests/store/reducer';
import {appReducer} from '../features/app/store/reducer';
import {conciergePointsReducer} from '../features/concierge-points/store/reducer';
import {standardPointsReducer} from '../features/standard-points/store/reducer';
import {transactionReducer} from '../features/transaction-list/store/reducer';
import {qrPaymentReducer} from '../features/qr-payment/store/reducer';
import {registerReducer} from '../features/register/store/reducer';
import {restoreReducer} from '../features/restore/store/reducer';
import {friendListReducer} from '../features/friend-list/store/reducer';
import {splitPaymentsReducer} from '../features/split-payment-list/store/reducer';
import {shopReducer} from '../features/shops/store/reducer';
import {infoReducer} from '../features/info/store/reducer';

const rootReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  user: userReducer,
  newRequest: newRequestReducer,
  requests: requestsReducer,
  conciergePoints: conciergePointsReducer,
  standardPoints: standardPointsReducer,
  transaction: transactionReducer,
  qrPayment: qrPaymentReducer,
  register: registerReducer,
  restore: restoreReducer,
  restoreConfirm: restoreConfirmReducer,
  friendList: friendListReducer,
  splitPayments: splitPaymentsReducer,
  shops: shopReducer,
  info: infoReducer,
});

export default rootReducer;
