import {useEffect} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {RequestState} from 'scl/src/redux/create-request-reducer';
import {UseFormSetError, FieldValues, FieldPath} from 'react-hook-form';
import {FieldServerError} from '../../api/models/field-server-error';

export default function useFieldServerError<
  Fields extends FieldValues = FieldValues,
>(request: RequestState<any>, setError: UseFormSetError<Fields>) {
  const toast = useToast();
  useEffect(() => {
    if (!request.error) return;

    if (request.error instanceof FieldServerError) {
      const errorFields = (request.error as FieldServerError).fields.filter(
        field => field.field !== undefined,
      );

      if (errorFields.length) {
        errorFields.forEach(errorField => {
          setError(errorField.field! as FieldPath<Fields>, {
            message: errorField.message,
          });
        });
      } else {
        toast.show(request.error.message);
      }
    } else {
      toast.show(request.error.message);
    }
  }, [setError, toast, request.error]);
}
