import React, {useCallback, useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../uikit/Points/container';
import {useTheme} from 'styled-components/native';
import {useAppDispatch, usePaginationRequestSelector} from '../../store/store';
import {AppLoader} from '../../uikit/AppLoader';
import {AppError} from '../../uikit/AppError';
import {
  TransactionList,
  TransactionListEmptyContainer,
  TransactionListEmptyIcon,
  TransactionListEmptyInfo,
  TransactionListItemContainer,
  TransactionListItemInfo,
  TransactionListItemInfoTitle,
  TransactionListLoading,
  TransactionListLoadingContainer,
} from './styles';
import {ListRenderItemInfo} from 'react-native';
import {useStrings} from '../../utils/providers/strings';
import {useToast} from 'react-native-toast-notifications';
import AppRefreshControl from '../../uikit/AppRefreshControl';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import AppHeader from '../../uikit/AppHeader';
import {ScreenLayoutWithHeader} from '../../uikit/ScreenLayout';
import {getTransactionListRequestAction} from './store/action';
import {TRANSACTION_LIST_LIMIT} from './store/saga';
import {
  getTransactionAmountSuffix,
  getTransactionType,
  Transaction,
  TransactionType,
} from '../../api/transaction/models/responses';
import {useFocusEffect} from '@react-navigation/native';

export default function TransactionListScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const transactionListRequest = usePaginationRequestSelector(
    store => store.transaction.transactionListRequest,
  );

  const isEmptyList = !transactionListRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getTransactionListRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      transactionListRequest.error &&
      transactionListRequest.data?.items.length
    ) {
      toast.show(transactionListRequest.error.message);
    }
  }, [transactionListRequest, toast]);

  const onFirstPage = useCallback(() => {
    dispatch(
      getTransactionListRequestAction.request({
        limit: TRANSACTION_LIST_LIMIT,
        offset: 0,
      }),
    );
  }, [dispatch]);

  useFocusEffect(onFirstPage);

  const onNextPage = () => {
    if (
      transactionListRequest.data?.isLastPage ||
      transactionListRequest.isLoading
    )
      return;

    dispatch(
      getTransactionListRequestAction.request({
        limit: TRANSACTION_LIST_LIMIT,
        offset: transactionListRequest.data?.items.length || 0,
      }),
    );
  };

  const renderTransactionItem = ({item}: ListRenderItemInfo<Transaction>) => {
    const createdAtLabel = strings.transaction_list_created_at;
    const createdAt = strings.app_date_time_string(item.createdAt);
    return (
      <TransactionListItemContainer>
        <TransactionListItemInfoTitle>{item.id}</TransactionListItemInfoTitle>
        <TransactionListItemInfo>
          {strings.app_number(item.amount)}{' '}
          {getTransactionAmountSuffix(strings, item)}
        </TransactionListItemInfo>
        <TransactionListItemInfo>
          {getTransactionType(strings, item)}
        </TransactionListItemInfo>
        <TransactionListItemInfo>
          {createdAtLabel}
          {createdAtLabel ? ` ${createdAt}` : createdAt}
        </TransactionListItemInfo>
        {item.description && (
          <TransactionListItemInfo>{item.description}</TransactionListItemInfo>
        )}
        {item.type === TransactionType.TRANSFER && item.fromWallet && (
          <TransactionListItemInfo>
            {item.fromWallet.owner.firstName} {item.fromWallet.owner.lastName}{' '}
            {strings.transaction_list_transfer_from_to_divider}{' '}
            {item.toWallet
              ? `${item.toWallet.owner.firstName} ${item.toWallet.owner.lastName}`
              : strings.transaction_list_transfer_concierge}
          </TransactionListItemInfo>
        )}
      </TransactionListItemContainer>
    );
  };

  const renderTransactionListLoading = () => {
    return (
      <TransactionListLoadingContainer>
        <TransactionListLoading />
      </TransactionListLoadingContainer>
    );
  };

  const renderTransactionListEmpty = () => {
    return (
      <TransactionListEmptyContainer>
        <TransactionListEmptyIcon />
        <TransactionListEmptyInfo>
          {strings.transaction_list_empty_title}
        </TransactionListEmptyInfo>
      </TransactionListEmptyContainer>
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.transaction_list_title}
              backNoHistoryRoute="/"
            />
            {isEmptyList && transactionListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && transactionListRequest.error ? (
              <AppError
                error={transactionListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              transactionListRequest.data && (
                <TransactionList
                  refreshControl={
                    <AppRefreshControl
                      onRefresh={onFirstPage}
                      refreshing={transactionListRequest.isFirstLoading}
                    />
                  }
                  data={transactionListRequest.data.items}
                  renderItem={renderTransactionItem}
                  keyExtractor={item => String(item.id)}
                  onEndReached={onNextPage}
                  ListFooterComponent={
                    transactionListRequest.isLoading
                      ? renderTransactionListLoading
                      : null
                  }
                  ListEmptyComponent={renderTransactionListEmpty}
                />
              )
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
