import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import AppHeader from '../../../../uikit/AppHeader';
import Star from '../../../../assets/icons/Star';
import StarOutline from '../../../../assets/icons/StarOutline';

export const ShopDetailReviewContainer = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 128px;
    `}
`;

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  padding: 0 16px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-bottom: 20px;
    `}
`;

export const ShopDetailReviewContentContainer = styled.ScrollView`
  margin-left: 16px;
  margin-right: 16px;
  background: ${props => props.theme.colors.white};
  border: 1px solid #cec5bd;
  border-radius: 4px;
  padding: 24px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 18px;
      margin-right: 0;
      margin-bottom: 24px;
    `}
`;

export const ShopDetailReviewTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 20px;
  font-weight: 500;
  margin-top: 16px;
`;

export const ShopDetailReviewText = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  margin-top: 16px;
`;

export const ShopStarContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const ShopStarIcon = styled(Star)`
  max-width: 40px;
  max-height: 40px;
`;

export const ShopStarOutlineIcon = styled(StarOutline)`
  max-width: 40px;
  max-height: 40px;
`;
