import {combineReducers} from 'redux';
import {createReducer} from '@reduxjs/toolkit';
import createRequestReducer, {
  addRequestCase,
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {SettingsResponse} from '../../../api/settings/models/settingsResponse';
import {
  postalInfoRequestAction,
  setLocalizationAction,
  setStringsAction,
  settingsRequestAction,
} from './action';
import {Locales, Strings} from '../../../resources/strings';
import RNLocalize from 'react-native-localize';
import {setJWTUserAction} from '../../user/store/action';
import {PostInfoResponse} from '../../../api/postcode-info/models/responses';

export const appReducer = combineReducers<{
  localization: Locales;
  strings: Strings | null;
  settingsRequest: RequestState<Array<SettingsResponse>> | null;
  postalinfoRequest: RequestState<PostInfoResponse> | null;
}>({
  localization: createReducer<Locales>(
    RNLocalize.getCountry() as Locales,
    builder => {
      builder.addCase(setLocalizationAction, (state, action) => action.payload);
    },
  ),
  strings: createReducer<Strings | null>(null, builder => {
    builder.addCase(setStringsAction, (state, action) => action.payload);
  }),
  settingsRequest: createReducer<RequestState<Array<SettingsResponse>> | null>(
    null,
    builder => {
      addRequestCase(builder, settingsRequestAction);

      builder.addCase(setJWTUserAction, (state, action) =>
        action.payload === null ? null : state,
      );
    },
  ),
  postalinfoRequest: createRequestReducer(postalInfoRequestAction),
});
