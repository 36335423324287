import {useEffect, useRef, useState} from 'react';

export default function useMatchMedia(query: string): boolean {
  const matchMediaRef = useRef(global.matchMedia(query));
  const [isMatch, setMatch] = useState(matchMediaRef.current.matches);
  useEffect(() => {
    const listener = event => {
      setMatch(event.matches);
    };
    matchMediaRef.current.addEventListener('change', listener);
    return function () {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      matchMediaRef.current.removeEventListener('change', listener);
    };
  }, []);

  return isMatch;
}
