import styled from 'styled-components';

export * from './index.common';

export const ShopCategoryContentContainer = styled.div`
  display: flex;
  overflow-y: auto;
  padding-top: 6px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e9e0d8;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #c0a48b;
  }
`;
