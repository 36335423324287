import styled from 'styled-components/native';

export const InfoListContainer = styled.View`
  background-color: white;
`;

export const InfoListItemWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: white;
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
  padding: 8px 16px;
`;

export const InfoListItemTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;

  margin-bottom: 2px;
`;

export const InfoListItemText = styled.Text`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors['color-basic-800']};
`;
