import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import StarWithSmile from '../../../../../../assets/icons/StarWithSmile';

export const ShopHeaderImageContainer = styled.View`
  display: flex;
  height: 148px;
  overflow: hidden;
  margin-top: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      width: 100%;
      border-radius: 2px;
      height: 268px;
      margin-top: 32px;
    `}
`;

export const ShopHeaderImage = styled.Image`
  flex: 1;
`;

export const ShopHeaderBadgesContainer = styled.View`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: row;
  padding: 8px;

  ${props =>
    props.theme.isDesktop &&
    css`
      display: flex;
      padding: 16px;
      justify-content: space-between;
    `}
`;

export const ShopHeaderLocationBadge = styled.Text`
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  border-radius: 10px;
  padding: 2px 8px;
  margin-right: 6px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${props => props.theme.colors['color-primary-500']};
  color: ${props => props.theme.colors['color-basic-900']};

  ${props =>
    props.theme.isDesktop &&
    css`
      background-color: ${props.theme.colors['color-primary-400']};
    `}
`;

export const ShopHeaderTagBadge = styled(ShopHeaderLocationBadge)`
  background-color: ${props => props.theme.colors.white};
`;

export const ShopHeaderRateBadge = styled.View`
  background-color: ${props => props.theme.colors.white};
  padding: 2px 8px 2px 6px;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  align-items: center;
  align-self: flex-start;
`;

export const ShopHeaderRateIcon = styled(StarWithSmile)`
  max-width: 18px;
  max-height: 18px;
  margin-right: 4px;
`;

export const ShopHeaderRateText = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
`;
