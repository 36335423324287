import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const Transactions = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      d="M16 2.667c7.364 0 13.333 5.969 13.333 13.333S23.364 29.333 16 29.333 2.667 23.364 2.667 16h2.666c0 5.89 4.776 10.667 10.667 10.667 5.89 0 10.667-4.776 10.667-10.667 0-5.89-4.776-10.667-10.667-10.667a10.645 10.645 0 00-8.18 3.82L10.667 12h-8V4l3.262 3.261A13.306 13.306 0 0116 2.667zm1.333 6.666v6.114l4.324 4.324-1.886 1.886-5.104-5.106V9.333h2.666z"
    />
  </Svg>
);

export default Transactions;
