import React from 'react';
import {AppErrorContainer, AppErrorRetryButton, AppErrorText} from './styles';
import {useStrings} from '../../utils/providers/strings';
import {ViewStyle} from 'react-native';

export interface AppErrorProps {
  error: string | undefined;
  retry?: () => void;
  retryText?: string;
  style?: ViewStyle;
}

export function AppError({error, retry, retryText, style}: AppErrorProps) {
  const strings = useStrings();

  return (
    <AppErrorContainer style={style}>
      <AppErrorText>{error}</AppErrorText>
      {retry && (
        <AppErrorRetryButton onPress={retry}>
          {retryText || strings.app_error_retry_button.toUpperCase()}
        </AppErrorRetryButton>
      )}
    </AppErrorContainer>
  );
}
