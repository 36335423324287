import {AxiosInstance} from 'axios';
import {DefaultResponse} from '../models/response/default';
import {
  ConciergePointsBuyByBtcRequest,
  ConciergePointsBuyByCybersourceRequest,
  ConciergePointsBuyByBankTransferRequest,
  ConciergePointsBuyRequest,
} from './models/requests/buy';
import {
  ConciergePointsBuyByBtcResponse,
  ConciergePointsBuyByCybersourceResponse,
  ConciergePointsBuyByBankTransferResponse,
  ConciergePointsBuyResponse,
} from './models/response/buy';
import {PaymentInfoResponse} from './models/response/status';

export default class ConciergePointsApi {
  constructor(private axiosInstance: AxiosInstance) {}

  buyPoints(
    request: ConciergePointsBuyRequest,
  ): Promise<DefaultResponse<ConciergePointsBuyResponse>> {
    return this.axiosInstance.post('wallet/deposit', request);
  }

  buyPointsByCybersource(
    request: ConciergePointsBuyByCybersourceRequest,
  ): Promise<DefaultResponse<ConciergePointsBuyByCybersourceResponse>> {
    return this.axiosInstance.post('wallet/deposit-cybersource', request);
  }

  buyPointsByBtc(
    request: ConciergePointsBuyByBtcRequest,
  ): Promise<DefaultResponse<ConciergePointsBuyByBtcResponse>> {
    return this.axiosInstance.post('wallet/deposit-btc', request);
  }

  paymentInfo(id: string): Promise<DefaultResponse<PaymentInfoResponse>> {
    return this.axiosInstance.get(`payment/${id}`);
  }

  buyPointsByBankTransfer(
    request: ConciergePointsBuyByBankTransferRequest,
  ): Promise<DefaultResponse<ConciergePointsBuyByBankTransferResponse>> {
    return this.axiosInstance.post('bank-transfer', request);
  }
}
