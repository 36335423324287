import React from 'react';
import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList, ImageStyle, Text} from 'react-native';
import {Spinner} from '@ui-kitten/components';
import AppRouteView from '../../../../uikit/AppRouteView';
import AppHeader from '../../../../uikit/AppHeader';
import {ShopItem} from '../../../../api/shops/models/responses/shop-categories';
import EmptyListScreen from '../../../../assets/icons/EmptyListScreen';
import {AppButton} from '../../../../uikit/AppButtons';
import Delete from '../../../../assets/icons/Delete';

export const ShopRecentlyViewedContainerContent = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 111px;
    `}
`;

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  padding: 0 16px;
  margin-bottom: 20px;
`;

export const ShopRecentlyViewedListContainer = styled.View`
  flex: 1;
  margin: 0 10px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 0;
    `}
`;

export const ShopRecentlyViewedList = styled(FlatList<ShopItem>)`
  display: flex;
  flex: 1;
`;

export const ScreenListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const ScreenListLoading = styled(Spinner)``;

export const ScreenListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const ScreenListEmptyIcon = styled(EmptyListScreen)``;

export const ScreenListEmptyTitle = styled(Text)`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ScreenListEmptyInfo = styled(Text)``;

export const CategoryListItemContainer = styled(AppRouteView)`
  display: flex;
  margin-bottom: 12px;
  min-height: 128px;
  background: ${props => props.theme.colors.white};
  overflow: hidden;
  border-radius: 2px;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-basis: calc(25% - 12px);
      margin-right: 6px;
      margin-left: 6px;
      margin-bottom: 12px;
    `}
`;

export const ShopRecentlyCardBadgesContainer = styled.View`
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-left: 8px;
`;

export const ShopRecentlyCardLocationBadge = styled.Text`
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;
  border-radius: 8px;
  overflow: hidden;
  padding: 2px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: ${props => props.theme.colors['color-primary-500']};
  color: ${props => props.theme.colors['color-basic-900']};
`;

export const ShopRecentlyCardTagBadge = styled(ShopRecentlyCardLocationBadge)`
  background-color: ${props => props.theme.colors.white};
`;

export const ShopRecentlyCardImage = styled.Image`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ShopRecentlyCardTitleContainer = styled.View`
  z-index: 10;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 4px 8px;
  background: #000000b2;
  min-height: 40px;
`;

export const ShopRecentlyCardTitle = styled.Text`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.white};
`;

export const ShopRecentlyClearHistoryButton = styled(AppButton).attrs(
  props => ({
    appearance: props.theme.isDesktop ? 'outline' : 'ghost',
    status: 'danger',
    accessoryLeft: iconProps => (
      <Delete
        {...iconProps}
        style={[iconProps?.style, {marginRight: -2, marginLeft: 0}]}
        fill={props.theme.colors['color-danger-500']}
        width={(iconProps?.style as ImageStyle)?.width}
        height={(iconProps?.style as ImageStyle)?.height}
      />
    ),
  }),
)`
  background-color: transparent;
`;
