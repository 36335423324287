import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {CheckBox} from '@ui-kitten/components';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldTitle,
} from '../../styles';

export const NewRequestQuestionFieldCheckBoxContainer = styled(
  NewRequestQuestionFieldContainer,
)`
  padding-left: 0;
  padding-right: 0;
`;

export const NewRequestQuestionFieldCheckBoxTitle = styled(
  NewRequestQuestionFieldTitle,
)`
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestQuestionFieldCheckBox = styled.View`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;
export const NewRequestQuestionFieldCheckBoxItem = styled(CheckBox)<{
  last: boolean;
}>`
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  border-bottom-width: ${props => (props.last ? '0' : '1px')};
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;
