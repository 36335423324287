export interface SettingsResponse {
  id: SettingId;
  name: string;
  value: string;
}
export enum SettingId {
  CREDIT_CARD_PAYMENT_SYSTEM = 'CREDIT_CARD_PAYMENT_SYSTEM',
  CP_TO_JPY_CONVERSION_RATE = 'CP_TO_JPY_CONVERSION_RATE',
  SP_MINIMAL_AMOUNT_TO_WITHDRAW = 'SP_MINIMAL_AMOUNT_TO_WITHDRAW',
  VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT = 'VIRTUALPAY_MINIMAL_BUY_CP_AMOUNT',
  CYBERSOURCE_MINIMAL_BUY_CP_AMOUNT = 'CYBERSOURCE_MINIMAL_BUY_CP_AMOUNT',
  BTC_PAY_MINIMAL_BUY_CP_AMOUNT = 'BTC_PAY_MINIMAL_BUY_CP_AMOUNT',
  BUSINESS_REQUISITES = 'BUSINESS_REQUISITES',
  CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER = 'CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER',
  CREDIT_CARD_SWITCHER = 'CREDIT_CARD_SWITCHER',
  BTC_PAY_SWITCHER = 'BTC_PAY_SWITCHER',
  BANK_TRANSFER_SWITCHER = 'BANK_TRANSFER_SWITCHER',
}

export enum CreditCardPaymentSystem {
  VIRTUALPAY = 'VIRTUALPAY',
  CYBERSOURCE = 'CYBERSOURCE',
}
