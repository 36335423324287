import {
  Friend,
  FriendStatus,
  getFriendStatusText,
  getFriendStatusType,
} from '../../../../api/friend/models/responses/friend';
import {useStrings} from '../../../../utils/providers/strings';
import {useMultipleRequestSelector} from '../../../../store/store';
import {AppMenuItem, AppOverflowMenu} from '../../../../uikit/AppMenu';
import React, {useEffect, useRef} from 'react';
import {useTheme} from 'styled-components/native';
import {BottomSheetModal} from '@gorhom/bottom-sheet';
import {
  FriendListItemApproveButton,
  FriendListItemApproveButtonIcon,
  FriendListItemContainer,
  FriendListItemDeclineButton,
  FriendListItemDeclineButtonIcon,
  FriendListItemDeclineProgress,
  FriendListItemInfo,
  FriendListItemInfoId,
  FriendListItemInfoName,
  FriendListItemInfoStatus,
  FriendListItemMoreButton,
  FriendListItemMoreButtonIcon,
  FriendListItemProgress,
  FriendListItemBottomSheetButton,
  FriendListItemBottomSheetCancelButton,
  FriendListItemBottomSheetView,
} from './styles';

export default function FriendListItem({
  item,
  openedFriend,
  onCloseMore,
  onAccept,
  onReject,
  onMore,
  onDelete,
}: {
  item: Friend;
  openedFriend: Friend | null;
  onCloseMore: () => void;
  onAccept: (item: Friend) => void;
  onReject: (item: Friend) => void;
  onMore: (item: Friend) => void;
  onDelete: (item: Friend) => void;
}) {
  const theme = useTheme();
  const strings = useStrings();

  const acceptRequest = useMultipleRequestSelector(
    store => store.friendList.acceptFriendRequest,
    item.friend.id,
  );
  const rejectRequest = useMultipleRequestSelector(
    store => store.friendList.rejectFriendRequest,
    item.friend.id,
  );
  const deleteRequest = useMultipleRequestSelector(
    store => store.friendList.deleteFriendRequest,
    item.friend.id,
  );

  const renderMoreButton = () => {
    return (
      <FriendListItemMoreButton onPress={() => onMore(item)}>
        <FriendListItemMoreButtonIcon />
      </FriendListItemMoreButton>
    );
  };

  return (
    <FriendListItemContainer>
      <FriendListItemInfo>
        <FriendListItemInfoName>
          {item.friend.firstName} {item.friend.lastName}
        </FriendListItemInfoName>
        <FriendListItemInfoId>
          {strings.friend_list_id_prefix_fn(item.friend.clientId)}
        </FriendListItemInfoId>
        <FriendListItemInfoStatus status={getFriendStatusType(item.status)}>
          {getFriendStatusText(strings, item.status)}
        </FriendListItemInfoStatus>
      </FriendListItemInfo>
      {(() => {
        switch (item.status) {
          case FriendStatus.WAITING_YOUR_APPROVE:
            return (
              <>
                {acceptRequest.isLoading ? (
                  <FriendListItemProgress />
                ) : (
                  <FriendListItemApproveButton
                    disabled={rejectRequest.isLoading}
                    onPress={() => onAccept(item)}>
                    <FriendListItemApproveButtonIcon />
                  </FriendListItemApproveButton>
                )}
                {rejectRequest.isLoading ? (
                  <FriendListItemDeclineProgress />
                ) : (
                  <FriendListItemDeclineButton
                    disabled={acceptRequest.isLoading}
                    onPress={() => onReject(item)}>
                    <FriendListItemDeclineButtonIcon />
                  </FriendListItemDeclineButton>
                )}
              </>
            );
          case FriendStatus.WAITING_FRIEND_APPROVE:
          case FriendStatus.APPROVED:
            return deleteRequest.isLoading ? (
              <FriendListItemProgress />
            ) : theme.isDesktop ? (
              <AppOverflowMenu
                anchor={renderMoreButton}
                placement="bottom end"
                visible={
                  openedFriend !== null &&
                  openedFriend.friend.id === item.friend.id
                }
                onSelect={index => {
                  switch (index.row) {
                    case 0:
                      onDelete(item);
                      break;
                  }
                  onCloseMore();
                }}
                onBackdropPress={onCloseMore}>
                <AppMenuItem
                  title={
                    item.status === FriendStatus.APPROVED
                      ? strings.friend_list_delete_button
                      : strings.friend_list_delete_own_request_button
                  }
                />
              </AppOverflowMenu>
            ) : (
              renderMoreButton()
            );
        }
      })()}
    </FriendListItemContainer>
  );
}

export function FriendListItemBottomSheet({
  openedFriend,
  onCloseMore,
  onDelete,
}: {
  openedFriend: Friend | null;
  onCloseMore: () => void;
  onDelete: (item: Friend) => void;
}) {
  const theme = useTheme();
  const strings = useStrings();

  const sheetRef = useRef<BottomSheetModal | null>(null);

  useEffect(() => {
    if (openedFriend !== null) {
      sheetRef.current?.present();
    } else {
      sheetRef.current?.dismiss();
    }
  }, [openedFriend]);

  if (theme.isDesktop) return null;

  return (
    <BottomSheetModal
      ref={sheetRef}
      snapPoints={[120]}
      enableDismissOnClose
      onDismiss={onCloseMore}>
      <FriendListItemBottomSheetView>
        {openedFriend && (
          <>
            <FriendListItemBottomSheetButton
              onPress={() => {
                onDelete(openedFriend);
                onCloseMore();
              }}>
              {openedFriend.status === FriendStatus.APPROVED
                ? strings.friend_list_delete_button.toUpperCase()
                : strings.friend_list_delete_own_request_button.toUpperCase()}
            </FriendListItemBottomSheetButton>
            <FriendListItemBottomSheetCancelButton onPress={onCloseMore}>
              {strings.friend_list_cancel_button.toUpperCase()}
            </FriendListItemBottomSheetCancelButton>
          </>
        )}
      </FriendListItemBottomSheetView>
    </BottomSheetModal>
  );
}
