import React from 'react';
import {ShopDetailResponse} from '../../../../../api/shops/models/responses/shop-detail';
import {
  ShopAccordionSection,
  ShopAccordionTextDescription,
  ShopProductItemContainer,
  ShopProductItemContentContainer,
  ShopProductItemContentDescription,
  ShopProductItemContentTitle,
  ShopProductItemImage,
  ShopProductItemImageContainer,
  ShopProductsContainer,
} from './styles';
import Collapse from '../../../../../uikit/Collapse';
import {useStrings} from '../../../../../utils/providers/strings';
import {ShopProductsResponse} from '../../../../../api/shops/models/responses/shop-products';

interface IProps {
  data?: ShopDetailResponse;
  products?: ShopProductsResponse[];
}

export default function ShopAccordion({data, products}: IProps) {
  const strings = useStrings();

  return (
    <ShopAccordionSection>
      <Collapse
        title={strings.shop_detail_product_description_field}
        initialIsOpen>
        <ShopAccordionTextDescription>
          {data?.public.productDescription}
        </ShopAccordionTextDescription>
        {products && (
          <ShopProductsContainer>
            {products.map((product, index) => (
              <ShopProductItemContainer
                key={product.id}
                isLastItem={products.length === index + 1}>
                <ShopProductItemImageContainer>
                  <ShopProductItemImage
                    source={{
                      uri: product.imageData.imageUrl,
                    }}
                  />
                </ShopProductItemImageContainer>
                <ShopProductItemContentContainer>
                  <ShopProductItemContentTitle>
                    {product.name}
                  </ShopProductItemContentTitle>
                  <ShopProductItemContentDescription numberOfLines={4}>
                    {product.description}
                  </ShopProductItemContentDescription>
                </ShopProductItemContentContainer>
              </ShopProductItemContainer>
            ))}
          </ShopProductsContainer>
        )}
      </Collapse>
      {data?.public.priceDescription && (
        <Collapse title={strings.shop_detail_price_field}>
          <ShopAccordionTextDescription>
            {data?.public.priceDescription}
          </ShopAccordionTextDescription>
        </Collapse>
      )}
      {data?.public.membershipBenefits && (
        <Collapse title={strings.shop_detail_member_benefits_field}>
          <ShopAccordionTextDescription>
            {data?.public.membershipBenefits}
          </ShopAccordionTextDescription>
        </Collapse>
      )}
      <Collapse title={strings.shop_detail_atmosphere_shop_field}>
        <ShopAccordionTextDescription>
          {data?.public.shopAtmosphere}
        </ShopAccordionTextDescription>
      </Collapse>
    </ShopAccordionSection>
  );
}
