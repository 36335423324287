import React from 'react';
import {useJwtUserSelector} from '../../../../../store/store';
import {useStrings} from '../../../../../utils/providers/strings';
import {
  SplitPaymentButtonsContainer,
  SplitPaymentFriendItemContainer,
  SplitPaymentFriendsContainer,
  SplitPaymentInfoContainer,
  SplitPaymentInfoItemContainer,
  SplitPaymentInfoItemStatus,
  SplitPaymentInfoItemText,
  SplitPaymentInfoItemTitle,
  SplitPaymentInfoRequestInfoTitleMobile,
  SplitPaymentMainButton,
  SplitPaymentMainContainer,
  SplitPaymentSecondButton,
} from './styles';
import {
  getSplitPaymentStatusText,
  getSplitPaymentStatusType,
  SplitPaymentStatus,
} from '../../../../../api/split-payments/models/responses/split-payment';
import {
  FriendStatus,
  getSplitPaymentFriendStatusText,
  getSplitPaymentFriendStatusType,
  SplitPaymentDetailResponse,
} from '../../../../../api/split-payments/models/responses/detail';
import {AccessoryLoadingIndicator} from '../../../../../uikit/AccessoryLoadingIndicator';

interface IProps {
  data: SplitPaymentDetailResponse;
  isLoading: boolean;
  onPay: () => void;
  onApprove: () => void;
  onReject: () => void;
}

export default function SplitPaymentInfo({
  data,
  isLoading,
  onPay,
  onApprove,
  onReject,
}: IProps) {
  const jwtUser = useJwtUserSelector();
  const strings = useStrings();
  const isSplitPaymentAuthor = jwtUser.id === data.author?.id;
  const isAllFriendsApproveSplitPayment = data.friends?.every(
    item => item.status === FriendStatus.APPROVED,
  );
  const allowedStatuses = [
    SplitPaymentStatus.PENDING,
    SplitPaymentStatus.WAIT_YOUR_PAYMENT,
  ];

  return (
    <>
      <SplitPaymentMainContainer>
        <SplitPaymentInfoContainer>
          <SplitPaymentInfoItemContainer>
            <SplitPaymentInfoItemTitle>
              {strings.split_payment_detail_status_title}
            </SplitPaymentInfoItemTitle>
            <SplitPaymentInfoItemStatus
              status={getSplitPaymentStatusType(data.status)}>
              {getSplitPaymentStatusText(strings, data.status)}
            </SplitPaymentInfoItemStatus>
          </SplitPaymentInfoItemContainer>

          {!isSplitPaymentAuthor && (
            <SplitPaymentInfoItemContainer>
              <SplitPaymentInfoItemTitle>
                {strings.split_payment_friend_creator_payment_title}
              </SplitPaymentInfoItemTitle>
              <SplitPaymentInfoItemText>
                {`${data.author?.firstName} ${data.author?.lastName} (ID: ${data.author?.clientId})`}
              </SplitPaymentInfoItemText>
            </SplitPaymentInfoItemContainer>
          )}

          <SplitPaymentInfoItemContainer>
            <SplitPaymentInfoItemTitle>
              {strings.split_payment_detail_created_date_title}
            </SplitPaymentInfoItemTitle>
            <SplitPaymentInfoItemText>
              {strings.app_date_time_string(data.createdAt)}
            </SplitPaymentInfoItemText>
          </SplitPaymentInfoItemContainer>

          <SplitPaymentInfoItemContainer last={!isSplitPaymentAuthor}>
            <SplitPaymentInfoItemTitle>
              {strings.split_payment_detail_payment_amount_title}
            </SplitPaymentInfoItemTitle>
            <SplitPaymentInfoItemText>
              {`${
                isSplitPaymentAuthor ? data.totalAmount : data.requestedAmount
              } CP`}
            </SplitPaymentInfoItemText>
          </SplitPaymentInfoItemContainer>

          {isSplitPaymentAuthor && (
            <SplitPaymentInfoItemContainer last>
              <SplitPaymentInfoItemTitle>
                {strings.split_payment_detail_you_pay_title}
              </SplitPaymentInfoItemTitle>
              <SplitPaymentInfoItemText>
                {`${data.requestedAmount} CP`}
              </SplitPaymentInfoItemText>
            </SplitPaymentInfoItemContainer>
          )}
        </SplitPaymentInfoContainer>

        {isSplitPaymentAuthor && (
          <SplitPaymentFriendsContainer>
            <SplitPaymentInfoRequestInfoTitleMobile>
              {strings.split_payment_detail_friends_list_title}
            </SplitPaymentInfoRequestInfoTitleMobile>

            {data.friends?.map(({id, firstName, lastName, status, amount}) => (
              <SplitPaymentFriendItemContainer key={id}>
                <SplitPaymentInfoItemTitle>
                  {`${firstName} ${lastName}`}
                </SplitPaymentInfoItemTitle>
                <SplitPaymentInfoItemText>
                  {`${amount} CP`}
                </SplitPaymentInfoItemText>
                <SplitPaymentInfoItemStatus
                  status={getSplitPaymentFriendStatusType(status)}>
                  {getSplitPaymentFriendStatusText(strings, status)}
                </SplitPaymentInfoItemStatus>
              </SplitPaymentFriendItemContainer>
            ))}
          </SplitPaymentFriendsContainer>
        )}
      </SplitPaymentMainContainer>

      {allowedStatuses.includes(data.status) && (
        <SplitPaymentButtonsContainer>
          <SplitPaymentSecondButton
            disabled={isLoading}
            accessoryRight={isLoading ? AccessoryLoadingIndicator : undefined}
            onPress={onReject}>
            {strings.split_payment_friend_reject_button.toUpperCase()}
          </SplitPaymentSecondButton>

          {isSplitPaymentAuthor ? (
            <SplitPaymentMainButton
              disabled={isLoading || !isAllFriendsApproveSplitPayment}
              accessoryRight={isLoading ? AccessoryLoadingIndicator : undefined}
              onPress={onPay}>
              {strings.split_payment_friend_pay_button.toUpperCase()}
            </SplitPaymentMainButton>
          ) : (
            <SplitPaymentMainButton
              disabled={isLoading}
              accessoryRight={isLoading ? AccessoryLoadingIndicator : undefined}
              onPress={onApprove}>
              {strings.split_payment_friend_approve_button.toUpperCase()}
            </SplitPaymentMainButton>
          )}
        </SplitPaymentButtonsContainer>
      )}
    </>
  );
}
