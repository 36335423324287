import React, {useCallback, useEffect, useState} from 'react';
import {ListRenderItemInfo} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useTheme} from 'styled-components/native';
import {
  AppTransparentHeader,
  CategoryListItemContainer,
  ScreenListLoading,
  ScreenListLoadingContainer,
  ShopCategoryCardTitle,
  ShopCategoryCardTitleContainer,
  ShopRecentlyCardBadgesContainer,
  ShopRecentlyCardImage,
  ShopRecentlyCardLocationBadge,
  ShopRecentlyCardTagBadge,
  ShopRecentlyViewedContainerContent,
  ShopRecentlyViewedList,
  ShopRecentlyViewedListContainer,
} from './styles';
import {NavigationState, useFocusEffect} from '@react-navigation/native';
import {useStrings} from '../../../utils/providers/strings';
import {
  useAppDispatch,
  usePaginationRequestSelector,
} from '../../../store/store';
import {REQUESTS_LIMIT} from '../../requests/store/saga';
import AppStatusBar from '../../../uikit/AppStatusBar';
import {AppLayout} from '../../../uikit/AppLayout';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import AppRefreshControl from '../../../uikit/AppRefreshControl';
import {ShopsContainerContainer} from '../styles';
import {REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT} from '../store/saga';
import {ShopItem} from '../../../api/shops/models/responses/shop-categories';
import {
  getShopByCategoryListRequestAction,
  searchShopsByCategoryRequestAction,
} from '../store/action';
import {ReviewsRequest} from '../../../api/shops';
import {ShopsSearchByCategory} from './ui/Search';
import {SearchResultsByCategory} from './ui/SearchResults';
import {EmptyList} from '../../../uikit/EmptyList';

/** Get query params of current screen */
const getScreenParams = (
  state: NavigationState,
  routeName: string,
): Record<string, string> | null => {
  return (
    (state.routes.find(item => item.name === routeName)?.params as Record<
      string,
      string
    >) || null
  );
};

export default function ShopsByCategoryScreen({navigation, route}) {
  const screenParams = getScreenParams(navigation.getState(), route.name);

  const [mode, setMode] = useState<'search' | 'main'>(
    screenParams?.search ? 'search' : 'main',
  );

  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const requestId = route.params.id;

  const shopByCategoryListRequest = usePaginationRequestSelector(
    store => store.shops.shopByCategoryListRequest,
  );

  const isEmptyList = !shopByCategoryListRequest.data?.items.length;

  useEffect(() => {
    if (
      shopByCategoryListRequest.error &&
      shopByCategoryListRequest.data?.items.length
    ) {
      toast.show(shopByCategoryListRequest.error.message);
    }
  }, [shopByCategoryListRequest]);

  const onFirstPage = useCallback(() => {
    const request: ReviewsRequest = {
      limit: REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT,
      offset: 0,
      shopId: Number(requestId),
    };

    dispatch(getShopByCategoryListRequestAction.request(request));
  }, [dispatch, requestId]);

  const onInit = useCallback(() => {
    dispatch(getShopByCategoryListRequestAction.clean());
    onFirstPage();
  }, [dispatch, onFirstPage]);

  useEffect(() => {
    if (screenParams?.search) {
      dispatch(
        searchShopsByCategoryRequestAction.request({
          query: screenParams.search,
          limit: REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT,
          offset: 0,
          categoryId: requestId,
        }),
      );
    }
    return function () {
      dispatch(getShopByCategoryListRequestAction.clean());
      dispatch(searchShopsByCategoryRequestAction.clean());
    };
  }, []);

  useFocusEffect(onInit);

  const onNextPage = () => {
    if (
      shopByCategoryListRequest.data?.isLastPage ||
      shopByCategoryListRequest.isLoading
    )
      return;

    const request: ReviewsRequest = {
      limit: REQUESTS_LIMIT,
      offset: shopByCategoryListRequest.data?.items.length || 0,
      shopId: Number(requestId),
    };

    dispatch(getShopByCategoryListRequestAction.request(request));
  };

  const onSearch = (searchValue: string) => {
    if (searchValue) {
      navigation.setParams({search: searchValue});
    } else {
      navigation.setParams({search: undefined});
    }

    setMode(prev => {
      if (!searchValue) return 'main';
      return prev === 'search' && !searchValue ? 'main' : 'search';
    });
  };

  const renderShopCardItem = ({item}: ListRenderItemInfo<ShopItem>) => {
    return (
      <CategoryListItemContainer to={`/shops/${item.id}`}>
        <ShopRecentlyCardBadgesContainer>
          {item.prefecture && (
            <ShopRecentlyCardLocationBadge numberOfLines={1}>
              {item.prefecture}
            </ShopRecentlyCardLocationBadge>
          )}

          {item.tag && (
            <ShopRecentlyCardTagBadge numberOfLines={1}>
              {item.tag}
            </ShopRecentlyCardTagBadge>
          )}
        </ShopRecentlyCardBadgesContainer>
        <ShopCategoryCardTitleContainer>
          <ShopCategoryCardTitle>{item.name}</ShopCategoryCardTitle>
        </ShopCategoryCardTitleContainer>
        <ShopRecentlyCardImage source={{uri: item.images[0].imageUrl}} />
      </CategoryListItemContainer>
    );
  };

  const renderScreenListLoading = () => {
    return (
      <ScreenListLoadingContainer>
        <ScreenListLoading />
      </ScreenListLoadingContainer>
    );
  };

  const renderScreenListEmpty = () => {
    return (
      <EmptyList
        title={strings.shop_no_shops_title}
        description={strings.shop_recently_try_later_text}
      />
    );
  };

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopRecentlyViewedContainerContent>
          <ShopsSearchByCategory
            mode={mode}
            initialSearchValue={screenParams?.search}
            onSearch={onSearch}
          />
          <AppLayout>
            {isEmptyList && shopByCategoryListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && shopByCategoryListRequest.error ? (
              <AppError
                error={shopByCategoryListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              <>
                <AppTransparentHeader
                  title={shopByCategoryListRequest.data?.categoryName}
                  backNoHistoryRoute="/shops"
                />
                {mode === 'main' ? (
                  shopByCategoryListRequest.data && (
                    <ShopRecentlyViewedListContainer>
                      <ShopRecentlyViewedList
                        numColumns={theme.isDesktop ? 4 : 1}
                        refreshControl={
                          <AppRefreshControl
                            onRefresh={onFirstPage}
                            refreshing={
                              shopByCategoryListRequest.isFirstLoading
                            }
                          />
                        }
                        data={shopByCategoryListRequest.data.items}
                        renderItem={renderShopCardItem}
                        keyExtractor={item => String(item.id)}
                        onEndReached={onNextPage}
                        ListFooterComponent={
                          shopByCategoryListRequest.isLoading
                            ? renderScreenListLoading
                            : null
                        }
                        ListEmptyComponent={renderScreenListEmpty}
                      />
                    </ShopRecentlyViewedListContainer>
                  )
                ) : (
                  <SearchResultsByCategory searchValue={screenParams?.search} />
                )}
              </>
            )}
          </AppLayout>
        </ShopRecentlyViewedContainerContent>
      </ShopsContainerContainer>
    </>
  );
}
