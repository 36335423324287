import {ComponentProps, ComponentType} from 'react';
import {ImageProps, ImageStyle} from 'react-native';
import styled from 'styled-components/native';
import {Divider, Text} from '@ui-kitten/components';
import {css} from 'scl/src/styled-components/css';
import Eye from '../../../assets/icons/Eye';
import EyeOff from '../../../assets/icons/EyeOff';
import {
  AppButton,
  GhostLink,
  OutlineButton,
  OutlineLink,
} from '../../../uikit/AppButtons';
import {AppInput} from '../../../uikit/AppInput';
import HideKeyboardContainer from '../../../uikit/HideKeyboardContainer';
import AppKeyboardAvoidingView from '../../../uikit/AppKeyboardAvoidingView';
import Logo from '../../../assets/icons/LoginLogo';

export const LoginContainerOutside = styled(HideKeyboardContainer)`
  flex: 1;
`;

export const LoginContainerView = styled(AppKeyboardAvoidingView)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  justify-content: center;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-items: center;
    `}
`;

export const LoginContentView = styled.View`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors['color-basic-400']};
  border-radius: 4px;

  ${props => {
    return (
      props.theme.isDesktop &&
      css`
        padding: 40px;
      `
    );
  }}
`;

export const LoginLogoContainer = styled.View`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const LoginLogo = styled<
  ComponentType<ImageProps & ComponentProps<typeof Logo>>
>(Logo)``;

export const LoginTitleText = styled(Text)`
  margin-top: ${props => props.theme.dimens.bigSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
`;

export const LoginInput = styled(AppInput)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
`;

export const LoginEye = styled<
  ComponentType<ImageProps & ComponentProps<typeof Eye>>
>(Eye).attrs(props => ({
  fill: (props.style as ImageStyle)?.tintColor,
}))``;

export const LoginEyeOff = styled<
  ComponentType<ImageProps & ComponentProps<typeof EyeOff>>
>(EyeOff).attrs(props => ({
  fill: (props.style as ImageStyle)?.tintColor,
}))``;

export const LoginInputErrorText = styled(Text).attrs({
  status: 'danger',
})``;

export const LoginButton = styled(AppButton).attrs({})`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

export const LoginSignUpButton = styled(OutlineLink)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

export const LoginButtonDivider = styled(Divider)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

export const LoginRestoreButton = styled(GhostLink).attrs({
  size: 'small',
})`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;
