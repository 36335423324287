import React, {useCallback, useEffect, useMemo} from 'react';
import {
  CreditCardPaymentSystem,
  SettingId,
} from '../../../api/settings/models/settingsResponse';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import {settingsRequestAction} from '../../../features/app/store/action';
import {AppError} from '../../../uikit/AppError';
import {AppLoader} from '../../../uikit/AppLoader';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {useTheme} from 'styled-components/native';
import AppHeader from '../../../uikit/AppHeader';
import {useStrings} from '../../../utils/providers/strings';
import {AppWhiteStatusBar} from '../../../uikit/AppStatusBar';
import {BackHandler} from 'react-native';
import useRouter from '../../../utils/use-router';
import {
  LinkBuyItem,
  LinkBuyItemContainer,
  LinkBuyItemInfo,
  LinkBuyItemInfoName,
  LinkBuyItemSelectIcon,
} from './styles';

export default function ConciergePointsBuyScreen() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const router = useRouter();
  const strings = useStrings();
  const settingsRequest = useRequestSelector(
    store => store.app.settingsRequest,
  );

  useEffect(() => {
    dispatch(settingsRequestAction.request());
  }, [dispatch]);

  const onBack = useCallback((): boolean => {
    router.back('/');
    return true;
  }, [router]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBack,
    );

    return () => backHandler.remove();
  }, [onBack]);

  const isPaymentActive = (settingId: SettingId) => {
    const switcherValue = settingsRequest.data?.find(
      item => item.id === settingId,
    )?.value;

    return switcherValue === 'true' ? true : false;
  };

  const routeCreditCard = useMemo(() => {
    const creditCardPaymentSystem = settingsRequest?.data?.find(
      ({id}) => id === SettingId.CREDIT_CARD_PAYMENT_SYSTEM,
    )?.value;

    if (creditCardPaymentSystem === CreditCardPaymentSystem.VIRTUALPAY) {
      return '/cp/virtualpay';
    }
    if (creditCardPaymentSystem === CreditCardPaymentSystem.CYBERSOURCE) {
      return '/cp/cybersource';
    }
    return undefined;
  }, [settingsRequest]);

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.concierge_points_buy_title}
              backNoHistoryRoute="/"
            />
            <LinkBuyItemContainer>
              {settingsRequest.isLoading ? (
                <AppLoader />
              ) : settingsRequest.error ? (
                <AppError error={settingsRequest.error.message} />
              ) : (
                isPaymentActive(SettingId.CREDIT_CARD_SWITCHER) &&
                routeCreditCard && (
                  <LinkBuyItem last={false} to={routeCreditCard}>
                    <LinkBuyItemInfo>
                      <LinkBuyItemInfoName>
                        {strings.concierge_points_buy_credit_card_title}
                      </LinkBuyItemInfoName>
                    </LinkBuyItemInfo>
                    <LinkBuyItemSelectIcon />
                  </LinkBuyItem>
                )
              )}

              {isPaymentActive(SettingId.BTC_PAY_SWITCHER) && (
                <LinkBuyItem last={false} to="/cp/btc">
                  <LinkBuyItemInfo>
                    <LinkBuyItemInfoName>
                      {strings.concierge_points_buy_btc_title}
                    </LinkBuyItemInfoName>
                  </LinkBuyItemInfo>
                  <LinkBuyItemSelectIcon />
                </LinkBuyItem>
              )}

              {isPaymentActive(SettingId.BANK_TRANSFER_SWITCHER) && (
                <LinkBuyItem last to="/cp/transfer">
                  <LinkBuyItemInfo>
                    <LinkBuyItemInfoName>
                      {strings.concierge_points_buy_by_transfer_title}
                    </LinkBuyItemInfoName>
                  </LinkBuyItemInfo>
                  <LinkBuyItemSelectIcon />
                </LinkBuyItem>
              )}
            </LinkBuyItemContainer>
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
