import {AxiosInstance} from 'axios';
import {SplitPaymentResponse} from './models/responses/split-payment';
import {ListResponse} from '../models/response/list';
import {PaginationRequest} from '../../utils/redux/create-pagination-request-reducer';
import {DefaultResponse} from '../models/response/default';
import {SplitPaymentDetailResponse} from './models/responses/detail';
import {UpdateSplitPaymentRequest} from './models/requests/detail';
import {CreateSplitPaymentRequest} from './models/requests/split-payment';

export default class SplitPaymentsApi {
  constructor(private axiosInstance: AxiosInstance) {}

  getSplitPaymentList(
    query: PaginationRequest,
  ): Promise<ListResponse<SplitPaymentResponse>> {
    return this.axiosInstance.get('split-payment', {
      params: query,
    });
  }

  getSplitPaymentDetailRequest(
    id: string,
  ): Promise<DefaultResponse<SplitPaymentDetailResponse>> {
    return this.axiosInstance.get(`split-payment/${id}`);
  }

  updateSplitPaymentRequest(
    id: string,
    request: UpdateSplitPaymentRequest,
  ): Promise<DefaultResponse<SplitPaymentDetailResponse>> {
    return this.axiosInstance.put(`split-payment/${id}`, request);
  }

  createSplitPaymentRequest(
    request: CreateSplitPaymentRequest,
  ): Promise<DefaultResponse<SplitPaymentResponse>> {
    return this.axiosInstance.post(`split-payment`, request);
  }
}
