import React, {useCallback, useEffect, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {NavigationState, useFocusEffect} from '@react-navigation/native';

import type {PaginationRequest} from '../../utils/redux/create-pagination-request-reducer';
import {useAppDispatch, usePaginationRequestSelector} from '../../store/store';
import {useStrings} from '../../utils/providers/strings';
import {
  getShopCategoriesListRequestAction,
  getShopsFlatCategoriesListAction,
  searchShopsRequestAction,
} from './store/action';

import ShopsCategoryReel from './components/category';
import AppStatusBar from '../../uikit/AppStatusBar';
import {AppLayout} from '../../uikit/AppLayout';
import {AppLoader} from '../../uikit/AppLoader';
import {AppError} from '../../uikit/AppError';
import ShopsSlider from './components/slider';
import {ShopsSearch, ShopsSearchResults} from './ui';

import StarWithSmile from '../../assets/icons/StarWithSmile';
import Heart from '../../assets/icons/Heart';
import Eye from '../../assets/icons/Eye';

import {
  ShopsContainerContainer,
  ShopsContainerContent,
  ShopsRouteContainer,
  ShopsRouteItem,
  ShopsScrollView,
} from './styles';

/** Get query params of current screen */
const getScreenParams = (
  state: NavigationState,
  routeName: string,
): Record<string, string> | null => {
  return (
    (state.routes.find(item => item.name === routeName)?.params as Record<
      string,
      string
    >) || null
  );
};

export default function ShopsScreen({navigation, route}) {
  const screenParams = getScreenParams(navigation.getState(), route.name);

  const [mode, setMode] = useState<'search' | 'main'>(
    screenParams?.search ? 'search' : 'main',
  );

  const dispatch = useAppDispatch();
  const strings = useStrings();
  const toast = useToast();

  const shopCategoriesListRequest = usePaginationRequestSelector(
    store => store.shops.shopCategoriesListRequest,
  );

  const cards = shopCategoriesListRequest.data?.items
    .filter(slide => slide.shops.length)
    .map(slide => ({
      id: slide.shops[0].id,
      title: slide.shops[0].name,
      location: slide.shops[0].prefecture,
      tag: slide.shops[0].tag,
      images: slide.shops[0].images,
    }));

  const onSearch = (searchValue: string) => {
    if (searchValue) {
      navigation.setParams({search: searchValue});
    } else {
      navigation.setParams({search: undefined});
      dispatch(getShopsFlatCategoriesListAction.clean());
      dispatch(searchShopsRequestAction.clean());
    }

    setMode(prev => {
      if (!searchValue) return 'main';
      return prev === 'search' && !searchValue ? 'main' : 'search';
    });
  };

  const onFirstPage = useCallback(() => {
    const request: PaginationRequest = {
      limit: 50,
      offset: 0,
    };
    dispatch(getShopCategoriesListRequestAction.request(request));
  }, [dispatch]);

  const onInit = useCallback(() => {
    dispatch(getShopCategoriesListRequestAction.clean());
    onFirstPage();
  }, [dispatch, onFirstPage]);

  useEffect(() => {
    if (screenParams?.search) {
      // Search categories
      dispatch(
        getShopsFlatCategoriesListAction.request({
          limit: 6,
          offset: 0,
          name: screenParams.search,
        }),
      );
      // Search shops
      dispatch(
        searchShopsRequestAction.request({
          query: screenParams.search,
          limit: 20,
          offset: 0,
        }),
      );
    }

    return function () {
      dispatch(getShopsFlatCategoriesListAction.clean());
      dispatch(getShopCategoriesListRequestAction.clean());
      dispatch(searchShopsRequestAction.clean());
    };
  }, []);

  useEffect(() => {
    if (
      shopCategoriesListRequest.error &&
      shopCategoriesListRequest.data?.items.length
    ) {
      toast.show(shopCategoriesListRequest.error.message);
    }
  }, [shopCategoriesListRequest, toast]);

  useFocusEffect(onInit);

  const isEmptyList = !shopCategoriesListRequest.data?.items.length;

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopsContainerContent>
          <ShopsSearch
            mode={mode}
            initialSearchValue={screenParams?.search}
            onSearch={onSearch}
          />
          <AppLayout>
            {isEmptyList && shopCategoriesListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && shopCategoriesListRequest.error ? (
              <AppError
                error={shopCategoriesListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              <>
                {mode === 'main' ? (
                  <>
                    <ShopsScrollView>
                      <ShopsRouteContainer>
                        <ShopsRouteItem
                          name={strings.shop_favorites_button}
                          route="/shops/favorites">
                          <Heart />
                        </ShopsRouteItem>
                        <ShopsRouteItem
                          name={strings.shop_recently_viewed_button}
                          route="/shops/recently-viewed">
                          <Eye fill="#DC9B2D" width={32} height={32} />
                        </ShopsRouteItem>
                        <ShopsRouteItem
                          name={strings.shop_all_reviews_button}
                          route="/shops/reviews"
                          lastItem>
                          <StarWithSmile />
                        </ShopsRouteItem>
                      </ShopsRouteContainer>
                      {cards && <ShopsSlider cards={cards} />}
                      {shopCategoriesListRequest.data && (
                        <ShopsCategoryReel
                          data={shopCategoriesListRequest.data?.items}
                        />
                      )}
                    </ShopsScrollView>
                  </>
                ) : (
                  <ShopsSearchResults searchValue={screenParams?.search} />
                )}
              </>
            )}
          </AppLayout>
        </ShopsContainerContent>
      </ShopsContainerContainer>
    </>
  );
}
