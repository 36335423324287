import styled from 'styled-components/native';

export const InfoPostCardWrapper = styled.TouchableOpacity`
  padding: 12px 16px;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-bottom: 12px;
  padding-right: 30%;
`;
export const InfoPostCardHeader = styled.View`
  padding-bottom: 12px;
`;
export const InfoPostCardDateWrapper = styled.View`
  display: flex;
`;

export const InfoPostCardDate = styled.Text`
  align-self: flex-start;
  background: ${props => props.theme.colors['color-basic-200']};
  padding: 4px 8px;
  border-radius: 10px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: ${props => props.theme.colors['color-basic-900']};
`;
export const InfoPostCardBody = styled.View``;
export const InfoPostCardBodyContent = styled.Text`
  color: ${props => props.theme.colors['color-basic-800']};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
`;
