import React, {useCallback, useEffect, useState} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../uikit/Points/container';
import {useTheme} from 'styled-components/native';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import {
  FriendNewEmptyContainer,
  FriendNewEmptyIcon,
  FriendNewEmptyInfo,
  FriendNewEmptyTitle,
  FriendNewItemAddButton,
  FriendNewItemAddButtonIcon,
  FriendNewItemAddedIcon,
  FriendNewItemContainer,
  FriendNewItemInfo,
  FriendNewItemInfoId,
  FriendNewItemInfoName,
  FriendNewItemInfoStatus,
  FriendNewItemProgress,
  FriendNewSearch,
} from './styles';
import {useStrings} from '../../../utils/providers/strings';
import AppStatusBar from '../../../uikit/AppStatusBar';
import {
  addFriendRequestAction,
  searchFriendRequestAction,
} from '../store/action';
import {AppTransparentHeader} from '../../../uikit/AppHeader';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {
  getFriendStatusText,
  getFriendStatusType,
} from '../../../api/friend/models/responses/friend';
import {useFocusEffect} from '@react-navigation/native';
import usePhoneMask from '../../../utils/use-phone-mask';
import {TouchableOpacity} from 'react-native';
import Close from '../../../assets/icons/Close';

export default function FriendNewScreen() {
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const searchFriendRequest = useRequestSelector(
    store => store.friendList.searchFriendRequest,
  );
  const addFriendRequest = useRequestSelector(
    store => store.friendList.addFriendRequest,
  );

  const [searchQuery, setSearchQuery] = useState('');
  const {masked, unmasked, phoneLength} = usePhoneMask(searchQuery);

  useEffect(() => {
    return function () {
      dispatch(addFriendRequestAction.clean());
      dispatch(searchFriendRequestAction.clean());
    };
  }, [dispatch]);

  const onRequest = useCallback(() => {
    if (!searchQuery || searchQuery.length < phoneLength) {
      dispatch(searchFriendRequestAction.clean());
      return;
    }

    dispatch(searchFriendRequestAction.request(unmasked));
  }, [dispatch, searchQuery]);

  useFocusEffect(onRequest);

  const onAdd = () => {
    const id = searchFriendRequest.data?.friend.id;
    if (id === undefined) return;

    dispatch(
      addFriendRequestAction.request({
        friendId: id,
      }),
    );
  };

  const renderClearIcon = () => (
    <TouchableOpacity onPress={() => setSearchQuery('')}>
      <Close />
    </TouchableOpacity>
  );

  return (
    <>
      <AppStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppTransparentHeader
              title={strings.friend_new_title}
              backNoHistoryRoute="/friends"
            />
            <FriendNewSearch
              value={masked}
              onChangeText={setSearchQuery}
              placeholder={strings.friend_new_search_placeholder}
              maxLength={phoneLength}
              accessoryLeft={searchQuery ? renderClearIcon : undefined}
            />
            {searchFriendRequest.isLoading ? (
              <AppLoader />
            ) : searchFriendRequest.error ? (
              <AppError error={searchFriendRequest.error.message} />
            ) : searchFriendRequest.data ? (
              <FriendNewItemContainer>
                <FriendNewItemInfo>
                  <FriendNewItemInfoName>
                    {searchFriendRequest.data.friend.firstName}{' '}
                    {searchFriendRequest.data.friend.lastName}
                  </FriendNewItemInfoName>
                  <FriendNewItemInfoId>
                    {strings.friend_list_id_prefix_fn(
                      searchFriendRequest.data.friend.clientId,
                    )}
                  </FriendNewItemInfoId>
                  {searchFriendRequest.data.status && (
                    <FriendNewItemInfoStatus
                      status={getFriendStatusType(
                        searchFriendRequest.data.status,
                      )}>
                      {getFriendStatusText(
                        strings,
                        searchFriendRequest.data.status,
                      )}
                    </FriendNewItemInfoStatus>
                  )}
                </FriendNewItemInfo>
                {addFriendRequest.isLoading ? (
                  <FriendNewItemProgress />
                ) : searchFriendRequest.data.status ? (
                  <FriendNewItemAddedIcon />
                ) : (
                  <FriendNewItemAddButton onPress={onAdd}>
                    <FriendNewItemAddButtonIcon />
                  </FriendNewItemAddButton>
                )}
              </FriendNewItemContainer>
            ) : searchFriendRequest.data === null ? (
              <FriendNewEmptyContainer>
                <FriendNewEmptyIcon />
                <FriendNewEmptyTitle>
                  {strings.friend_new_empty_title}
                </FriendNewEmptyTitle>
                <FriendNewEmptyInfo>
                  {strings.friend_new_empty_message}
                </FriendNewEmptyInfo>
              </FriendNewEmptyContainer>
            ) : (
              <FriendNewEmptyContainer>
                <FriendNewEmptyIcon />
                <FriendNewEmptyTitle>
                  {strings.friend_new_empty_search_title}
                </FriendNewEmptyTitle>
              </FriendNewEmptyContainer>
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
