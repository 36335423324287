import React, {useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {useTheme} from 'styled-components/native';
import AppHeader from '../../../uikit/AppHeader';
import {useStrings} from '../../../utils/providers/strings';
import {
  StandardPointsExchangeButton,
  StandardPointsExchangeContainer,
  StandardPointsExchangeHeader,
  StandardPointsExchangeInfo,
  StandardPointsExchangeInput,
  StandardPointsExchangeInputError,
  StandardPointsExchangeKeyboardAvoidingView,
  StandardPointsExchangeOutside,
} from './styles';
import {Controller, useForm} from 'react-hook-form';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import {onNumberChangeText} from 'scl/src/react-native/input/on-number-change-text';
import {AccessoryLoadingIndicator} from '../../../uikit/AccessoryLoadingIndicator';
import {useToast} from 'react-native-toast-notifications';
import {AppWhiteStatusBar} from '../../../uikit/AppStatusBar';
import {exchangePointsRequestAction} from '../store/action';
import useRouter from '../../../utils/use-router';
import {getUserProfileRequestAction} from '../../user/store/action';

interface ExchangeForm {
  amount: string;
}

export default function StandardPointsExchangeScreen() {
  const toast = useToast();
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const exchangePointsRequest = useRequestSelector(
    store => store.standardPoints.exchangePointsRequest,
  );

  const {
    control,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm<ExchangeForm>({
    defaultValues: {
      amount: '',
    },
  });

  useEffect(() => {
    return function () {
      dispatch(exchangePointsRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (exchangePointsRequest.data) {
      toast.show(strings.standard_points_exchange_success, {type: 'success'});
      router.push('/exchanges');
    }
  }, [exchangePointsRequest.data, toast, strings, router]);

  useEffect(() => {
    if (exchangePointsRequest.error) {
      toast.show(exchangePointsRequest.error.message);
    }

    if (exchangePointsRequest.data) {
      dispatch(getUserProfileRequestAction.request());
    }
  }, [exchangePointsRequest]);

  const onExchange = (form: ExchangeForm) => {
    dispatch(
      exchangePointsRequestAction.request({
        amount: Number(form.amount),
      }),
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.standard_points_exchange_title}
              backNoHistoryRoute="/"
            />
            <StandardPointsExchangeOutside>
              <StandardPointsExchangeKeyboardAvoidingView>
                <StandardPointsExchangeContainer>
                  <StandardPointsExchangeHeader>
                    {strings.standard_points_exchange_input_title}
                  </StandardPointsExchangeHeader>
                  <Controller
                    control={control}
                    rules={{
                      required: strings.standard_points_exchange_required,
                    }}
                    render={({
                      field: {onChange, onBlur, value},
                      fieldState: {error},
                    }) => (
                      <StandardPointsExchangeInput
                        status={error ? 'danger' : 'basic'}
                        onBlur={onBlur}
                        onChangeText={onNumberChangeText(onChange)}
                        value={value}
                        placeholder={
                          strings.standard_points_exchange_placeholder
                        }
                      />
                    )}
                    name="amount"
                  />
                  <StandardPointsExchangeInfo>
                    {strings.standard_points_exchange_info}
                  </StandardPointsExchangeInfo>
                  {errors.amount && (
                    <StandardPointsExchangeInputError>
                      {errors.amount.message}
                    </StandardPointsExchangeInputError>
                  )}
                </StandardPointsExchangeContainer>
                <StandardPointsExchangeButton
                  disabled={exchangePointsRequest.isLoading}
                  accessoryRight={
                    exchangePointsRequest.isLoading
                      ? AccessoryLoadingIndicator
                      : undefined
                  }
                  onPress={handleSubmit(onExchange)}>
                  {strings.standard_points_exchange_button.toUpperCase()}
                </StandardPointsExchangeButton>
              </StandardPointsExchangeKeyboardAvoidingView>
            </StandardPointsExchangeOutside>
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
