import React, {useLayoutEffect, useRef} from 'react';

export default function ConciergePointsPayment({html}: {html: string}) {
  const htmlRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (htmlRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      htmlRef.current.querySelector('#checkoutForm')?.submit();
    }
  });

  return <div ref={htmlRef} dangerouslySetInnerHTML={{__html: html}} />;
}
