import React from 'react';
import {RequestCategoryTypeField} from '../../../../../../../api/request/models/responses/category-type';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldErrorText,
  NewRequestQuestionFieldTitle,
} from '../styles';
import {NewRequestQuestionFieldTimeInputContainer} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  NewRequestQuestionFieldTimeState,
  NewRequestQuestionForm,
} from '../../../form';
import AppTimePicker from '../../../../../../../uikit/AppTimePicker';

export default function NewRequestQuestionFieldTime({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  return (
    <NewRequestQuestionFieldContainer>
      <NewRequestQuestionFieldTitle>{field.title}</NewRequestQuestionFieldTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const timeValue = value as NewRequestQuestionFieldTimeState;
          return (
            <NewRequestQuestionFieldTimeInputContainer>
              <AppTimePicker
                value={timeValue}
                onChange={newValue => onChange(newValue)}
              />
            </NewRequestQuestionFieldTimeInputContainer>
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
    </NewRequestQuestionFieldContainer>
  );
}
