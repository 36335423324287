import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import api from '../../../api';
import {
  acceptFriendRequestAction,
  addFriendRequestAction,
  deleteFriendRequestAction,
  getFriendListRequestAction,
  rejectFriendRequestAction,
  searchFriendRequestAction,
} from './action';
import {ListResponse} from '../../../api/models/response/list';
import {
  Friend,
  SearchFriend,
} from '../../../api/friend/models/responses/friend';
import {DefaultResponse} from '../../../api/models/response/default';
import {
  UpdateFriendRequest,
  UpdateFriendStatus,
} from '../../../api/friend/models/requests/update';
import {SearchFriendRequest} from '../../../api/friend/models/requests/friend';
import {ServerError} from '../../../api/models/server-error';

export const FRIEND_LIST_LIMIT = 10;

const getFriendListRequest = function* (
  action: ReturnType<typeof getFriendListRequestAction.request>,
) {
  try {
    const response: ListResponse<Friend> = yield call(
      [api, api.friend.getFriendList],
      action.payload,
    );
    yield put(getFriendListRequestAction.success([action.payload, response]));
  } catch (error) {
    yield put(getFriendListRequestAction.failure(error as Error));
  }
};

const addFriendRequest = function* (
  action: ReturnType<typeof addFriendRequestAction.request>,
) {
  try {
    const response: DefaultResponse<Friend> = yield call(
      [api, api.friend.addFriend],
      action.payload,
    );
    yield put(addFriendRequestAction.success(response.data));
  } catch (error) {
    yield put(addFriendRequestAction.failure(error as Error));
  }
};

const acceptFriendRequest = function* (
  action: ReturnType<typeof acceptFriendRequestAction.request>,
) {
  const [id] = action.payload;
  try {
    const response: DefaultResponse<Friend> = yield call(
      [api, api.friend.updateFriend],
      id.toString(),
      {
        status: UpdateFriendStatus.APPROVED,
      } as UpdateFriendRequest,
    );
    yield put(acceptFriendRequestAction.success([id, response.data]));
  } catch (error) {
    yield put(acceptFriendRequestAction.failure([id, error as Error]));
  }
};

const rejectFriendRequest = function* (
  action: ReturnType<typeof rejectFriendRequestAction.request>,
) {
  const [id] = action.payload;
  try {
    const response: DefaultResponse<void> = yield call(
      [api, api.friend.deleteFriend],
      id.toString(),
    );
    yield put(rejectFriendRequestAction.success([id, {}]));
  } catch (error) {
    yield put(rejectFriendRequestAction.failure([id, error as Error]));
  }
};

const deleteFriendRequest = function* (
  action: ReturnType<typeof deleteFriendRequestAction.request>,
) {
  const [id] = action.payload;
  try {
    const response: DefaultResponse<void> = yield call(
      [api, api.friend.deleteFriend],
      id.toString(),
    );
    yield put(deleteFriendRequestAction.success([id, {}]));
  } catch (error) {
    yield put(deleteFriendRequestAction.failure([id, error as Error]));
  }
};

const searchFriendRequest = function* (
  action: ReturnType<typeof searchFriendRequestAction.request>,
) {
  try {
    const response: DefaultResponse<SearchFriend> = yield call(
      [api, api.friend.searchFriendByPhone],
      {
        phone: action.payload,
      } as SearchFriendRequest,
    );
    yield put(searchFriendRequestAction.success(response.data));
  } catch (error) {
    if (error instanceof ServerError) {
      if (error.status === 404) {
        yield put(searchFriendRequestAction.success(null));
      } else {
        yield put(searchFriendRequestAction.failure(error as Error));
      }
    } else {
      yield put(searchFriendRequestAction.failure(error as Error));
    }
  }
};

export const friendListSaga = function* () {
  yield all([
    throttle(500, getFriendListRequestAction.request, getFriendListRequest),
    takeLatest(addFriendRequestAction.request, addFriendRequest),
    takeEvery(acceptFriendRequestAction.request, acceptFriendRequest),
    takeEvery(rejectFriendRequestAction.request, rejectFriendRequest),
    takeEvery(deleteFriendRequestAction.request, deleteFriendRequest),
    takeEvery(deleteFriendRequestAction.request, deleteFriendRequest),
    throttle(1000, searchFriendRequestAction.request, searchFriendRequest),
  ]);
};
