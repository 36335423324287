import {AxiosInstance} from 'axios';
import {DefaultResponse} from '../models/response/default';
import {StandardPointsWithdrawalResponse} from './models/responses/withdrawal';
import {
  StandardPointsWithdrawalRequest,
  StandardPointsWithdrawalUpdateRequest,
} from './models/requests/withdrawal';
import {ListResponse} from '../models/response/list';
import {PaginationRequest} from '../../utils/redux/create-pagination-request-reducer';
import {StandardPointsExchangeRequest} from './models/requests/exchange';
import {Exchange} from './models/responses/exchange';

export default class StandardPointsApi {
  constructor(private axiosInstance: AxiosInstance) {}

  withdrawalPoints(
    request: StandardPointsWithdrawalRequest,
  ): Promise<DefaultResponse<StandardPointsWithdrawalResponse>> {
    return this.axiosInstance.post('withdraw-request', request);
  }

  getWithdrawalList(
    query: PaginationRequest,
  ): Promise<ListResponse<StandardPointsWithdrawalResponse>> {
    return this.axiosInstance.get('withdraw-request', {
      params: query,
    });
  }

  updateWithdrawalPoints(
    request: StandardPointsWithdrawalUpdateRequest,
  ): Promise<DefaultResponse<StandardPointsWithdrawalResponse>> {
    return this.axiosInstance.put(`withdraw-request/${request.id}`, {
      status: request.status,
    });
  }

  exchangePoints(
    request: StandardPointsExchangeRequest,
  ): Promise<DefaultResponse<Exchange>> {
    return this.axiosInstance.post('exchange-request', request);
  }

  getExchangeList(query: PaginationRequest): Promise<ListResponse<Exchange>> {
    return this.axiosInstance.get('exchange-request', {
      params: query,
    });
  }
}
