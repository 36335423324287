import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  useAppDispatch,
  useAppSelector,
  useJwtUserSelector,
  usePaginationRequestSelector,
  useRequestSelector,
} from '../../../../../store/store';
import {useTheme} from 'styled-components/native';
import {useToast} from 'react-native-toast-notifications';
import {
  getDetailRequestChatRequestAction,
  sendRequestMessageRequestAction,
} from '../../../store/action';
import {REQUEST_CHAT_LIMIT} from '../../../store/saga';
import {useStrings} from '../../../../../utils/providers/strings';
import {
  DetailRequestChatContainer,
  DetailRequestChatControlContainer,
  DetailRequestChatInput,
  DetailRequestChatInputButton,
  DetailRequestChatInputContainer,
  DetailRequestChatInputIcon,
  DetailRequestChatInputLoading,
  DetailRequestChatInputLoadingContainer,
  DetailRequestChatList,
  DetailRequestChatListLoading,
  DetailRequestChatListLoadingContainer,
  DetailRequestDeleteUploadButton,
  DetailRequestDeleteUploadIcon,
  DetailRequestImagePreview,
  DetailRequestPreviewUploadContainer,
} from './styles';
import {AppLoader} from '../../../../../uikit/AppLoader';
import {AppError} from '../../../../../uikit/AppError';
import AppHeader from '../../../../../uikit/AppHeader';
import {Dimensions, ListRenderItemInfo, Platform} from 'react-native';
import {
  RequestChatMessage,
  RequestChatMessageType,
} from '../../../../../api/request/models/responses/chat';
import DetailRequestChatCommon from './components/messages/common';
import DetailRequestChatMy from './components/messages/my';
import AppRefreshControl from '../../../../../uikit/AppRefreshControl';
import {Controller, useForm} from 'react-hook-form';
import {getMultipleAnyError} from 'scl/src/redux/create-multiple-request-reducer';
import DetailRequestChatTransferRequest from './components/messages/transfer-request';
import InvertedFlatList from 'scl/src/components/inverted-flat-list';
import DetailRequestChatSystem from './components/messages/system';
import {Modal} from '@ui-kitten/components';
import {
  DetailRequestChatCommonBigImage,
  DetailRequestChatCommonImageButton,
} from './components/messages/common/styles';
import DetailRequestRecorderSound, {TypeBloblUrl} from './components/recorder';
import DetailRequestSound from './components/sound';
import DetailRequestImageUploader from './components/image-uploader';

interface DetailRequestChatForm {
  message: string;
}

export default function DetailRequestChat({
  requestId,
  onBack,
}: {
  requestId: string;
  onBack: () => void;
}) {
  const screenHeight = Dimensions.get('window').height;
  const modalStyle = {
    width: Platform.OS === 'web' ? '640px' : '100%',
    height: screenHeight,
  };
  const imageStyle = {
    height: screenHeight,
    backgroundColor: Platform.OS === 'web' ? 'transparent' : 'black',
  };

  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const user = useJwtUserSelector();
  const chatRequest = usePaginationRequestSelector(
    store => store.requests.chatRequest,
  );
  const isEmptyList = !chatRequest.data?.items.length;
  const sendMessageRequest = useRequestSelector(
    store => store.requests.sendMessageRequest,
  );
  const acceptTransferRequest = useAppSelector(
    store => store.requests.acceptTransferRequest,
  );
  const rejectTransferRequest = useAppSelector(
    store => store.requests.rejectTransferRequest,
  );
  const listRef = useRef<InvertedFlatList<RequestChatMessage> | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const [isAttachUploading, setIsAttachUploading] = useState<boolean>(false);
  const [blobUrl, setBlobUrl] = useState<TypeBloblUrl | null>(null);

  const {control, handleSubmit, resetField, getValues} =
    useForm<DetailRequestChatForm>({
      mode: 'onSubmit',
      defaultValues: {
        message: '',
      },
    });

  useEffect(() => {
    if (chatRequest.error && chatRequest.data?.items.length) {
      toast.show(chatRequest.error.message);
    }
  }, [chatRequest, toast]);

  useEffect(() => {
    if (sendMessageRequest.error) {
      toast.show(sendMessageRequest.error.message);
    }
  }, [sendMessageRequest, toast]);

  useEffect(() => {
    const error = getMultipleAnyError(acceptTransferRequest);
    if (error) {
      toast.show(error.message);
    }
  }, [acceptTransferRequest, toast]);

  useEffect(() => {
    const error = getMultipleAnyError(rejectTransferRequest);
    if (error) {
      toast.show(error.message);
    }
  }, [rejectTransferRequest, toast]);

  useEffect(() => {
    if (sendMessageRequest.data) {
      dispatch(sendRequestMessageRequestAction.clean());
      listRef.current?.scrollToOffset({
        animated: true,
        offset: 0,
      });
      resetField('message');
    }
  }, [dispatch, resetField, sendMessageRequest]);

  useEffect(() => {
    if (!isAttachUploading) {
      setBlobUrl(null);
    }
  }, [isAttachUploading]);

  const onChatFirstPage = useCallback(() => {
    dispatch(
      getDetailRequestChatRequestAction.request({
        id: requestId,
        limit: REQUEST_CHAT_LIMIT,
        offset: 0,
      }),
    );
  }, [dispatch, requestId]);

  const onChatNextPage = () => {
    if (chatRequest.data?.isLastPage || chatRequest.isLoading) return;

    dispatch(
      getDetailRequestChatRequestAction.request({
        id: requestId,
        limit: REQUEST_CHAT_LIMIT,
        offset: chatRequest.data?.items.length || 0,
      }),
    );
  };

  const onRemoveUpload = () => {
    setBlobUrl(null);
  };

  const onSendMessage = async (form: DetailRequestChatForm) => {
    if (blobUrl?.type === 'audio') {
      setIsAttachUploading(true);
      setBlobUrl(null);
    } else if (blobUrl?.type === 'image') {
      setIsAttachUploading(true);
      setBlobUrl(null);
    } else {
      if (form.message.trim().length < 1) return;

      dispatch(
        sendRequestMessageRequestAction.request({
          id: requestId,
          messageText: form.message.trim(),
        }),
      );
    }
  };

  const handleImageModal = (url: string) => {
    setImageUrl(url);
    setIsModalOpen(true);
  };

  const renderChatItem = ({item}: ListRenderItemInfo<RequestChatMessage>) => {
    switch (item.type) {
      case RequestChatMessageType.NORMAL:
        if (item.author.id === user.id) {
          return (
            <DetailRequestChatMy onAction={handleImageModal} message={item} />
          );
        } else {
          return (
            <DetailRequestChatCommon
              onAction={handleImageModal}
              message={item}
              type={strings.detail_chat_item_concierge_title}
            />
          );
        }
      case RequestChatMessageType.TRANSFER_REQUEST:
        return <DetailRequestChatTransferRequest message={item} />;
      case RequestChatMessageType.SYSTEM:
        return (
          <DetailRequestChatSystem
            message={item}
            type={strings.detail_chat_item_system_title}
          />
        );
    }
    return null;
  };

  const renderChatListLoading = () => {
    return (
      <DetailRequestChatListLoadingContainer>
        <DetailRequestChatListLoading />
      </DetailRequestChatListLoadingContainer>
    );
  };

  return (
    <>
      <DetailRequestChatContainer>
        {!theme.isDesktop && (
          <AppHeader title={strings.detail_chat_title} onBackPressed={onBack} />
        )}

        {isEmptyList && chatRequest.isLoading ? (
          <AppLoader />
        ) : isEmptyList && chatRequest.error ? (
          <AppError error={chatRequest.error.message} retry={onChatFirstPage} />
        ) : (
          chatRequest.data && (
            <>
              <DetailRequestChatList
                ref={listRef}
                data={chatRequest.data.items}
                renderItem={renderChatItem}
                keyExtractor={item => String(item.id)}
                onEndReached={onChatNextPage}
                ListFooterComponent={
                  chatRequest.isLoading ? renderChatListLoading : null
                }
                refreshControl={
                  Platform.OS !== 'web' ? (
                    <AppRefreshControl
                      onRefresh={onChatFirstPage}
                      refreshing={chatRequest.isFirstLoading}
                    />
                  ) : undefined
                }
              />

              <DetailRequestChatInputContainer>
                <DetailRequestImageUploader
                  filePath={setBlobUrl}
                  message={getValues('message')}
                  requestId={requestId}
                  isUploading={setIsAttachUploading}
                  isStartUpload={isAttachUploading}
                />

                <DetailRequestRecorderSound
                  filePath={setBlobUrl}
                  message={getValues('message')}
                  requestId={requestId}
                  isUploading={setIsAttachUploading}
                  isStartUpload={isAttachUploading}
                />
                <DetailRequestChatControlContainer>
                  <Controller
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <>
                        <DetailRequestChatInput
                          status="basic"
                          onBlur={onBlur}
                          onChangeText={onChange}
                          value={value}
                          placeholder={strings.detail_chat_message_placeholder}
                          onSubmitEditing={
                            Platform.OS === 'web'
                              ? handleSubmit(onSendMessage)
                              : undefined
                          }
                          numberOfLines={1}
                          multiline={Platform.OS !== 'web'}
                        />
                      </>
                    )}
                    name="message"
                  />

                  {blobUrl ? (
                    <DetailRequestPreviewUploadContainer>
                      {blobUrl.type === 'audio' ? (
                        <DetailRequestSound audioUrl={blobUrl.url} />
                      ) : (
                        <DetailRequestImagePreview
                          source={{uri: blobUrl.url}}
                        />
                      )}
                      <DetailRequestDeleteUploadButton onPress={onRemoveUpload}>
                        <DetailRequestDeleteUploadIcon />
                      </DetailRequestDeleteUploadButton>
                    </DetailRequestPreviewUploadContainer>
                  ) : null}
                </DetailRequestChatControlContainer>

                {sendMessageRequest.isLoading || isAttachUploading ? (
                  <DetailRequestChatInputLoadingContainer>
                    <DetailRequestChatInputLoading />
                  </DetailRequestChatInputLoadingContainer>
                ) : (
                  <DetailRequestChatInputButton
                    onPress={handleSubmit(onSendMessage)}>
                    <DetailRequestChatInputIcon />
                  </DetailRequestChatInputButton>
                )}
              </DetailRequestChatInputContainer>
            </>
          )
        )}
      </DetailRequestChatContainer>

      <Modal
        visible={isModalOpen}
        style={modalStyle}
        onBackdropPress={() => setIsModalOpen(false)}>
        <DetailRequestChatCommonImageButton
          onPress={() => setIsModalOpen(false)}>
          <DetailRequestChatCommonBigImage
            style={imageStyle}
            source={{uri: imageUrl}}
            resizeMode="contain"
          />
        </DetailRequestChatCommonImageButton>
      </Modal>
    </>
  );
}
