import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import HideKeyboardContainer from '../../../../../uikit/HideKeyboardContainer';

export const NewRequestChangeFieldQuestionOutside = styled(
  HideKeyboardContainer,
)`
  flex: 1;
`;

export const NewRequestChangeFieldQuestionContainer = styled.View`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding: ${props => props.theme.dimens.normalSpacer};
    `}
`;
