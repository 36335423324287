import {ShortUser} from '../../../user/models/responses/short-user';
import {Strings} from '../../../../resources/strings';
import {EvaStatus} from '@ui-kitten/components/devsupport';

export interface Friend {
  friend: ShortUser;
  status: FriendStatus;
  createdAt: string;
  updatedAt: string;
}

export interface SearchFriend {
  friend: ShortUser;
  status?: FriendStatus;
  createdAt?: string;
  updatedAt?: string;
}

export enum FriendStatus {
  WAITING_YOUR_APPROVE = 'WAITING_YOUR_APPROVE',
  WAITING_FRIEND_APPROVE = 'WAITING_FRIEND_APPROVE',
  APPROVED = 'APPROVED',
}

export function getFriendStatusText(
  strings: Strings,
  status: FriendStatus,
): string {
  switch (status) {
    case FriendStatus.WAITING_YOUR_APPROVE:
      return strings.friend_status_waiting_your_approve;
    case FriendStatus.WAITING_FRIEND_APPROVE:
      return strings.friend_status_waiting_friend_approve;
    case FriendStatus.APPROVED:
      return strings.friend_status_approved;
  }
}

export function getFriendStatusType(status: FriendStatus): EvaStatus {
  switch (status) {
    case FriendStatus.WAITING_YOUR_APPROVE:
      return 'warning';
    case FriendStatus.WAITING_FRIEND_APPROVE:
      return 'warning';
    case FriendStatus.APPROVED:
      return 'info';
  }
}
