import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import ChatAvatarCommon from '../../../../../../../../../assets/icons/ChatAvatarCommon';
import {Text} from '@ui-kitten/components';

export const DetailRequestChatCommonContainer = styled.View`
  display: flex;
  flex-direction: column;
  margin-right: 48px;
`;

export const DetailRequestChatCommonIcon = styled(ChatAvatarCommon)`
  flex-shrink: 0;
`;

export const DetailRequestChatCommonInfoContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const DetailRequestChatCommonMessageContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
`;

export const DetailRequestChatCommonInfoType = styled(Text)`
  margin-left: 48px;
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  font-size: 12px;
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const DetailRequestChatCommonInfoBubble = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  margin-left: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors['color-basic-500']}33;
  border-radius: 4px;
  overflow: hidden;
`;

export const DetailRequestChatCommonImageButton = styled.TouchableOpacity``;

export const DetailRequestChatCommonImage = styled.Image`
  width: 160px;
  height: 100px;
  border-radius: 4px;
  margin-left: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const DetailRequestChatCommonBigImage = styled.Image``;
