import styled from 'styled-components/native';
import {Text} from '@ui-kitten/components';
import {css} from 'scl/src/styled-components/css';
import {AppButton} from '../../../uikit/AppButtons';
import {AppInput} from '../../../uikit/AppInput';
import HideKeyboardContainer from '../../../uikit/HideKeyboardContainer';
import AppKeyboardAvoidingView from '../../../uikit/AppKeyboardAvoidingView';
import {AppTransparentHeader} from '../../../uikit/AppHeader';

export const RestoreHeader = styled(AppTransparentHeader).attrs({
  withBigDesktop: false,
})``;

export const RestoreContainerOutside = styled(HideKeyboardContainer)`
  flex: 1;
`;

export const RestoreContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const RestoreContainerView = styled(AppKeyboardAvoidingView)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  justify-content: center;

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: stretch;
      align-items: center;
    `}
`;

export const RestoreContentView = styled.View`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors['color-basic-400']};
  border-radius: 4px;

  ${props => {
    return (
      props.theme.isDesktop &&
      css`
        flex: 1;
        margin-top: 10%;
        margin-bottom: 10%;
        padding: 40px;
      `
    );
  }}
`;

export const RestoreInput = styled(AppInput)``;

export const RestoreInputErrorText = styled(Text).attrs({
  status: 'danger',
})``;

export const RestoreInfo = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const RestoreButton = styled(AppButton).attrs({})`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

export const RestoreCountdown = styled(Text)`
  margin-top: 12px;
  font-weight: 300;
  font-size: 14px;
`;
