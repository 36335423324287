import {AxiosInstance} from 'axios';
import {ListResponse} from '../models/response/list';
import {PaginationRequest} from '../../utils/redux/create-pagination-request-reducer';
import {Transaction} from './models/responses';
import {QrNormalPayRequest} from './models/requests/qr-normal-pay';
import {DefaultResponse} from '../models/response/default';
import {SplitPaymentResponse} from '../split-payments/models/responses/split-payment';
import {CreateSplitPaymentRequest} from '../split-payments/models/requests/split-payment';

export default class TransactionApi {
  constructor(private axiosInstance: AxiosInstance) {}

  getTransactionList(
    query: PaginationRequest,
  ): Promise<ListResponse<Transaction>> {
    return this.axiosInstance.get('transaction', {
      params: query,
    });
  }

  qrNormalPay(
    request: QrNormalPayRequest,
  ): Promise<DefaultResponse<Transaction>> {
    return this.axiosInstance.post('qr/pay', request);
  }

  qrSplitPay(
    request: CreateSplitPaymentRequest,
  ): Promise<DefaultResponse<SplitPaymentResponse>> {
    return this.axiosInstance.post('split-payment', request);
  }
}
