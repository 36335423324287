import * as React from 'react';
import Svg, {SvgProps, Path, Rect} from 'react-native-svg';

const EmptyRequests = (props: SvgProps) => (
  <Svg width={192} height={192} viewBox="0 0 192 192" fill="none" {...props}>
    <Path
      d="M84.09 20.48 22.6 69.96V150c0 7.069 5.73 12.8 12.8 12.8h113.018c7.069 0 12.8-5.731 12.8-12.8V69.961l-61.046-49.455a12.8 12.8 0 0 0-16.082-.027Z"
      fill="#1F1B16"
    />
    <Rect
      x={42.602}
      y={35.724}
      width={99.079}
      height={123.338}
      rx={15.36}
      fill="#fff"
    />
    <Path
      d="M51.127 54.32a2.56 2.56 0 0 1 2.56-2.56h74.533a2.56 2.56 0 0 1 0 5.12H53.687a2.56 2.56 0 0 1-2.56-2.56Z"
      fill="#DC9B2D"
    />
    <Path
      d="M51.127 70.96a2.56 2.56 0 0 1 2.56-2.56h74.533a2.56 2.56 0 0 1 0 5.12H53.687a2.56 2.56 0 0 1-2.56-2.56ZM51.127 87.6a2.56 2.56 0 0 1 2.56-2.56h74.533a2.56 2.56 0 0 1 0 5.12H53.687a2.56 2.56 0 0 1-2.56-2.56ZM51.127 104.24a2.56 2.56 0 0 1 2.56-2.56h74.533a2.56 2.56 0 0 1 0 5.12H53.687a2.56 2.56 0 0 1-2.56-2.56Z"
      fill="#E9E0D8"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6 69.83V150c0 7.069 5.73 12.8 12.8 12.8h77.481c12.455 0 17.574-15.982 7.437-23.218L22.6 69.829Z"
      fill="#8F8880"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.218 69.83V150c0 7.069-5.731 12.8-12.8 12.8H34.558l126.66-92.97Z"
      fill="#AEA69E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M161.48 71.034 39.338 162.8H34.24l125.738-93.834 1.502 2.068ZM155.384 40.204c.776-3.143.725-6.17.818-9.357a1.128 1.128 0 0 0-2.256-.064c-.088 3.023-.017 5.9-.753 8.885a1.127 1.127 0 0 0 2.191.536ZM161.97 46.294c5.259-4.792 10.214-10.958 13.677-17.183a1.124 1.124 0 0 0-.439-1.53 1.131 1.131 0 0 0-1.534.439c-3.349 6.019-8.141 11.981-13.227 16.613a1.125 1.125 0 0 0-.071 1.59 1.13 1.13 0 0 0 1.594.071ZM163.302 51.53c4.81.79 9.767-.56 14.141-2.468.571-.251.831-.915.581-1.481a1.13 1.13 0 0 0-1.486-.582c-3.983 1.74-8.488 3.03-12.869 2.31a1.13 1.13 0 0 0-1.297.927 1.127 1.127 0 0 0 .93 1.294Z"
      fill="#1F1B16"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.375 29.114c.686-.244 1.407-.569 1.963-1.039.66-.558.93-1.277 1.11-2.033.231-.972.324-2.008.604-2.981.104-.362.305-.498.39-.56a.956.956 0 0 1 .643-.178c.245.018.58.11.802.52.031.059.072.148.1.27.02.09.033.372.054.487.053.285.098.57.14.857.139.955.218 1.765.658 2.642.595 1.19 1.193 1.918 2.002 2.24.783.313 1.72.254 2.916.01.113-.028.226-.052.337-.071a.984.984 0 0 1 .381 1.931c-.109.024-.216.047-.323.068-1.616.4-3.487 1.828-4.575 3.079-.335.385-.826 1.463-1.326 2.151-.37.507-.785.842-1.133.96-.234.08-.43.067-.593.027a1.004 1.004 0 0 1-.583-.39 1.106 1.106 0 0 1-.194-.451 3.386 3.386 0 0 1-.02-.43c-.101-.352-.226-.694-.317-1.048-.217-.845-.643-1.38-1.149-2.086a3.951 3.951 0 0 0-1.725-1.409c-.097-.024-.879-.215-1.155-.325-.403-.16-.596-.43-.665-.576a1.104 1.104 0 0 1-.107-.642 1.05 1.05 0 0 1 .365-.675c.13-.113.326-.224.587-.278.202-.042.738-.067.813-.07Zm4.587-1.377c.036.08.075.162.116.244.872 1.743 1.848 2.716 3.035 3.188l.04.016a13.245 13.245 0 0 0-2.062 1.879c-.226.26-.525.8-.848 1.353-.294-.955-.775-1.63-1.379-2.475-.461-.644-.945-1.13-1.54-1.525a6.808 6.808 0 0 0 1.285-.838 4.894 4.894 0 0 0 1.353-1.842Z"
      fill="#B8481F"
    />
  </Svg>
);

export default EmptyRequests;
