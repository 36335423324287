import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import Plus from '../../../../../../../../assets/icons/Plus';
import Delete from '../../../../../../../../assets/icons/Delete';
import {GhostButton} from '../../../../../../../../uikit/AppButtons';

export const NewRequestQuestionFieldAttachContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.theme.dimens.smallSpacer};
`;

export const NewRequestQuestionFieldAttachButton = styled.TouchableOpacity<{
  lastChild?: boolean;
}>`
  flex: 1;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${props => props.theme.colors['color-basic-400']};
  margin-right: ${props =>
    props.lastChild ? 0 : props.theme.dimens.smallSpacer};
`;

export const NewRequestQuestionFieldAttachIcon = styled(Plus).attrs({
  fill: 'black',
})``;

export const NewRequestQuestionFieldPreviewImage = styled.Image`
  flex: 1;
  aspect-ratio: 1;
`;

export const NewRequestQuestionFieldDeteleIcon = styled(Delete).attrs(
  props => ({
    fill: props.theme.colors.white,
  }),
)`
  position: absolute;
`;

export const AttachModalCancel = styled(GhostButton)`
  flex: 1;
`;

export const AttachModalButton = styled.TouchableOpacity`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
`;

export const AttachModalButtonText = styled.Text`
  color: black;
  font-size: 18px;
  padding: ${props => props.theme.dimens.smallSpacer};
`;

export const AttachModalButtonsContainer = styled.View`
  display: flex;
  flex-direction: column;
`;
