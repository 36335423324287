import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import ArrowBottom from '../../../../../assets/icons/ArrowBottom';
import {ImageStyle} from 'react-native';
import React from 'react';
import Plus from '../../../../../assets/icons/Plus';
import {AppMenuItem, AppOverflowMenu} from '../../../../../uikit/AppMenu';
import {PopoverPlacements} from '@ui-kitten/components/ui/popover/type';
import {AppLink} from '../../../../../uikit/AppButtons';

export const RequestsHeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 48px;
      padding-left: 40px;
      padding-right: ${props.theme.dimens.normalSpacer};
    `}
`;

export const RequestsHeaderPicker = styled(AppOverflowMenu).attrs({
  placement: PopoverPlacements.BOTTOM_START,
})``;

export const RequestsHeaderPickerContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RequestsHeaderPickerTitle = styled(Text)`
  font-size: 20px;

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 28px;
    `}
`;

export const RequestsHeaderPickerIcon = styled(ArrowBottom)``;

export const RequestsHeaderPickerItem = styled(AppMenuItem)``;

export const RequestsHeaderCreateLink = styled(AppLink).attrs(props => ({
  appearance: props.theme.isDesktop ? 'outline' : 'ghost',
  status: 'danger',
  accessoryLeft: iconProps => (
    <Plus
      {...iconProps}
      style={[iconProps?.style, {marginRight: -6, marginLeft: 0}]}
      fill={props.theme.colors['color-danger-500']}
      width={(iconProps?.style as ImageStyle)?.width}
      height={(iconProps?.style as ImageStyle)?.height}
    />
  ),
}))`
  background-color: transparent;
`;
