import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import RequestsScreen from './screen';
import NewRequestScreen from '../new-request/screen';
import DetailRequestScreen from './detail/screen';

const Stack = createNativeStackNavigator();

export default function RequestsNavigator() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="Requests" component={RequestsScreen} />
      <Stack.Screen name="NewRequest" component={NewRequestScreen} />
      <Stack.Screen name="DetailRequest" component={DetailRequestScreen} />
    </Stack.Navigator>
  );
}
