import styled from 'styled-components/native';

export const MyPageContainer = styled.View`
  padding: 16px 0;
  flex-grow: 1;
  background-color: ${props => props.theme.colors['color-basic-100']};
`;

export const StripesGroup = styled.View`
  margin-bottom: 20px;
`;
