import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Refresh = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#AEA69E" {...props}>
    <Path d="M18.537 19.567A9.962 9.962 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c0 2.136-.67 4.116-1.81 5.74L17 12h3a8 8 0 1 0-2.46 5.772l.997 1.795Z" />
  </Svg>
);

export default Refresh;
