import React, {PropsWithChildren} from 'react';
import {Keyboard, TouchableWithoutFeedback, ViewStyle} from 'react-native';

export default function HideKeyboardContainer(
  props: PropsWithChildren<{style?: ViewStyle}>,
) {
  return (
    <TouchableWithoutFeedback
      style={props.style}
      onPress={Keyboard.dismiss}
      accessible={false}>
      {props.children}
    </TouchableWithoutFeedback>
  );
}
