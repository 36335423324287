import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList} from 'react-native';
import {Text} from '@ui-kitten/components';
import {NewRequestQuestionItem} from '../index';
import ArrowRight from '../../../../../assets/icons/ArrowRight';
import {AppInput} from '../../../../../uikit/AppInput';
import {AppButton} from '../../../../../uikit/AppButtons';
import AppKeyboardAvoidingView from '../../../../../uikit/AppKeyboardAvoidingView';

export const NewRequestConfirmContainer = styled(AppKeyboardAvoidingView)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NewRequestConfirmInputsContainer = styled.ScrollView`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;
export const NewRequestConfirmHeader = styled(Text).attrs({
  category: 'h6',
})`
  padding: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestConfirmInputTitle = styled(Text)`
  margin-bottom: ${props => props.theme.dimens.normalSpacer};
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestConfirmTitleInput = styled(AppInput)`
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestConfirmInputErrorText = styled(Text).attrs({
  status: 'danger',
})`
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestConfirmCommentInput = styled(AppInput)`
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestConfirmList = styled(FlatList<NewRequestQuestionItem>)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestConfirmFieldItemContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  padding: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;

export const NewRequestConfirmFieldItemInfoContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const NewRequestConfirmFieldItemTitle = styled(Text)``;

export const NewRequestConfirmFieldItemAnswer = styled(Text)``;

export const NewRequestConfirmFieldItemIcon = styled(ArrowRight).attrs(
  props => ({
    fill: props.theme.colors['color-basic-500'],
  }),
)``;

export const NewRequestConfirmButtons = styled.View`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: flex-end;
      margin-top: ${props.theme.dimens.bigSpacer};
      padding-right: 0;
      background-color: transparent;
    `}
`;

export const NewRequestConfirmButton = styled(AppButton)`
  flex: 1;
  ${props =>
    props.theme.isDesktop &&
    css`
      flex: none;
    `}
`;

export const NewRequestConfirmImagePreviewContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const NewRequestConfirmImagePreview = styled.Image`
  width: 50px;
  height: 40px;
  border-radius: 4px;
  margin-right: ${props => props.theme.dimens.smallSpacer};
`;
