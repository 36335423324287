import React from 'react';
import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList, ImageStyle} from 'react-native';
import {Spinner, Text} from '@ui-kitten/components';
import {Friend} from '../../../api/friend/models/responses/friend';
import Plus from '../../../assets/icons/Plus';
import AppRouteView from '../../../uikit/AppRouteView';
import EmptyRequests from '../../../assets/icons/EmptyRequests';
import {AppLink} from '../../../uikit/AppButtons';
import {AppInput} from '../../../uikit/AppInput';
import Search from '../../../assets/icons/Search';

export const FriendListSearch = styled(AppInput).attrs({
  accessoryRight: props => {
    return <Search {...props} />;
  },
})`
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 0;
    `}
`;

export const FriendList = styled(FlatList<Friend>)`
  flex: 1;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.smallSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: ${props.theme.dimens.smallSpacer};
    `}
`;

export const FriendListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const FriendListLoading = styled(Spinner)``;

export const FriendListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const FriendListEmptyIcon = styled(EmptyRequests)``;

export const FriendListEmptyTitle = styled(Text)`
  font-weight: bold;
  font-size: 20px;
`;

export const FriendListEmptyCreateNewLink = styled(AppLink)`
  align-self: stretch;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
      min-width: 240px;
    `}
`;

export const FriendListAddRoute = styled(AppRouteView)`
  padding: ${props => props.theme.dimens.smallSpacer};
`;

export const FriendListAddRouteIcon = styled(Plus).attrs(props => ({
  fill: props.theme.colors['color-danger-500'],
}))``;

export const FriendListAddRouteDesktop = styled(AppLink).attrs(props => ({
  appearance: 'outline',
  status: 'danger',
  accessoryLeft: iconProps => (
    <Plus
      {...iconProps}
      style={[iconProps?.style, {marginRight: -6, marginLeft: 0}]}
      fill={props.theme.colors['color-danger-500']}
      width={(iconProps?.style as ImageStyle)?.width}
      height={(iconProps?.style as ImageStyle)?.height}
    />
  ),
}))`
  background-color: transparent;
`;
