import React, {RefObject} from 'react';
import {IInvertedFlatList, InvertedFlatListProps} from './index.common';
import './styles/web.css';

export default class InvertedFlatList<ItemT>
  extends React.Component<InvertedFlatListProps<ItemT> & {className?: string}>
  implements IInvertedFlatList<ItemT>
{
  listRef: RefObject<HTMLDivElement>;
  listEndRef: RefObject<HTMLDivElement>;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  scrollObserver: IntersectionObserver | null = null;

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.listEndRef = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.scrollObserver = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          this.props.onEndReached?.();
        }
      },
      {
        root: this.listRef.current,
      },
    );
    this.listEndRef.current &&
      this.scrollObserver.observe(this.listEndRef.current);
  }

  componentWillUnmount() {
    this.scrollObserver?.disconnect();
    this.scrollObserver = null;
  }

  scrollToOffset(params: {
    animated?: boolean | null | undefined;
    offset: number;
  }) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.listRef.current?.scrollTo({
      top: params.offset,
      behavior: params.animated ? 'smooth' : 'auto',
    });
  }

  render() {
    const {data, renderItem, keyExtractor, ListFooterComponent} = this.props;
    return (
      <div
        ref={this.listRef}
        className={`inverted-flat-list ${this.props.className ?? ''}`}>
        {data?.map((item, index) => {
          const jsxItem = renderItem({item, index});
          if (jsxItem && keyExtractor) {
            return React.cloneElement(jsxItem, {
              key: keyExtractor(item, index),
            });
          }

          return jsxItem;
        })}
        {ListFooterComponent &&
          (React.isValidElement(ListFooterComponent) ? (
            ListFooterComponent
          ) : (
            <ListFooterComponent />
          ))}
        <div ref={this.listEndRef} />
      </div>
    );
  }
}
