import {
  PointsContainer,
  PointsInfoContainer,
  PointsRefreshContainer,
  PointsRefreshIcon,
  PointsRefreshLoader,
  PointsSpContainer,
  PointsSpSuffix,
  PointsSpText,
  PointsSuffix,
  PointsText,
  PointsTitle,
  PointsTitleContainer,
} from './styles';
import React, {useCallback, useEffect, useMemo} from 'react';
import {ViewStyle} from 'react-native';
import {
  useAppDispatch,
  useJwtUserSelector,
  useRequestSelector,
} from '../../store/store';
import {getUserProfileRequestAction} from '../../features/user/store/action';
import {useStrings} from '../../utils/providers/strings';
import {AppLoader} from '../AppLoader';
import {AppError} from '../AppError';
import {CurrencyTypes} from '../../api/user/models/responses/user-profile';
import {UserRole} from '../../api/user/models/responses/user';

export default function Points(props: {style?: ViewStyle}) {
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const user = useJwtUserSelector();

  const getUserProfileRequest = useRequestSelector(
    store => store.user.getUserProfileRequest,
  );

  const cpPoints = useMemo<number>(() => {
    return (
      getUserProfileRequest.data?.wallets?.find(
        wallet => wallet.currency === CurrencyTypes.CP,
      )?.amount || 0
    );
  }, [getUserProfileRequest.data]);

  const spPoints = useMemo<number>(() => {
    return (
      getUserProfileRequest.data?.wallets?.find(
        wallet => wallet.currency === CurrencyTypes.SP,
      )?.amount || 0
    );
  }, [getUserProfileRequest.data]);

  useEffect(() => {
    if (
      !getUserProfileRequest.data &&
      !getUserProfileRequest.error &&
      !getUserProfileRequest.isLoading
    ) {
      dispatch(getUserProfileRequestAction.request());
    }
  }, [dispatch, getUserProfileRequest]);

  const onRefreshPoints = useCallback(() => {
    dispatch(getUserProfileRequestAction.request());
  }, [dispatch]);

  const withSp = user.role === UserRole.MEMBER;

  return (
    <PointsContainer style={props.style} withSp={withSp}>
      {getUserProfileRequest.data ? (
        <>
          <PointsTitleContainer>
            <PointsTitle>{strings.points_title}</PointsTitle>
            {!getUserProfileRequest.isLoading ? (
              <PointsRefreshContainer onPress={onRefreshPoints}>
                <PointsRefreshIcon />
              </PointsRefreshContainer>
            ) : (
              <PointsRefreshLoader />
            )}
          </PointsTitleContainer>
          <PointsInfoContainer>
            <PointsText>{strings.app_number(cpPoints)}</PointsText>
            <PointsSuffix>{strings.points_suffix.toUpperCase()}</PointsSuffix>
          </PointsInfoContainer>
          {withSp && (
            <PointsSpContainer>
              <PointsSpText>{strings.app_number(spPoints)}</PointsSpText>
              <PointsSpSuffix>
                {strings.points_sp_suffix.toUpperCase()}
              </PointsSpSuffix>
            </PointsSpContainer>
          )}
        </>
      ) : getUserProfileRequest.isLoading ? (
        <AppLoader />
      ) : getUserProfileRequest.error ? (
        <AppError
          error={getUserProfileRequest.error.message}
          retry={onRefreshPoints}
        />
      ) : null}
    </PointsContainer>
  );
}
