import React, {useEffect, useState} from 'react';
import {useWebRecorderPermission} from '../../../../../../../utils/use-web-recorder-permissions';
import {useToast} from 'react-native-toast-notifications';
import {
  DetailRequestChatMicIcon,
  DetailRequestChatStopIcon,
  DetailRequestRecordAudioButton,
  DetailRequestStopAudioButton,
} from './styles';
import {useStrings} from '../../../../../../../utils/providers/strings';
import {TypeBloblUrl} from '.';
import api from '../../../../../../../api';
import {sendRequestMessageRequestAction} from '../../../../../store/action';
import {useDispatch} from 'react-redux';

export default function DetailRequestRecorderSound({
  filePath,
  requestId,
  message,
  isStartUpload,
  isUploading,
}: {
  filePath: (data: TypeBloblUrl | null) => void;

  requestId: string;
  message: string;
  isStartUpload: boolean;
  isUploading: (data: boolean) => void;
}) {
  const dispatch = useDispatch();
  const strings = useStrings();
  const toast = useToast();
  const recorder = useWebRecorderPermission();

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [blobAudio, setBlobAudio] = useState<Blob | null>(null);
  const [blobUrl, setBlobUrl] = useState<TypeBloblUrl | null>(null);

  useEffect(() => {
    filePath(blobUrl);
  }, [filePath, blobUrl]);

  useEffect(() => {
    if (isStartUpload && blobAudio) {
      audioUploader(blobAudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartUpload, blobAudio]);

  const onStartRecord = async () => {
    setIsRecording(true);
    setBlobUrl(null);

    try {
      await recorder.startRecording();
    } catch (e) {
      toast.show(strings.detail_chat_error_microphone_permission);
    }
  };

  const onStopRecord = async () => {
    try {
      await recorder.stopRecording();
      const blob = await recorder.getBlob();
      const blobURL = URL.createObjectURL(blob);

      setBlobAudio(blob);
      setBlobUrl({type: 'audio', url: blobURL});
    } catch (e) {
      toast.show(strings.detail_chat_error_save_record);
      console.error(e);
    } finally {
      setIsRecording(false);
    }
  };

  const audioUploader = async (blob: Blob) => {
    isUploading(true);

    try {
      const response = await api.request.uploadFile({file: blob});

      dispatch(
        sendRequestMessageRequestAction.request({
          id: requestId,
          messageText: message.trim(),
          attachmentKeys: [response.data],
        }),
      );
    } catch (err) {
      toast.show(strings.detail_chat_error_save_record);
      console.error(err);
    } finally {
      setBlobAudio(null);
      isUploading(false);
    }
  };

  return !isRecording ? (
    <DetailRequestRecordAudioButton onPress={onStartRecord}>
      <DetailRequestChatMicIcon />
    </DetailRequestRecordAudioButton>
  ) : (
    <DetailRequestStopAudioButton onPress={onStopRecord}>
      <DetailRequestChatStopIcon />
    </DetailRequestStopAudioButton>
  );
}
