import styled from 'styled-components/native';
import AppRouteView from '../../../../../uikit/AppRouteView';
import {css} from 'scl/src/styled-components/css';

const OFFSET = 12;

export const ShopCardContainer = styled(AppRouteView)`
  flex: 0 25%;
  min-height: ${128 + OFFSET}px;
  padding-bottom: ${OFFSET}px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-right: ${OFFSET}px;
    `}
`;

export const ShopCardContent = styled.View`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 3px;
  overflow: hidden;
`;

export const ShopCardBadgesContainer = styled.View`
  z-index: 10;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-left: 8px;
`;

export const ShopCardLocationBadge = styled.Text`
  font-size: 10px;
  line-height: 12px;
  font-weight: 300;
  border-radius: 8px;
  overflow: hidden;
  padding: 2px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: ${props => props.theme.colors['color-primary-500']};
  color: ${props => props.theme.colors['color-basic-900']};
`;

export const ShopCardTagBadge = styled(ShopCardLocationBadge)`
  background-color: ${props => props.theme.colors.white};
`;

export const ShopCardImage = styled.Image`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ShopCardTitleContainer = styled.View`
  z-index: 10;
  width: 100%;
  padding: 4px 8px;
  background: #000000b2;
  min-height: 40px;
`;

export const ShopCardTitle = styled.Text`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.white};
`;
