import styled from 'styled-components/native';
import {Text} from '@ui-kitten/components';
import Calendar from '../../../../../assets/icons/Calendar';
import {AppCard} from '../../../../../uikit/AppCard';
import Home from '../../../../../assets/icons/Home';
import {ComponentProps, ComponentType} from 'react';
import Requests from '../../../../../assets/icons/Requests';
import AppRouteView from '../../../../../uikit/AppRouteView';
import {AppLoader} from '../../../../../uikit/AppLoader';
import {AppError} from '../../../../../uikit/AppError';
import AppLogoutButton from '../../../../../uikit/AppLogoutButton';
import Shops from '../../../../../assets/icons/Shops';
import Points from '../../../../../uikit/Points';

export const MainNavigationContainer = styled.View`
  width: 416px;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 128px;
`;

export const MainNavigationInfoContainer = styled(AppCard)``;

export const MainNavigationInfoSpinner = styled(AppLoader)`
  align-self: center;
  flex: none;
  padding: ${props => props.theme.dimens.normalSpacer};
`;

export const MainNavigationInfoError = styled(AppError)`
  align-self: center;
  flex: none;
  padding: ${props => props.theme.dimens.normalSpacer};
`;

export const MainNavigationInfoNameContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MainNavigationInfoName = styled(Text).attrs({
  category: 'h5',
})``;

export const MainNavigationLogoutButton = styled(AppLogoutButton)`
  margin-top: -8px;
`;

export const MainNavigationInfoId = styled(Text).attrs({})``;

export const MainNavigationMenu = styled(AppCard)`
  padding: 0;
  margin-top: 24px;
  margin-bottom: 14px;
`;

export const MainNavigationPointsContainer = styled(Points)`
  margin-left: 0;
  margin-top: 24px;
`;

export const CommonMainNavigationMenuItemContainer = styled(AppRouteView)<{
  active: boolean;
}>`
  padding: 16px;
  display: flex;
  flex-direction: row;
  background-color: ${props =>
    props.active
      ? props.theme.colors['color-primary-500'] + '3D'
      : 'transparent'};
  border-left-width: 3px;
  border-left-color: ${props =>
    props.active ? props.theme.colors['color-primary-500'] : 'transparent'};
`;

export const MainNavigationMenuItemFirstContainer = styled(
  CommonMainNavigationMenuItemContainer,
)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const MainNavigationMenuItemLastContainer = styled(
  CommonMainNavigationMenuItemContainer,
)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const MainNavigationMenuItemName = styled(Text)<{active: boolean}>`
  margin: ${props => `auto 0px auto ${props.theme.dimens.smallSpacer}`};
  color: ${props =>
    props.theme.colors[props.active ? 'color-primary-500' : 'color-basic-500']};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;

export const MainNavigationMenuItemHomeIcon = styled<
  ComponentType<ComponentProps<typeof Home> & {active: boolean}>
>(Home)
  .attrs(props => ({
    fill: props.theme.colors[
      props.active ? 'color-primary-500' : 'color-basic-500'
    ],
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['active'].includes(prop) && defaultValidatorFn(prop),
  })``;

export const MainNavigationMenuItemRequestsIcon = styled<
  ComponentType<ComponentProps<typeof Requests> & {active: boolean}>
>(Requests)
  .attrs(props => ({
    fill: props.theme.colors[
      props.active ? 'color-primary-500' : 'color-basic-500'
    ],
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['active'].includes(prop) && defaultValidatorFn(prop),
  })``;

export const MainNavigationMenuItemCalendarIcon = styled<
  ComponentType<ComponentProps<typeof Calendar> & {active: boolean}>
>(Calendar)
  .attrs(props => ({
    fill: props.theme.colors[
      props.active ? 'color-primary-500' : 'color-basic-500'
    ],
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['active'].includes(prop) && defaultValidatorFn(prop),
  })``;

export const MainNavigationMenuItemShopsIcon = styled<
  ComponentType<ComponentProps<typeof Shops> & {active: boolean}>
>(Shops)
  .attrs(props => ({
    fill: props.theme.colors[
      props.active ? 'color-primary-500' : 'color-basic-500'
    ],
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['active'].includes(prop) && defaultValidatorFn(prop),
  })``;
