import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath} from 'react-native-svg';

const Send = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#000" {...props}>
    <G clipPath="url(#a)">
      <Path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2 .01 7Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Send;
