import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Call = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#000" {...props}>
    <Path d="M19.5 15.683v2.947a.833.833 0 0 1-.775.832c-.364.025-.662.038-.892.038C10.47 19.5 4.5 13.53 4.5 6.167c0-.23.013-.528.038-.892A.833.833 0 0 1 5.37 4.5h2.947a.417.417 0 0 1 .415.375c.019.192.036.344.053.46.166 1.156.505 2.28 1.007 3.334a.38.38 0 0 1-.123.473l-1.798 1.285a10.872 10.872 0 0 0 5.703 5.703l1.284-1.795a.385.385 0 0 1 .477-.124c1.054.5 2.178.84 3.333 1.004.116.017.269.035.459.053a.417.417 0 0 1 .374.415H19.5Z" />
  </Svg>
);

export default Call;
