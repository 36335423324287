import styled from 'styled-components/native';
// import AppRouteView from '../../../../../../uikit/AppRouteView';
import {css} from 'styled-components';
import {GhostLink} from '../../../../../../uikit/AppButtons';

export const ShopCommitmentSection = styled.View`
  margin-top: 24px;
  display: flex;
  width: 100%;
`;

export const ShopCommitmentHeadContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 0;
    `};
`;

export const ShopCommitmentHeadTitle = styled.Text`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: ${props => props.theme.colors['color-basic-900']};

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 16px;
      line-height: 22px;
    `}
`;

export const ShopCommitmentHeadLink = styled(GhostLink).attrs({
  status: 'danger',
})`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  padding: 0;
`;

export const ShopCommitmentContainer = styled.ScrollView`
  margin-top: 4px;
  padding-bottom: 6px;
`;

export const ShopCommitmentItemContainer = styled.View<{
  lastItem?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 205px;
  margin-left: 12px;
  margin-right: ${props => (props.lastItem ? '12px' : 0)};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 0;
      margin-right: ${props.lastItem ? 0 : '12px'};
    `}
`;

export const ShopCommitmentItemTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  margin-top: 8px;
`;

export const ShopCommitmentItemDescription = styled.Text`
  color: ${props => props.theme.colors['color-basic-800']};

  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
`;

export const ShopCommitmentItemImageContainer = styled.View`
  width: 205px;
  height: 128px;
`;

export const ShopCommitmentItemImage = styled.Image`
  flex: 1;
  border-radius: 3px;
`;
