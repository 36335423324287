import {useRoute, Route} from '@react-navigation/native';
import React, {useCallback, useEffect, useState} from 'react';
import {AppLayout} from '../../uikit/AppLayout';
import AppModal from '../../uikit/AppModal';
import {AppModalRoute, AppModalTitle} from '../../uikit/AppModal/styles';
import AppStatusBar from '../../uikit/AppStatusBar';
import useRouter from '../../utils/use-router';
import {
  RestoreConfirmButton,
  RestoreConfirmContainer,
  RestoreConfirmContainerOutside,
  RestoreConfirmContainerView,
  RestoreConfirmContentView,
  RestoreConfirmEye,
  RestoreConfirmEyeOff,
  RestoreConfirmFieldLabel,
  RestoreConfirmInput as RestoreConfirmInputComponent,
  RestoreConfirmInputErrorText,
  RestoreConfirmLogo,
  RestoreConfirmLogoContainer,
  RestoreConfirmLogoText,
  RestoreConfirmRules,
  RestoreConfirmTitleText,
} from './styles';
import {useStrings} from '../../utils/providers/strings';
import {
  Control,
  Controller,
  useForm,
  FieldPath,
  FieldValues,
  ValidateResult,
} from 'react-hook-form';
import {useAppDispatch, useRequestSelector} from '../../store/store';
import {restoreConfirmRequestAction} from './store/action';
import {AccessoryLoadingIndicator} from '../../uikit/AccessoryLoadingIndicator';
import {TouchableWithoutFeedback} from 'react-native';
import useFieldServerError from '../../utils/react-hook-form/use-field-server-error';
import validatePassword from '../../utils/validate-password';

interface RestoreConfirmForm {
  password: string;
  passwordRepeat: string;
}

export default function RestoreConfirmScreen() {
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const route = useRoute<Route<string, {token: string}>>();
  const confirmationToken = route.params.token;
  const restoreConfirmRequest = useRequestSelector(
    state => state.restoreConfirm.restoreConfirmRequest,
  );

  const {
    control,
    handleSubmit,
    formState: {isValid},
    setError,
    watch,
  } = useForm<RestoreConfirmForm>({
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
  });

  useEffect(() => {
    return function () {
      dispatch(restoreConfirmRequestAction.clean());
    };
  }, [dispatch]);

  useFieldServerError(restoreConfirmRequest, setError);

  const password = watch('password');
  const passwordRepeat = watch('passwordRepeat');
  const isDisabled = !(password && passwordRepeat);

  const onRestoreConfirm = useCallback(
    (form: RestoreConfirmForm) => {
      if (form.password !== form.passwordRepeat) {
        setError('passwordRepeat', {
          message: strings.restore_password_repeat_error,
        });
        return;
      }

      dispatch(
        restoreConfirmRequestAction.request({
          ...form,
          confirmationToken,
        }),
      );
    },
    [
      confirmationToken,
      dispatch,
      setError,
      strings.restore_password_repeat_error,
    ],
  );

  return (
    <>
      <AppStatusBar />
      <AppLayout>
        <RestoreConfirmContainerOutside>
          <RestoreConfirmContainer>
            <RestoreConfirmContainerView>
              <RestoreConfirmContentView>
                <RestoreConfirmLogoContainer>
                  <RestoreConfirmLogo />
                  <RestoreConfirmLogoText>
                    {strings.short_app_name}
                  </RestoreConfirmLogoText>
                </RestoreConfirmLogoContainer>
                <RestoreConfirmTitleText>
                  {strings.restore_password_title}
                </RestoreConfirmTitleText>
                <RestoreConfirmFieldLabel>
                  {strings.restore_password_label}
                </RestoreConfirmFieldLabel>
                <RestoreConfirmInput
                  name="password"
                  control={control}
                  placeholder={strings.restore_password_placeholder}
                  required={strings.restore_password_required}
                  disabled={restoreConfirmRequest.isLoading}
                  validate={value => validatePassword(value, strings)}
                />
                <RestoreConfirmRules>
                  {strings.app_password_rules_prefix}{' '}
                  {strings.app_password_rules_8_char}
                  {',\n'}
                  {strings.app_password_rules_lower_case}
                  {', '}
                  {strings.app_password_rules_capital}
                  {', '}
                  {strings.app_password_rules_number}
                  {', '}
                  {strings.app_password_rules_special_character}
                </RestoreConfirmRules>
                <RestoreConfirmFieldLabel>
                  {strings.restore_password_repeat_label}
                </RestoreConfirmFieldLabel>
                <RestoreConfirmInput
                  name="passwordRepeat"
                  control={control}
                  placeholder={strings.restore_password_repeat_placeholder}
                  required={strings.restore_password_repeat_required}
                  disabled={restoreConfirmRequest.isLoading}
                />
                <RestoreConfirmButton
                  onPress={handleSubmit(onRestoreConfirm)}
                  accessoryRight={
                    restoreConfirmRequest.isLoading
                      ? AccessoryLoadingIndicator
                      : undefined
                  }
                  disabled={isDisabled}>
                  {strings.restore_password_button.toUpperCase()}
                </RestoreConfirmButton>
              </RestoreConfirmContentView>
            </RestoreConfirmContainerView>
          </RestoreConfirmContainer>
        </RestoreConfirmContainerOutside>
      </AppLayout>

      <AppModal visible={!!restoreConfirmRequest.data}>
        <AppModalTitle>{strings.restore_password_success}</AppModalTitle>
        <AppModalRoute to="/login" onPress={() => router.push('/login')}>
          {strings.login_button.toUpperCase()}
        </AppModalRoute>
      </AppModal>
    </>
  );
}

function RestoreConfirmInput<Form extends FieldValues>({
  control,
  required,
  name,
  placeholder,
  disabled,
  validate,
}: {
  control: Control<Form>;
  required: string;
  name: FieldPath<Form>;
  placeholder: string;
  disabled: boolean;
  validate?: (value: string) => ValidateResult;
}) {
  const [securePassEntry, setSecurePassEntry] = useState(true);

  const toggleSecurePassEntry = useCallback(() => {
    setSecurePassEntry(!securePassEntry);
  }, [securePassEntry]);

  return (
    <Controller
      control={control}
      rules={{
        required,
        validate,
      }}
      render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
        <>
          <RestoreConfirmInputComponent
            status={error ? 'danger' : 'basic'}
            onBlur={onBlur}
            onChangeText={text => onChange(text.trim())}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            accessoryRight={props => {
              return (
                <TouchableWithoutFeedback onPress={toggleSecurePassEntry}>
                  {securePassEntry ? (
                    <RestoreConfirmEye key="eye" {...props} />
                  ) : (
                    <RestoreConfirmEyeOff key="eye-off" {...props} />
                  )}
                </TouchableWithoutFeedback>
              );
            }}
            secureTextEntry={securePassEntry}
          />
          {error && (
            <RestoreConfirmInputErrorText>
              {error.message}
            </RestoreConfirmInputErrorText>
          )}
        </>
      )}
      name={name}
    />
  );
}
