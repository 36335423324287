import React, {useCallback, useEffect, useState} from 'react';
import {ListRenderItemInfo} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useTheme} from 'styled-components/native';
import {
  RequestsQuery,
  RequestsStatusTypeAll,
  RequestsStatusTypes,
} from '../../api/request/models/requests';
import {
  getStatusText,
  getStatusType,
  RequestResponse,
} from '../../api/request/models/responses';
import {useAppDispatch, usePaginationRequestSelector} from '../../store/store';
import {AppError} from '../../uikit/AppError';
import {AppLayout} from '../../uikit/AppLayout';
import {AppLoader} from '../../uikit/AppLoader';
import AppRefreshControl from '../../uikit/AppRefreshControl';
import AppStatusBar from '../../uikit/AppStatusBar';
import CallFAB from '../../uikit/CallFAB';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../uikit/Points/container';
import {onCallConcierge} from '../../utils/on-call-concierge';
import {useStrings} from '../../utils/providers/strings';
import RequestsHeader from './components/header';
import {getRequestsRequestAction} from './store/action';
import {REQUESTS_LIMIT} from './store/saga';
import {
  RequestsList,
  RequestsListEmptyCallButton,
  RequestsListEmptyContainer,
  RequestsListEmptyCreateNewLink,
  RequestsListEmptyIcon,
  RequestsListEmptyInfo,
  RequestsListItemContainer,
  RequestsListItemIcon,
  RequestsListItemInfo,
  RequestsListItemInfoContainer,
  RequestsListItemInfoStatus,
  RequestsListItemInfoTitle,
  RequestsListLoading,
  RequestsListLoadingContainer,
} from './styles';
import {useFocusEffect} from '@react-navigation/native';

export default function RequestsScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const requestsRequest = usePaginationRequestSelector(
    store => store.requests.requestsRequest,
  );
  const [selectedStatus, setSelectedStatus] = useState<RequestsStatusTypes>(
    RequestsStatusTypeAll,
  );

  const isEmptyList = !requestsRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getRequestsRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (requestsRequest.error && requestsRequest.data?.items.length) {
      toast.show(requestsRequest.error.message);
    }
  }, [requestsRequest, toast]);

  const onFirstPage = useCallback(() => {
    const request: RequestsQuery = {
      limit: REQUESTS_LIMIT,
      offset: 0,
    };
    if (selectedStatus !== RequestsStatusTypeAll) {
      request.status = selectedStatus;
    }
    dispatch(getRequestsRequestAction.request(request));
  }, [dispatch, selectedStatus]);

  const onInit = useCallback(() => {
    dispatch(getRequestsRequestAction.clean());
    onFirstPage();
  }, [dispatch, onFirstPage]);

  useFocusEffect(onInit);

  const onNextPage = () => {
    if (requestsRequest.data?.isLastPage || requestsRequest.isLoading) return;

    const request: RequestsQuery = {
      limit: REQUESTS_LIMIT,
      offset: requestsRequest.data?.items.length || 0,
    };
    if (selectedStatus !== RequestsStatusTypeAll) {
      request.status = selectedStatus;
    }

    dispatch(getRequestsRequestAction.request(request));
  };

  const renderRequestItem = ({item}: ListRenderItemInfo<RequestResponse>) => {
    const createdAt = strings.app_date_string(item.createdAt);

    return (
      <RequestsListItemContainer to={`/requests/${item.id}`}>
        <RequestsListItemInfoContainer>
          <RequestsListItemInfoTitle>{item.title}</RequestsListItemInfoTitle>
          <RequestsListItemInfo>
            {strings.requests_item_description_fn(item.type.name, createdAt)}
          </RequestsListItemInfo>
          <RequestsListItemInfoStatus status={getStatusType(item.status)}>
            {getStatusText(strings, item.status)}
          </RequestsListItemInfoStatus>
        </RequestsListItemInfoContainer>
        <RequestsListItemIcon />
      </RequestsListItemContainer>
    );
  };

  const renderRequestListLoading = () => {
    return (
      <RequestsListLoadingContainer>
        <RequestsListLoading />
      </RequestsListLoadingContainer>
    );
  };

  const renderRequestListEmpty = () => {
    return (
      <RequestsListEmptyContainer>
        <RequestsListEmptyIcon />
        <RequestsListEmptyInfo>
          {strings.requests_empty_title}
        </RequestsListEmptyInfo>
        <RequestsListEmptyCreateNewLink to="/create-request">
          {strings.requests_empty_new_button.toUpperCase()}
        </RequestsListEmptyCreateNewLink>
        <RequestsListEmptyCallButton onPress={onCallConcierge}>
          {strings.main_header_call_button.toUpperCase()}
        </RequestsListEmptyCallButton>
      </RequestsListEmptyContainer>
    );
  };

  return (
    <>
      <AppStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <AppLayout>
            {isEmptyList && requestsRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && requestsRequest.error ? (
              <AppError
                error={requestsRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              <>
                <RequestsHeader
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  allCounts={requestsRequest.data?.totalCount || 0}
                />
                {requestsRequest.data && (
                  <RequestsList
                    refreshControl={
                      <AppRefreshControl
                        onRefresh={onFirstPage}
                        refreshing={requestsRequest.isFirstLoading}
                      />
                    }
                    data={requestsRequest.data.items}
                    renderItem={renderRequestItem}
                    keyExtractor={item => String(item.id)}
                    onEndReached={onNextPage}
                    ListFooterComponent={
                      requestsRequest.isLoading
                        ? renderRequestListLoading
                        : null
                    }
                    ListEmptyComponent={renderRequestListEmpty}
                  />
                )}
              </>
            )}
            {!theme.isDesktop && !isEmptyList && <CallFAB />}
          </AppLayout>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
