import styled from 'styled-components/native';
import {Text} from '@ui-kitten/components';

import {css} from 'scl/src/styled-components/css';

import AppKeyboardAvoidingView from '../../../../../../uikit/AppKeyboardAvoidingView';
import {AppInput} from '../../../../../../uikit/AppInput';
import {AppButton} from '../../../../../../uikit/AppButtons';
import {
  AppAutocomplete,
  AppAutocompleteItem,
} from '../../../../../../uikit/AppAutocomplete';

export const ConciergePointsBuyKeyboardAvoidingView = styled(
  AppKeyboardAvoidingView,
)`
  flex: 1;
`;

export const ConciergePointsBuyContainer = styled.ScrollView`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${props => props.theme.dimens.smallSpacer};
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
      padding: 24px;
    `}
`;

export const ConciergePointsBuyHeader = styled(Text).attrs({
  category: 'h6',
})`
  margin-bottom: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-bottom: 20px;
    `}
`;

export const ConciergePointsBuyInputTitle = styled(Text)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;

const CommonConciergePointsBuyInput = css`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
`;

export const ConciergePointsAddAmountButtons = styled.View`
  padding-top: 12px;
`;

export const ConciergePointsBuyInput = styled(AppInput).attrs({
  keyboardType: 'numeric',
})`
  ${CommonConciergePointsBuyInput};
`;

export const ConciergePointsBuyTextInput = styled(AppInput)<{
  lastItem?: boolean;
}>`
  ${CommonConciergePointsBuyInput};
  margin-bottom: ${props =>
    props.lastItem ? props.theme.dimens.bigSpacer : 0};
`;

export const ConciergePointsBuyAutocomplete = styled(AppAutocomplete)``;

export const ConciergePointsBuyAutocompleteItem = styled(AppAutocompleteItem)``;

export const ConciergePointsBuyInputError = styled(Text).attrs({
  status: 'danger',
})``;

export const ConciergePointsBuyButton = styled(AppButton)`
  margin-bottom: 36px;
  margin-left: 60px;
  margin-right: 60px;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: flex-end;

      margin-top: ${props.theme.dimens.bigSpacer};
      margin-bottom: ${props.theme.dimens.smallSpacer};
      margin-left: 0;
      margin-right: 0;
    `}
`;

export const ConciergePointsBuyCourseTitle = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;
