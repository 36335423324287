import styled from 'styled-components/native';

export const DetailedInfoScreenContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  background-color: ${props => props.theme.colors['color-basic-100']};
`;
export const DetailedInfoScreenContentWrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${props => (props.theme.isDesktop ? '48px 24px 0 40px' : '0')};
`;
export const DetailedInfoScreenContent = styled.ScrollView`
  padding: ${props => (props.theme.isDesktop ? '0' : '0 16px')};
  padding-bottom: 60px;
`;
export const DetailedInfoScreenDate = styled.Text`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors['color-basic-900']};

  margin-bottom: 12px;
`;

export const DetailedInfoScreenTitle = styled.Text`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${props => props.theme.colors['color-basic-900']};

  margin-bottom: 12px;
`;

export const DetailedInfoScreenTextContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: ${props => props.theme.colors['color-basic-900']};

  padding-bottom: 60px;
`;
