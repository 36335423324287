import React from 'react';
import {
  RequestCategoryTypeField,
  RequestCategoryTypeFieldExtraDataDate,
} from '../../../../../../../api/request/models/responses/category-type';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldErrorText,
  NewRequestQuestionFieldTitle,
} from '../styles';
import {
  NewRequestQuestionFieldDateFixedCheckbox,
  NewRequestQuestionFieldDateIcon,
  NewRequestQuestionFieldDateInput,
} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {useStrings} from '../../../../../../../utils/providers/strings';
import {
  NewRequestQuestionFieldDateState,
  NewRequestQuestionForm,
} from '../../../form';

export default function NewRequestQuestionFieldDate({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  const strings = useStrings();
  const extraData = field.extraData as RequestCategoryTypeFieldExtraDataDate;

  const defaultMinMaxProps = {
    min: new Date(),
    max: new Date(863999999999999),
  };
  const dateMinMaxProps = (
    extra: RequestCategoryTypeFieldExtraDataDate | null,
  ) => {
    if (!extra) {
      return defaultMinMaxProps;
    }

    try {
      return {
        min: new Date(extraData.minDate),
        max: new Date(extraData.maxDate),
      };
    } catch (_) {
      return defaultMinMaxProps;
    }
  };

  return (
    <NewRequestQuestionFieldContainer>
      <NewRequestQuestionFieldTitle>{field.title}</NewRequestQuestionFieldTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const dateValue = value as NewRequestQuestionFieldDateState;
          return (
            <NewRequestQuestionFieldDateInput
              placeholder=" "
              dateService={strings.dateService}
              status={error ? 'danger' : 'basic'}
              accessoryRight={props => (
                <NewRequestQuestionFieldDateIcon {...props} />
              )}
              onBlur={onBlur}
              date={dateValue.date}
              onSelect={date => onChange({date, isFixed: dateValue.isFixed})}
              {...dateMinMaxProps(extraData)}
            />
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}}) => {
          const dateValue = value as NewRequestQuestionFieldDateState;

          return (
            <NewRequestQuestionFieldDateFixedCheckbox
              onBlur={onBlur}
              checked={!dateValue.isFixed}
              onChange={isNotFixed =>
                onChange({date: dateValue.date, isFixed: !isNotFixed})
              }>
              {strings.new_request_question_date_is_fixed_checkbox}
            </NewRequestQuestionFieldDateFixedCheckbox>
          );
        }}
        name={field.id}
      />
    </NewRequestQuestionFieldContainer>
  );
}
