import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';

export const ConciergePointsBuyByBtcContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${props => props.theme.dimens.smallSpacer};
  padding: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      flex: none;
      margin-top: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
      padding: 24px;
    `}
`;
