import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const Exchange = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      d="M4 4h25.333v24H2.667V4H4zm16 5.333V12h-5.333v2.667H20v2.666l4.667-4-4.667-4zm-8 13.334V20h5.333v-2.667H12v-2.666l-4.667 4 4.667 4z"
    />
  </Svg>
);

export default Exchange;
