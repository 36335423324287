import {all, call, put, takeLatest} from 'redux-saga/effects';
import api from '../../../api';
import {
  createShopFavoriteRequestAction,
  createShopReviewRequestAction,
  deleteRecentlyViewedRequestAction,
  deleteShopFavoriteRequestAction,
  getAllReviewsListRequestAction,
  getDetailShopRequestAction,
  getDetailShopReviewRequestAction,
  getShopByCategoryListRequestAction,
  getShopCategoriesListRequestAction,
  getShopCommitmentsListRequestAction,
  getShopFavoritesListRequestAction,
  getShopProductsListRequestAction,
  getShopRecentlyViewedListRequestAction,
  getShopReviewsListRequestAction,
  getShopsFlatCategoriesListAction,
  searchShopsByCategoryRequestAction,
  searchShopsRequestAction,
} from './action';
import {
  ListResponse,
  ListWithCategoryNameResponse,
} from '../../../api/models/response/list';
import {
  ShopCategoriesResponse,
  ShopItem,
} from '../../../api/shops/models/responses/shop-categories';
import {DefaultResponse} from '../../../api/models/response/default';
import {ShopDetailResponse} from '../../../api/shops/models/responses/shop-detail';
import {ShopProductsResponse} from '../../../api/shops/models/responses/shop-products';
import {ShopCommitmentsResponse} from '../../../api/shops/models/responses/shop-commitments';
import {ShopReviewsResponse} from '../../../api/shops/models/responses/shop-reviews';
import {ShopSearchItem} from '../../../api/shops/models/shop-search';

export const REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT = 24;
export const REQUESTS_SHOP_REVIEWS_LIMIT = 21;

const getShopCategoriesListRequest = function* (
  action: ReturnType<typeof getShopCategoriesListRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopCategoriesResponse> = yield call(
      [api, api.shops.getShopsCategoriesList],
      action.payload,
    );
    yield put(
      getShopCategoriesListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getShopCategoriesListRequestAction.failure(error as Error));
  }
};

const getShopsFlatCategoriesListRequest = function* (
  action: ReturnType<typeof getShopsFlatCategoriesListAction.request>,
) {
  try {
    const response = yield call(
      [api, api.shops.getFlatCategoriesList],
      action.payload,
    );

    yield put(
      getShopsFlatCategoriesListAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getShopsFlatCategoriesListAction.failure(error as Error));
  }
};

const searchShopsRequest = function* (
  action: ReturnType<typeof searchShopsRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopSearchItem> = yield call(
      [api, api.shops.searchShops],
      action.payload,
    );
    yield put(searchShopsRequestAction.success([action.payload, response]));
  } catch (err) {
    yield put(searchShopsRequestAction.failure(err as Error));
  }
};

const getDetailShopRequest = function* (
  action: ReturnType<typeof getDetailShopRequestAction.request>,
) {
  try {
    const response: DefaultResponse<ShopDetailResponse> = yield call(
      [api, api.shops.getShopDetailRequest],
      action.payload,
    );
    yield put(getDetailShopRequestAction.success(response.data));
  } catch (error) {
    yield put(getDetailShopRequestAction.failure(error as Error));
  }
};

const getShopProductsListRequest = function* (
  action: ReturnType<typeof getShopProductsListRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopProductsResponse> = yield call(
      [api, api.shops.getShopsProductsList],
      action.payload,
    );
    yield put(getShopProductsListRequestAction.success(response.data));
  } catch (error) {
    yield put(getShopProductsListRequestAction.failure(error as Error));
  }
};

const getShopCommitmentsListRequest = function* (
  action: ReturnType<typeof getShopCommitmentsListRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopCommitmentsResponse> = yield call(
      [api, api.shops.getShopsCommitmentsList],
      action.payload,
    );
    yield put(getShopCommitmentsListRequestAction.success(response.data));
  } catch (error) {
    yield put(getShopCommitmentsListRequestAction.failure(error as Error));
  }
};

const getAllReviewsListRequest = function* (
  action: ReturnType<typeof getAllReviewsListRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopReviewsResponse> = yield call(
      [api, api.shops.getAllReviewsList],
      action.payload,
    );
    yield put(
      getAllReviewsListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getAllReviewsListRequestAction.failure(error as Error));
  }
};

const getShopReviewsListRequest = function* (
  action: ReturnType<typeof getShopReviewsListRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopReviewsResponse> = yield call(
      [api, api.shops.getShopsReviewsList],
      action.payload,
    );
    yield put(
      getShopReviewsListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getShopReviewsListRequestAction.failure(error as Error));
  }
};

const getDetailShopReviewRequest = function* (
  action: ReturnType<typeof getDetailShopReviewRequestAction.request>,
) {
  try {
    const response: DefaultResponse<ShopReviewsResponse> = yield call(
      [api, api.shops.getShopDetailReview],
      action.payload,
    );
    yield put(getDetailShopReviewRequestAction.success(response.data));
  } catch (error) {
    yield put(getDetailShopReviewRequestAction.failure(error as Error));
  }
};

const createShopReviewRequest = function* (
  action: ReturnType<typeof createShopReviewRequestAction.request>,
) {
  try {
    const response: DefaultResponse<null> = yield call(
      [api, api.shops.createShopReview],
      action.payload,
    );
    yield put(createShopReviewRequestAction.success(response.success));
  } catch (error) {
    yield put(createShopReviewRequestAction.failure(error as Error));
  }
};

const getShopRecentlyViewedListRequest = function* (
  action: ReturnType<typeof getShopRecentlyViewedListRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopItem> = yield call(
      [api, api.shops.getRecentlyViewedList],
      action.payload,
    );
    yield put(
      getShopRecentlyViewedListRequestAction.success([
        action.payload,
        response,
      ]),
    );
  } catch (error) {
    yield put(getShopRecentlyViewedListRequestAction.failure(error as Error));
  }
};

const deleteRecentlyViewedRequest = function* (
  action: ReturnType<typeof getShopFavoritesListRequestAction.request>,
) {
  try {
    const response: DefaultResponse<null> = yield call([
      api,
      api.shops.deleteRecentlyViewed,
    ]);
    yield getShopRecentlyViewedListRequest(action);
    yield put(deleteRecentlyViewedRequestAction.success(response.success));
  } catch (error) {
    yield put(deleteRecentlyViewedRequestAction.failure(error as Error));
  }
};

const getShopFavoritesListRequest = function* (
  action: ReturnType<typeof getShopFavoritesListRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopItem> = yield call(
      [api, api.shops.getFavoritesList],
      action.payload,
    );
    yield put(
      getShopFavoritesListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getShopFavoritesListRequestAction.failure(error as Error));
  }
};

const createShopFavoriteRequest = function* (
  action: ReturnType<typeof createShopFavoriteRequestAction.request>,
) {
  try {
    const response: DefaultResponse<null> = yield call(
      [api, api.shops.createShopFavorite],
      action.payload,
    );
    yield put(createShopFavoriteRequestAction.success(response.success));
  } catch (error) {
    yield put(createShopFavoriteRequestAction.failure(error as Error));
  }
};

const deleteShopFavoriteRequest = function* (
  action: ReturnType<typeof deleteShopFavoriteRequestAction.request>,
) {
  try {
    const response: DefaultResponse<null> = yield call(
      [api, api.shops.deleteShopFavorite],
      action.payload,
    );
    yield put(deleteShopFavoriteRequestAction.success(response.success));
  } catch (error) {
    yield put(deleteShopFavoriteRequestAction.failure(error as Error));
  }
};

const getShopByCategoryListRequest = function* (
  action: ReturnType<typeof getShopByCategoryListRequestAction.request>,
) {
  try {
    const response: ListWithCategoryNameResponse<ShopItem> = yield call(
      [api, api.shops.getShopsByCategoryList],
      action.payload,
    );
    yield put(
      getShopByCategoryListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getShopByCategoryListRequestAction.failure(error as Error));
  }
};

const searchShopsByCategoryRequest = function* (
  action: ReturnType<typeof searchShopsByCategoryRequestAction.request>,
) {
  try {
    const response: ListResponse<ShopSearchItem> = yield call(
      [api, api.shops.searchShopsByCategory],
      action.payload,
    );
    yield put(
      searchShopsByCategoryRequestAction.success([action.payload, response]),
    );
  } catch (err) {
    yield put(searchShopsByCategoryRequestAction.failure(err as Error));
  }
};

export const shopSaga = function* () {
  yield all([
    takeLatest(
      getShopCategoriesListRequestAction.request,
      getShopCategoriesListRequest,
    ),
    takeLatest(
      getShopsFlatCategoriesListAction.request,
      getShopsFlatCategoriesListRequest,
    ),
    takeLatest(searchShopsRequestAction.request, searchShopsRequest),
    takeLatest(getDetailShopRequestAction.request, getDetailShopRequest),
    takeLatest(
      getShopProductsListRequestAction.request,
      getShopProductsListRequest,
    ),
    takeLatest(
      getShopCommitmentsListRequestAction.request,
      getShopCommitmentsListRequest,
    ),
    takeLatest(
      getAllReviewsListRequestAction.request,
      getAllReviewsListRequest,
    ),
    takeLatest(
      getShopReviewsListRequestAction.request,
      getShopReviewsListRequest,
    ),
    takeLatest(
      getDetailShopReviewRequestAction.request,
      getDetailShopReviewRequest,
    ),
    takeLatest(createShopReviewRequestAction.request, createShopReviewRequest),
    takeLatest(
      getShopRecentlyViewedListRequestAction.request,
      getShopRecentlyViewedListRequest,
    ),
    takeLatest(
      deleteRecentlyViewedRequestAction.request,
      deleteRecentlyViewedRequest,
    ),
    takeLatest(
      getShopFavoritesListRequestAction.request,
      getShopFavoritesListRequest,
    ),
    takeLatest(
      createShopFavoriteRequestAction.request,
      createShopFavoriteRequest,
    ),
    takeLatest(
      deleteShopFavoriteRequestAction.request,
      deleteShopFavoriteRequest,
    ),
    takeLatest(
      getShopByCategoryListRequestAction.request,
      getShopByCategoryListRequest,
    ),
    takeLatest(
      searchShopsByCategoryRequestAction.request,
      searchShopsByCategoryRequest,
    ),
  ]);
};
