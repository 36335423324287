import React, {ReactNode} from 'react';
import {useTheme} from 'styled-components/native';
import {GestureResponderEvent, Text} from 'react-native';

import useRouter from '../../utils/use-router';

import {
  TouchableStripeIcon,
  TouchableStripeTitle,
  TouchableStripeWrapper,
} from './styles';

type TouchableStripeLink = {
  title: ReactNode;
  to: string;
};

type TouchableStripeButton = {
  title: ReactNode;
  onPress: (event: GestureResponderEvent) => void;
};

const isLink = (
  props: TouchableStripeButton | TouchableStripeLink,
): props is TouchableStripeLink => {
  if ((props as TouchableStripeLink).to) return true;
  return false;
};

const TouchableStripe = (
  props: TouchableStripeLink | TouchableStripeButton,
) => {
  const theme = useTheme();
  const router = useRouter();

  const onPress = isLink(props) ? () => router.push(props.to) : props.onPress;

  return (
    <TouchableStripeWrapper onPress={onPress}>
      {typeof props.title === 'string' ? (
        <TouchableStripeTitle>{props.title}</TouchableStripeTitle>
      ) : (
        props.title
      )}
      <TouchableStripeIcon fill={theme.colors['color-basic-500']} />
    </TouchableStripeWrapper>
  );
};

export default TouchableStripe;
