import React, {PropsWithChildren} from 'react';
import {ThemeProvider as OriginalThemeProvider} from 'styled-components/native';
import {ThemeProvider as OriginalWebThemeProvider} from 'styled-components';
import * as eva from '@eva-design/eva';
import {ApplicationProvider} from '@ui-kitten/components';
import useMatchMedia from 'scl/src/hooks/use-match-media';
import {colors, Colors} from '../../resources/colors';
import {Dimens, dimens} from '../../resources/dimens';
import {uiKittenColors} from '../../resources/ui-kitten-colors';

export interface Theme {
  colors: Colors;
  dimens: Dimens;

  isDesktop: boolean;
}

export default function ThemeProvider({
  children,
}: PropsWithChildren): React.ReactElement {
  const isDesktop = useMatchMedia('(min-width: 1200px)');

  const theme: Theme = {
    colors: {
      ...colors,
      ...uiKittenColors,
    },
    dimens,
    isDesktop,
  };

  return (
    <ApplicationProvider {...eva} theme={{...eva.light, ...uiKittenColors}}>
      <OriginalWebThemeProvider theme={theme}>
        <OriginalThemeProvider theme={theme} children={children} />
      </OriginalWebThemeProvider>
    </ApplicationProvider>
  );
}
