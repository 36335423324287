import React from 'react';
import {
  RequestCategoryTypeField,
  RequestCategoryTypeFieldExtraDataCheckBox,
} from '../../../../../../../api/request/models/responses/category-type';
import {NewRequestQuestionFieldErrorText} from '../styles';
import {
  NewRequestQuestionFieldCheckBoxItem,
  NewRequestQuestionFieldCheckBox as NewRequestQuestionFieldCheckBoxComponent,
  NewRequestQuestionFieldCheckBoxContainer,
  NewRequestQuestionFieldCheckBoxTitle,
} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  NewRequestQuestionFieldCheckBoxState,
  NewRequestQuestionForm,
} from '../../../form';

export default function NewRequestQuestionFieldCheckBox({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  const extraData =
    field.extraData as RequestCategoryTypeFieldExtraDataCheckBox;
  return (
    <NewRequestQuestionFieldCheckBoxContainer>
      <NewRequestQuestionFieldCheckBoxTitle>
        {field.title}
      </NewRequestQuestionFieldCheckBoxTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const checkBoxValue = value as NewRequestQuestionFieldCheckBoxState;
          return (
            <NewRequestQuestionFieldCheckBoxComponent>
              {extraData?.map((item, position) => {
                return (
                  <NewRequestQuestionFieldCheckBoxItem
                    last={position === extraData.length - 1}
                    key={item.id}
                    onBlur={onBlur}
                    checked={
                      checkBoxValue.findIndex(
                        checkBox => checkBox.id === item.id,
                      ) >= 0
                    }
                    onChange={checked =>
                      onChange(
                        checked
                          ? checkBoxValue.concat(item)
                          : checkBoxValue.filter(
                              checkBox => checkBox.id !== item.id,
                            ),
                      )
                    }>
                    {item.value}
                  </NewRequestQuestionFieldCheckBoxItem>
                );
              })}
            </NewRequestQuestionFieldCheckBoxComponent>
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
    </NewRequestQuestionFieldCheckBoxContainer>
  );
}
