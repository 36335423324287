import React from 'react';
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

export default function AppKeyboardAvoidingView(
  props: KeyboardAvoidingViewProps,
) {
  const insets = useSafeAreaInsets();

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={insets.top}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      {...props}
    />
  );
}
