import {ShortUser} from '../../../user/models/responses/short-user';
import {Strings} from '../../../../resources/strings';
import {EvaStatus} from '@ui-kitten/components/devsupport';

export interface StandardPointsWithdrawalResponse {
  id: number;
  user: ShortUser;
  amount: number;
  status: WithdrawalStatus;
  declineReason?: string;
  createdAt: string;
  updatedAt: string;
}

export enum WithdrawalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CASH_OUT = 'CASH_OUT',
  DECLINED = 'DECLINED',
}

export function getWithdrawalStatusText(
  strings: Strings,
  status: WithdrawalStatus,
): string {
  switch (status) {
    case WithdrawalStatus.PENDING:
      return strings.standard_points_withdrawal_pending;
    case WithdrawalStatus.APPROVED:
      return strings.standard_points_withdrawal_approved;
    case WithdrawalStatus.CASH_OUT:
      return strings.standard_points_withdrawal_cash_out;
    case WithdrawalStatus.DECLINED:
      return strings.standard_points_withdrawal_declined;
  }
}

export function getWithdrawalStatusType(status: WithdrawalStatus): EvaStatus {
  switch (status) {
    case WithdrawalStatus.PENDING:
      return 'warning';
    case WithdrawalStatus.APPROVED:
      return 'success';
    case WithdrawalStatus.CASH_OUT:
      return 'success';
    case WithdrawalStatus.DECLINED:
      return 'danger';
  }
}
