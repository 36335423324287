import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Config from 'react-native-config';
import SettingsApi from './settings';
import UserApi from './user';
import {ServerError} from './models/server-error';
import refreshTokenInterceptor from 'scl/src/axios/refresh-token-interceptor';
import {getJWTToken, getRefreshToken, saveTokens} from '../storage';
import RequestApi from './request';
import {store} from '../store';
import {logoutRequestAction} from '../features/user/store/action';
import {FieldServerError} from './models/field-server-error';
import {DefaultResponse} from './models/response/default';
import ConciergePointsApi from './concierge-points';
import StandardPointsApi from './standard-points';
import TransactionApi from './transaction';
import FriendApi from './friend';
import SplitPaymentsApi from './split-payments';
import ShopsApi from './shops';
import InfoApi from './info';
import ContactUsApi from './contact-us';
import PostCodeApi from './postcode-info';

export class Api {
  user = new UserApi(this.axiosInstance);
  request = new RequestApi(this.axiosInstance);
  conciergePoints = new ConciergePointsApi(this.axiosInstance);
  standardPoints = new StandardPointsApi(this.axiosInstance);
  transaction = new TransactionApi(this.axiosInstance);
  friend = new FriendApi(this.axiosInstance);
  splitPayment = new SplitPaymentsApi(this.axiosInstance);
  shops = new ShopsApi(this.axiosInstance);
  info = new InfoApi(this.axiosInstance);
  contactUs = new ContactUsApi(this.axiosInstance);
  postCode = new PostCodeApi(this.axiosInstance);

  settings = new SettingsApi(this.axiosInstance);
  constructor(private axiosInstance: AxiosInstance) {}
}

const BASE_PATH = Config.URL;

export const ACCESS_TOKEN_KEY = 'Authorization';
const ACCESS_TOKEN_HEADER_PREFIX = 'Bearer ';
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

const commonConfiguration: AxiosRequestConfig = {
  headers: {...DEFAULT_HEADERS},
};

const instance = axios.create({baseURL: BASE_PATH, ...commonConfiguration});

async function addTokenHeader(request: AxiosRequestConfig, withReset = false) {
  const accessToken = await getJWTToken();
  if (!accessToken) return;

  if (!request.headers) {
    request.headers = {};
  }
  if (!Object.keys(request.headers).includes(ACCESS_TOKEN_KEY) || withReset) {
    request.headers[ACCESS_TOKEN_KEY] =
      ACCESS_TOKEN_HEADER_PREFIX + accessToken;
  }
}

const [refreshTokenRequestInterceptor, refreshTokenResponseErrorInterceptor] =
  refreshTokenInterceptor(
    async config => {
      const refreshToken = await getRefreshToken();
      if (!refreshToken) {
        throw new Error();
      }

      return api.user
        .refreshToken({refreshToken}, config)
        .then(async response => {
          await saveTokens(
            response.data.accessToken,
            response.data.refreshToken,
          );
        })
        .catch(() => store.dispatch(logoutRequestAction.request()));
    },
    instance,
    request => addTokenHeader(request, true),
    () => store.dispatch(logoutRequestAction.request()),
  );

instance.interceptors.request.use(async (request: AxiosRequestConfig) => {
  await addTokenHeader(request);
  return refreshTokenRequestInterceptor(request);
});

instance.interceptors.response.use(
  (value: AxiosResponse) => {
    // TODO: log for check success responses

    // console.log(
    //   'Response Success',
    //   value.request.responseURL,
    //   value.status,
    //   value.data,
    // );

    if (value.data?.status >= 200 && value.data?.status < 400) {
      return value.data;
    } else {
      if (value.data?.status === 422) {
        throw new FieldServerError(value.data);
      } else {
        throw new ServerError(value.data.status, value.data.message);
      }
    }
  },
  async (error: AxiosError<DefaultResponse<any>>) => {
    console.log('Response Error', error, error.response?.data);

    try {
      const data = await refreshTokenResponseErrorInterceptor(error);

      if (data) {
        return data;
      }

      throw error;
    } catch (error) {
      const axiosError = error as AxiosError<DefaultResponse<any>>;
      if (axiosError?.response?.data) {
        const errorData = axiosError.response.data;
        if (errorData.status === 422) {
          throw new FieldServerError(errorData);
        } else {
          throw new ServerError(errorData.status, errorData.message);
        }
      }

      throw error;
    }
  },
);

const api = new Api(instance);
export default api;
