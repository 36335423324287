import React from 'react';
import {
  MainHeaderCallButton,
  MainHeaderContainer,
  MainHeaderLogo,
  MainHeaderLogoContainer,
} from './styles';
import {useStrings} from '../../../../utils/providers/strings';
import {onCallConcierge} from '../../../../utils/on-call-concierge';

export default function MainHeader() {
  const strings = useStrings();

  return (
    <MainHeaderContainer>
      <MainHeaderLogoContainer>
        <MainHeaderLogo />
      </MainHeaderLogoContainer>
      <MainHeaderCallButton onPress={onCallConcierge}>
        {strings.main_header_call_button.toUpperCase()}
      </MainHeaderCallButton>
    </MainHeaderContainer>
  );
}
