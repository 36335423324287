import React from 'react';
import {ViewProps} from 'react-native';
import {Spinner} from '@ui-kitten/components';
import styled from 'styled-components/native';

const LoadingContainer = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
`;

export const AccessoryLoadingIndicator = (props?: ViewProps) => (
  <LoadingContainer {...props}>
    <Spinner size="small" />
  </LoadingContainer>
);
