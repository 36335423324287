import {ShortUser} from './short-user';

export interface User extends ShortUser {
  login: string;
  status: UserStatus;
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserRole {
  USER = 'USER',
  MEMBER = 'MEMBER',
  CONCIERGE = 'CONCIERGE',
  ADMIN = 'ADMIN',
}
