import {ServerError} from './server-error';
import {DefaultResponse} from './response/default';

export class FieldServerError extends ServerError {
  readonly fields: FieldServerErrorItem[];

  constructor(response: DefaultResponse<FieldServerErrorItem[]>) {
    super(
      response.status,
      response.data && response.data.length
        ? response.data.map(error => error.message).join('\n')
        : response.message,
    );
    this.fields = response.data;
  }
}

export interface FieldServerErrorItem {
  message: string;
  code: number;
  field?: string;
}
