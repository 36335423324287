import React from 'react';
import {
  AppHeaderBackButtonContainer,
  AppHeaderBackButtonIcon,
  AppHeaderContainer,
  AppHeaderTitle,
} from './styles';
import useRouter from '../../utils/use-router';
import {TextStyle, ViewStyle} from 'react-native';
import styled from 'styled-components/native';

export interface AppHeaderProps {
  title?: string;
  backNoHistoryRoute?: string;
  onBackPressed?: () => void;
  rightButton?: React.ReactNode;
  style?: ViewStyle;
  titleStyle?: TextStyle;
  withBigDesktop?: boolean;
}

export default function AppHeader({
  title,
  backNoHistoryRoute,
  onBackPressed,
  rightButton,
  style,
  titleStyle,
  withBigDesktop = true,
}: AppHeaderProps) {
  const router = useRouter();
  const onBack =
    onBackPressed ?? (() => router.back(backNoHistoryRoute ?? '/'));

  return (
    <AppHeaderContainer style={style}>
      {(backNoHistoryRoute || onBackPressed) && (
        <AppHeaderBackButtonContainer onPress={onBack}>
          <AppHeaderBackButtonIcon />
        </AppHeaderBackButtonContainer>
      )}

      <AppHeaderTitle style={titleStyle} withBigDesktop={withBigDesktop}>
        {title}
      </AppHeaderTitle>
      {rightButton}
    </AppHeaderContainer>
  );
}

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
`;
