import React from 'react';
import {
  ShopAdditionalAdressContainer,
  ShopAdditionalAdressContentContainer,
  ShopAdditionalContainer,
  ShopAdditionalDescription,
  ShopAdditionalHeadContainer,
  ShopAdditionalHeadTitle,
  ShopAdditionalHeading,
  ShopAdditionalLink,
  ShopAdditionalLocationIcon,
  ShopAdditionalMapDesktopLinkContainer,
  ShopAdditionalMapLink,
  ShopAdditionalMapLinkContainer,
  ShopAdditionalSection,
} from './styles';
import {useStrings} from '../../../../../utils/providers/strings';
import {ShopDetailResponse} from '../../../../../api/shops/models/responses/shop-detail';
import Collapse from '../../../../../uikit/Collapse';
import {Linking} from 'react-native';
import {useTheme} from 'styled-components';

interface IProps {
  data: ShopDetailResponse | undefined;
}

export default function ShopAdditionalInfo({data}: IProps) {
  const strings = useStrings();
  const theme = useTheme();
  const shopData = data?.public;

  const openExternalLink = (url: string) => {
    const validateUrl = url.match('https?://');
    const externalUrl = validateUrl ? url : `https://${url}`;

    Linking.canOpenURL(externalUrl).then(() => {
      Linking.openURL(externalUrl);
    });
  };

  return (
    <ShopAdditionalSection>
      {/* TODO: Need added google map */}
      <ShopAdditionalHeadContainer>
        <ShopAdditionalHeadTitle>
          {strings.shop_detail_additional_info_title}
        </ShopAdditionalHeadTitle>
      </ShopAdditionalHeadContainer>

      {!theme.isDesktop &&
        !!shopData?.coordinate.lat &&
        !!shopData?.coordinate.lng && (
          <ShopAdditionalMapDesktopLinkContainer>
            <ShopAdditionalMapLink
              onPress={() =>
                openExternalLink(
                  `https://maps.google.com/?q=${shopData?.coordinate.lat},${shopData?.coordinate.lng}`,
                )
              }>
              {strings.shop_detail_google_map_button.toUpperCase()}
            </ShopAdditionalMapLink>
          </ShopAdditionalMapDesktopLinkContainer>
        )}

      {shopData?.address && (
        <ShopAdditionalAdressContainer>
          <ShopAdditionalLocationIcon />
          <ShopAdditionalAdressContentContainer>
            <ShopAdditionalHeading>
              {strings.shop_detail_adress_field}
            </ShopAdditionalHeading>
            <ShopAdditionalDescription>
              {shopData?.address}
            </ShopAdditionalDescription>
          </ShopAdditionalAdressContentContainer>

          {theme.isDesktop && (
            <ShopAdditionalMapLinkContainer>
              <ShopAdditionalMapLink
                onPress={() =>
                  openExternalLink(
                    `https://maps.google.com/?q=${shopData?.coordinate.lat},${shopData?.coordinate.lng}`,
                  )
                }>
                {strings.shop_detail_google_map_button.toUpperCase()}
              </ShopAdditionalMapLink>
            </ShopAdditionalMapLinkContainer>
          )}
        </ShopAdditionalAdressContainer>
      )}

      {shopData?.access && (
        <Collapse title={strings.shop_detail_access_field}>
          <ShopAdditionalDescription>
            {shopData?.access}
          </ShopAdditionalDescription>
        </Collapse>
      )}

      {shopData?.outlineShopAtmosphere && (
        <Collapse title={strings.shop_detail_outline_shop_athmospere_field}>
          <ShopAdditionalDescription>
            {shopData?.outlineShopAtmosphere}
          </ShopAdditionalDescription>
        </Collapse>
      )}

      {shopData?.phone && (
        <ShopAdditionalContainer
          indented={
            !!shopData?.address ||
            !!shopData?.access ||
            !!shopData?.outlineShopAtmosphere
          }>
          <ShopAdditionalHeading>
            {strings.shop_detail_phone_field}
          </ShopAdditionalHeading>
          <ShopAdditionalDescription>
            {shopData?.phone}
          </ShopAdditionalDescription>
        </ShopAdditionalContainer>
      )}

      {shopData?.shopContactPerson && (
        <ShopAdditionalContainer>
          <ShopAdditionalHeading>
            {strings.shop_detail_contact_person_field}
          </ShopAdditionalHeading>
          <ShopAdditionalDescription>
            {shopData?.shopContactPerson}
          </ShopAdditionalDescription>
        </ShopAdditionalContainer>
      )}

      {shopData?.businessHours && (
        <ShopAdditionalContainer>
          <ShopAdditionalHeading>
            {strings.shop_detail_business_hour_field}
          </ShopAdditionalHeading>
          <ShopAdditionalDescription>
            {shopData?.businessHours}
          </ShopAdditionalDescription>
        </ShopAdditionalContainer>
      )}

      {shopData?.referencePrice && (
        <ShopAdditionalContainer>
          <ShopAdditionalHeading>
            {strings.shop_detail_reference_price_field}
          </ShopAdditionalHeading>
          <ShopAdditionalDescription>
            {shopData?.referencePrice}
          </ShopAdditionalDescription>
        </ShopAdditionalContainer>
      )}

      {shopData?.budget && (
        <ShopAdditionalContainer>
          <ShopAdditionalHeading>
            {strings.shop_detail_budget_field}
          </ShopAdditionalHeading>
          <ShopAdditionalDescription>
            {shopData?.budget}
          </ShopAdditionalDescription>
        </ShopAdditionalContainer>
      )}

      <ShopAdditionalContainer>
        <ShopAdditionalHeading>
          {strings.shop_detail_representive_name_field}
        </ShopAdditionalHeading>
        <ShopAdditionalDescription>
          {shopData?.storeRepresentativeName}
        </ShopAdditionalDescription>
      </ShopAdditionalContainer>

      {data?.urls?.map(url => (
        <ShopAdditionalContainer key={url.id}>
          <ShopAdditionalHeading>{url.title}</ShopAdditionalHeading>
          <ShopAdditionalLink
            onPress={() => {
              openExternalLink(`${url.link}`);
            }}>
            {url.link}
          </ShopAdditionalLink>
        </ShopAdditionalContainer>
      ))}
    </ShopAdditionalSection>
  );
}
