import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath} from 'react-native-svg';

const ArrowRight = (props: SvgProps) => (
  <Svg width={24} height={24} fill="#1F1B16" {...props}>
    <G clipPath="url(#a)">
      <Path d="M14.475 11.818 8.111 5.454 9.93 3.636l8.182 8.182L9.93 20l-1.818-1.818 6.364-6.364Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ArrowRight;
