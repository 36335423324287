import {all, call, put, takeLatest} from 'redux-saga/effects';
import api from '../../../api';
import {getTransactionListRequestAction} from './action';
import {ListResponse} from '../../../api/models/response/list';
import {Transaction} from '../../../api/transaction/models/responses';

export const TRANSACTION_LIST_LIMIT = 10;

const getTransactionListRequest = function* (
  action: ReturnType<typeof getTransactionListRequestAction.request>,
) {
  try {
    const response: ListResponse<Transaction> = yield call(
      [api, api.transaction.getTransactionList],
      action.payload,
    );
    yield put(
      getTransactionListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getTransactionListRequestAction.failure(error as Error));
  }
};

export const transactionSaga = function* () {
  yield all([
    takeLatest(
      getTransactionListRequestAction.request,
      getTransactionListRequest,
    ),
  ]);
};
