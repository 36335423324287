import React, {FC} from 'react';

import {formatInfoPostDate} from '../../lib';
import useRouter from '../../../../utils/use-router';

import {
  InfoPostCardWrapper,
  InfoPostCardHeader,
  InfoPostCardDateWrapper,
  InfoPostCardDate,
  InfoPostCardBody,
  InfoPostCardBodyContent,
} from './styles';

/** alias for string date */
type DateString = string;
type Props = {
  postId: number;
  date: DateString;
  title: string;
};

const InfoPostCard: FC<Props> = ({postId, date, title}) => {
  const router = useRouter();
  console.log(new Date(date));

  return (
    <InfoPostCardWrapper
      onPress={() => router.replace(`/info/${postId.toString()}`)}>
      <InfoPostCardHeader>
        <InfoPostCardDateWrapper>
          <InfoPostCardDate>
            {formatInfoPostDate(new Date(date))}
          </InfoPostCardDate>
        </InfoPostCardDateWrapper>
      </InfoPostCardHeader>
      <InfoPostCardBody>
        <InfoPostCardBodyContent>{title}</InfoPostCardBodyContent>
      </InfoPostCardBody>
    </InfoPostCardWrapper>
  );
};

export {InfoPostCard};
