import styled from 'styled-components/native';
import {ShopSearchItem} from '../../../../../../api/shops/models/shop-search';
import {FlatList} from 'react-native';

export const ShopsSearchResultsContent = styled.View`
  flex: 1;
  flex-direction: row;
`;

export const ShopsSearchResultsShops = styled(FlatList<ShopSearchItem>)`
  width: ${props => (props.theme.isDesktop ? 'calc(100% + 16px)' : '100%')};
  padding: 8px 16px;
`;
