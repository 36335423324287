import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import {AppButton} from '../../../../../uikit/AppButtons';
import Success from '../../../../../assets/images/Success.png';
import Error from '../../../../../assets/images/Error.png';
import {AppLoader} from '../../../../../uikit/AppLoader';

export const ConciergePointsBuyByBTCSuccessContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.dimens.smallSpacer};
`;

export const ConciergePointsBuyByBTCSuccessIcon = styled.Image.attrs({
  source: Success,
})`
  width: 192px;
  height: 192px;
`;

export const ConciergePointsBuyByBTCErrorIcon = styled.Image.attrs({
  source: Error,
})`
  width: 192px;
  height: 192px;
`;

export const ConciergePointsBuyByBTCSpinner = styled(AppLoader)`
  align-self: center;
  flex: none;
  padding: ${props => props.theme.dimens.normalSpacer};
`;

export const ConciergePointsBuyByBTCSuccessMessage = styled(Text).attrs({
  category: 's1',
})`
  text-align: center;
`;

export const ConciergePointsBuyByBTCSuccessButton = styled(AppButton)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  align-self: stretch;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
    `}
`;

export const ConciergePointsBuyByBTCErrorButton = styled(AppButton)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  align-self: stretch;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
    `}
`;
