import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';

export const FABContainer = styled.TouchableOpacity`
  position: absolute;
  bottom: ${props => props.theme.dimens.normalSpacer};
  right: ${props => props.theme.dimens.normalSpacer};
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors['color-primary-500']};

  border: 2px solid ${props => props.theme.colors['color-primary-500']};
  box-shadow: 0 2px 8px ${props => props.theme.colors['color-primary-500']}3D;
  border-radius: 28px;
`;
