import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {LoginResponse} from '../../../api/user/models/responses/login';
import {registerRequestAction} from './action';

export const registerReducer = combineReducers<{
  registerRequest: RequestState<LoginResponse> | null;
}>({
  registerRequest: createRequestReducer(registerRequestAction),
});
