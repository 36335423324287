import {createAction} from '@reduxjs/toolkit';
import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {SettingsResponse} from '../../../api/settings/models/settingsResponse';
import {Locales, Strings} from '../../../resources/strings';
import {PostInfoResponse} from '../../../api/postcode-info/models/responses';
import {RequestEncryptPostCode} from '../../../api/postcode-info/models/requests';

export const setLocalizationAction = createAction<Locales>('SET_LOCALIZATION');

export const SET_STRINGS_KEY = 'SET_STRINGS';

export const setStringsAction = createAction<Strings>(SET_STRINGS_KEY);

export const settingsRequestAction = createAsyncAction<
  void,
  Array<SettingsResponse>,
  Error
>('SETTINGS_REQUEST');

export const postalInfoRequestAction = createAsyncAction<
  RequestEncryptPostCode,
  PostInfoResponse,
  Error
>('GET_POSTAL_INFO_REQUEST_ACTION');
