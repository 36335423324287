import React from 'react';
import {
  RequestCategoryTypeField,
  RequestCategoryTypeFieldExtraDataMinMaxValues,
} from '../../../../../../../api/request/models/responses/category-type';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldErrorText,
  NewRequestQuestionFieldTitle,
} from '../styles';
import {
  NewRequestQuestionFieldStepperButtonText,
  NewRequestQuestionFieldStepperContainer,
  NewRequestQuestionFieldStepperMinusButton,
  NewRequestQuestionFieldStepperNumber,
  NewRequestQuestionFieldStepperPlusButton,
} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  NewRequestQuestionFieldStepperState,
  NewRequestQuestionForm,
} from '../../../form';

export default function NewRequestQuestionFieldStepper({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  return (
    <NewRequestQuestionFieldContainer>
      <NewRequestQuestionFieldTitle>{field.title}</NewRequestQuestionFieldTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const stepperValue = value as NewRequestQuestionFieldStepperState;
          const extraData =
            field.extraData as RequestCategoryTypeFieldExtraDataMinMaxValues;

          return (
            <NewRequestQuestionFieldStepperContainer>
              <NewRequestQuestionFieldStepperNumber>
                {stepperValue.toString()}
              </NewRequestQuestionFieldStepperNumber>
              <NewRequestQuestionFieldStepperMinusButton
                onPress={() => {
                  if (stepperValue > extraData.minVal) {
                    onChange(stepperValue - 1);
                  }
                }}>
                <NewRequestQuestionFieldStepperButtonText>
                  -
                </NewRequestQuestionFieldStepperButtonText>
              </NewRequestQuestionFieldStepperMinusButton>
              <NewRequestQuestionFieldStepperPlusButton
                onPress={() => {
                  if (stepperValue < extraData.maxValue) {
                    onChange(stepperValue + 1);
                  }
                }}>
                <NewRequestQuestionFieldStepperButtonText>
                  +
                </NewRequestQuestionFieldStepperButtonText>
              </NewRequestQuestionFieldStepperPlusButton>
            </NewRequestQuestionFieldStepperContainer>
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
    </NewRequestQuestionFieldContainer>
  );
}
