import React from 'react';
import {useTheme} from 'styled-components/native';
import {Linking, TextStyle} from 'react-native';

import {useStrings} from '../../utils/providers/strings';

import {PointsContainerPoints} from '../../uikit/Points/container';
import {InfoList, InfoListRows} from '../../uikit/InfoList';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {AppLayout} from '../../uikit/AppLayout';
import CallFAB from '../../uikit/CallFAB';

import {
  AppTransparentHeader,
  OperatingCompanyContainer,
  OperatingCompanyContent,
  OperatingCompanyContentWrapper,
  OperatingCompanyLink,
  OperatingCompanyLinkWrapper,
} from './styles';

const LINK = 'https://about.aloalo.inc/';

export default function OperatingCompanyScreen() {
  const strings = useStrings();
  const theme = useTheme();

  /** Info list rows config */
  const infoListRows: InfoListRows = [
    {
      title: strings.operating_company_interpreter_item,
      value: '株式会社VRバンク',
    },
    {
      title: strings.operating_company_location_item,
      value: '大阪府大阪市北区東天満1-3-10 南森町アーバンビル',
    },
    {
      title: strings.operating_company_phone_item,
      value: '050-5526-5554',
    },
    {
      title: strings.operating_company_representative_director_item,
      value: '岡本 公功',
    },
    {
      title: strings.operating_company_founded_item,
      value: '2015年10月5日',
    },
    {
      title: strings.operating_company_site_item,
      value: (
        <OperatingCompanyLinkWrapper onPress={() => Linking.openURL(LINK)}>
          <OperatingCompanyLink>
            {strings.operating_company_link}
          </OperatingCompanyLink>
        </OperatingCompanyLinkWrapper>
      ),
    },
  ];

  const titleStyles: TextStyle = theme.isDesktop
    ? {
        fontWeight: '600',
        fontSize: 28,
        lineHeight: 36,
      }
    : {
        fontSize: 16,
      };

  return (
    <>
      <AppWhiteStatusBar />
      <OperatingCompanyContainer>
        <OperatingCompanyContentWrapper>
          <AppTransparentHeader
            title={strings.operating_company_title}
            backNoHistoryRoute={theme.isDesktop ? '/' : '/my-page'}
            titleStyle={titleStyles}
          />
          <OperatingCompanyContent>
            <InfoList rows={infoListRows} />
            <AppLayout>{!theme.isDesktop && <CallFAB />}</AppLayout>
          </OperatingCompanyContent>
        </OperatingCompanyContentWrapper>
        {theme.isDesktop && <PointsContainerPoints />}
      </OperatingCompanyContainer>
    </>
  );
}
