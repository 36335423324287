import React from 'react';
import RefreshControl from 'scl/src/components/refresh-control';
import {RefreshControlProps} from 'react-native';
import {useTheme} from 'styled-components/native';

export default function AppRefreshControl(props: RefreshControlProps) {
  const theme = useTheme();

  return (
    <RefreshControl {...props} tintColor={theme.colors['color-primary-500']} />
  );
}
