import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath, SvgProps} from 'react-native-svg';

const Profile = ({
  color = '#DC9B2D',
  ...props
}: SvgProps & {color?: string}) => (
  <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
    <G clipPath="url(#clip0_2284_1935)">
      <Path
        d="M5.333 30.667a10.667 10.667 0 1121.334 0H5.333zm10.667-12c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_2284_1935">
        <Path fill="#fff" d="M0 0H32V32H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Profile;
