import React from 'react';
import {ExternalLink} from '../../uikit/ExternalLink';
import {TextContainer, TextItem} from './styles';

const matchUrlInMessage = new RegExp(
  /(https?\:\/\/)?(www\.)?[^\s\]\\[\$#:;,._(){}]+\.[^\s\]\\[\$#:;,._(){}!]+/g,
);

const urlWithProtocol = new RegExp(
  /(https?\:\/\/[^\s\[\]$#:;,_(){}]+\.?[^\s\]\\[\$#:;,._(){}]+\.[^\s\]\\[\$#:;,._(){}!]+)/,
);

const urlWithoutProtocol = new RegExp(
  /([a-zA-Z0-9][^\s\[\]$#:;,_(){}]+\.?[^\s\]\\[\$#:;,._(){}]+\.[^\s\]\\[\$#:;,._(){}!]+)/,
);

function renderTextItem(
  value: string,
  match: RegExp,
  linkWithProtocol?: boolean,
) {
  return value.split(match).map((item, idx) =>
    item.match(match) ? (
      <ExternalLink
        key={`${item}_${idx}`}
        size={16}
        to={linkWithProtocol ? `http://${item}` : item}>
        {item}
      </ExternalLink>
    ) : (
      <TextItem key={`${item}_${idx}`}>{item}</TextItem>
    ),
  );
}

function renderExternalUrl(value: string) {
  if (value.match(urlWithProtocol)) {
    return renderTextItem(value, urlWithProtocol);
  }

  if (value.match(urlWithoutProtocol)) {
    return renderTextItem(value, urlWithoutProtocol, true);
  }
}

export default function renderTextWithUrls(value: string) {
  if (!value.match(matchUrlInMessage)) {
    return <TextItem>{value}</TextItem>;
  }

  const messages = value.split(' ').map((msg, index) => {
    if (msg.match(matchUrlInMessage)) {
      return (
        <TextContainer key={`${msg}_${index}`}>
          {renderExternalUrl(msg)}
          <TextItem> </TextItem>
        </TextContainer>
      );
    }

    return <TextItem key={`${msg}_${index}`}>{`${msg} `}</TextItem>;
  });

  return <>{messages}</>;
}
