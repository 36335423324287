import React from 'react';
import {ShopCategoriesResponse} from '../../../../api/shops/models/responses/shop-categories';
import {useStrings} from '../../../../utils/providers/strings';
import {
  ShopCardBadgesContainer,
  ShopCardLocationBadge,
  ShopCardTagBadge,
  ShopCategoryCard,
  ShopCategoryCardTitle,
  ShopCategoryCardTitleContainer,
  ShopCategoryContainer,
  ShopCategoryContentContainer,
  ShopCategoryHeadContainer,
  ShopCategoryHeadLink,
  ShopCategoryHeadTitle,
  ShopCategoryImage,
} from './styles';

export default function ShopsCategoryReel({
  data,
}: {
  data: ShopCategoriesResponse[];
}) {
  const strings = useStrings();

  return (
    <>
      {data
        .filter(item => item.shops.length)
        .map(item => (
          <ShopCategoryContainer key={item.category.id}>
            <ShopCategoryHeadContainer>
              <ShopCategoryHeadTitle>
                {item.category.name}
              </ShopCategoryHeadTitle>
              <ShopCategoryHeadLink to={`/shops/category/${item.category.id}`}>
                {strings.shop_see_all_button}
              </ShopCategoryHeadLink>
            </ShopCategoryHeadContainer>

            <ShopCategoryContentContainer horizontal>
              {item.shops.map((shop, index) => (
                <ShopCategoryCard
                  to={`/shops/${shop.id}`}
                  key={shop.id}
                  lastItem={index === item.shops.length - 1}>
                  <ShopCardBadgesContainer>
                    {shop.prefecture && (
                      <ShopCardLocationBadge>
                        {shop.prefecture}
                      </ShopCardLocationBadge>
                    )}

                    {shop.tag && (
                      <ShopCardTagBadge>{shop.tag}</ShopCardTagBadge>
                    )}
                  </ShopCardBadgesContainer>
                  <ShopCategoryCardTitleContainer>
                    <ShopCategoryCardTitle>{shop.name}</ShopCategoryCardTitle>
                  </ShopCategoryCardTitleContainer>
                  <ShopCategoryImage source={{uri: shop.images[0].imageUrl}} />
                </ShopCategoryCard>
              ))}
            </ShopCategoryContentContainer>
          </ShopCategoryContainer>
        ))}
    </>
  );
}
