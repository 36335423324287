import {AxiosInstance} from 'axios';
import {DefaultResponse} from '../models/response/default';
import {RequestEncryptPostCode} from './models/requests';
import {PostInfoResponse} from './models/responses';

export default class PostCodeApi {
  constructor(private axiosInstance: AxiosInstance) {}

  getPostcodeInfo(
    request: RequestEncryptPostCode,
  ): Promise<DefaultResponse<PostInfoResponse>> {
    return this.axiosInstance.post(`user/address`, request);
  }
}
