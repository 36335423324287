import styled from 'styled-components/native';
import AppHeader from '../../../uikit/AppHeader';

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  margin-bottom: 20px;
`;

export const OperatingCompanyContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  background-color: ${props => props.theme.colors['color-basic-100']};
`;
export const OperatingCompanyContentWrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  ${props => (props.theme.isDesktop ? 'padding: 48px 24px 0 40px;' : '')};
`;
export const OperatingCompanyContent = styled.View`
  flex-grow: 1;
`;

export const OperatingCompanyLinkWrapper = styled.TouchableOpacity`
  cursor: pointer;
`;

export const OperatingCompanyLink = styled.Text`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors['color-success-500']};
`;
