import React, {ComponentProps, ComponentType} from 'react';
import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {CheckBox, Text} from '@ui-kitten/components';
import {AppButton, OutlineButton} from '../../../uikit/AppButtons';
import HideKeyboardContainer from '../../../uikit/HideKeyboardContainer';
import {AppInput} from '../../../uikit/AppInput';
import AppRouteView from '../../../uikit/AppRouteView';
import {TextProps} from '@ui-kitten/components/ui/text/text.component';
import {ImageProps, ImageStyle} from 'react-native';
import Eye from '../../../assets/icons/Eye';
import EyeOff from '../../../assets/icons/EyeOff';
import AppKeyboardAvoidingView from '../../../uikit/AppKeyboardAvoidingView';

export const RegisterContainer = styled(HideKeyboardContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RegisterContent = styled(AppKeyboardAvoidingView)`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      width: 376px;
      align-self: center;
      margin-top: 5%;
      margin-bottom: 5%;
      padding-top: 40px;
      padding-bottom: 48px;
      padding-left: 40px;
      padding-right: 40px;
      background: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;

export const RegisterSteps = styled(Text)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
  font-weight: 600;
  font-size: 20px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: ${props.theme.dimens.bigSpacer};
      margin-left: 0;
      margin-right: 0;
    `};
`;

export const RegisterStepTitle = styled(Text)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
  font-weight: 300;
  font-size: 16px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: 0;
      margin-right: 0;
    `};
`;

export const RegisterInputs = styled.ScrollView`
  flex: 1;
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  padding-bottom: 20px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const RegisterInput = styled(AppInput)`
  margin-top: 12px;
`;

export const RegisterEye = styled<
  ComponentType<ImageProps & ComponentProps<typeof Eye>>
>(Eye).attrs(props => ({
  fill: (props.style as ImageStyle)?.tintColor,
}))``;

export const RegisterEyeOff = styled<
  ComponentType<ImageProps & ComponentProps<typeof EyeOff>>
>(EyeOff).attrs(props => ({
  fill: (props.style as ImageStyle)?.tintColor,
}))``;

export const RegisterCheckbox = styled(CheckBox)`
  margin-top: 12px;
  font-weight: 300;
  font-size: 12px;
`;

export const RegisterCheckboxText = (props: TextProps) => {
  return (
    <Text
      {...props}
      style={[props.style, {marginRight: 0, fontWeight: '300', fontSize: 12}]}
    />
  );
};

export const RegisterTermOfService = styled(AppRouteView)`
  margin-left: 4px;
`;

export const RegisterTermOfServiceText = styled(Text)`
  font-weight: 300;
  font-size: 12px;
  color: ${props => props.theme.colors['color-danger-500']};
`;

export const RegisterInputErrorText = styled(Text).attrs({
  status: 'danger',
})`
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const RegisterButtons = styled.View`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.dimens.smallSpacer};
  border-top-width: 1px;
  border-top-color: ${props => props.theme.colors['color-basic-300']};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: ${props.theme.dimens.bigSpacer};
      padding: 0;
      background-color: transparent;
      border-top-width: 0;
    `}
`;

const CommonRegisterButton = css`
  font-size: 14px;
`;

export const RegisterBackButton = styled(OutlineButton)`
  ${CommonRegisterButton};
  flex: 1;
`;

export const RegisterNextButton = styled(AppButton)`
  ${CommonRegisterButton};
  flex: 1;
  margin-left: ${props => props.theme.dimens.smallSpacer};
`;

export const RegisterRules = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;
