import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath} from 'react-native-svg';

const ArrowBack = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#fff" {...props}>
    <G clipPath="url(#a)">
      <Path
        d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414L7.828 11Z"
        fill="#000"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ArrowBack;
