import React from 'react';
import {TextStyle, View} from 'react-native';
import {useTheme} from 'styled-components/native';

import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {useStrings} from '../../utils/providers/strings';
import {AppLayout} from '../../uikit/AppLayout';
import CallFAB from '../../uikit/CallFAB';
import {PointsContainerPoints} from '../../uikit/Points/container';

import {
  PrivacyPolicyContainer,
  PrivacyPolicyContentWrapper,
  PrivacyPolicyContent,
  PrivacyPolicySubtitle,
  PrivacyPolicyText,
  AppTransparentHeader,
} from './styles';

const PrivacyPolicyScreen = () => {
  const strings = useStrings();
  const theme = useTheme();

  const titleStyles: TextStyle = theme.isDesktop
    ? {
        fontWeight: '600',
        fontSize: 28,
        lineHeight: 36,
      }
    : {
        fontSize: 16,
      };

  return (
    <>
      <AppWhiteStatusBar />
      <PrivacyPolicyContainer>
        <PrivacyPolicyContentWrapper>
          <AppTransparentHeader
            title={strings.privacy_policy_title}
            backNoHistoryRoute={theme.isDesktop ? '/' : '/my-page'}
            titleStyle={titleStyles}
          />
          <PrivacyPolicyContent>
            <View style={{paddingBottom: 60}}>
              <PrivacyPolicySubtitle>
                1. {strings.privacy_policy_item_1_title}
              </PrivacyPolicySubtitle>
              <PrivacyPolicyText>
                {strings.privacy_policy_item_1_text}
              </PrivacyPolicyText>
              {/* */}
              <PrivacyPolicySubtitle>
                2. {strings.privacy_policy_item_2_title}
              </PrivacyPolicySubtitle>
              <PrivacyPolicyText>
                {strings.privacy_policy_item_2_text}
              </PrivacyPolicyText>
              {/* */}
              <PrivacyPolicySubtitle>
                3．{strings.privacy_policy_item_3_title}
              </PrivacyPolicySubtitle>
              <PrivacyPolicyText>
                {strings.privacy_policy_item_3_text}
              </PrivacyPolicyText>
              {/* */}
              <PrivacyPolicySubtitle>
                4．{strings.privacy_policy_item_4_title}
              </PrivacyPolicySubtitle>
              <PrivacyPolicyText>
                {strings.privacy_policy_item_4_text}
              </PrivacyPolicyText>
              {/* */}
              <PrivacyPolicySubtitle>
                5．{strings.privacy_policy_item_5_title}
              </PrivacyPolicySubtitle>
              <PrivacyPolicyText>
                {strings.privacy_policy_item_5_text}
              </PrivacyPolicyText>
            </View>
          </PrivacyPolicyContent>
          <AppLayout>{!theme.isDesktop && <CallFAB />}</AppLayout>
        </PrivacyPolicyContentWrapper>
        {theme.isDesktop && <PointsContainerPoints />}
      </PrivacyPolicyContainer>
    </>
  );
};

export default PrivacyPolicyScreen;
