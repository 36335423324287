import {combineReducers} from 'redux';
import {
  addPaginationRequestCase,
  PaginationRequest,
  PaginationRequestState,
} from '../../../utils/redux/create-pagination-request-reducer';
import {createReducer} from '@reduxjs/toolkit';
import {ListResponse} from '../../../api/models/response/list';
import {Transaction} from '../../../api/transaction/models/responses';
import {getTransactionListRequestAction} from './action';

export interface TransactionList {
  items: Transaction[];
  totalCount: number;
  isLastPage: boolean;
}

export const transactionReducer = combineReducers<{
  transactionListRequest: PaginationRequestState<TransactionList> | null;
}>({
  transactionListRequest:
    createReducer<PaginationRequestState<TransactionList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<Transaction>],
          TransactionList
        >(builder, getTransactionListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
});
