import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import SplitPaymentListScreen from './screen';
import SplitPaymentDetailScreen from './detail/screen';

const Stack = createNativeStackNavigator();

export default function SplitPaymentsNavigator() {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen
        name="SplitPaymentList"
        component={SplitPaymentListScreen}
      />
      <Stack.Screen
        name="SplitPaymentDetail"
        component={SplitPaymentDetailScreen}
      />
    </Stack.Navigator>
  );
}
