import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import ChatAvatarCommon from '../../../../../../../../../assets/icons/ChatAvatarCommon';
import {Button, Text} from '@ui-kitten/components';
import {OutlineButton} from '../../../../../../../../../uikit/AppButtons';
import {AppLoader} from '../../../../../../../../../uikit/AppLoader';
import {AppError} from '../../../../../../../../../uikit/AppError';

export const DetailRequestChatTransferRequestContainer = styled.View`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 48px;
  padding-bottom: 16px;
`;

export const DetailRequestChatTransferRequestIcon = styled(ChatAvatarCommon)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const DetailRequestChatTransferRequestInfoType = styled(Text)`
  margin-left: 48px;
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
  font-size: 12px;
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const DetailRequestChatTransferRequestInfoBubble = styled(Text)`
  align-self: flex-start;
  min-width: 0;
  margin-left: 36px;
  margin-bottom: 10px;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors['color-basic-500']}33;
  border-radius: 4px;
`;

export const DetailRequestChatTransferRequestSpinner = styled(AppLoader)`
  flex: none;
  margin-left: 36px;
`;
export const DetailRequestChatTransferRequestError = styled(AppError)`
  flex: none;
  margin-left: 36px;
`;

export const DetailRequestChatTransferRequestStatus = styled(Text)`
  margin-left: 48px;
`;

export const DetailRequestChatTransferRequestButtons = styled.View`
  margin-left: 36px;
  display: flex;
  flex-direction: row;
`;

export const DetailRequestChatTransferRequestAcceptButton = styled(
  Button,
).attrs({
  size: 'small',
})``;

export const DetailRequestChatTransferRequestRejectButton = styled(
  OutlineButton,
).attrs({
  size: 'small',
})`
  margin-left: ${props => props.theme.dimens.smallSpacer};
`;
