import styled from 'styled-components/native';
import AppHeader from '../../../uikit/AppHeader';

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  margin-bottom: 20px;
`;

export const PrivacyPolicyContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  background-color: ${props => props.theme.colors['color-basic-100']};
`;

export const PrivacyPolicyContentWrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${props => (props.theme.isDesktop ? '48px 24px 0 48px' : '0')};
`;

export const PrivacyPolicyContent = styled.ScrollView`
  padding: ${props => (props.theme.isDesktop ? '0 0 60px 0' : '8px 16px 60px')};
`;

export const PrivacyPolicySubtitle = styled.Text`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${props => props.theme.colors['color-basic-900']};

  margin-bottom: 16px;
`;

export const PrivacyPolicyText = styled.Text`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors['color-basic-800']};

  margin-bottom: 24px;
`;
