import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';

export const DetailRequestInfoContainer = styled.ScrollView`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: ${props.theme.dimens.normalSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;

export const DetailRequestInfoItemContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;

export const DetailRequestInfoItemTitle = styled(Text)`
  font-size: 16px;
`;

export const DetailRequestInfoItemStatus = styled(Text)`
  font-size: 14px;
  font-weight: bold;
`;

export const DetailRequestInfoItemText = styled(Text)`
  color: ${props => props.theme.colors['color-basic-800']};
  font-size: 14px;
`;

export const DetailRequestInfoRequestInfoTitleMobile = styled(Text)`
  margin-top: 20px;
  margin-bottom: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const DetailRequestInfoImagesContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const DetailRequestInfoCommonImageButton = styled.TouchableOpacity``;

export const DetailRequestInfoImagePreview = styled.Image`
  width: 60px;
  height: 40px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: ${props => props.theme.dimens.smallestSpacer};
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const DetailRequestInfoCommonBigImage = styled.Image``;
