import React from 'react';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {AppLayout} from '../../uikit/AppLayout';
import {TermOfUseContainer, TermOfUseScrollView, TermOfUseText} from './styles';
import AppHeader from '../../uikit/AppHeader';
import {useStrings} from '../../utils/providers/strings';
import AppMarkdownViewer from '../../uikit/AppMarkdownViewer';

export default function TermOfUseScreen() {
  const strings = useStrings();
  const termsOfUse = `${strings.term_of_use_text_part_one}
  ${strings.term_of_use_text_part_two}`;

  return (
    <>
      <AppWhiteStatusBar />
      <AppLayout>
        <TermOfUseContainer>
          <AppHeader title={strings.term_of_use_title} backNoHistoryRoute="/" />
          <TermOfUseScrollView>
            <TermOfUseText>
              <AppMarkdownViewer>{termsOfUse}</AppMarkdownViewer>
            </TermOfUseText>
          </TermOfUseScrollView>
        </TermOfUseContainer>
      </AppLayout>
    </>
  );
}
