import React from 'react';
import styled from 'styled-components/native';
import {FlatList, ImageStyle, Text} from 'react-native';
import {Spinner} from '@ui-kitten/components';

import {css} from 'scl/src/styled-components/css';

import AppHeader from '../../../../uikit/AppHeader';
import {ShopReviewsResponse} from '../../../../api/shops/models/responses/shop-reviews';
import {AppLink} from '../../../../uikit/AppButtons';

import Plus from '../../../../assets/icons/Plus';

export const ShopReviewsViewedContainerContent = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 111px;
    `}
`;

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  padding: 0 16px;
  margin-bottom: 20px;
`;

export const ShopReviewsViewedListContainer = styled.View`
  flex: 1;
  padding: 0 10px;
`;

export const ShopReviewsViewedList = styled(FlatList<ShopReviewsResponse>)`
  display: flex;
  flex: 1;
`;

export const ScreenListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const ScreenListLoading = styled(Spinner)``;

export const ScreenListEmptyInfo = styled(Text)``;

export const ShopReviewsAddNewReview = styled(AppLink).attrs(props => ({
  appearance: props.theme.isDesktop ? 'outline' : 'ghost',
  status: 'danger',
  accessoryLeft: iconProps => (
    <Plus
      {...iconProps}
      style={[iconProps?.style, {marginRight: -6, marginLeft: 0}]}
      fill={props.theme.colors['color-danger-500']}
      width={(iconProps?.style as ImageStyle)?.width}
      height={(iconProps?.style as ImageStyle)?.height}
    />
  ),
}))`
  background-color: transparent;
`;
