import styled from 'styled-components/native';

export const TextContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TextItem = styled.Text`
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: ${props => props.theme.colors['color-basic-900']};
`;
