import styled from 'styled-components/native';
import {AppInput} from '../../../../../uikit/AppInput';
import {Text} from '@ui-kitten/components';

export const ContactUsFormWrapper = styled.View<{loading?: boolean}>`
  padding: ${props =>
    props.theme.isDesktop ? '24px 24px 16px 24px' : '0 16px'};
  background-color: ${props =>
    props.theme.isDesktop ? props.theme.colors.white : 'none'};

  border: ${props => (props.theme.isDesktop ? '1px solid #cec5bd' : 'none')};
  border-radius: 4px;

  opacity: ${props => (props.loading ? '0.6' : 1)};
  pointer-events: ${props => (props.loading ? 'none' : 'auto')}; ;
`;

export const ContactUsFormInputs = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  width: ${props => (props.theme.isDesktop ? 'calc(100% + 20px)' : '100%')};
`;

export const ContactUsInputWrapper = styled.View<{
  half?: boolean;
}>`
  margin-bottom: ${props => (props.theme.isDesktop ? '12px' : '12px')};
  padding-right: ${props => (props.theme.isDesktop ? '20px' : '0')};
  width: ${({theme, half}) => (theme.isDesktop && half ? '50%' : '100%')};
  max-width: ${({theme, half}) => (theme.isDesktop && half ? '50%' : '100%')};
`;
export const ContactUsInput = styled(AppInput)`
  min-width: 100%;
  margin-bottom: 4px;
`;
export const ContactUsTextarea = styled(ContactUsInput).attrs({
  textStyle: {minHeight: 96, textAlignVertical: 'top'},
})``;

export const ContactUsSymbolsCounterWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
`;
export const ContactUsSymbolsCounter = styled.Text<{limit?: boolean}>`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${props =>
    props.limit
      ? props.theme.colors['color-danger-500']
      : props.theme.colors['color-basic-800']};
`;

export const ContactUsInputError = styled(Text).attrs({
  status: 'danger',
})`
  font-size: 14px;
  margin-bottom: 8px;
`;
