import React, {useCallback, useEffect} from 'react';
import {
  DetailRequestChatTransferRequestAcceptButton,
  DetailRequestChatTransferRequestButtons,
  DetailRequestChatTransferRequestContainer,
  DetailRequestChatTransferRequestError,
  DetailRequestChatTransferRequestIcon,
  DetailRequestChatTransferRequestInfoBubble,
  DetailRequestChatTransferRequestInfoType,
  DetailRequestChatTransferRequestRejectButton,
  DetailRequestChatTransferRequestSpinner,
  DetailRequestChatTransferRequestStatus,
} from './styles';
import {RequestChatMessageTransferRequest} from '../../../../../../../../api/request/models/responses/chat';
import {useStrings} from '../../../../../../../../utils/providers/strings';
import {
  useAppDispatch,
  useMultipleRequestSelector,
} from '../../../../../../../../store/store';
import {
  acceptTransferRequestAction,
  getTransferRequestAction,
  rejectTransferRequestAction,
} from '../../../../../../store/action';
import {AccessoryLoadingIndicator} from '../../../../../../../../uikit/AccessoryLoadingIndicator';
import {
  getRequestChatMessageTransferRequestStatusEva,
  getRequestChatMessageTransferRequestStatusText,
  TransferRequestStatus,
} from '../../../../../../../../api/request/models/responses/transfer';

export default function DetailRequestChatTransferRequest({
  message,
}: {
  message: RequestChatMessageTransferRequest;
}) {
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const transferId = message.extraData.transferRequestId;
  const transferRequest = useMultipleRequestSelector(
    store => store.requests.getTransferRequest,
    transferId,
  );
  const acceptTransferRequest = useMultipleRequestSelector(
    store => store.requests.acceptTransferRequest,
    transferId,
  );
  const rejectTransferRequest = useMultipleRequestSelector(
    store => store.requests.rejectTransferRequest,
    transferId,
  );

  const isLoading =
    acceptTransferRequest.isLoading || rejectTransferRequest.isLoading;

  const loadTransfer = useCallback(() => {
    dispatch(
      getTransferRequestAction.request([transferId, String(transferId)]),
    );
  }, [dispatch, transferId]);

  useEffect(() => {
    if (
      !transferRequest.data &&
      !transferRequest.error &&
      !transferRequest.isLoading
    ) {
      loadTransfer();
    }
  }, [loadTransfer, transferRequest]);

  const onAcceptTransfer = () => {
    dispatch(
      acceptTransferRequestAction.request([transferId, String(transferId)]),
    );
  };

  const onRejectTransfer = () => {
    dispatch(
      rejectTransferRequestAction.request([transferId, String(transferId)]),
    );
  };

  return (
    <DetailRequestChatTransferRequestContainer>
      <DetailRequestChatTransferRequestInfoType>
        {strings.detail_chat_item_system_title}
      </DetailRequestChatTransferRequestInfoType>
      <DetailRequestChatTransferRequestInfoBubble>
        {message.messageText}
      </DetailRequestChatTransferRequestInfoBubble>
      {transferRequest.data ? (
        transferRequest.data.status ===
        TransferRequestStatus.APPROVED_BY_ADMIN ? (
          <DetailRequestChatTransferRequestButtons>
            <DetailRequestChatTransferRequestAcceptButton
              disabled={isLoading}
              onPress={onAcceptTransfer}
              accessoryRight={
                acceptTransferRequest.isLoading
                  ? AccessoryLoadingIndicator
                  : undefined
              }>
              {strings.detail_chat_transfer_request_accept_button}
            </DetailRequestChatTransferRequestAcceptButton>
            <DetailRequestChatTransferRequestRejectButton
              disabled={isLoading}
              onPress={onRejectTransfer}
              accessoryRight={
                rejectTransferRequest.isLoading
                  ? AccessoryLoadingIndicator
                  : undefined
              }>
              {strings.detail_chat_transfer_request_reject_button}
            </DetailRequestChatTransferRequestRejectButton>
          </DetailRequestChatTransferRequestButtons>
        ) : (
          <DetailRequestChatTransferRequestStatus
            status={getRequestChatMessageTransferRequestStatusEva(
              transferRequest.data.status,
            )}>
            {getRequestChatMessageTransferRequestStatusText(
              strings,
              transferRequest.data.status,
            )}
          </DetailRequestChatTransferRequestStatus>
        )
      ) : transferRequest.isLoading ? (
        <DetailRequestChatTransferRequestSpinner />
      ) : transferRequest.error ? (
        <DetailRequestChatTransferRequestError
          error={transferRequest.error.message}
          retry={loadTransfer}
        />
      ) : null}
      <DetailRequestChatTransferRequestIcon />
    </DetailRequestChatTransferRequestContainer>
  );
}
