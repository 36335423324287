import {
  RequestCategoryTypeField,
  RequestCategoryTypeFieldExtraDataAttachItem,
  RequestCategoryTypeFieldExtraDataCheckBoxItem,
  RequestCategoryTypeFieldExtraDataMinMaxValues,
  RequestCategoryTypeFieldExtraDataRadioGroupItem,
  RequestCategoryTypeFieldExtraDataSingleSelectionItem,
  RequestCategoryTypeFieldType,
  RequestCategoryTypeScreen,
  RequestDateValueJson,
} from '../../../../api/request/models/responses/category-type';
import {Strings} from '../../../../resources/strings';
import {AppTime} from '../../../../uikit/AppTimePicker/common';
import {RequestCreateFieldValue} from '../../../../api/request/models/requests/create';

export type NewRequestQuestionFormType =
  | NewRequestQuestionFieldTextState
  | NewRequestQuestionFieldDateState
  | NewRequestQuestionFieldTimeState
  | NewRequestQuestionFieldRadioGroupState
  | NewRequestQuestionFieldSingleSelectionState
  | NewRequestQuestionFieldNumberState
  | NewRequestQuestionFieldCheckBoxState
  | NewRequestQuestionFieldStepperState
  | NewRequestQuestionFieldAttachmentState;

export interface NewRequestQuestionForm {
  [key: string]: NewRequestQuestionFormType;
}

export type NewRequestQuestionFieldTextState = string;

export const defaultNewRequestQuestionFieldText =
  (): NewRequestQuestionFieldTextState => '';

export interface NewRequestQuestionFieldDateState {
  date?: Date;
  isFixed: boolean;
}

export const defaultNewRequestQuestionFieldDate =
  (): NewRequestQuestionFieldDateState => {
    return {
      date: undefined,
      isFixed: true,
    };
  };

export type NewRequestQuestionFieldTimeState = AppTime;

export const defaultNewRequestQuestionFieldTime =
  (): NewRequestQuestionFieldTimeState => ({
    hours: 0,
    minutes: 0,
  });

export type NewRequestQuestionFieldRadioGroupState =
  | RequestCategoryTypeFieldExtraDataRadioGroupItem
  | undefined;

export const defaultNewRequestQuestionFieldRadioGroup =
  (): NewRequestQuestionFieldRadioGroupState => undefined;

export type NewRequestQuestionFieldSingleSelectionState =
  | RequestCategoryTypeFieldExtraDataSingleSelectionItem
  | undefined;

export const defaultNewRequestQuestionFieldSingleSelection =
  (): NewRequestQuestionFieldSingleSelectionState => undefined;

export type NewRequestQuestionFieldNumberState = string;

export const defaultNewRequestQuestionFieldNumber =
  (): NewRequestQuestionFieldNumberState => '';

export const CHECKBOX_DIVIDER = ',';

export type NewRequestQuestionFieldCheckBoxState =
  RequestCategoryTypeFieldExtraDataCheckBoxItem[];

export const defaultNewRequestQuestionFieldCheckBox =
  (): NewRequestQuestionFieldCheckBoxState => [];

export type NewRequestQuestionFieldStepperState = number;

export const defaultNewRequestQuestionFieldStepper =
  (): NewRequestQuestionFieldStepperState => 0;

export type NewRequestQuestionFieldAttachmentState =
  RequestCategoryTypeFieldExtraDataAttachItem[];

export const defaultNewRequestQuestionFieldAttachment =
  (): NewRequestQuestionFieldAttachmentState => [
    {id: 1, asset: null, isLoading: false, key: ''},
    {id: 2, asset: null, isLoading: false, key: ''},
    {id: 3, asset: null, isLoading: false, key: ''},
  ];

export function validateNewRequestQuestionFields(
  strings: Strings,
  screen: RequestCategoryTypeScreen,
  values: NewRequestQuestionForm,
): {[key: string]: {type: string; message: string}} {
  const errors = {};

  screen.fields.forEach(field => {
    let value;

    if (field.type === RequestCategoryTypeFieldType.DATE && !field.required) {
      value = values[field.id] as NewRequestQuestionFieldDateState;

      if (!value.isFixed && value.date) return;

      if (value.isFixed && !value.date) {
        errors[field.id] = {
          type: 'required',
          message: `${field.emptyMessage} ${strings.new_request_question_date_is_fixed_postfix_message}`,
        };
      }
    }

    if (!field.required) return;

    switch (field.type) {
      case RequestCategoryTypeFieldType.INPUT:
        value = values[field.id] as NewRequestQuestionFieldTextState;
        if (!value.length) {
          errors[field.id] = {
            type: 'required',
            message: field.emptyMessage,
          };
        }
        return;
      case RequestCategoryTypeFieldType.DATE:
        value = values[field.id] as NewRequestQuestionFieldDateState;
        if (!value.date) {
          errors[field.id] = {
            type: 'required',
            message: field.emptyMessage,
          };
        }
        return;
      case RequestCategoryTypeFieldType.RADIO_GROUP:
        value = values[field.id] as NewRequestQuestionFieldRadioGroupState;
        if (value === undefined) {
          errors[field.id] = {
            type: 'required',
            message: field.emptyMessage,
          };
        }
        return;
      case RequestCategoryTypeFieldType.SINGLE_SELECTION:
        value = values[field.id] as NewRequestQuestionFieldSingleSelectionState;
        if (value === undefined) {
          errors[field.id] = {
            type: 'required',
            message: field.emptyMessage,
          };
        }
        return;
      case RequestCategoryTypeFieldType.INPUT_NUMBER:
        value = values[field.id] as NewRequestQuestionFieldNumberState;
        if (!value.length) {
          errors[field.id] = {
            type: 'required',
            message: field.emptyMessage,
          };
        }
        return;
      case RequestCategoryTypeFieldType.CHECKBOX:
        value = values[field.id] as NewRequestQuestionFieldCheckBoxState;
        if (!value.length) {
          errors[field.id] = {
            type: 'required',
            message: field.emptyMessage,
          };
        }
        return;
      case RequestCategoryTypeFieldType.STEPPER:
        value = values[field.id] as NewRequestQuestionFieldStepperState;
        const extraData =
          field.extraData as RequestCategoryTypeFieldExtraDataMinMaxValues;

        if (Number(value) < extraData.minVal) {
          errors[field.id] = {
            type: 'required',
            message: field.emptyMessage,
          };
        }
        return;
    }
  });

  return errors;
}

export function defaultNewRequestQuestionValues(
  screen: RequestCategoryTypeScreen,
): NewRequestQuestionForm {
  return screen.fields.reduce((o, field) => {
    const defaultValue = (() => {
      switch (field.type) {
        case RequestCategoryTypeFieldType.INPUT:
          return defaultNewRequestQuestionFieldText();
        case RequestCategoryTypeFieldType.DATE:
          return defaultNewRequestQuestionFieldDate();
        case RequestCategoryTypeFieldType.TIME:
          return defaultNewRequestQuestionFieldTime();
        case RequestCategoryTypeFieldType.RADIO_GROUP:
          return defaultNewRequestQuestionFieldRadioGroup();
        case RequestCategoryTypeFieldType.SINGLE_SELECTION:
          return defaultNewRequestQuestionFieldSingleSelection();
        case RequestCategoryTypeFieldType.INPUT_NUMBER:
          return defaultNewRequestQuestionFieldNumber();
        case RequestCategoryTypeFieldType.CHECKBOX:
          return defaultNewRequestQuestionFieldCheckBox();
        case RequestCategoryTypeFieldType.STEPPER:
          return defaultNewRequestQuestionFieldStepper();
        case RequestCategoryTypeFieldType.ATTACHMENT_IMAGE:
          return defaultNewRequestQuestionFieldAttachment();
      }
    })();
    return {
      ...o,
      [field.id]: defaultValue,
    };
  }, {});
}

export function getNewRequestQuestionAnswer(
  strings: Strings,
  field: RequestCategoryTypeField,
  answer: NewRequestQuestionForm,
): string {
  switch (field.type) {
    case RequestCategoryTypeFieldType.INPUT:
      return answer[field.id] as NewRequestQuestionFieldTextState;
    case RequestCategoryTypeFieldType.DATE:
      const date = (answer[field.id] as NewRequestQuestionFieldDateState).date;
      return date ? strings.app_date(date) : '';
    case RequestCategoryTypeFieldType.TIME:
      const time = answer[field.id] as NewRequestQuestionFieldTimeState;
      return strings.app_time(new Date(0, 0, 0, time.hours, time.minutes));
    case RequestCategoryTypeFieldType.RADIO_GROUP:
      return (
        (answer[field.id] as NewRequestQuestionFieldRadioGroupState)?.value ||
        ''
      );
    case RequestCategoryTypeFieldType.SINGLE_SELECTION:
      return (
        (answer[field.id] as NewRequestQuestionFieldSingleSelectionState)
          ?.value || ''
      );
    case RequestCategoryTypeFieldType.INPUT_NUMBER:
      return answer[field.id] as NewRequestQuestionFieldNumberState;
    case RequestCategoryTypeFieldType.CHECKBOX:
      return (answer[field.id] as NewRequestQuestionFieldCheckBoxState)
        .map(checkBox => checkBox.value)
        .join(`${CHECKBOX_DIVIDER} `);
    case RequestCategoryTypeFieldType.STEPPER:
      return (
        answer[field.id] as NewRequestQuestionFieldStepperState
      ).toString();
    case RequestCategoryTypeFieldType.ATTACHMENT_IMAGE:
      return '';
  }
}

export function getAnswer(
  answer: NewRequestQuestionFormType | undefined,
): RequestCreateFieldValue | undefined {
  switch (typeof answer) {
    case 'string': {
      return answer;
    }
    case 'number':
      return answer.toString();
    case 'object': {
      if (Array.isArray(answer) && Object.hasOwn(answer[0], 'asset')) {
        const _answer = answer as NewRequestQuestionFieldAttachmentState;
        const keys = _answer
          .filter(item => item.key.length !== 0)
          .map(item => item.key);

        return JSON.stringify(keys);
      }

      if (Array.isArray(answer)) {
        return answer.map(checkBox => checkBox.id).join(CHECKBOX_DIVIDER);
      }

      if (Object.hasOwn(answer, 'isFixed')) {
        const _answer = answer as NewRequestQuestionFieldDateState;
        if (!_answer.date) return;
        const json: RequestDateValueJson = {
          isFixed: _answer.isFixed,
          date: _answer.date.toISOString(),
        };
        return JSON.stringify(json);
      }

      if (Object.hasOwn(answer, 'id')) {
        const _answer = answer as
          | NewRequestQuestionFieldRadioGroupState
          | NewRequestQuestionFieldSingleSelectionState
          | RequestCategoryTypeFieldExtraDataCheckBoxItem;

        return _answer?.id;
      }

      if (Object.hasOwn(answer, 'hours')) {
        const _answer = answer as NewRequestQuestionFieldTimeState;
        return `${_answer.hours}:${_answer.minutes}`;
      }
    }
  }
}
