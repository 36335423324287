import {all, call, put, takeLatest} from 'redux-saga/effects';
import {restoreRequestAction} from './action';
import api from '../../../api';
import {DefaultResponse} from '../../../api/models/response/default';
import {RestoreResponse} from '../../../api/user/models/responses/restore';

const restoreRequest = function* (
  action: ReturnType<typeof restoreRequestAction.request>,
) {
  try {
    const response: DefaultResponse<RestoreResponse> = yield call(
      [api, api.user.restore],
      action.payload,
    );

    yield put(restoreRequestAction.success({}));
  } catch (error) {
    yield put(restoreRequestAction.failure(error as Error));
  }
};

export const restoreSaga = function* () {
  yield all([takeLatest(restoreRequestAction.request, restoreRequest)]);
};
