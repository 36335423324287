import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {GhostButton} from '../../AppButtons';
import {ViewProps} from 'react-native';
import React from 'react';
import Logout from '../../../assets/icons/Logout';

export const AppLogoutButton = styled(GhostButton).attrs({
  status: 'danger',
  accessoryRight: (props?: ViewProps) => (
    <AppLogoutIconContainer {...props}>
      <Logout />
    </AppLogoutIconContainer>
  ),
})`
  font-size: 14px;
`;

const AppLogoutIconContainer = styled.View`
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -12px;
`;
