import {combineReducers} from 'redux';
import {
  acceptTransferRequestAction,
  getCalendarRequestsAction,
  getDetailRequestChatRequestAction,
  getDetailRequestRequestAction,
  getRequestsRequestAction,
  getTransferRequestAction,
  rejectTransferRequestAction,
  sendRequestMessageRequestAction,
  updateDetailRequestChatRequestAction,
} from './action';
import {ListResponse} from '../../../api/models/response/list';
import {
  CalendarRequestResponse,
  RequestResponse,
} from '../../../api/request/models/responses';
import {RequestsQuery} from '../../../api/request/models/requests';
import {
  addPaginationRequestCase,
  PaginationRequestState,
} from '../../../utils/redux/create-pagination-request-reducer';
import createRequestReducer, {
  data,
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {DetailRequestResponse} from '../../../api/request/models/responses/detail';
import {RequestChatMessage} from '../../../api/request/models/responses/chat';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import createMultipleRequestReducer, {
  addMultipleRequestCase,
  multipleInitState,
  MultipleRequestState,
} from 'scl/src/redux/create-multiple-request-reducer';
import {TransferResponse} from '../../../api/request/models/responses/transfer';
import {createRequestRequestAction} from '../../new-request/store/action';

export interface RequestList {
  items: RequestResponse[];
  totalCount: number;
  isLastPage: boolean;
}

export interface ChatList {
  items: RequestChatMessage[];
  totalCount: number;
  isLastPage: boolean;
}

export const requestsReducer = combineReducers<{
  requestsRequest: PaginationRequestState<RequestList> | null;
  request: RequestState<DetailRequestResponse> | null;
  chatRequest: PaginationRequestState<ChatList> | null;
  sendMessageRequest: RequestState<RequestChatMessage> | null;
  getTransferRequest: MultipleRequestState<TransferResponse>;
  acceptTransferRequest: MultipleRequestState<TransferResponse>;
  rejectTransferRequest: MultipleRequestState<TransferResponse>;
  calendarRequests: RequestState<ListResponse<CalendarRequestResponse>> | null;
}>({
  requestsRequest: createReducer<PaginationRequestState<RequestList> | null>(
    null,
    builder => {
      addPaginationRequestCase<
        RequestsQuery,
        [RequestsQuery, ListResponse<RequestResponse>],
        RequestList
      >(builder, getRequestsRequestAction, (prevData, newData) => {
        const [query, response] = newData;
        const items = [
          ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
          ...response.data,
        ];
        return {
          items,
          totalCount: response.totalCount,
          isLastPage: items.length >= response.totalCount,
        };
      });

      builder.addCase(
        createRequestRequestAction.success,
        (
          state: PaginationRequestState<RequestList> | null,
          action: PayloadAction<RequestResponse>,
        ) => {
          if (!state || !state.data) return state;

          return {
            ...state,
            data: {
              ...state.data,
              items: [action.payload, ...state.data.items],
              totalCount: state.data.totalCount + 1,
            },
          };
        },
      );
    },
  ),
  request: createRequestReducer(getDetailRequestRequestAction),
  chatRequest: createReducer<PaginationRequestState<ChatList> | null>(
    null,
    builder => {
      addPaginationRequestCase(
        builder,
        getDetailRequestChatRequestAction,
        (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        },
      );

      const mergeNewMessages = (
        state: PaginationRequestState<ChatList> | null,
        action: PayloadAction<ListResponse<RequestChatMessage>>,
      ) => {
        if (!state || !state.data) return state;

        let newItems = [...state.data.items];

        let prevMessageId: number | null = null;
        let newMessagesCount = 0;
        action.payload.data.reverse().forEach(message => {
          if (!newItems.find(prevMessage => prevMessage.id === message.id)) {
            newMessagesCount++;
            if (prevMessageId !== null) {
              const newMessagePosition = newItems.findIndex(
                prevMessage => prevMessage.id === prevMessageId,
              );
              newItems.splice(newMessagePosition, 0, message);
              prevMessageId = message.id;
            } else {
              newItems.unshift(message);
            }
          } else {
            prevMessageId = message.id;
          }
        });

        if (newMessagesCount === action.payload.data.length) {
          newItems = action.payload.data;
        }

        return {
          ...state,
          data: {
            ...state.data,
            items: newItems,
            totalCount: action.payload.totalCount,
            isLastPage: newItems.length >= action.payload.totalCount,
          },
        };
      };

      builder.addCase(
        updateDetailRequestChatRequestAction.success,
        mergeNewMessages,
      );
      builder.addCase(
        sendRequestMessageRequestAction.success,
        mergeNewMessages,
      );
    },
  ),
  sendMessageRequest: createRequestReducer(sendRequestMessageRequestAction),
  getTransferRequest: createReducer<MultipleRequestState<TransferResponse>>(
    multipleInitState(),
    builder => {
      addMultipleRequestCase(builder, getTransferRequestAction);

      function updateTransfer(
        state: MultipleRequestState<TransferResponse>,
        action: PayloadAction<[number, TransferResponse]>,
      ) {
        const [id, transfer] = action.payload;
        return {
          ...state,
          [id]: data(state[id], transfer),
        };
      }
      builder.addCase(acceptTransferRequestAction.success, updateTransfer);
      builder.addCase(rejectTransferRequestAction.success, updateTransfer);
    },
  ),
  acceptTransferRequest: createMultipleRequestReducer(
    acceptTransferRequestAction,
  ),
  rejectTransferRequest: createMultipleRequestReducer(
    rejectTransferRequestAction,
  ),
  calendarRequests: createRequestReducer(getCalendarRequestsAction),
});
