import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList} from 'react-native';
import {Spinner, Text} from '@ui-kitten/components';
import EmptyTransactionList from '../../../assets/icons/EmptyRequests';
import {AppLink} from '../../../uikit/AppButtons';
import {Transaction} from '../../../api/transaction/models/responses';

export const TransactionList = styled(FlatList<Transaction>)`
  flex: 1;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.smallSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: ${props.theme.dimens.smallSpacer};
    `}
`;

export const TransactionListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const TransactionListLoading = styled(Spinner)``;

export const TransactionListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const TransactionListEmptyIcon = styled(EmptyTransactionList)``;

export const TransactionListEmptyInfo = styled(Text)``;

export const TransactionListEmptyCreateNewLink = styled(AppLink)`
  align-self: stretch;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
      min-width: 240px;
    `}
`;

export const TransactionListItemContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const TransactionListItemInfoTitle = styled(Text)``;

export const TransactionListItemInfo = styled(Text).attrs({
  category: 'c1',
})`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};
`;
