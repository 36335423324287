import React from 'react';

export default function recursiveAddChildProps(
  children: React.ReactNode,
  newProps: any,
) {
  return React.Children.map(children, (child: React.ReactNode) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props) {
      if (child.props.children) {
        return React.cloneElement(
          child,
          newProps,
          recursiveAddChildProps(child.props.children, newProps),
        );
      } else {
        return React.cloneElement(child, newProps);
      }
    }
    return child;
  });
}
