import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Attach = (props: SvgProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    strokeWidth={2}
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}>
    <Path d="M0 0h24v24H0z" stroke="none" />
    <Path d="M15 7l-6.5 6.5a1.5 1.5 0 003 3L18 10a3 3 0 00-6-6l-6.5 6.5a4.5 4.5 0 009 9L21 13" />
  </Svg>
);

export default Attach;
