import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import {AppInput} from '../../../../uikit/AppInput';
import {AppButton} from '../../../../uikit/AppButtons';
import HideKeyboardContainer from '../../../../uikit/HideKeyboardContainer';
import AppKeyboardAvoidingView from '../../../../uikit/AppKeyboardAvoidingView';

export const StandardPointsWithdrawalOutside = styled(HideKeyboardContainer)`
  flex: 1;
`;

export const StandardPointsWithdrawalKeyboardAvoidingView = styled(
  AppKeyboardAvoidingView,
)`
  flex: 1;
`;

export const StandardPointsWithdrawalContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${props => props.theme.dimens.smallSpacer};
  padding: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      flex: none;
      margin-top: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
      padding: 24px;
    `}
`;

export const StandardPointsWithdrawalHeader = styled(Text).attrs({
  category: 'h6',
})``;

export const StandardPointsWithdrawalInput = styled(AppInput).attrs({
  keyboardType: 'numeric',
})`
  margin-top: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: 20px;
    `}
`;

export const StandardPointsWithdrawalInputError = styled(Text).attrs({
  status: 'danger',
})``;

export const StandardPointsWithdrawalButton = styled(AppButton)`
  margin-bottom: 36px;
  margin-left: 60px;
  margin-right: 60px;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: flex-end;

      margin-top: ${props.theme.dimens.bigSpacer};
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    `}
`;
