import {AxiosInstance} from 'axios';
import {LoginRequest} from './models/requests/login';
import {RestoreConfirmRequest} from './models/requests/restore-confirm';
import {LoginResponse} from './models/responses/login';
import {RestoreConfirmResponse} from './models/responses/restore-confirm';
import {User} from './models/responses/user';
import {RefreshRequest} from './models/requests/refresh';
import {DefaultResponse} from '../models/response/default';
import {LogoutRequest} from './models/requests/logout';
import {UserProfileResponse} from './models/responses/user-profile';
import {UpdateProfileRequest} from './models/requests/update-profile';
import {RegisterRequest} from './models/requests/register';
import {RestoreRequest} from './models/requests/restore';
import {RestoreResponse} from './models/responses/restore';

export default class UserApi {
  constructor(private axiosInstance: AxiosInstance) {}

  login(request: LoginRequest): Promise<DefaultResponse<LoginResponse>> {
    return this.axiosInstance.post('auth/signin', request);
  }

  logout(request: LogoutRequest): Promise<DefaultResponse<void>> {
    return this.axiosInstance.post('auth/logout', request);
  }

  refreshToken(
    request: RefreshRequest,
    config: any,
  ): Promise<DefaultResponse<LoginResponse>> {
    return this.axiosInstance.post('auth/refresh', request, config);
  }

  getUser(id: string): Promise<DefaultResponse<User>> {
    return this.axiosInstance.get(`user/${id}`);
  }

  getUserProfile(id: string): Promise<DefaultResponse<UserProfileResponse>> {
    return this.axiosInstance.get(`user/${id}/profile`);
  }

  updateUserProfile(
    id: string,
    request: UpdateProfileRequest,
  ): Promise<DefaultResponse<void>> {
    return this.axiosInstance.put(`user/${id}/profile`, request);
  }

  deleteUserAccount(id: string): Promise<DefaultResponse<void>> {
    return this.axiosInstance.delete(`user/${id}/profile`);
  }

  register(request: RegisterRequest): Promise<DefaultResponse<LoginResponse>> {
    return this.axiosInstance.post('auth/signup', request);
  }

  restore(request: RestoreRequest): Promise<DefaultResponse<RestoreResponse>> {
    return this.axiosInstance.post('auth/restore-by-email', request);
  }

  restoreConfirm(
    request: RestoreConfirmRequest,
  ): Promise<DefaultResponse<RestoreConfirmResponse>> {
    return this.axiosInstance.put('auth/restore-by-email', request);
  }
}
