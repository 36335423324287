import React, {ReactElement} from 'react';
import {RequestState} from 'scl/src/redux/create-request-reducer';
import {AppLoader} from './AppLoader';
import {AppError, AppErrorProps} from './AppError';

export default function AppRequest<Data>({
  children,
  request,
  ...errorProps
}: {
  children?: ReactElement;
  request: RequestState<Data>;
} & Omit<AppErrorProps, 'error'>) {
  if (request.isLoading) {
    return <AppLoader />;
  }

  if (request.error) {
    return <AppError {...errorProps} error={request.error.message} />;
  }

  return children || null;
}
