export interface UserProfileResponse {
  user: ShortUserProfile;
  wallets: UserWallet[];
  bankDetails?: BankDetail;
}

export interface ShortUserProfile {
  phone: string;
  email: string;
  firstName: string;
  lastName: string;
  seiFurigana: string;
  meiFurigana: string;
  prefectures: string;
  municipalities: string;
  streetAddress: string;
  buildingName: string;
  companyName: string;
  postCode: string;
  country: string;
  city: string;
}

export interface UserWallet {
  amount: number;
  currency: CurrencyTypes;
}

export interface BankDetail {
  bankName?: string;
  bankCode?: string;
  branchName?: string;
  branchCode?: string;
  accountName?: string;
  accountNumber?: string;
}

export enum CurrencyTypes {
  CP = 'CP',
  SP = 'SP',
}
