import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {RequestCategory} from '../../../api/request/models/responses/categories';
import {RequestCategoryType} from '../../../api/request/models/responses/category-type';
import {RequestCreateRequest} from '../../../api/request/models/requests/create';
import {RequestResponse} from '../../../api/request/models/responses';

export const getRequestCategoriesRequestAction = createAsyncAction<
  void,
  RequestCategory[],
  Error
>('GET_REQUEST_CATEGORIES_REQUEST');

export const getRequestCategoryTypeRequestAction = createAsyncAction<
  string,
  RequestCategoryType,
  Error
>('GET_REQUEST_CATEGORY_TYPE_REQUEST');

export const createRequestRequestAction = createAsyncAction<
  RequestCreateRequest,
  RequestResponse,
  Error
>('CREATE_REQUEST_REQUEST');
