import {all, call, put, takeLatest} from 'redux-saga/effects';
import {
  logoutRequestAction,
  getUserProfileRequestAction,
  setJWTUserAction,
  userRequestAction,
  updateUserProfileRequestAction,
  deleteAccountRequestAction,
} from './action';
import api from '../../../api';
import {getRefreshToken, saveSettings, saveTokens} from '../../../storage';
import {DefaultResponse} from '../../../api/models/response/default';
import {User} from '../../../api/user/models/responses/user';
import {LogoutRequest} from '../../../api/user/models/requests/logout';
import {UserProfileResponse} from '../../../api/user/models/responses/user-profile';
import {appSelect} from '../../../store/saga';
import {JwtUser} from '../../../api/user/models/responses/jwt-user';
import {Strings} from '../../../resources/strings';

const userRequest = function* (
  action: ReturnType<typeof userRequestAction.request>,
) {
  try {
    const strings: Strings | null = yield appSelect(store => store.app.strings);
    const user: JwtUser | null = yield appSelect(state => state.user.jwtUser);
    if (!user) throw new Error(strings?.app_error_no_user);

    const response: DefaultResponse<User> = yield call(
      [api, api.user.getUser],
      String(user.id),
    );
    yield put(userRequestAction.success(response.data));
  } catch (error) {
    yield put(userRequestAction.failure(error as Error));
  }
};

const getUserProfileRequest = function* (
  action: ReturnType<typeof getUserProfileRequestAction.request>,
) {
  try {
    const strings: Strings | null = yield appSelect(store => store.app.strings);
    const user: JwtUser | null = yield appSelect(state => state.user.jwtUser);
    if (!user) throw new Error(strings?.app_error_no_user);

    const response: DefaultResponse<UserProfileResponse> = yield call(
      [api, api.user.getUserProfile],
      String(user.id),
    );
    yield put(getUserProfileRequestAction.success(response.data));
  } catch (error) {
    yield put(getUserProfileRequestAction.failure(error as Error));
  }
};

const updateUserProfileRequest = function* (
  action: ReturnType<typeof updateUserProfileRequestAction.request>,
) {
  try {
    const strings: Strings | null = yield appSelect(store => store.app.strings);
    const user: JwtUser | null = yield appSelect(state => state.user.jwtUser);
    if (!user) throw new Error(strings?.app_error_no_user);

    const response: DefaultResponse<UserProfileResponse> = yield call(
      [api, api.user.updateUserProfile],
      String(user.id),
      action.payload,
    );
    yield put(updateUserProfileRequestAction.success(response.data));
  } catch (error) {
    yield put(updateUserProfileRequestAction.failure(error as Error));
  }
};

const deleteAccountRequest = function* (
  action: ReturnType<typeof deleteAccountRequestAction.request>,
) {
  try {
    const user: JwtUser | null = yield appSelect(state => state.user.jwtUser);

    if (user) {
      yield call([api, api.user.deleteUserAccount], String(user.id));
    }
    yield call(saveTokens, null, null);
    yield call(saveSettings, null);
    yield put(setJWTUserAction(null));

    yield put(deleteAccountRequestAction.success());
  } catch (error) {
    yield put(deleteAccountRequestAction.failure(error as Error));
  }
};

const logoutRequest = function* (
  action: ReturnType<typeof logoutRequestAction.request>,
) {
  try {
    const refreshToken = yield call(getRefreshToken);
    yield call(saveTokens, null, null);
    yield call(saveSettings, null);
    yield put(setJWTUserAction(null));

    if (refreshToken !== null && refreshToken !== undefined) {
      const request: LogoutRequest = {
        refreshToken,
      };
      yield call([api, api.user.logout], request);
    }

    yield put(logoutRequestAction.success());
  } catch (error) {
    yield put(logoutRequestAction.failure(error as Error));
  }
};

export const userSaga = function* () {
  yield all([
    takeLatest(userRequestAction.request, userRequest),
    takeLatest(getUserProfileRequestAction.request, getUserProfileRequest),
    takeLatest(
      updateUserProfileRequestAction.request,
      updateUserProfileRequest,
    ),
    takeLatest(deleteAccountRequestAction.request, deleteAccountRequest),
    takeLatest(logoutRequestAction.request, logoutRequest),
  ]);
};
