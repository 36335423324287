import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from 'react-native';
import AppHeader from '../../../../uikit/AppHeader';
import Star from '../../../../assets/icons/Star';
import StarOutline from '../../../../assets/icons/StarOutline';
import {AppButton} from '../../../../uikit/AppButtons';
import {AppInput} from '../../../../uikit/AppInput';
import AppKeyboardAvoidingView from '../../../../uikit/AppKeyboardAvoidingView';

export const ShopReviewsViewedContainerContent = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 128px;
    `}
`;

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  padding: 0 16px;
  margin-bottom: 20px;
`;

export const AddReviewFormContainer = styled.View`
  margin-left: 16px;
  margin-right: 16px;
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex: none;
      background: ${props.theme.colors.white};
      border: 1px solid #cec5bd;
      padding: 24px 24px 16px 24px;
      margin-left: 18px;
      margin-right: 0;
      border-radius: 4px;
    `}
`;

export const AddReviewFieldContainer = styled.View`
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 12px;
`;

export const AddReviewTextField = styled.Text`
  display: none;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  margin-bottom: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      display: flex;
    `}
`;

export const ShopStarContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const ShopStarButton = styled.TouchableOpacity`
  margin-right: 8px;
`;

export const ShopStarIcon = styled(Star)`
  max-width: 40px;
  max-height: 40px;
`;

export const ShopStarOutlineIcon = styled(StarOutline)`
  max-width: 40px;
  max-height: 40px;
`;

export const AddReviewInputWrapper = styled.View<{
  isLastItem?: boolean;
}>`
  margin-bottom: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-bottom: ${props.isLastItem ? '0' : '30px'};
    `}
`;
export const AddReviewInput = styled(AppInput)`
  min-width: 100%;
  margin-bottom: 4px;
`;

export const AddReviewTextarea = styled(AddReviewInput).attrs({
  textStyle: {minHeight: 96, textAlignVertical: 'top'},
})``;

export const AddReviewInputError = styled(Text).attrs({
  status: 'danger',
})`
  font-size: 14px;
  margin-top: 6px;
  color: ${props => props.theme.colors['color-danger-500']};
`;

export const AddReviewSymbolsCounterWrapper = styled.View`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
`;

export const AddReviewSymbolsCounter = styled.Text<{limit?: boolean}>`
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${props =>
    props.limit
      ? props.theme.colors['color-danger-500']
      : props.theme.colors['color-basic-800']};
`;

export const AddReviewBuyKeyboardAvoidingView = styled(AppKeyboardAvoidingView)`
  flex: 1;
`;

export const AddReviewButton = styled(AppButton)`
  margin-bottom: 36px;
  margin-left: 60px;
  margin-right: 60px;
  max-width: ${props => (props.theme.isDesktop ? '160px' : '100%')};
  width: ${props => (props.theme.isDesktop ? '100%' : 'auto')};

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: flex-end;
      margin-top: ${props.theme.dimens.bigSpacer};
      margin-bottom: ${props.theme.dimens.smallSpacer};
      margin-left: 0;
      margin-right: 0;
    `}
`;
