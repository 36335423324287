import React, {useCallback, useEffect} from 'react';
import {AppLayout} from '../../uikit/AppLayout';
import AppStatusBar from '../../uikit/AppStatusBar';
import {
  RestoreButton,
  RestoreContainer,
  RestoreContainerOutside,
  RestoreContainerView,
  RestoreContentView,
  RestoreCountdown,
  RestoreHeader,
  RestoreInfo,
  RestoreInput,
  RestoreInputErrorText,
} from './styles';
import {useStrings} from '../../utils/providers/strings';
import {Controller, useForm} from 'react-hook-form';
import {useAppDispatch, useRequestSelector} from '../../store/store';
import {restoreRequestAction} from './store/action';
import useCountdown from 'scl/src/hooks/use-countdown';
import {useTheme} from 'styled-components/native';
import useFieldServerError from '../../utils/react-hook-form/use-field-server-error';

interface RestoreForm {
  email: string;
}

export default function RestoreScreen() {
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const restoreRequest = useRequestSelector(
    state => state.restore.restoreRequest,
  );

  const {
    control,
    handleSubmit,
    formState: {isValid},
    setError,
  } = useForm<RestoreForm>({
    defaultValues: {
      email: '',
    },
  });

  const countdownInterval = 60 * 1000;

  const {countdown, startCountdown, resetCountdown} = useCountdown(
    1000,
    countdownInterval,
  );

  const isCountdownEnd = countdown === 0;
  const isCountdownTicking = countdown < countdownInterval && !isCountdownEnd;

  const countdownMinutes = Math.floor(countdown / 60000);
  const countdownSeconds = (countdown % 60000) / 1000;

  useEffect(() => {
    return function () {
      dispatch(restoreRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (restoreRequest.data) {
      resetCountdown();
      startCountdown();
    }
  }, [resetCountdown, restoreRequest.data, startCountdown]);

  useFieldServerError(restoreRequest, setError);

  const onRestore = useCallback(
    (form: RestoreForm) => {
      dispatch(restoreRequestAction.request(form));
    },
    [dispatch],
  );

  return (
    <>
      <AppStatusBar />
      <AppLayout>
        <RestoreContainerOutside>
          <RestoreContainer>
            {!theme.isDesktop && (
              <RestoreHeader
                key="title"
                title={strings.restore_title}
                backNoHistoryRoute="/login"
              />
            )}
            <RestoreContainerView>
              <RestoreContentView>
                {theme.isDesktop && (
                  <RestoreHeader
                    key="title"
                    title={strings.restore_title}
                    backNoHistoryRoute="/login"
                  />
                )}
                <Controller
                  control={control}
                  rules={{
                    required: strings.restore_input_required,
                  }}
                  render={({
                    field: {onChange, onBlur, value},
                    fieldState: {error},
                  }) => (
                    <>
                      <RestoreInput
                        status={error ? 'danger' : 'basic'}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        placeholder={strings.restore_input_placeholder}
                        disabled={restoreRequest.isLoading}
                      />
                      {error && (
                        <RestoreInputErrorText>
                          {error.message}
                        </RestoreInputErrorText>
                      )}
                    </>
                  )}
                  name="email"
                />
                <RestoreInfo>{strings.restore_info}</RestoreInfo>
                <RestoreButton
                  disabled={
                    !isValid || isCountdownTicking || restoreRequest.isLoading
                  }
                  onPress={handleSubmit(onRestore)}>
                  {strings.restore_button}
                </RestoreButton>
                {isCountdownTicking && (
                  <RestoreCountdown>
                    {strings.restore_timeout_fn(
                      countdownMinutes,
                      countdownSeconds,
                    )}
                  </RestoreCountdown>
                )}
              </RestoreContentView>
            </RestoreContainerView>
          </RestoreContainer>
        </RestoreContainerOutside>
      </AppLayout>
    </>
  );
}
