import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import ArrowBack from '../../../../assets/icons/ArrowBack';
import {AppLayout} from '../../../../uikit/AppLayout';
import {AppButton} from '../../../../uikit/AppButtons';

export const DetailRequestLayout = styled(AppLayout)`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding-bottom: 40px;
    `}
`;

export const DetailRequestTitleContainer = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-bottom: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-direction: row;
      align-items: center;
      margin-top: 48px;
      margin-left: 40px;
      margin-bottom: ${props.theme.dimens.bigSpacer};
    `}
`;

export const DetailRequestTitleBackButtonContainer = styled.TouchableOpacity``;

export const DetailRequestTitleBackButtonIcon = styled(ArrowBack)``;

export const DetailRequestTitleText = styled(Text).attrs(props => ({
  category: props.theme.isDesktop ? 'h4' : 'h5',
}))`
  margin-top: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-left: ${props.theme.dimens.smallSpacer};
      margin-top: 0;
    `}
`;

export const DetailRequestDesktopContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 80px;
`;

export const DetailRequestDesktopChatContainer = styled.View`
  flex: 1;
  margin-left: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors['color-basic-400']};
  border-radius: 4px;
`;

export const DetailRequestChatButtonMobileContainer = styled.View`
  display: flex;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;

export const DetailRequestChatButtonMobile = styled(AppButton)``;
