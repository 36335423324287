import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {BottomTabBar} from '@react-navigation/bottom-tabs';

export const MainNavigatorContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors['color-basic-100']};
`;

export const MainNavigatorBottomNavigation = styled(BottomTabBar).attrs({
  appearance: 'noIndicator',
})`
  background-color: ${props => props.theme.colors.white};
  border-top-width: 1px;
  border-top-color: ${props => props.theme.colors['color-basic-400']};
`;
