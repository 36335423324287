import {useMemo} from 'react';
import {SettingId} from '../api/settings/models/settingsResponse';
import {useRequestSelector} from '../store/store';

export default function useMinimalAmount(
  settingId: SettingId,
  amount = 0,
): number {
  const settingsRequest = useRequestSelector(
    store => store.app.settingsRequest,
  );

  return useMemo(() => {
    try {
      return Number(
        settingsRequest.data?.find(({id}) => id === settingId)?.value || amount,
      );
    } catch (e) {
      return amount;
    }
  }, [amount, settingId, settingsRequest.data]);
}
