import React from 'react';
import styled, {useTheme} from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList} from 'react-native';
import {Text} from '@ui-kitten/components';
import {RequestCategorySubtype} from '../../../../../api/request/models/responses/categories';
import {useStrings} from '../../../../../utils/providers/strings';
import {AppButton} from '../../../../../uikit/AppButtons';

export const NewRequestSubcategoriesRoutes = styled(
  FlatList<RequestCategorySubtype>,
)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  flex-grow: 0;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;

const CommonNewRequestSubcategoriesRouteItemContainer = css`
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.dimens.normalSpacer};
  margin-top: ${props => props.theme.dimens.smallSpacer};
  border-bottom-color: ${props => props.theme.colors['color-basic-400']};
  border-bottom-width: 1px;
`;

const NewRequestSubcategoriesRouteItemContainerMobile = styled.TouchableOpacity`
  ${CommonNewRequestSubcategoriesRouteItemContainer};
`;

const NewRequestSubcategoriesRouteItemContainerDesktop = styled.View`
  ${CommonNewRequestSubcategoriesRouteItemContainer};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NewRequestSubcategoriesRouteItemName = styled(Text)``;

const NewRequestSubcategoriesRouteItemButton = styled(AppButton)``;

export const NewRequestSubcategoriesRouteItem = ({
  name,
  onPress,
}: {
  name: string;
  onPress: () => void;
}) => {
  const theme = useTheme();
  const strings = useStrings();

  if (theme.isDesktop) {
    return (
      <NewRequestSubcategoriesRouteItemContainerDesktop>
        <NewRequestSubcategoriesRouteItemName>
          {name}
        </NewRequestSubcategoriesRouteItemName>
        <NewRequestSubcategoriesRouteItemButton onPress={onPress}>
          {strings.new_request_subcategories_choose_button.toUpperCase()}
        </NewRequestSubcategoriesRouteItemButton>
      </NewRequestSubcategoriesRouteItemContainerDesktop>
    );
  } else {
    return (
      <NewRequestSubcategoriesRouteItemContainerMobile onPress={onPress}>
        <NewRequestSubcategoriesRouteItemName>
          {name}
        </NewRequestSubcategoriesRouteItemName>
      </NewRequestSubcategoriesRouteItemContainerMobile>
    );
  }
};

export const NewRequestSubcategoriesRouteHeaderText = styled(Text).attrs({
  category: 'h6',
})`
  padding: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      border-bottom-color: ${props.theme.colors['color-basic-400']};
      border-bottom-width: 1px;
    `}
`;
