import React, {ReactNode} from 'react';

import {
  InfoListContainer,
  InfoListItemWrapper,
  InfoListItemText,
  InfoListItemTitle,
} from './styles';

/** Info list single row config */
export interface InfoListItem {
  title: string;
  value: ReactNode;
}

/** Info list rows config */
export type InfoListRows = InfoListItem[];

type Props = {
  rows: InfoListRows;
};

export const InfoListItem = ({title, value}: InfoListItem) => (
  <InfoListItemWrapper key={title}>
    <InfoListItemTitle>{title}</InfoListItemTitle>
    {typeof value === 'string' ? (
      <InfoListItemText>{value}</InfoListItemText>
    ) : (
      value
    )}
  </InfoListItemWrapper>
);

/** Component for displaying list with information */
export const InfoList = ({rows}: Props) => {
  return (
    <InfoListContainer>
      {rows.map(row => (
        <InfoListItem key={row.title} {...row} />
      ))}
    </InfoListContainer>
  );
};
