import {useState, useEffect} from 'react';
import {RecordRTCPromisesHandler} from 'recordrtc';

export const useWebRecorderPermission = () => {
  const [recorder, setRecorder] = useState<any>();

  useEffect(() => {
    getPermissionInitializeRecorder();
  }, []);

  const getPermissionInitializeRecorder = async () => {
    const stream = await global.navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });

    const streamRecorder = new RecordRTCPromisesHandler(stream, {
      type: 'audio',
      mimeType: 'audio/webm',
    });
    setRecorder(streamRecorder);
  };

  return recorder;
};
