import React from 'react';
import {Control, Controller, UseFormWatch} from 'react-hook-form';

import type {ContactUsForm as ContactUsFormValues} from '../../lib/consts';
import {useStrings} from '../../../../utils/providers/strings';

import {
  ContactUsFormInputs,
  ContactUsFormWrapper,
  ContactUsInput,
  ContactUsInputError,
  ContactUsInputWrapper,
  ContactUsSymbolsCounter,
  ContactUsSymbolsCounterWrapper,
  ContactUsTextarea,
} from './styles';

type Props = {
  isLoading: boolean;
  control: Control<ContactUsFormValues>;
  watch: UseFormWatch<ContactUsFormValues>;
};

const TEXT_CHARACTER_LIMIT = 480;

const ContactUsForm = ({isLoading, control, watch}: Props) => {
  const strings = useStrings();

  return (
    <ContactUsFormWrapper loading={isLoading}>
      <ContactUsFormInputs>
        <Controller
          control={control}
          name="userName"
          rules={{required: strings.contact_us_form_input_required}}
          render={({field: {onChange, ...field}, fieldState: {error}}) => {
            return (
              <ContactUsInputWrapper>
                <ContactUsInput
                  {...field}
                  onChangeText={onChange}
                  label={strings.contact_us_form_input_name}
                  placeholder={strings.contact_us_form_input_name_placeholder}
                />
                {error?.message && (
                  <ContactUsInputError>{error?.message}</ContactUsInputError>
                )}
              </ContactUsInputWrapper>
            );
          }}
        />
        <Controller
          control={control}
          name="phone"
          rules={{required: strings.contact_us_form_input_required}}
          render={({field: {onChange, ...field}, fieldState: {error}}) => (
            <ContactUsInputWrapper half>
              <ContactUsInput
                {...field}
                onChangeText={onChange}
                label={strings.contact_us_form_input_phone_name}
                placeholder={strings.contact_us_form_input_phone_placeholder}
                keyboardType="numeric"
              />
              {error?.message && (
                <ContactUsInputError>{error?.message}</ContactUsInputError>
              )}
            </ContactUsInputWrapper>
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={{required: strings.contact_us_form_input_required}}
          render={({field: {onChange, ...field}, fieldState: {error}}) => (
            <ContactUsInputWrapper half>
              <ContactUsInput
                {...field}
                onChangeText={onChange}
                label={strings.contact_us_form_input_email_name}
                placeholder={strings.contact_us_form_input_email_placeholder}
              />
              {error?.message && (
                <ContactUsInputError>{error?.message}</ContactUsInputError>
              )}
            </ContactUsInputWrapper>
          )}
        />
        <Controller
          control={control}
          name="inputText"
          rules={{required: strings.contact_us_form_input_required}}
          render={({field: {onChange, ...field}, fieldState: {error}}) => (
            <ContactUsInputWrapper>
              <ContactUsTextarea
                {...field}
                onChangeText={onChange}
                label={strings.contact_us_form_input_text_name}
                placeholder={strings.contact_us_form_input_text_placeholder}
                maxLength={TEXT_CHARACTER_LIMIT}
                multiline
                numberOfLines={5}
              />
              {error?.message && (
                <ContactUsInputError>{error?.message}</ContactUsInputError>
              )}
            </ContactUsInputWrapper>
          )}
        />
      </ContactUsFormInputs>
      <ContactUsSymbolsCounterWrapper>
        <ContactUsSymbolsCounter
          limit={Boolean(watch('inputText')?.length >= TEXT_CHARACTER_LIMIT)}>
          {watch('inputText')?.length}/{TEXT_CHARACTER_LIMIT}
        </ContactUsSymbolsCounter>
      </ContactUsSymbolsCounterWrapper>
    </ContactUsFormWrapper>
  );
};

export {ContactUsForm};
