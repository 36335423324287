import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Button} from '@ui-kitten/components';
import RouteLink from './RouteLink';

export const CommonAppButton = css`
  color: ${props => props.theme.colors['color-basic-900']};
`;

export const AppButton = styled(Button)<{
  formType?: 'roundBrick' | 'brick' | 'brickRound';
}>`
  ${CommonAppButton};
  ${props =>
    props.formType === 'roundBrick' &&
    css`
      border-radius: 0;
      border-bottom-left-radius: 4px !important;
      border-top-left-radius: 4px !important;
    `}
  ${props =>
    props.formType === 'brick' &&
    css`
      border-radius: 0;
    `}
  ${props =>
    props.formType === 'brickRound' &&
    css`
      border-radius: 0;
      border-bottom-right-radius: 4px !important;
      border-top-right-radius: 4px !important;
    `}
`;

const CommonOutlineButton = css`
  color: ${props => props.theme.colors['color-basic-900']};
`;

export const OutlineButton = styled(Button).attrs({
  appearance: 'outline',
})`
  ${CommonOutlineButton};
`;

const CommonGhostButton = css`
  color: ${props => props.theme.colors['color-danger-500']};
`;

export const GhostButton = styled(Button).attrs({
  appearance: 'ghost',
})`
  ${CommonGhostButton};
`;

const CommonAuxiliaryButton = css`
  background-color: ${props => props.theme.colors['color-primary-500']}3D;
`;

export const AuxiliaryButton = styled(Button)`
  ${CommonAuxiliaryButton};
`;

export const AppLink = styled(RouteLink)`
  ${CommonAppButton};
`;

export const OutlineLink = styled(RouteLink).attrs({
  appearance: 'outline',
})`
  ${CommonOutlineButton};
`;

export const GhostLink = styled(RouteLink).attrs({
  appearance: 'ghost',
})`
  ${CommonGhostButton};
`;

export const AuxiliaryLink = styled(RouteLink)`
  ${CommonAuxiliaryButton};
`;

export type AppButtonProps = React.ComponentProps<typeof AppButton>;
