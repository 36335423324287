import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {StandardPointsWithdrawalResponse} from '../../../api/standard-points/models/responses/withdrawal';
import {
  exchangePointsRequestAction,
  getExchangeListRequestAction,
  getWithdrawalListRequestAction,
  updateWithdrawalPointsRequestAction,
  withdrawalPointsRequestAction,
} from './action';
import {
  addPaginationRequestCase,
  PaginationRequest,
  PaginationRequestState,
} from '../../../utils/redux/create-pagination-request-reducer';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ListResponse} from '../../../api/models/response/list';
import {Exchange} from '../../../api/standard-points/models/responses/exchange';

export interface WithdrawalList {
  items: StandardPointsWithdrawalResponse[];
  totalCount: number;
  isLastPage: boolean;
}

export interface ExchangeList {
  items: Exchange[];
  totalCount: number;
  isLastPage: boolean;
}

export const standardPointsReducer = combineReducers<{
  withdrawalPointsRequest: RequestState<StandardPointsWithdrawalResponse> | null;
  withdrawalListRequest: PaginationRequestState<WithdrawalList> | null;
  updateWithdrawalPointsRequest: RequestState<StandardPointsWithdrawalResponse> | null;
  exchangePointsRequest: RequestState<Exchange> | null;
  exchangeListRequest: PaginationRequestState<ExchangeList> | null;
}>({
  withdrawalPointsRequest: createRequestReducer(withdrawalPointsRequestAction),
  withdrawalListRequest:
    createReducer<PaginationRequestState<WithdrawalList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<StandardPointsWithdrawalResponse>],
          WithdrawalList
        >(builder, getWithdrawalListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });

        builder.addCase(
          withdrawalPointsRequestAction.success,
          (
            state: PaginationRequestState<WithdrawalList> | null,
            action: PayloadAction<StandardPointsWithdrawalResponse>,
          ) => {
            if (!state || !state.data) return state;

            return {
              ...state,
              data: {
                ...state.data,
                items: [action.payload, ...state.data.items],
                totalCount: state.data.totalCount + 1,
              },
            };
          },
        );
      },
    ),
  updateWithdrawalPointsRequest: createRequestReducer(
    updateWithdrawalPointsRequestAction,
  ),
  exchangePointsRequest: createRequestReducer(exchangePointsRequestAction),
  exchangeListRequest:
    createReducer<PaginationRequestState<ExchangeList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<Exchange>],
          ExchangeList
        >(builder, getExchangeListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });

        builder.addCase(
          exchangePointsRequestAction.success,
          (
            state: PaginationRequestState<ExchangeList> | null,
            action: PayloadAction<Exchange>,
          ) => {
            if (!state || !state.data) return state;

            return {
              ...state,
              data: {
                ...state.data,
                items: [action.payload, ...state.data.items],
                totalCount: state.data.totalCount + 1,
              },
            };
          },
        );
      },
    ),
});
