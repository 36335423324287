import {ShortUser} from '../../../user/models/responses/short-user';

interface messageAttachment {
  attachmentUrl: string;
  meta: {
    byteSize: number;
    mimeType: string; // TODO: Need change to enum
    originalName: string;
  };
}

export type RequestChatMessage =
  | RequestChatMessageNormal
  | RequestChatMessageSystem
  | RequestChatMessageTransferRequest;

export interface RequestChatMessageInfo {
  id: number;
  author: ShortUser;
  messageText: string;
  dateTime: string;
  messageContentType: RequestChatMessageContentType;
  attachments: messageAttachment[];
}

export interface RequestChatMessageNormal extends RequestChatMessageInfo {
  type: RequestChatMessageType.NORMAL;
}

export interface RequestChatMessageSystem extends RequestChatMessageInfo {
  type: RequestChatMessageType.SYSTEM;
}

export interface RequestChatMessageTransferRequest
  extends RequestChatMessageInfo {
  type: RequestChatMessageType.TRANSFER_REQUEST;
  extraData: {
    transferRequestId: number;
  };
}

export enum RequestChatMessageType {
  NORMAL = 'PLAIN',
  TRANSFER_REQUEST = 'TRANSFER_REQUEST',
  SYSTEM = 'SYSTEM',
}

export enum RequestChatMessageContentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
}
