import styled from 'styled-components/native';

export const CollapseContainer = styled.View`
  position: relative;
  overflow: hidden;
  background-color: white;
  max-width: 100%;

  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;

export const CollapseHeader = styled.TouchableOpacity<{open: boolean}>`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: ${({open, theme}) =>
    open ? theme.colors['color-primary-transparent-300'] : 'white'};
`;

export const CollapseTitle = styled.Text`
  flex: 1;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors['color-basic-900']};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const CollapseBody = styled.View`
  padding: 16px 16px 20px;
`;

export const CollapseBodyText = styled.Text`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors['color-basic-800']};
`;
