import React from 'react';
import {ChildrenWithProps} from '@ui-kitten/components/devsupport';
import {AppButtonProps} from '../AppButtons';

import {AppButtonGroupContainer, AppButtonGroupWrapper} from './styles';
type Props = {
  children: ChildrenWithProps<AppButtonProps>;
};

const AppButtonGroup = ({children}: Props) => {
  return (
    <AppButtonGroupWrapper>
      <AppButtonGroupContainer>
        {Array.isArray(children)
          ? children.map((child, index) =>
              React.cloneElement(child, {
                formType:
                  index === 0
                    ? 'roundBrick'
                    : index === children.length - 1
                    ? 'brickRound'
                    : 'brick',
                key: index,
              }),
            )
          : null}
      </AppButtonGroupContainer>
    </AppButtonGroupWrapper>
  );
};

export {AppButtonGroup};
