import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {
  AppSelect,
  AppSelectItem,
} from '../../../../../../../../uikit/AppSelect';

export const NewRequestQuestionFieldSingleSelectionSelect = styled(AppSelect)``;
export const NewRequestQuestionFieldSingleSelectionSelectItem = styled(
  AppSelectItem,
)``;
