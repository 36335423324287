import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useRequestSelector} from '../../store/store';
import {useStrings} from '../../utils/providers/strings';
import {
  DeleteModalButtonsContainer,
  DeleteModalCancel,
  DeleteModalContent,
  DeleteModalDelete,
  DeleteModalTitle,
  EditModalTitle,
  ProfileAutocomplete,
  ProfileAutocompleteItem,
  ProfileContainer,
  ProfileDeleteButton,
  ProfileEditButton,
  ProfileInput as ProfileInputComponent,
  ProfileInputErrorText,
  ProfileInputGroupTitle,
  ProfileInputTitle,
  ProfileSaveButton,
  ProfileSaveSpinner,
  ProfileSaveSpinnerContainer,
} from './styles';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {
  deleteAccountRequestAction,
  getUserProfileRequestAction,
  updateUserProfileRequestAction,
} from '../user/store/action';
import AppHeader from '../../uikit/AppHeader';
import {ScreenLayoutWithHeader} from '../../uikit/ScreenLayout';
import {AppLoader} from '../../uikit/AppLoader';
import {AppError} from '../../uikit/AppError';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../uikit/Points/container';
import {Controller, useForm, Control, FieldPath} from 'react-hook-form';
import {
  UpdateProfileBankDetails,
  UpdateProfileRequest,
  UpdateProfileUserData,
} from '../../api/user/models/requests/update-profile';
import {FieldServerError} from '../../api/models/field-server-error';
import {useToast} from 'react-native-toast-notifications';
import {useTheme} from 'styled-components/native';
import {BackHandler} from 'react-native';
import useRouter from '../../utils/use-router';
import getChangedObject from 'scl/src/object/get-changed-object';
import AppModal from '../../uikit/AppModal';

export interface ProfileForm {
  userData: Omit<UpdateProfileUserData, 'country'> & {
    countryCode: {id: string; value: string} | undefined;
  };
  bankDetails: UpdateProfileBankDetails;
}

export default function ProfileScreen() {
  const router = useRouter();
  const toast = useToast();
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const profileRequest = useRequestSelector(
    store => store.user.getUserProfileRequest,
  );
  const updateProfileRequest = useRequestSelector(
    store => store.user.updateUserProfileRequest,
  );
  const deleteAccountRequest = useRequestSelector(
    store => store.user.deleteAccountRequest,
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {control, handleSubmit, formState, setValue, setError, clearErrors} =
    useForm<ProfileForm>();

  const extraData = useMemo<{id: string; value: string}[]>(
    () =>
      strings.countryData.map(
        ({value, label}: {value: string; label: string}) => ({
          id: value,
          value: label,
        }),
      ),
    [strings.countryData],
  );

  const [countryQuery, setCountryQuery] = useState(
    extraData.find(country => country.id === profileRequest.data?.user?.country)
      ?.value || '',
  );

  const filteredExtraData = useMemo(
    () =>
      extraData.filter(({value}) =>
        value.toLowerCase().includes(countryQuery.toLowerCase()),
      ),
    [extraData, countryQuery],
  );

  useEffect(() => {
    dispatch(updateUserProfileRequestAction.clean());
  }, [dispatch]);

  const onBack = useCallback((): boolean => {
    if (isEdit) {
      clearErrors();
      setIsEdit(false);
      return true;
    }

    router.back('/');
    return true;
  }, [isEdit, router]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBack,
    );

    return () => backHandler.remove();
  }, [onBack]);

  useEffect(() => {
    if (profileRequest.data || !isEdit) {
      const countryCode = extraData.find(
        country => country.id === profileRequest.data?.user?.country,
      );
      setValue('userData', {
        phone: profileRequest.data?.user?.phone,
        email: profileRequest.data?.user?.email,
        firstName: profileRequest.data?.user?.firstName,
        lastName: profileRequest.data?.user?.lastName,
        prefectures: profileRequest.data?.user?.prefectures,
        municipalities: profileRequest.data?.user?.municipalities,
        streetAddress: profileRequest.data?.user?.streetAddress,
        buildingName: profileRequest.data?.user?.buildingName,
        companyName: profileRequest.data?.user?.companyName,
        postCode: profileRequest.data?.user?.postCode,
        countryCode,
        city: profileRequest.data?.user?.city,
      });
      setValue('bankDetails', {
        bankName: profileRequest.data?.bankDetails?.bankName,
        bankCode: profileRequest.data?.bankDetails?.bankCode,
        branchName: profileRequest.data?.bankDetails?.branchName,
        branchCode: profileRequest.data?.bankDetails?.branchCode,
        accountName: profileRequest.data?.bankDetails?.accountName,
        accountNumber: profileRequest.data?.bankDetails?.accountNumber,
      });
      setCountryQuery(countryCode?.value || '');
    }
  }, [profileRequest.data, setValue, isEdit, extraData]);

  const onProfileRequest = useCallback(() => {
    dispatch(getUserProfileRequestAction.request());
  }, [dispatch]);

  useEffect(() => {
    if (
      !profileRequest.data &&
      !profileRequest.error &&
      !profileRequest.isLoading
    ) {
      onProfileRequest();
    }
  }, [onProfileRequest, profileRequest]);

  useEffect(() => {
    if (!updateProfileRequest.error) return;

    if (updateProfileRequest.error instanceof FieldServerError) {
      const errorFields = (updateProfileRequest.error as FieldServerError)
        .fields;

      if (errorFields.length) {
        errorFields.forEach(errorField => {
          switch (
            errorField.field as keyof UpdateProfileUserData &
              keyof UpdateProfileBankDetails
          ) {
            //UpdateProfileUserData
            case 'phone':
              setError('userData.phone', {message: errorField.message});
              break;
            case 'email':
              setError('userData.email', {message: errorField.message});
              break;
            case 'firstName':
              setError('userData.firstName', {message: errorField.message});
              break;
            case 'lastName':
              setError('userData.lastName', {message: errorField.message});
              break;
            case 'prefectures':
              setError('userData.prefectures', {message: errorField.message});
              break;
            case 'municipalities':
              setError('userData.municipalities', {
                message: errorField.message,
              });
              break;
            case 'streetAddress':
              setError('userData.streetAddress', {
                message: errorField.message,
              });
              break;
            case 'buildingName':
              setError('userData.buildingName', {
                message: errorField.message,
              });
              break;
            case 'companyName':
              setError('userData.companyName', {message: errorField.message});
              break;
            case 'postCode':
              setError('userData.postCode', {message: errorField.message});
              break;
            case 'country':
              setError('userData.countryCode', {message: errorField.message});
              break;
            case 'city':
              setError('userData.city', {message: errorField.message});
              break;
            //UpdateProfileBankDetails
            case 'bankName':
              setError('bankDetails.bankName', {message: errorField.message});
              break;
            case 'bankCode':
              setError('bankDetails.bankCode', {message: errorField.message});
              break;
            case 'branchName':
              setError('bankDetails.branchName', {
                message: errorField.message,
              });
              break;
            case 'branchCode':
              setError('bankDetails.branchCode', {
                message: errorField.message,
              });
              break;
            case 'accountName':
              setError('bankDetails.accountName', {
                message: errorField.message,
              });
              break;
            case 'accountNumber':
              setError('bankDetails.accountNumber', {
                message: errorField.message,
              });
              break;
          }
        });
      } else {
        toast.show(updateProfileRequest.error.message);
      }
    } else {
      toast.show(updateProfileRequest.error.message);
    }
  }, [updateProfileRequest.error]);

  useEffect(() => {
    setIsEdit(false);
  }, [setIsEdit, updateProfileRequest.data]);

  const onChangeCountryQuery = (
    onChange: (state: {id: string; value: string} | undefined) => void,
  ) => {
    return function (query) {
      setCountryQuery(query);
      const findCountry = extraData.find(country => country.value === query);
      if (findCountry) {
        onChange(findCountry);
      } else {
        onChange(undefined);
      }
    };
  };

  const onSave = (request: ProfileForm) => {
    const serverRequest: UpdateProfileRequest = {
      ...request,
      userData: {
        ...request.userData,
        country: request?.userData?.countryCode?.id || '',
      },
    };

    const newRequest = getChangedObject(
      serverRequest ?? {},
      profileRequest.data
        ? {
            userData: profileRequest.data.user,
            bankDetails: profileRequest.data.bankDetails,
          }
        : {},
    );

    if (!Object.keys(newRequest).length) return;

    dispatch(updateUserProfileRequestAction.request(newRequest));

    if (profileRequest.error) {
      toast.show(profileRequest.error.message);
    }

    setIsEditModalOpen(false);
  };

  const onAccountDelete = () => {
    dispatch(deleteAccountRequestAction.request());

    if (deleteAccountRequest.error) {
      toast.show(deleteAccountRequest.error.message);
    }

    setIsDeleteModalOpen(false);
  };

  const onModalOpen = () => {
    setIsDeleteModalOpen(prevState => !prevState);
  };

  const onEditModalOpen = () => {
    setIsEditModalOpen(prevState => !prevState);
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.profile_title}
              onBackPressed={onBack}
              rightButton={
                updateProfileRequest.isLoading ? (
                  <ProfileSaveSpinnerContainer>
                    <ProfileSaveSpinner />
                  </ProfileSaveSpinnerContainer>
                ) : isEdit ? (
                  <ProfileSaveButton onPress={onEditModalOpen}>
                    {strings.profile_user_save_button.toUpperCase()}
                  </ProfileSaveButton>
                ) : (
                  <ProfileEditButton onPress={() => setIsEdit(true)}>
                    {strings.profile_user_edit_button.toUpperCase()}
                  </ProfileEditButton>
                )
              }
            />
            {profileRequest.data ? (
              <ProfileContainer>
                <ProfileInputGroupTitle>
                  {strings.profile_user_group_title}
                </ProfileInputGroupTitle>
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_phone_title}
                  placeholder={strings.profile_user_group_phone_placeholder}
                  name="userData.phone"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_email_title}
                  placeholder={strings.profile_user_group_email_placeholder}
                  name="userData.email"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_last_name_title}
                  placeholder={strings.profile_user_group_last_name_placeholder}
                  name="userData.lastName"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_first_name_title}
                  placeholder={
                    strings.profile_user_group_first_name_placeholder
                  }
                  name="userData.firstName"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_post_code_title}
                  placeholder={strings.profile_user_group_post_code_placeholder}
                  name="userData.postCode"
                />
                <ProfileInputTitle>
                  {strings.profile_user_group_country_code_title}
                </ProfileInputTitle>
                <Controller
                  control={control}
                  render={({
                    field: {onChange, onBlur, value},
                    fieldState: {error},
                  }) => {
                    return (
                      <ProfileAutocomplete
                        disabled={!isEdit}
                        status={error ? 'danger' : 'basic'}
                        onBlur={onBlur}
                        value={countryQuery}
                        onChangeText={onChangeCountryQuery(onChange)}
                        onSelect={index => {
                          onChange(filteredExtraData[index]);
                          setCountryQuery(filteredExtraData[index].value);
                        }}
                        placeholder={
                          strings.profile_user_group_country_code_placeholder
                        }>
                        {filteredExtraData?.map(item => {
                          return (
                            <ProfileAutocompleteItem
                              key={item.id}
                              title={item.value}
                            />
                          );
                        })}
                      </ProfileAutocomplete>
                    );
                  }}
                  name="userData.countryCode"
                />
                {formState.errors.userData?.countryCode && (
                  <ProfileInputErrorText>
                    {formState.errors.userData?.countryCode?.message}
                  </ProfileInputErrorText>
                )}
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_city_title}
                  placeholder={strings.profile_user_group_city_placeholder}
                  name="userData.city"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_prefectures_title}
                  placeholder={
                    strings.profile_user_group_prefectures_placeholder
                  }
                  name="userData.prefectures"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_municipalities_title}
                  placeholder={
                    strings.profile_user_group_municipalities_placeholder
                  }
                  name="userData.municipalities"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_street_address_title}
                  placeholder={
                    strings.profile_user_group_street_address_placeholder
                  }
                  name="userData.streetAddress"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_building_name_title}
                  placeholder={
                    strings.profile_user_group_building_name_placeholder
                  }
                  name="userData.buildingName"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_user_group_company_name_title}
                  placeholder={
                    strings.profile_user_group_company_name_placeholder
                  }
                  name="userData.companyName"
                />

                <ProfileInputGroupTitle>
                  {strings.profile_bank_details_group_title}
                </ProfileInputGroupTitle>
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_bank_details_bank_name_title}
                  placeholder={
                    strings.profile_bank_details_bank_name_placeholder
                  }
                  name="bankDetails.bankName"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_bank_details_bank_code_title}
                  placeholder={
                    strings.profile_bank_details_bank_code_placeholder
                  }
                  name="bankDetails.bankCode"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_bank_details_branch_name_title}
                  placeholder={
                    strings.profile_bank_details_branch_name_placeholder
                  }
                  name="bankDetails.branchName"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_bank_details_branch_code_title}
                  placeholder={
                    strings.profile_bank_details_branch_code_placeholder
                  }
                  name="bankDetails.branchCode"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_bank_details_account_name_title}
                  placeholder={
                    strings.profile_bank_details_account_name_placeholder
                  }
                  name="bankDetails.accountName"
                />
                <ProfileInput
                  isEdit={isEdit}
                  control={control}
                  title={strings.profile_bank_details_account_number_title}
                  placeholder={
                    strings.profile_bank_details_account_number_placeholder
                  }
                  name="bankDetails.accountNumber"
                />
                <ProfileInputGroupTitle>
                  {strings.profile_danger_zone_title}
                </ProfileInputGroupTitle>
                <ProfileDeleteButton onPress={onModalOpen}>
                  {strings.profile_danger_zone_delete_button}
                </ProfileDeleteButton>
              </ProfileContainer>
            ) : profileRequest.isLoading ? (
              <AppLoader />
            ) : profileRequest.error ? (
              <AppError
                error={profileRequest.error.message}
                retry={onProfileRequest}
              />
            ) : null}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>

      <AppModal visible={isDeleteModalOpen} onBackdropPress={onModalOpen}>
        <DeleteModalTitle>
          {strings.profile_danger_zone_delete_modal_title}
        </DeleteModalTitle>
        <DeleteModalContent>
          {strings.profile_danger_zone_delete_modal_content}
        </DeleteModalContent>
        <DeleteModalButtonsContainer>
          <DeleteModalDelete onPress={onAccountDelete}>
            {strings.profile_danger_zone_delete_modal_delete_button}
          </DeleteModalDelete>
          <DeleteModalCancel onPress={onModalOpen}>
            {strings.profile_danger_zone_delete_modal_cancel_button}
          </DeleteModalCancel>
        </DeleteModalButtonsContainer>
      </AppModal>

      <AppModal visible={isEditModalOpen} onBackdropPress={onEditModalOpen}>
        <EditModalTitle>{strings.profile_user_edit_modal_title}</EditModalTitle>
        <DeleteModalButtonsContainer>
          <DeleteModalDelete onPress={handleSubmit(onSave)}>
            {strings.profile_user_confirm_button.toUpperCase()}
          </DeleteModalDelete>
          <DeleteModalCancel onPress={onEditModalOpen}>
            {strings.profile_user_cancel_button.toUpperCase()}
          </DeleteModalCancel>
        </DeleteModalButtonsContainer>
      </AppModal>
    </>
  );
}

function ProfileInput({
  isEdit,
  control,
  title,
  placeholder,
  name,
}: {
  isEdit: boolean;
  control: Control<ProfileForm>;
  title: string;
  placeholder: string;
  name: FieldPath<ProfileForm>;
}) {
  const strings = useStrings();

  return (
    <>
      <ProfileInputTitle>{title}</ProfileInputTitle>
      <Controller
        control={control}
        name={name}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          return (
            <>
              <ProfileInputComponent
                disabled={!isEdit}
                status={error ? 'danger' : 'basic'}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value as string}
                placeholder={placeholder}
              />
              {error?.message && (
                <ProfileInputErrorText>
                  {typeof error.message === 'string'
                    ? strings[error.message] || error.message
                    : strings.error}
                </ProfileInputErrorText>
              )}
            </>
          );
        }}
      />
    </>
  );
}
