import React, {useEffect} from 'react';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../../uikit/ScreenLayout';
import AppHeader from '../../../../uikit/AppHeader';
import {useTheme} from 'styled-components/native';
import {useStrings} from '../../../../utils/providers/strings';
import {
  ConciergePointsBuyErrorButton,
  ConciergePointsBuyErrorIcon,
  ConciergePointsBuySuccessButton,
  ConciergePointsBuySuccessContainer,
  ConciergePointsBuySuccessIcon,
  ConciergePointsBuySuccessMessage,
} from './styles';
import useRouter from '../../../../utils/use-router';
import {useAppDispatch, useRequestSelector} from '../../../../store/store';
import {getUserProfileRequestAction} from '../../../user/store/action';
import {buyPointsCybersourceRequestAction} from '../../store/action';

export default function ConciergePointsBuyByCybersourceSuccessScreen() {
  const theme = useTheme();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const isSuccess: boolean | null =
    useRequestSelector(
      store => store.conciergePoints.buyPointsByCybersourceRequest,
    )?.data?.success ?? null;

  useEffect(() => {
    return () => {
      dispatch(buyPointsCybersourceRequestAction.clean());
    };
  }, []);

  const onSuccessClick = () => {
    dispatch(getUserProfileRequestAction.request());
    router.push('/');
  };

  const onErrorClick = () => {
    router.back('/cp');
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={
                isSuccess
                  ? strings.concierge_points_buy_success_title
                  : strings.concierge_points_buy_error_title
              }
            />
            <ConciergePointsBuySuccessContainer>
              {isSuccess ? (
                <ConciergePointsBuySuccessIcon />
              ) : (
                <ConciergePointsBuyErrorIcon />
              )}
              <ConciergePointsBuySuccessMessage>
                {isSuccess
                  ? strings.concierge_points_buy_success_message
                  : strings.concierge_points_buy_error_message}
              </ConciergePointsBuySuccessMessage>
              {isSuccess ? (
                <ConciergePointsBuySuccessButton onPress={onSuccessClick}>
                  {strings.concierge_points_buy_success_button}
                </ConciergePointsBuySuccessButton>
              ) : (
                <ConciergePointsBuyErrorButton onPress={onErrorClick}>
                  {strings.concierge_points_buy_error_button}
                </ConciergePointsBuyErrorButton>
              )}
            </ConciergePointsBuySuccessContainer>
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
