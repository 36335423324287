import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {App} from 'components/src/app';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

reportWebVitals();
