import styled from 'styled-components';
import InvertedFlatList from 'scl/src/components/inverted-flat-list';
import {RequestChatMessage} from '../../../../../../api/request/models/responses/chat';
import {CommonDetailRequestChatList} from './index.common';

export * from './index.common';

export const DetailRequestChatList = styled(
  InvertedFlatList<RequestChatMessage>,
)`
  ${CommonDetailRequestChatList};
`;
