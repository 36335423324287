import styled from 'styled-components/native';
import {AppButton} from '../../../../../uikit/AppButtons';

export const ContactUsSuccessModalText = styled.Text`
  text-align: center;
  color: ${props => props.theme.colors['color-basic-900']};

  width: 100%;
  padding: 4px 20px 0;
  margin: 0 auto 20px;
`;

export const ContactUsSuccesModalButton = styled(AppButton)`
  width: 100%;
`;
