import React, {useEffect, useState} from 'react';
import {
  ShopSlide,
  ShopSlideBadgesContainer,
  ShopSlideContentContainer,
  ShopSlideImage,
  ShopSlideImageContainer,
  ShopSlideLocationBadge,
  ShopSlideTagBadge,
  ShopSlideTitle,
  ShopsSliderContainer,
  ShopsSliderDot,
  ShopsSliderDotsContainer,
} from './styles';
import {ViewPager} from '@ui-kitten/components';
import {useTheme} from 'styled-components/native';

export interface SliderCards {
  id: number;
  title: string;
  location: string;
  tag: string;
  images: {
    imageKey: string;
    imageUrl: string;
    description: string;
  }[];
}

interface IProps {
  cards: SliderCards[];
  delay?: number;
}

export default function ShopsSlider({cards, delay = 6000}: IProps) {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (cards.length === selectedIndex + 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    }, delay);
    return () => clearInterval(interval);
  }, [cards, delay, selectedIndex]);

  return (
    <>
      <ShopsSliderContainer>
        <ViewPager
          selectedIndex={selectedIndex}
          onSelect={index => setSelectedIndex(index)}>
          {cards.map((card, index) => {
            return (
              <ShopSlide
                key={`${card.id}-${card.title}-${index}-slide`}
                to={`/shops/${card.id}`}>
                <ShopSlideContentContainer>
                  {theme.isDesktop && (
                    <ShopSlideBadgesContainer>
                      {card.location && (
                        <ShopSlideLocationBadge numberOfLines={1}>
                          {card.location}
                        </ShopSlideLocationBadge>
                      )}

                      {card.tag && (
                        <ShopSlideTagBadge numberOfLines={1}>
                          {card.tag}
                        </ShopSlideTagBadge>
                      )}
                    </ShopSlideBadgesContainer>
                  )}
                  <ShopSlideTitle>{card.title}</ShopSlideTitle>
                </ShopSlideContentContainer>
                <ShopSlideImageContainer>
                  {!theme.isDesktop && (
                    <ShopSlideBadgesContainer>
                      {card.location && (
                        <ShopSlideLocationBadge numberOfLines={1}>
                          {card.location}
                        </ShopSlideLocationBadge>
                      )}

                      {card.tag && (
                        <ShopSlideTagBadge numberOfLines={1}>
                          {card.tag}
                        </ShopSlideTagBadge>
                      )}
                    </ShopSlideBadgesContainer>
                  )}
                  <ShopSlideImage
                    source={{uri: card.images[0].imageUrl}}
                    accessibilityLabel={card.images[0].description}
                  />
                </ShopSlideImageContainer>
              </ShopSlide>
            );
          })}
        </ViewPager>
      </ShopsSliderContainer>
      <ShopsSliderDotsContainer>
        {cards.map((card, index) => (
          <ShopsSliderDot
            key={`${card.id}-${index}-dot`}
            onPress={() => setSelectedIndex(index)}
            isActive={index === selectedIndex}
          />
        ))}
      </ShopsSliderDotsContainer>
    </>
  );
}
