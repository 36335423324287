import React, {ReactNode} from 'react';
import styled, {useTheme} from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import AppRouteView from '../../../uikit/AppRouteView';
import {ScreenLayout} from '../../../uikit/ScreenLayout';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';

export const HomeLayout = styled(ScreenLayout)`
  position: relative;
  ${props =>
    props.theme.isDesktop &&
    css`
      align-items: flex-start;
      flex-direction: row-reverse;
    `}
`;

export const HomeUserSpinnerMobile = styled(AppLoader)`
  align-self: center;
  flex: none;
  padding: ${props => props.theme.dimens.normalSpacer};
`;

export const HomeUserErrorMobile = styled(AppError)`
  align-self: center;
  flex: none;
  padding: ${props => props.theme.dimens.normalSpacer};
`;

export const HomeRouteContainer = styled.View`
  flex: 1;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: 0;
    `}
`;

export const CommonHomeRouteItemContainer = css`
  align-self: center;
  aspect-ratio: 1;
  border-radius: 4px;
  background-color: ${props => props.theme.colors['color-primary-500']}3D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HomeRouteItemIcon = styled.View`
  width: 60%;
  aspect-ratio: 1;
  background-color: ${props => props.theme.colors['color-primary-500']};

  ${props =>
    props.theme.isDesktop &&
    css`
      width: 32px;
    `}
`;

const HomeRouteItemName = styled(Text)`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  align-self: center;
  text-align: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

const HomeRouteItemContainerMobile = styled(AppRouteView)`
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

const HomeRouteItemContainer = styled.View`
  ${CommonHomeRouteItemContainer};

  width: 50%;
`;

const HomeRouteItemContentContainerDesktop = styled.View`
  height: 104px;
  flex-basis: 25%;
  display: flex;
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.smallSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};
`;

const HomeRouteItemContainerDesktop = styled(AppRouteView)`
  ${CommonHomeRouteItemContainer};
  width: 100%;
  height: 100%;
`;

export const HomeRouteItem = ({
  name,
  route,
  children,
}: {
  name: string;
  route: string;
  children?: ReactNode;
}) => {
  const theme = useTheme();

  if (theme.isDesktop) {
    return (
      <HomeRouteItemContentContainerDesktop>
        <HomeRouteItemContainerDesktop to={route}>
          {children ? children : <HomeRouteItemIcon />}
          <HomeRouteItemName>{name}</HomeRouteItemName>
        </HomeRouteItemContainerDesktop>
      </HomeRouteItemContentContainerDesktop>
    );
  } else {
    return (
      <HomeRouteItemContainerMobile to={route}>
        <HomeRouteItemContainer>
          {children ? children : <HomeRouteItemIcon />}
        </HomeRouteItemContainer>
        <HomeRouteItemName>{name}</HomeRouteItemName>
      </HomeRouteItemContainerMobile>
    );
  }
};
