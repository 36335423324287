import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {restoreConfirmRequestAction} from './action';
import {RestoreConfirmResponse} from '../../../api/user/models/responses/restore-confirm';

export const restoreConfirmReducer = combineReducers<{
  restoreConfirmRequest: RequestState<RestoreConfirmResponse> | null;
}>({
  restoreConfirmRequest: createRequestReducer(restoreConfirmRequestAction),
});
