import {RequestResponse} from './index';
import {
  RequestCategoryTypeFieldType,
  RequestDateValueJson,
  ShortRequestCategoryTypeField,
} from './category-type';
import {Strings} from '../../../../resources/strings';

export interface DetailRequestResponse extends RequestResponse {
  fields: DetailRequestAnswer[];
}

export interface DetailRequestAnswer extends ShortRequestCategoryTypeField {
  value?: string;
}

export function getAnswerString(
  strings: Strings,
  answer: DetailRequestAnswer,
): string {
  switch (answer.type) {
    case RequestCategoryTypeFieldType.DATE:
      if (answer.value === undefined || answer.value === null) return '';
      try {
        const json: RequestDateValueJson = JSON.parse(answer.value);
        const dateString: string = json.date
          ? strings.app_date_string(json.date)
          : '';
        return json.isFixed
          ? strings.detail_request_date_fixed_format_fn(dateString)
          : strings.detail_request_date_not_fixed_format_fn(dateString);
      } catch (e) {
        // process old format
        try {
          return strings.app_date_string(answer.value);
        } catch (e) {
          return answer.value;
        }
      }
    case RequestCategoryTypeFieldType.INPUT:
    case RequestCategoryTypeFieldType.TIME:
    case RequestCategoryTypeFieldType.RADIO_GROUP:
    case RequestCategoryTypeFieldType.SINGLE_SELECTION:
    case RequestCategoryTypeFieldType.INPUT_NUMBER:
    case RequestCategoryTypeFieldType.CHECKBOX:
    case RequestCategoryTypeFieldType.STEPPER:
    case RequestCategoryTypeFieldType.ATTACHMENT_IMAGE:
      return answer.value || '';
  }
}
