import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const Favorites = (props: SvgProps) => (
  <Svg width={32} height={32} viewBox="0 0 32 32" fill="#DC9B2D" {...props}>
    <Path d="M16.334 7.318c3.132-2.738 7.972-2.647 10.99.296 3.015 2.945 3.12 7.635.314 10.693L16.332 29.333 5.028 18.307a7.645 7.645 0 01.315-10.693c3.02-2.94 7.85-3.038 10.991-.296z" />
  </Svg>
);

export default Favorites;
