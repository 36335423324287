import React from 'react';
import {useTheme} from 'styled-components/native';
import createMainNavigator, {isHideBar} from './components/navigator';
import HomeNavigator from '../home/navigator';
import RequestsNavigator from '../requests/navigator';
import {useStrings} from '../../utils/providers/strings';
import RequestsCalendarNavigator from '../requests/calendar/navigator';
import MyPageNavigator from '../my-page/navigator';
import Home from '../../assets/icons/Home';
import Requests from '../../assets/icons/Requests';
import Shops from '../../assets/icons/Shops';
import ShopsNavigator from '../shops/navigator';
import Calendar from '../../assets/icons/Calendar';
import Profile from '../../assets/icons/Profile';

const Stack = createMainNavigator();

export default function MainNavigator() {
  const strings = useStrings();
  const theme = useTheme();
  return (
    <Stack.Navigator
      backBehavior="history"
      screenOptions={{
        tabBarActiveTintColor: theme.colors['color-primary-500'],
        tabBarInactiveTintColor: theme.colors['color-basic-500'],
      }}>
      <Stack.Screen
        name="HomeNavigator"
        component={HomeNavigator}
        options={props => {
          if (isHideBar(theme, props.navigation.getState())) {
            return {
              tabBarStyle: {
                height: 0,
                display: 'none',
              },
            };
          } else {
            return {
              tabBarLabel: strings.main_navigator_home,
              tabBarIcon: ({color, size}) => (
                <Home fill={color} width={size} height={size} />
              ),
            };
          }
        }}
      />
      <Stack.Screen
        name="RequestsNavigator"
        component={RequestsNavigator}
        options={props => {
          if (isHideBar(theme, props.navigation.getState())) {
            return {
              tabBarStyle: {
                height: 0,
                display: 'none',
              },
            };
          } else {
            return {
              tabBarLabel: strings.main_navigator_requests,
              tabBarIcon: ({color, size}) => (
                <Requests fill={color} width={size} height={size} />
              ),
            };
          }
        }}
      />
      <Stack.Screen
        name="RequestsCalendarNavigator"
        component={RequestsCalendarNavigator}
        options={props => {
          if (isHideBar(theme, props.navigation.getState())) {
            return {
              tabBarStyle: {
                height: 0,
                display: 'none',
              },
            };
          } else {
            return {
              tabBarLabel: strings.main_navigator_calendar,
              tabBarIcon: ({color, size}) => (
                <Calendar fill={color} width={size} height={size} />
              ),
            };
          }
        }}
      />
      <Stack.Screen
        name="ShopsNavigator"
        component={ShopsNavigator}
        options={props => {
          if (isHideBar(theme, props.navigation.getState())) {
            return {
              tabBarStyle: {
                height: 0,
                display: 'none',
              },
            };
          } else {
            return {
              tabBarLabel: strings.main_navigator_shops,
              tabBarIcon: ({color, size}) => (
                <Shops fill={color} width={size} height={size} />
              ),
            };
          }
        }}
      />
      <Stack.Screen
        name="MyPageNavigator"
        component={MyPageNavigator}
        options={props => {
          if (isHideBar(theme, props.navigation.getState())) {
            return {
              tabBarStyle: {
                height: 0,
                display: 'none',
              },
            };
          } else {
            return {
              tabBarLabel: strings.main_navigator_my_page,
              tabBarIcon: ({color, size}) => (
                <Profile color={color} width={size} height={size} />
              ),
            };
          }
        }}
      />
    </Stack.Navigator>
  );
}
