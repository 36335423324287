import {Router} from './types';
import {
  CommonActions,
  useLinkTo,
  useNavigation,
} from '@react-navigation/native';

export default function useRouter(): Router {
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  return {
    reload: () => navigation.dispatch(CommonActions.reset(undefined)),
    back: (noHistoryRoute: string) => {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        linkTo(noHistoryRoute);
      }
    },
    canBack: () => navigation.canGoBack(),
    push: (url: string) =>
      new Promise(resolve => {
        linkTo(url);
        resolve(true);
      }),
    replace: (url: string) =>
      new Promise(resolve => {
        navigation.goBack();
        linkTo(url);
        resolve(true);
      }),
  };
}
