import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Spinner} from '@ui-kitten/components';
import Send from '../../../../../../assets/icons/Send';
import {AppInput} from '../../../../../../uikit/AppInput';
import AppKeyboardAvoidingView from '../../../../../../uikit/AppKeyboardAvoidingView';
import Close from '../../../../../../assets/icons/Close';

export const DetailRequestChatContainer = styled(AppKeyboardAvoidingView)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CommonDetailRequestChatList = css`
  flex: 1;
  padding: ${props => props.theme.dimens.normalSpacer};
`;

export const DetailRequestChatListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: ${props => props.theme.dimens.bigSpacer};
  padding-bottom: ${props => props.theme.dimens.smallestSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: ${props.theme.dimens.smallSpacer};
    `}
`;

export const DetailRequestChatListLoading = styled(Spinner)``;

export const DetailRequestChatControlContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const DetailRequestChatInputContainer = styled.View`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};

  ${props =>
    props.theme.isDesktop &&
    css`
      background-color: transparent;
    `}
`;

export const DetailRequestChatInput = styled(AppInput)`
  max-height: 138px;
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const DetailRequestChatInputLoadingContainer = styled.View`
  margin-left: ${props => props.theme.dimens.smallSpacer};
  margin-top: 3px;
  padding: ${props => props.theme.dimens.smallestSpacer};
`;

export const DetailRequestChatInputLoading = styled(Spinner)``;

export const DetailRequestChatInputButton = styled.TouchableOpacity`
  margin-left: ${props => props.theme.dimens.smallSpacer};
  margin-top: 3px;
  padding: ${props => props.theme.dimens.smallestSpacer};
`;

export const DetailRequestChatInputIcon = styled(Send).attrs(props => ({
  fill: props.theme.colors['color-basic-500'],
}))``;

export const DetailRequestPreviewUploadContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DetailRequestDeleteUploadButton = styled.TouchableOpacity``;

export const DetailRequestDeleteUploadIcon = styled(Close)``;

export const DetailRequestImagePreview = styled.Image`
  width: 60px;
  height: 40px;
`;
