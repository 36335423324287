import {makeStore} from './store';
import {getJWTToken} from '../storage';
import {setJWTUserAction} from '../features/user/store/action';
import {getUserFromJwt} from '../api/user/get-user-from-jwt';

//Use only for dynamic apps
export const store = makeStore();

getJWTToken().then(accessToken => {
  if (accessToken) {
    store.dispatch(setJWTUserAction(getUserFromJwt(accessToken)));
  }
});
