/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {ChangeEvent, useRef, useState} from 'react';
import {RequestCategoryTypeField} from '../../../../../../../api/request/models/responses/category-type';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldErrorText,
  NewRequestQuestionFieldTitle,
} from '../styles';
import {
  NewRequestQuestionFieldAttachButton,
  NewRequestQuestionFieldAttachContainer,
  NewRequestQuestionFieldAttachIcon,
  NewRequestQuestionFieldDeteleIcon,
  NewRequestQuestionFieldPreviewImage,
} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  NewRequestQuestionFieldAttachmentState,
  NewRequestQuestionForm,
} from '../../../form';
import {useStrings} from '../../../../../../../utils/providers/strings';
import {useToast} from 'react-native-toast-notifications';
import {Spinner} from '@ui-kitten/components';
import api from '../../../../../../../api';

export default function NewRequestQuestionFieldAttachImage({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  const strings = useStrings();
  const toast = useToast();

  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const refs = [inputRef1, inputRef2, inputRef3];

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const onAddFile = (index: number) => {
    const ref = refs[index];
    setSelectedIndex(index);

    if (ref) {
      //@ts-ignore
      ref.current.click();
    }
  };

  const imageUploader = async (blob: Blob) => {
    try {
      const response = await api.request.uploadFile({file: blob});

      return response;
    } catch (err) {
      toast.show(strings.detail_chat_error_save_image);
      console.error(err);
    }
  };

  return (
    <NewRequestQuestionFieldContainer>
      <NewRequestQuestionFieldTitle>{field.title}</NewRequestQuestionFieldTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const attachValue = value as NewRequestQuestionFieldAttachmentState;

          const handleChoosePhoto = async (e: ChangeEvent) => {
            //@ts-ignore
            const file = (e.target as HTMLInputElement).files[0];

            if (file) {
              const attachIsLoading = attachValue.map(item =>
                item.id === selectedIndex + 1
                  ? {...item, isLoading: true}
                  : item,
              );
              onChange(attachIsLoading);
            }

            const response = await imageUploader(file);

            if (response?.data) {
              const newAttachValue = attachValue.map(item =>
                item.id === selectedIndex + 1
                  ? {
                      ...item,
                      asset: {uri: URL.createObjectURL(file)},
                      key: response.data,
                    }
                  : item,
              );
              onChange(newAttachValue);
            }
          };

          const handleRemovePhoto = (index: number) => {
            const newAttachValue = attachValue.map(item =>
              item.id === index + 1 ? {...item, asset: null, key: ''} : item,
            );
            onChange(newAttachValue);
          };

          return (
            <>
              <NewRequestQuestionFieldAttachContainer>
                {attachValue.map((item, i) =>
                  !item.asset ? (
                    <NewRequestQuestionFieldAttachButton
                      key={item.id}
                      onPress={() => onAddFile(i)}
                      lastChild={i === attachValue.length - 1}>
                      {!item.isLoading ? (
                        <NewRequestQuestionFieldAttachIcon />
                      ) : (
                        <Spinner />
                      )}
                      <input
                        ref={refs[i]}
                        type="file"
                        accept="image/*"
                        onChange={handleChoosePhoto}
                        hidden
                      />
                    </NewRequestQuestionFieldAttachButton>
                  ) : (
                    <NewRequestQuestionFieldAttachButton
                      key={item.id}
                      onPress={() => handleRemovePhoto(i)}
                      lastChild={i === attachValue.length - 1}>
                      <NewRequestQuestionFieldPreviewImage
                        source={{uri: item.asset.uri}}
                      />

                      <NewRequestQuestionFieldDeteleIcon />
                    </NewRequestQuestionFieldAttachButton>
                  ),
                )}
              </NewRequestQuestionFieldAttachContainer>
            </>
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
    </NewRequestQuestionFieldContainer>
  );
}
