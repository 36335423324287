import React, {useCallback, useEffect} from 'react';
import {ListRenderItemInfo} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useTheme} from 'styled-components/native';
import {
  AppTransparentHeader,
  CategoryListItemContainer,
  ScreenListEmptyContainer,
  ScreenListEmptyIcon,
  ScreenListEmptyTitle,
  ScreenListLoading,
  ScreenListLoadingContainer,
  ShopFavoritesButton,
  ShopFavoritesButtonContainer,
  ShopFavoritesCardBadgesContainer,
  ShopFavoritesCardImage,
  ShopFavoritesCardLocationBadge,
  ShopFavoritesCardTagBadge,
  ShopFavoritesCardTitle,
  ShopFavoritesCardTitleContainer,
  ShopFavoritesIcon,
  ShopFavoritesViewedContainerContent,
  ShopFavoritesViewedList,
  ShopFavoritesViewedListContainer,
} from './styles';
import {useFocusEffect} from '@react-navigation/native';
import {useStrings} from '../../../utils/providers/strings';
import {
  useAppDispatch,
  usePaginationRequestSelector,
  useRequestSelector,
} from '../../../store/store';
import {REQUESTS_LIMIT} from '../../requests/store/saga';
import AppStatusBar from '../../../uikit/AppStatusBar';
import {AppLayout} from '../../../uikit/AppLayout';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import AppRefreshControl from '../../../uikit/AppRefreshControl';
import {ShopsContainerContainer} from '../styles';
import {REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT} from '../store/saga';
import {ShopItem} from '../../../api/shops/models/responses/shop-categories';
import {PaginationRequest} from '../../../utils/redux/create-pagination-request-reducer';
import {
  deleteShopFavoriteRequestAction,
  getShopFavoritesListRequestAction,
} from '../store/action';
import {RequestsListEmptyInfo} from '../../requests/styles';

export default function FavoritesShopScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const shopFavoritesListRequest = usePaginationRequestSelector(
    store => store.shops.shopFavoritesListRequest,
  );

  const deleteShopFavoriteRequest = useRequestSelector(
    store => store.shops.deleteShopFavoriteRequest,
  );

  const isEmptyList = !shopFavoritesListRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getShopFavoritesListRequestAction.clean());
      dispatch(deleteShopFavoriteRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      shopFavoritesListRequest.error &&
      shopFavoritesListRequest.data?.items.length
    ) {
      toast.show(shopFavoritesListRequest.error.message);
    }
  }, [shopFavoritesListRequest]);

  useEffect(() => {
    if (deleteShopFavoriteRequest.data) {
      dispatch(deleteShopFavoriteRequestAction.clean());
      onInit();
    }

    if (deleteShopFavoriteRequest.error && !deleteShopFavoriteRequest.data) {
      toast.show(deleteShopFavoriteRequest.error.message);
    }
  }, [deleteShopFavoriteRequest]);

  const onFirstPage = useCallback(() => {
    const request: PaginationRequest = {
      limit: REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT,
      offset: 0,
    };

    dispatch(getShopFavoritesListRequestAction.request(request));
  }, [dispatch]);

  const onInit = useCallback(() => {
    dispatch(getShopFavoritesListRequestAction.clean());
    dispatch(deleteShopFavoriteRequestAction.clean());
    onFirstPage();
  }, [dispatch, onFirstPage]);

  useFocusEffect(onInit);

  const onNextPage = () => {
    if (
      shopFavoritesListRequest.data?.isLastPage ||
      shopFavoritesListRequest.isLoading
    )
      return;

    const request: PaginationRequest = {
      limit: REQUESTS_LIMIT,
      offset: shopFavoritesListRequest.data?.items.length || 0,
    };

    dispatch(getShopFavoritesListRequestAction.request(request));
  };

  const onDeleteShopFavorite = (id: number) => {
    dispatch(deleteShopFavoriteRequestAction.request({shopId: id}));
  };

  const renderShopCardItem = ({item}: ListRenderItemInfo<ShopItem>) => {
    return (
      <CategoryListItemContainer to={`/shops/${item.id}`}>
        <ShopFavoritesCardBadgesContainer>
          {item.prefecture && (
            <ShopFavoritesCardLocationBadge numberOfLines={1}>
              {item.prefecture}
            </ShopFavoritesCardLocationBadge>
          )}

          {item.tag && (
            <ShopFavoritesCardTagBadge numberOfLines={1}>
              {item.tag}
            </ShopFavoritesCardTagBadge>
          )}
        </ShopFavoritesCardBadgesContainer>
        <ShopFavoritesButtonContainer>
          <ShopFavoritesButton
            onPress={event => {
              event.preventDefault();
              onDeleteShopFavorite(item.id);
            }}>
            <ShopFavoritesIcon fill={theme.colors['color-danger-500']} />
          </ShopFavoritesButton>
        </ShopFavoritesButtonContainer>
        <ShopFavoritesCardTitleContainer>
          <ShopFavoritesCardTitle>{item.name}</ShopFavoritesCardTitle>
        </ShopFavoritesCardTitleContainer>
        <ShopFavoritesCardImage source={{uri: item.images[0].imageUrl}} />
      </CategoryListItemContainer>
    );
  };

  const renderScreenListLoading = () => {
    return (
      <ScreenListLoadingContainer>
        <ScreenListLoading />
      </ScreenListLoadingContainer>
    );
  };

  const renderScreenListEmpty = () => {
    return (
      <ScreenListEmptyContainer>
        <ScreenListEmptyIcon />
        <ScreenListEmptyTitle>
          {strings.shop_no_favorites_title}
        </ScreenListEmptyTitle>
        <RequestsListEmptyInfo>
          {strings.shop_recently_try_later_text}
        </RequestsListEmptyInfo>
      </ScreenListEmptyContainer>
    );
  };

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopFavoritesViewedContainerContent>
          <AppLayout>
            {isEmptyList && shopFavoritesListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && shopFavoritesListRequest.error ? (
              <AppError
                error={shopFavoritesListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              <>
                <AppTransparentHeader
                  title={`${strings.shop_favorites_title} (${shopFavoritesListRequest.data?.totalCount})`}
                  backNoHistoryRoute="/shops"
                />
                {shopFavoritesListRequest.data && (
                  <ShopFavoritesViewedListContainer>
                    <ShopFavoritesViewedList
                      numColumns={theme.isDesktop ? 4 : 1}
                      refreshControl={
                        <AppRefreshControl
                          onRefresh={onFirstPage}
                          refreshing={shopFavoritesListRequest.isFirstLoading}
                        />
                      }
                      data={shopFavoritesListRequest.data.items}
                      renderItem={renderShopCardItem}
                      keyExtractor={item => String(item.id)}
                      onEndReached={onNextPage}
                      ListFooterComponent={
                        shopFavoritesListRequest.isLoading
                          ? renderScreenListLoading
                          : null
                      }
                      ListEmptyComponent={renderScreenListEmpty}
                    />
                  </ShopFavoritesViewedListContainer>
                )}
              </>
            )}
          </AppLayout>
        </ShopFavoritesViewedContainerContent>
      </ShopsContainerContainer>
    </>
  );
}
