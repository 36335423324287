import styled from 'styled-components/native';

export const LinksWrapper = styled.View`
  padding-top: 8px;
  flex-wrap: wrap;
  flex-direction: row;
`;
export const LinkItem = styled.TouchableOpacity`
  margin-right: 28px;
  margin-bottom: 16px;
`;
export const LinkItemText = styled.Text`
  color: ${props => props.theme.colors['color-basic-800']};
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
`;
