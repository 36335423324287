import styled from 'styled-components/native';
import ArrowRight from '../../../assets/icons/ArrowRight';

export const TouchableStripeIcon = styled(ArrowRight)``;

export const TouchableStripeWrapper = styled.TouchableOpacity`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  line-height: 24px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: white;
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;

export const TouchableStripeTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  line-height: 24px;
`;
