import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath} from 'react-native-svg';

const EyeOff = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path d="M4.52 5.934 1.393 2.808l1.415-1.415 19.8 19.8-1.416 1.414-3.31-3.31A10.949 10.949 0 0 1 12 21c-5.392 0-9.878-3.88-10.819-9a10.982 10.982 0 0 1 3.34-6.066Zm10.237 10.238-1.464-1.464a3 3 0 0 1-4-4.001L7.827 9.243a5 5 0 0 0 6.93 6.929ZM7.974 3.76A10.99 10.99 0 0 1 12 3c5.392 0 9.878 3.88 10.82 9a10.949 10.949 0 0 1-2.013 4.592l-3.86-3.86a5 5 0 0 0-5.68-5.68L7.974 3.761V3.76Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default EyeOff;
