import {
  RequestsCalendar,
  RequestsCalendarContainer,
  RequestsCalendarDay,
  RequestsCalendarDayPin,
  RequestsCalendarDayText,
  RequestsCalendarError,
  RequestsCalendarInfo,
  RequestsCalendarInfoCounter,
  RequestsCalendarInfoEmpty,
  RequestsCalendarInfoTitle,
  RequestsCalendarInfoTitleContainer,
  RequestsCalendarItem,
  RequestsCalendarItemInfo,
  RequestsCalendarItemInfoName,
  RequestsCalendarItemInfoType,
  RequestsCalendarItemSelectIcon,
  RequestsCalendarLoader,
} from './styles';
import AppStatusBar from '../../../uikit/AppStatusBar';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {useStrings} from '../../../utils/providers/strings';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import {getCalendarRequestsAction} from '../store/action';
import {
  CalendarViewMode,
  CalendarViewModes,
  StyleType,
} from '@ui-kitten/components';
import {RequestResponse} from '../../../api/request/models/responses';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import {useFocusEffect} from '@react-navigation/native';
import {getLocale} from '../../../resources/strings';

interface RequestsCalendarRequests {
  year: number;
  month: number;
  [day: number]: RequestResponse[];
}

const RequestsCalendarContext = createContext<{
  selectedDay: Date;
  days: RequestsCalendarRequests | undefined;
}>({
  selectedDay: new Date(),
  days: undefined,
});

export default function RequestsCalendarScreen() {
  const strings = useStrings();
  const locale = getLocale();
  const dispatch = useAppDispatch();
  const now = new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(now);
  const [selectedDay, setSelectedDay] = useState<Date>(now);

  const calendarRequests = useRequestSelector(
    store => store.requests.calendarRequests,
  );

  const calendarDays = useMemo<RequestsCalendarRequests | undefined>(() => {
    if (!calendarRequests.data) return undefined;

    const days: RequestsCalendarRequests = {
      year: selectedMonth.getFullYear(),
      month: selectedMonth.getMonth(),
    };

    calendarRequests.data.data.forEach(request => {
      const dates = request.dateTimeFields.map(item => new Date(item));

      dates.forEach(date => {
        const day = date.getDate();
        if (!days[day]) {
          days[day] = [];
        }
        days[day].push(request);
      });
    });

    return days;
  }, [calendarRequests.data, selectedMonth]);

  const selectedDayRequests = useMemo<RequestResponse[] | undefined>(() => {
    if (!calendarDays) return undefined;
    if (
      calendarDays.year !== selectedDay.getFullYear() ||
      calendarDays.month !== selectedDay.getMonth()
    )
      return undefined;

    return calendarDays[selectedDay.getDate()];
  }, [calendarDays, selectedDay]);

  const onRequest = useCallback(() => {
    const firstMonthDay = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth(),
      1,
    );
    const lastMonthDay = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth() + 1,
      0,
    );
    dispatch(
      getCalendarRequestsAction.request({
        dateStart: firstMonthDay.toISOString(),
        dateEnd: lastMonthDay.toISOString(),
      }),
    );
  }, [dispatch, selectedMonth]);

  const onInit = useCallback(() => {
    onRequest();

    return function () {
      dispatch(getCalendarRequestsAction.clean());
    };
  }, [dispatch, onRequest]);
  useFocusEffect(onInit);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 100);

  const getCustomTitle = (
    date: Date,
    monthYearDate: Date,
    viewMode: CalendarViewMode,
  ) => {
    switch (viewMode.id) {
      case CalendarViewModes.DATE.id: {
        const month = date.toLocaleString(locale, {month: 'long'});

        return `${date.getFullYear()} ${month}`;
      }
      case CalendarViewModes.MONTH.id: {
        return `${monthYearDate.getFullYear()}`;
      }
      case CalendarViewModes.YEAR.id: {
        return `${monthYearDate.getFullYear()} - ${
          monthYearDate.getFullYear() + 11
        }`;
      }
      default: {
        return `${date.getFullYear()}`;
      }
    }
  };

  return (
    <RequestsCalendarContext.Provider value={{selectedDay, days: calendarDays}}>
      <AppStatusBar />
      <ScreenLayoutWithHeader>
        <RequestsCalendarContainer>
          <RequestsCalendar
            title={getCustomTitle}
            boundingMonth={false}
            dateService={strings.dateService}
            min={new Date(0)} // not remove min date - Calendar will disable all dates from not current year
            max={maxDate} // not remove max date - Calendar will disable all dates from not current year
            date={selectedDay}
            onSelect={date => {
              if (date.getTime() !== selectedDay.getTime()) {
                setSelectedDay(date);
              }
            }}
            onVisibleDateChange={(date, viewModeId) => {
              if (
                viewModeId === 'DATE' &&
                date.getTime() !== selectedMonth.getTime()
              ) {
                setSelectedMonth(date);
              }
            }}
            renderDay={(info, style) => (
              <RequestsCalendarDayCell date={info.date} style={style} />
            )}
          />
          <RequestsCalendarInfo>
            {calendarRequests.isLoading ? (
              <RequestsCalendarLoader />
            ) : calendarRequests.error ? (
              <RequestsCalendarError
                error={calendarRequests.error.message}
                retry={onRequest}
              />
            ) : (
              <>
                <RequestsCalendarInfoTitleContainer>
                  <RequestsCalendarInfoTitle>
                    {strings.requests_calendar_date(selectedDay)}
                  </RequestsCalendarInfoTitle>
                  <RequestsCalendarInfoCounter>
                    {strings.requests_calendar_events_counter_fn(
                      selectedDayRequests?.length ?? 0,
                    )}
                  </RequestsCalendarInfoCounter>
                </RequestsCalendarInfoTitleContainer>
                {selectedDayRequests && selectedDayRequests.length ? (
                  selectedDayRequests.map((request, position, list) => {
                    return (
                      <RequestsCalendarItem
                        key={request.id}
                        last={position === list.length - 1}
                        to={`/requests/${request.id}`}>
                        <RequestsCalendarItemInfo>
                          <RequestsCalendarItemInfoName>
                            {request.title}
                          </RequestsCalendarItemInfoName>
                          <RequestsCalendarItemInfoType>
                            {request.type.name}
                          </RequestsCalendarItemInfoType>
                        </RequestsCalendarItemInfo>
                        <RequestsCalendarItemSelectIcon />
                      </RequestsCalendarItem>
                    );
                  })
                ) : (
                  <RequestsCalendarInfoEmpty>
                    {strings.requests_calendar_empty}
                  </RequestsCalendarInfoEmpty>
                )}
              </>
            )}
          </RequestsCalendarInfo>
        </RequestsCalendarContainer>
      </ScreenLayoutWithHeader>
    </RequestsCalendarContext.Provider>
  );
}

const RequestsCalendarDayCell = ({
  date,
  style,
}: {
  date: Date;
  style: StyleType;
}) => {
  const {selectedDay, days} = useContext(RequestsCalendarContext);

  return (
    <RequestsCalendarDay style={style.container}>
      <RequestsCalendarDayText
        style={style.text}>{`${date.getDate()}`}</RequestsCalendarDayText>
      <RequestsCalendarDayPin
        show={!!days?.[date.getDate()]?.length}
        selected={selectedDay.getTime() === date.getTime()}
      />
    </RequestsCalendarDay>
  );
};
