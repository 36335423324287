import {AxiosInstance} from 'axios';

import {DefaultResponse} from '../models/response/default';
import {SettingsResponse} from './models/settingsResponse';

export default class SettingsApi {
  constructor(private axiosInstance: AxiosInstance) {}

  getSettings(): Promise<DefaultResponse<Array<SettingsResponse>>> {
    return this.axiosInstance.get(`settings`);
  }
}
