import React, {useState} from 'react';
import {TouchableOpacity} from 'react-native';

import {useAppDispatch} from '../../../../store/store';
import {useStrings} from '../../../../utils/providers/strings';
import {
  getShopsFlatCategoriesListAction,
  searchShopsRequestAction,
} from '../../store/action';

import Search from '../../../../assets/icons/Search';
import Close from '../../../../assets/icons/Close';

import {ShopsSearchInput} from './styles';

type Props = {
  mode: 'main' | 'search';
  initialSearchValue?: string;
  onSearch: (searchValue: string) => void;
};

const ShopsSearch = ({mode, initialSearchValue = '', onSearch}: Props) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const dispatch = useAppDispatch();
  const strings = useStrings();

  const onClearHandler = () => {
    setSearchValue('');
    if (mode === 'search') {
      onSearch('');
    }
  };

  const search = () => {
    // if (!searchValue) return
    onSearch(searchValue);

    // Search categories
    dispatch(
      getShopsFlatCategoriesListAction.request({
        offset: 0,
        limit: 6,
        name: searchValue,
      }),
    );

    // Search shops
    dispatch(
      searchShopsRequestAction.request({
        query: searchValue,
        offset: 0,
        limit: 20,
      }),
    );
  };

  const renderClearIcon = () => (
    <TouchableOpacity onPress={onClearHandler}>
      <Close />
    </TouchableOpacity>
  );

  const renderSearchIcon = () => (
    <TouchableOpacity onPress={search}>
      <Search />
    </TouchableOpacity>
  );

  return (
    <ShopsSearchInput
      placeholder={strings.shop_search_placeholder}
      accessoryLeft={
        searchValue || mode === 'search' ? renderClearIcon : undefined
      }
      accessoryRight={renderSearchIcon}
      onChangeText={setSearchValue}
      value={searchValue}
      onSubmitEditing={search}
    />
  );
};

export {ShopsSearch};
