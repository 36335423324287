import React, {useCallback, useEffect} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../uikit/Points/container';
import {useTheme} from 'styled-components/native';
import {useAppDispatch, usePaginationRequestSelector} from '../../store/store';
import {AppLoader} from '../../uikit/AppLoader';
import {AppError} from '../../uikit/AppError';
import {
  SplitPaymentList,
  SplitPaymentListEmptyContainer,
  SplitPaymentListEmptyIcon,
  SplitPaymentListEmptyInfo,
  SplitPaymentListItemContainer,
  SplitPaymentListItemIcon,
  SplitPaymentListItemInfoAuthor,
  SplitPaymentListItemInfoContainer,
  SplitPaymentListItemInfoDate,
  SplitPaymentListItemInfoStatus,
  SplitPaymentListItemInfoTitle,
  SplitPaymentListLoading,
  SplitPaymentListLoadingContainer,
} from './styles';
import {ListRenderItemInfo} from 'react-native';
import {useStrings} from '../../utils/providers/strings';
import {useToast} from 'react-native-toast-notifications';
import AppRefreshControl from '../../uikit/AppRefreshControl';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {getSplitPaymentListRequestAction} from './store/action';
import {SPLIT_PAYMENT_LIST_LIMIT} from './store/saga';
import {
  getSplitPaymentStatusText,
  getSplitPaymentStatusType,
  SplitPaymentResponse,
} from '../../api/split-payments/models/responses/split-payment';
import AppHeader from '../../uikit/AppHeader';
import {ScreenLayoutWithHeader} from '../../uikit/ScreenLayout';
import {useFocusEffect} from '@react-navigation/native';

export default function SplitPaymentListScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const splitPaymentListRequest = usePaginationRequestSelector(
    store => store.splitPayments.splitPaymentListRequest,
  );

  const isEmptyList = !splitPaymentListRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getSplitPaymentListRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      splitPaymentListRequest.error &&
      splitPaymentListRequest.data?.items.length
    ) {
      toast.show(splitPaymentListRequest.error.message);
    }
  }, [splitPaymentListRequest, toast]);

  const onFirstPage = useCallback(() => {
    dispatch(
      getSplitPaymentListRequestAction.request({
        limit: SPLIT_PAYMENT_LIST_LIMIT,
        offset: 0,
      }),
    );
  }, [dispatch]);

  useFocusEffect(onFirstPage);

  const onNextPage = () => {
    if (
      splitPaymentListRequest.data?.isLastPage ||
      splitPaymentListRequest.isLoading
    )
      return;

    dispatch(
      getSplitPaymentListRequestAction.request({
        limit: SPLIT_PAYMENT_LIST_LIMIT,
        offset: splitPaymentListRequest.data?.items.length || 0,
      }),
    );
  };

  const renderSplitPaymentItem = ({
    item,
  }: ListRenderItemInfo<SplitPaymentResponse>) => {
    return (
      <SplitPaymentListItemContainer to={`/split-payments/${item.id}`}>
        <SplitPaymentListItemInfoContainer>
          <SplitPaymentListItemInfoTitle>
            {item.name}
          </SplitPaymentListItemInfoTitle>
          <SplitPaymentListItemInfoAuthor>
            {`${item.author?.firstName} ${item.author?.lastName} (ID: ${item.author?.clientId})`}
          </SplitPaymentListItemInfoAuthor>
          <SplitPaymentListItemInfoDate>
            {strings.app_date_time_string(item.createdAt)}
          </SplitPaymentListItemInfoDate>
          <SplitPaymentListItemInfoStatus
            status={getSplitPaymentStatusType(item.status)}>
            {getSplitPaymentStatusText(strings, item.status)}
          </SplitPaymentListItemInfoStatus>
        </SplitPaymentListItemInfoContainer>
        <SplitPaymentListItemIcon />
      </SplitPaymentListItemContainer>
    );
  };

  const renderSplitPaymentListLoading = () => {
    return (
      <SplitPaymentListLoadingContainer>
        <SplitPaymentListLoading />
      </SplitPaymentListLoadingContainer>
    );
  };

  const renderSplitPaymentListEmpty = () => {
    return (
      <SplitPaymentListEmptyContainer>
        <SplitPaymentListEmptyIcon />
        <SplitPaymentListEmptyInfo>
          {strings.split_payment_list_title_empty}
        </SplitPaymentListEmptyInfo>
      </SplitPaymentListEmptyContainer>
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={strings.split_payment_list_title}
              backNoHistoryRoute="/"
            />
            {isEmptyList && splitPaymentListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && splitPaymentListRequest.error ? (
              <AppError
                error={splitPaymentListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              splitPaymentListRequest.data && (
                <SplitPaymentList
                  refreshControl={
                    <AppRefreshControl
                      onRefresh={onFirstPage}
                      refreshing={splitPaymentListRequest.isFirstLoading}
                    />
                  }
                  data={splitPaymentListRequest.data.items}
                  renderItem={renderSplitPaymentItem}
                  keyExtractor={item => String(item.id)}
                  onEndReached={onNextPage}
                  ListFooterComponent={
                    splitPaymentListRequest.isLoading
                      ? renderSplitPaymentListLoading
                      : null
                  }
                  ListEmptyComponent={renderSplitPaymentListEmpty}
                />
              )
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
