import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList} from 'react-native';
import {Spinner, Text} from '@ui-kitten/components';
import EmptyExchangeList from '../../../../assets/icons/EmptyRequests';
import {AppLink} from '../../../../uikit/AppButtons';
import {Exchange} from '../../../../api/standard-points/models/responses/exchange';

export const ExchangeList = styled(FlatList<Exchange>)`
  flex: 1;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.smallSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: ${props.theme.dimens.smallSpacer};
    `}
`;

export const ExchangeListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const ExchangeListLoading = styled(Spinner)``;

export const ExchangeListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const ExchangeListEmptyIcon = styled(EmptyExchangeList)``;

export const ExchangeListEmptyInfo = styled(Text)``;

export const ExchangeListEmptyCreateNewLink = styled(AppLink)`
  align-self: stretch;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
      min-width: 240px;
    `}
`;

export const ExchangeListItemContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const ExchangeListItemInfoStatus = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-size: 12px;
  font-weight: bold;
`;

export const ExchangeListItemInfoContainer = styled.View`
  display: flex;
  justify-content: space-between;
`;

export const ExchangeListItemControlsInfoContainer = styled.View`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ExchangeListItemInfoTitle = styled(Text)`
  font-size: 15px;
  font-weight: 500;
`;

export const ExchangeListItemInfoText = styled(Text)`
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const ExchangeListItemInfoPrice = styled(Text)`
  font-size: 20px;
  font-weight: 700;
`;

export const ExchangeListItemInfoSign = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`;
