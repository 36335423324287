import styled from 'styled-components/native';
import {Text} from '@ui-kitten/components';
import ArrowRight from '../../../../assets/icons/ArrowRight';
import AppRouteView from '../../../../uikit/AppRouteView';

export const LinkBuyItemContainer = styled.View`
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.theme.dimens.normalSpacer};
`;

export const LinkBuyItem = styled(AppRouteView)<{last?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-bottom: ${props => props.theme.dimens.normalSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
  background-color: ${props => props.theme.colors.white};
  border-bottom-width: ${props => (props.last ? '0' : '1px')};
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;

export const LinkBuyItemInfo = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const LinkBuyItemInfoName = styled(Text)``;

export const ConciergePointsAmountAfterChargeContainer = styled.View`
  display: flex;
  flex-direction: row;
  padding-top: ${props => props.theme.dimens.normalSpacer};
`;

export const ConciergePointsAmountAfterChargeTitle = styled(Text)``;

export const ConciergePointsAmountAfterChargePoints = styled(Text)`
  margin-left: ${props => props.theme.dimens.smallestSpacer};
  font-weight: 800;
`;

export const ConciergePointsAmountAfterChargeSuffix = styled(Text)`
  margin-left: ${props => props.theme.dimens.smallestSpacer};
`;

export const LinkBuyItemSelectIcon = styled(ArrowRight).attrs(props => ({
  fill: props.theme.colors['color-basic-500'],
}))``;
