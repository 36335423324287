import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {
  CalendarRequestResponse,
  RequestResponse,
} from '../../../api/request/models/responses';
import {ListResponse} from '../../../api/models/response/list';
import {
  CalendarRequestsQuery,
  RequestsQuery,
} from '../../../api/request/models/requests';
import {DetailRequestResponse} from '../../../api/request/models/responses/detail';
import {RequestChatQuery} from '../../../api/request/models/requests/chat';
import {RequestChatMessage} from '../../../api/request/models/responses/chat';
import {RequestSendMessageRequest} from '../../../api/request/models/requests/send';
import {TransferResponse} from '../../../api/request/models/responses/transfer';

export const getRequestsRequestAction = createAsyncAction<
  RequestsQuery,
  [RequestsQuery, ListResponse<RequestResponse>],
  Error
>('GET_REQUESTS_REQUEST');

export const getDetailRequestRequestAction = createAsyncAction<
  string,
  DetailRequestResponse,
  Error
>('GET_DETAIL_REQUEST_REQUEST');

export const getDetailRequestChatRequestAction = createAsyncAction<
  RequestChatQuery,
  [RequestChatQuery, ListResponse<RequestChatMessage>],
  Error
>('GET_DETAIL_REQUEST_CHAT_REQUEST');

export const updateDetailRequestChatRequestAction = createAsyncAction<
  string,
  ListResponse<RequestChatMessage>,
  Error
>('UPDATE_DETAIL_REQUEST_CHAT_REQUEST');

export const sendRequestMessageRequestAction = createAsyncAction<
  RequestSendMessageRequest,
  ListResponse<RequestChatMessage>,
  Error
>('SEND_REQUEST_MESSAGE_REQUEST');

export const getTransferRequestAction = createAsyncAction<
  [number, string],
  [number, TransferResponse],
  [number, Error]
>('GET_TRANSFER_REQUEST');

export const acceptTransferRequestAction = createAsyncAction<
  [number, string],
  [number, TransferResponse],
  [number, Error]
>('ACCEPT_TRANSFER_REQUEST');

export const rejectTransferRequestAction = createAsyncAction<
  [number, string],
  [number, TransferResponse],
  [number, Error]
>('REJECT_TRANSFER_REQUEST');

export const getCalendarRequestsAction = createAsyncAction<
  CalendarRequestsQuery,
  ListResponse<CalendarRequestResponse>,
  Error
>('GET_CALENDAR_REQUESTS');
