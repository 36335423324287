import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {ListResponse} from '../../../api/models/response/list';
import {PaginationRequest} from '../../../utils/redux/create-pagination-request-reducer';
import {SplitPaymentResponse} from '../../../api/split-payments/models/responses/split-payment';
import {SplitPaymentDetailResponse} from '../../../api/split-payments/models/responses/detail';
import {UpdateSplitPaymentRequest} from '../../../api/split-payments/models/requests/detail';
import {CreateSplitPaymentRequest} from '../../../api/split-payments/models/requests/split-payment';

export const getSplitPaymentListRequestAction = createAsyncAction<
  PaginationRequest,
  [PaginationRequest, ListResponse<SplitPaymentResponse>],
  Error
>('GET_SPLIT_PAYMENT_LIST_REQUEST');

export const getSplitPaymentDetailRequestAction = createAsyncAction<
  string,
  SplitPaymentDetailResponse,
  Error
>('GET_SPLIT_PAYMENT_REQUEST');

export const acceptSplitPaymentRequestAction = createAsyncAction<
  [string, UpdateSplitPaymentRequest],
  SplitPaymentDetailResponse,
  Error
>('ACCEPT_SPLIT_PAYMENT_REQUEST');

export const rejectSplitPaymentRequestAction = createAsyncAction<
  [string, UpdateSplitPaymentRequest],
  SplitPaymentDetailResponse,
  Error
>('REJECT_SPLIT_PAYMENT_REQUEST');

export const createSplitPaymentRequestAction = createAsyncAction<
  CreateSplitPaymentRequest,
  SplitPaymentDetailResponse,
  Error
>('CREATE_SPLIT_PAYMENT_REQUEST');
