import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {RadioGroup, Radio} from '@ui-kitten/components';
import {
  NewRequestQuestionFieldContainer,
  NewRequestQuestionFieldTitle,
} from '../../styles';

export const NewRequestQuestionFieldRadioGroupContainer = styled(
  NewRequestQuestionFieldContainer,
)`
  padding-left: 0;
  padding-right: 0;
`;

export const NewRequestQuestionFieldRadioGroupTitle = styled(
  NewRequestQuestionFieldTitle,
)`
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestQuestionFieldRadioGroup = styled(RadioGroup)`
  background-color: ${props => props.theme.colors.white};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;
export const NewRequestQuestionFieldRadioItem = styled(Radio)<{last: boolean}>`
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
  border-bottom-width: ${props => (props.last ? '0' : '1px')};
  border-bottom-color: ${props => props.theme.colors['color-basic-200']};
`;
