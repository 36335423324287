import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const LoginLogo = (props: SvgProps) => (
  <Svg width={68} height={40} fill="none" viewBox="0 0 68 40" {...props}>
    <Path
      d="M10.866 29.246c-.223-.495-.647-.767-1.196-.767-.55 0-.977.276-1.193.76L4.121 38.39c-.288.71.05 1.183.46 1.387l.071.029c.55.182 1.053-.022 1.347-.552l1.034-2.194h5.276l1.017 2.161.018.033c.223.401.567.616.962.616.126 0 .255-.022.388-.065l.072-.028c.41-.204.743-.678.445-1.416l-4.342-9.116h-.003zm-2.974 5.938l1.778-3.788 1.777 3.788H7.888h.004zM23.66 36.574h-5.32v-8.432c0-.663-.41-1.093-1.045-1.093-.636 0-1.045.43-1.045 1.093v8.87c0 .902.542 1.443 1.447 1.443h5.959c.603 0 .995-.369.995-.938 0-.57-.392-.94-.995-.94l.003-.003zM42.442 29.246c-.222-.495-.646-.767-1.196-.767-.55 0-.977.276-1.192.76l-4.357 9.152c-.287.71.05 1.183.46 1.387l.072.029c.55.182 1.052-.022 1.347-.552l1.034-2.194h5.276l1.017 2.161.017.033c.223.401.568.616.963.616.126 0 .255-.022.388-.065l.072-.028c.41-.204.743-.678.445-1.416l-4.342-9.116h-.004zm-2.974 5.938l1.778-3.788 1.778 3.788h-3.56.005zM55.236 36.574h-5.32v-8.432c0-.663-.409-1.093-1.044-1.093-.636 0-1.045.43-1.045 1.093v8.87c0 .902.542 1.443 1.447 1.443h5.958c.604 0 .995-.369.995-.938 0-.57-.391-.94-.995-.94l.004-.003zM58.723 28.482c-.56 0-1.017.459-1.017 1.015v9.489c0 .559.46 1.014 1.017 1.014h6.073c.56 0 1.016-.459 1.016-1.014v-9.49c0-.559-.46-1.014-1.016-1.014h-6.073zm3.038 11.195c-.391 0-.711-.3-.711-.67 0-.369.316-.67.711-.67.395 0 .711.301.711.67 0 .37-.316.67-.71.67zm2.54-2.659c0 .56-.46 1.014-1.017 1.014h-3.046c-.56 0-1.016-.458-1.016-1.014v-5.952c0-.559.46-1.014 1.016-1.014h3.046c.56 0 1.016.459 1.016 1.014v5.952zM33.977 30.432c-1.077-1.075-2.388-1.62-3.904-1.62-1.515 0-2.826.545-3.904 1.62-1.077 1.075-1.623 2.383-1.623 3.895s.546 2.824 1.623 3.895c1.078 1.076 2.389 1.62 3.904 1.62 1.516 0 2.827-.544 3.904-1.62 1.078-1.075 1.624-2.383 1.624-3.895s-.546-2.82-1.624-3.895zm-3.904 7.558c-1.02 0-1.867-.352-2.593-1.076-.725-.723-1.077-1.57-1.077-2.587 0-1.018.352-1.863 1.077-2.587.726-.724 1.573-1.075 2.593-1.075s1.868.35 2.593 1.075c.726.724 1.078 1.57 1.078 2.587 0 1.018-.352 1.864-1.078 2.587-.725.724-1.573 1.076-2.593 1.076z"
      fill="#231916"
    />
    <Path
      d="M33.705 24.68c-1.365 0-2.647-.473-3.617-1.333-1.099-.975-1.702-2.387-1.702-3.978 0-2.078 1.34-5.271 3.462-8.471-2.564-3.824-6.156-7.318-9.596-7.318-2.425 0-6.828 1.383-16.324 13.044-.625.767-4.988 3.752-5.757 3.129-.769-.624 1.268-4.261 1.893-5.028C10.022 4.956 16.814 0 22.252 0 26.73 0 30.993 3.612 34.1 7.873 37.648 3.601 42.35 0 46.724 0c5.125 0 10.692 4.268 19.211 14.729.625.766 2.665 4.404 1.893 5.027-.769.624-5.132-2.358-5.757-3.128-7.55-9.27-11.536-13.044-15.347-13.044-2.719 0-6.35 2.39-9.708 6.39-.301.357-.589.716-.865 1.078 1.8 3.182 2.877 6.325 2.877 8.32 0 3.484-2.676 5.308-5.32 5.308h-.003zm.176-10.26c-1.225 2.118-1.904 3.95-1.904 4.953 0 1.602 1.322 1.723 1.728 1.723.406 0 1.727-.125 1.727-1.723 0-1.147-.578-2.96-1.551-4.953z"
      fill="#F2941A"
    />
    <Path
      d="M9.82 12.732s1.272 3.81 10.183 3.81c7.635 0 8.907-3.81 8.907-3.81s-3.818 1.27-8.907 1.27c-5.09 0-10.182-1.27-10.182-1.27z"
      fill="#F2941A"
    />
    <Path
      d="M14.25 14.76s.405 0-1.631 1.997c-2.036 1.996-3.742 1.512-3.742 1.512 1.232-1.293 2.399-2.748 3.078-3.849M15.726 15.04l-1.358 3.49s1.343-.017 2.471-1.766c1.128-1.749.83-1.666.83-1.666M25.915 14.682l2.212 3.02s-1.3.327-2.844-1.07c-1.545-1.398-1.232-1.398-1.232-1.398M22.64 15.015l.883 3.637s-1.329-.193-2.216-2.078c-.887-1.885-.604-1.763-.604-1.763M57.6 12.518s-1.272 3.809-10.183 3.809c-7.635 0-8.907-3.81-8.907-3.81s3.818 1.269 8.907 1.269c5.09 0 10.182-1.268 10.182-1.268z"
      fill="#F2941A"
    />
    <Path
      d="M53.17 14.306s-.405 0 1.631 1.996c2.037 1.996 3.743 1.512 3.743 1.512-1.232-1.294-2.4-2.749-3.078-3.849M51.694 14.585l1.358 3.49s-1.343-.017-2.47-1.766c-1.129-1.749-.83-1.667-.83-1.667M41.505 14.227l-2.212 3.02s1.3.327 2.844-1.07c1.545-1.398 1.232-1.398 1.232-1.398M44.78 14.56l-.883 3.637s1.33-.193 2.216-2.078c.89-1.881.604-1.763.604-1.763"
      fill="#F2941A"
    />
  </Svg>
);

export default LoginLogo;
