import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath} from 'react-native-svg';

const ArrowBottom = ({color, ...props}: SvgProps & {color?: string}) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="#fff" {...props}>
    <G clipPath="url(#a)">
      <Path
        d="m12 13.172 4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222l4.95 4.95Z"
        fill={color || '#000'}
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ArrowBottom;
