import React, {PropsWithChildren} from 'react';
import {useLinkProps} from '@react-navigation/native';
import {To} from '@react-navigation/native/lib/typescript/src/useLinkTo';
import {Button, ButtonProps} from '@ui-kitten/components';

export default function RouteLink({
  to,
  children,
  ...rest
}: PropsWithChildren<ButtonProps> & {to: To}) {
  const props = useLinkProps({to});

  return (
    <Button {...props} {...rest}>
      {children}
    </Button>
  );
}
