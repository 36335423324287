import {all, call, put, takeLatest} from 'redux-saga/effects';
import {SettingsResponse} from '../../../api/settings/models/settingsResponse';
import {postalInfoRequestAction, settingsRequestAction} from './action';
import api from '../../../api';
import {DefaultResponse} from '../../../api/models/response/default';
import {getSettings, saveSettings} from '../../../storage';
import {PostInfoResponse} from '../../../api/postcode-info/models/responses';

const settingsRequest = function* () {
  try {
    const settingsResponse: DefaultResponse<Array<SettingsResponse>> =
      yield call([api, api.settings.getSettings]);

    yield put(settingsRequestAction.success(settingsResponse.data));
    yield call(saveSettings, settingsResponse.data);
  } catch (error) {
    const prefSettings = yield call(getSettings);

    if (prefSettings !== null) {
      yield put(settingsRequestAction.success(prefSettings));
    } else {
      yield put(settingsRequestAction.failure(error as Error));
    }
  }
};

const postalinfoRequest = function* (
  action: ReturnType<typeof postalInfoRequestAction.request>,
) {
  try {
    const response: DefaultResponse<PostInfoResponse> = yield call(
      [api, api.postCode.getPostcodeInfo],
      action.payload,
    );
    yield put(postalInfoRequestAction.success(response.data));
  } catch (error) {
    yield put(postalInfoRequestAction.failure(error as Error));
  }
};

export const settingsSaga = function* () {
  yield all([takeLatest(settingsRequestAction.request, settingsRequest)]);
  yield all([takeLatest(postalInfoRequestAction.request, postalinfoRequest)]);
};
