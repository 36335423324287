import React, {useCallback, useEffect} from 'react';
import {ListRenderItemInfo} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useTheme} from 'styled-components/native';
import {Route, useFocusEffect, useRoute} from '@react-navigation/native';

import {ReviewsRequest} from '../../../api/shops';
import type {ShopReviewsResponse} from '../../../api/shops/models/responses/shop-reviews';
import {getShopReviewsListRequestAction} from '../store/action';
import {useStrings} from '../../../utils/providers/strings';
import {REQUESTS_SHOP_REVIEWS_LIMIT} from '../store/saga';
import {REQUESTS_LIMIT} from '../../requests/store/saga';
import {
  useAppDispatch,
  usePaginationRequestSelector,
} from '../../../store/store';

import AppStatusBar from '../../../uikit/AppStatusBar';
import {AppLayout} from '../../../uikit/AppLayout';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import AppRefreshControl from '../../../uikit/AppRefreshControl';
import {ReviewsEmptyList, ShopReviewCard} from './ui';

import {ShopsContainerContainer} from '../styles';
import {
  AppTransparentHeader,
  ScreenListLoading,
  ScreenListLoadingContainer,
  ShopReviewsViewedContainerContent,
  ShopReviewsViewedList,
  ShopReviewsViewedListContainer,
  ShopReviewsAddNewReview,
} from './styles';

export default function ReviewsShopScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  const shopReviewsListRequest = usePaginationRequestSelector(
    store => store.shops.shopReviewsListRequest,
  );

  const isEmptyList = !shopReviewsListRequest.data?.items.length;

  const onFirstPage = useCallback(() => {
    const request: ReviewsRequest = {
      limit: REQUESTS_SHOP_REVIEWS_LIMIT,
      offset: 0,
      shopId: Number(requestId),
    };

    dispatch(getShopReviewsListRequestAction.request(request));
  }, [dispatch, requestId]);

  const onInit = useCallback(() => {
    dispatch(getShopReviewsListRequestAction.clean());
    onFirstPage();
  }, [dispatch, onFirstPage]);

  useFocusEffect(onInit);

  const onNextPage = () => {
    if (
      shopReviewsListRequest.data?.isLastPage ||
      shopReviewsListRequest.isLoading
    )
      return;

    const request: ReviewsRequest = {
      limit: REQUESTS_LIMIT,
      offset: shopReviewsListRequest.data?.items.length || 0,
      shopId: Number(requestId),
    };

    dispatch(getShopReviewsListRequestAction.request(request));
  };

  useEffect(() => {
    if (
      shopReviewsListRequest.error &&
      shopReviewsListRequest.data?.items.length
    ) {
      toast.show(shopReviewsListRequest.error.message);
    }
  }, [shopReviewsListRequest]);

  useEffect(() => {
    return function () {
      dispatch(getShopReviewsListRequestAction.clean());
    };
  }, []);

  const renderShopCardItem = ({
    item,
  }: ListRenderItemInfo<ShopReviewsResponse>) => {
    return <ShopReviewCard review={item} />;
  };

  const renderScreenListLoading = () => {
    return (
      <ScreenListLoadingContainer>
        <ScreenListLoading />
      </ScreenListLoadingContainer>
    );
  };

  const renderScreenListEmpty = () => <ReviewsEmptyList />;

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopReviewsViewedContainerContent>
          <AppLayout>
            {isEmptyList && shopReviewsListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && shopReviewsListRequest.error ? (
              <AppError
                error={shopReviewsListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              <>
                <AppTransparentHeader
                  title={strings.shop_detail_reviews_title}
                  backNoHistoryRoute="/shops"
                  rightButton={
                    <ShopReviewsAddNewReview
                      to={`/shops/${requestId}/reviews/add`}>
                      {strings.shop_detail_add_new_review_button.toLocaleUpperCase()}
                    </ShopReviewsAddNewReview>
                  }
                />
                {shopReviewsListRequest.data && (
                  <ShopReviewsViewedListContainer>
                    <ShopReviewsViewedList
                      numColumns={theme.isDesktop ? 3 : 1}
                      refreshControl={
                        <AppRefreshControl
                          onRefresh={onFirstPage}
                          refreshing={shopReviewsListRequest.isFirstLoading}
                        />
                      }
                      data={shopReviewsListRequest.data.items}
                      renderItem={renderShopCardItem}
                      keyExtractor={item => String(item.id)}
                      onEndReached={onNextPage}
                      ListFooterComponent={
                        shopReviewsListRequest.isLoading
                          ? renderScreenListLoading
                          : null
                      }
                      ListEmptyComponent={renderScreenListEmpty}
                    />
                  </ShopReviewsViewedListContainer>
                )}
              </>
            )}
          </AppLayout>
        </ShopReviewsViewedContainerContent>
      </ShopsContainerContainer>
    </>
  );
}
