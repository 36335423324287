import styled from 'styled-components/native';
import {AppButton} from '../../../uikit/AppButtons';
import AppHeader from '../../../uikit/AppHeader';

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  margin-bottom: 20px;
`;

export const ContactUsContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  background-color: ${props => props.theme.colors['color-basic-100']};
`;

export const ContactUsContentWrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  ${props => (props.theme.isDesktop ? 'padding: 48px 24px 0 40px;' : '')};
`;

export const ContactUsContent = styled.ScrollView`
  padding-bottom: 60px;
`;

export const ContactUsText = styled.Text`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors['color-basic-900']};

  margin: ${props => (props.theme.isDesktop ? '0 0 24px 0' : '0 16px 12px')};
`;

export const ContactUsContactUsButton = styled(AppButton)`
  color: ${props => props.theme.colors['color-basic-900']};
  text-transform: uppercase;
  text-align: center;

  max-width: ${props => (props.theme.isDesktop ? '160px' : '100%')};
  margin: ${props => (props.theme.isDesktop ? '24px 0 0 auto' : '20px 16px 0')};
  width: ${props => (props.theme.isDesktop ? '100%' : 'auto')};
`;
