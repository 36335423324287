import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import api from '../../../api';
import {
  acceptTransferRequestAction,
  getCalendarRequestsAction,
  getDetailRequestChatRequestAction,
  getDetailRequestRequestAction,
  getRequestsRequestAction,
  getTransferRequestAction,
  rejectTransferRequestAction,
  sendRequestMessageRequestAction,
  updateDetailRequestChatRequestAction,
} from './action';
import {ListResponse} from '../../../api/models/response/list';
import {
  CalendarRequestResponse,
  RequestResponse,
} from '../../../api/request/models/responses';
import {DefaultResponse} from '../../../api/models/response/default';
import {DetailRequestResponse} from '../../../api/request/models/responses/detail';
import {RequestChatMessage} from '../../../api/request/models/responses/chat';
import {
  TransferRequestStatus,
  TransferResponse,
} from '../../../api/request/models/responses/transfer';
import {CalendarRequestsQuery} from '../../../api/request/models/requests';

export const REQUESTS_LIMIT = 10;
export const REQUEST_CHAT_LIMIT = 30;
export const CALENDAR_REQUESTS_LIMIT = 100;

const getRequestsRequest = function* (
  action: ReturnType<typeof getRequestsRequestAction.request>,
) {
  try {
    const response: ListResponse<RequestResponse> = yield call(
      [api, api.request.getRequests],
      action.payload,
    );
    yield put(getRequestsRequestAction.success([action.payload, response]));
  } catch (error) {
    yield put(getRequestsRequestAction.failure(error as Error));
  }
};

const getDetailRequestRequest = function* (
  action: ReturnType<typeof getDetailRequestRequestAction.request>,
) {
  try {
    const response: DefaultResponse<DetailRequestResponse> = yield call(
      [api, api.request.getDetailRequest],
      action.payload,
    );
    yield put(getDetailRequestRequestAction.success(response.data));
  } catch (error) {
    yield put(getDetailRequestRequestAction.failure(error as Error));
  }
};

const getDetailRequestChatRequest = function* (
  action: ReturnType<typeof getDetailRequestChatRequestAction.request>,
) {
  try {
    const response: ListResponse<RequestChatMessage> = yield call(
      [api, api.request.getDetailRequestChat],
      action.payload,
    );
    yield put(
      getDetailRequestChatRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(getDetailRequestChatRequestAction.failure(error as Error));
  }
};

const updateDetailRequestChatRequest = function* (
  action: ReturnType<typeof updateDetailRequestChatRequestAction.request>,
) {
  try {
    const response: ListResponse<RequestChatMessage> = yield call(
      [api, api.request.getDetailRequestChat],
      {
        id: action.payload,
        limit: REQUEST_CHAT_LIMIT,
        offset: 0,
      },
    );
    yield put(updateDetailRequestChatRequestAction.success(response));
  } catch (error) {
    yield put(updateDetailRequestChatRequestAction.failure(error as Error));
  }
};

const sendRequestMessageRequest = function* (
  action: ReturnType<typeof sendRequestMessageRequestAction.request>,
) {
  try {
    const response: ListResponse<RequestChatMessage> = yield call(
      [api, api.request.sendRequestMessage],
      action.payload,
    );
    yield put(sendRequestMessageRequestAction.success(response));
  } catch (error) {
    yield put(sendRequestMessageRequestAction.failure(error as Error));
  }
};

const getTransferRequest = function* (
  action: ReturnType<typeof getTransferRequestAction.request>,
) {
  const [id, transferId] = action.payload;
  try {
    const response: DefaultResponse<TransferResponse> = yield call(
      [api, api.request.getTransferRequest],
      transferId,
    );
    yield put(getTransferRequestAction.success([id, response.data]));
  } catch (error) {
    yield put(getTransferRequestAction.failure([id, error as Error]));
  }
};

const acceptTransferRequest = function* (
  action: ReturnType<typeof acceptTransferRequestAction.request>,
) {
  const [id, transferId] = action.payload;
  try {
    const response: DefaultResponse<TransferResponse> = yield call(
      [api, api.request.updateTransferRequest],
      transferId,
      {
        status: TransferRequestStatus.APPROVED_BY_USER,
      },
    );
    yield put(acceptTransferRequestAction.success([id, response.data]));
  } catch (error) {
    yield put(acceptTransferRequestAction.failure([id, error as Error]));
  }
};

const rejectTransferRequest = function* (
  action: ReturnType<typeof rejectTransferRequestAction.request>,
) {
  const [id, transferId] = action.payload;
  try {
    const response: DefaultResponse<TransferResponse> = yield call(
      [api, api.request.updateTransferRequest],
      transferId,
      {
        status: TransferRequestStatus.REJECTED,
      },
    );
    yield put(rejectTransferRequestAction.success([id, response.data]));
  } catch (error) {
    yield put(rejectTransferRequestAction.failure([id, error as Error]));
  }
};

const getCalendarRequests = function* (
  action: ReturnType<typeof getCalendarRequestsAction.request>,
) {
  try {
    const response: ListResponse<CalendarRequestResponse> = yield call(
      [api, api.request.getRequestsByDate],
      {
        ...action.payload,
      } as CalendarRequestsQuery,
    );
    yield put(getCalendarRequestsAction.success(response));
  } catch (error) {
    yield put(getCalendarRequestsAction.failure(error as Error));
  }
};

export const requestsSaga = function* () {
  yield all([
    takeLatest(getRequestsRequestAction.request, getRequestsRequest),
    takeLatest(getDetailRequestRequestAction.request, getDetailRequestRequest),
    takeLatest(
      getDetailRequestChatRequestAction.request,
      getDetailRequestChatRequest,
    ),
    takeLatest(
      updateDetailRequestChatRequestAction.request,
      updateDetailRequestChatRequest,
    ),
    takeLatest(
      sendRequestMessageRequestAction.request,
      sendRequestMessageRequest,
    ),
    takeEvery(getTransferRequestAction.request, getTransferRequest),
    takeEvery(acceptTransferRequestAction.request, acceptTransferRequest),
    takeEvery(rejectTransferRequestAction.request, rejectTransferRequest),
    takeLatest(getCalendarRequestsAction.request, getCalendarRequests),
  ]);
};
