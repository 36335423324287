import React, {ComponentProps, ComponentType} from 'react';
import styled from 'styled-components/native';
import Call from '../../../../../assets/icons/Call';
import {ImageProps, ImageStyle} from 'react-native';
import {AppButton} from '../../../../../uikit/AppButtons';
import Logo from '../../../../../assets/icons/LoginLogo';

export const MainHeaderContainer = styled.View`
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 128px;
  padding-right: 128px;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors['color-basic-300']};
`;

export const MainHeaderLogoContainer = styled.View`
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const MainHeaderLogo = styled<
  ComponentType<ImageProps & ComponentProps<typeof Logo>>
>(Logo)``;

export const MainHeaderCallButton = styled(AppButton).attrs({
  accessoryLeft: props => (
    <Call
      {...props}
      fill={(props?.style as ImageStyle)?.tintColor}
      width={(props?.style as ImageStyle)?.width}
      height={(props?.style as ImageStyle)?.height}
    />
  ),
  size: 'small',
})``;
