import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {ListResponse} from '../../../api/models/response/list';
import {
  Friend,
  SearchFriend,
} from '../../../api/friend/models/responses/friend';
import {AddFriendRequest} from '../../../api/friend/models/requests/add';
import {FriendRequest} from '../../../api/friend/models/requests/friend';

export const getFriendListRequestAction = createAsyncAction<
  FriendRequest,
  [FriendRequest, ListResponse<Friend>],
  Error
>('GET_FRIEND_LIST_REQUEST');

export const addFriendRequestAction = createAsyncAction<
  AddFriendRequest,
  Friend,
  Error
>('ADD_FRIEND_REQUEST');

export const acceptFriendRequestAction = createAsyncAction<
  [number, void],
  [number, Friend],
  [number, Error]
>('ACCEPT_FRIEND_REQUEST');

export const rejectFriendRequestAction = createAsyncAction<
  [number, void],
  [number, object],
  [number, Error]
>('REJECT_FRIEND_REQUEST');

export const deleteFriendRequestAction = createAsyncAction<
  [number, void],
  [number, object],
  [number, Error]
>('DELETE_FRIEND_REQUEST');

export const searchFriendRequestAction = createAsyncAction<
  string,
  SearchFriend | null,
  Error
>('SEARCH_FRIEND_REQUEST');
