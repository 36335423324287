import React from 'react';
// import {Route, useRoute} from '@react-navigation/native';

import {useStrings} from '../../../../../utils/providers/strings';
import type {ShopCommitmentsResponse} from '../../../../../api/shops/models/responses/shop-commitments';

import {
  ShopCommitmentContainer,
  ShopCommitmentHeadContainer,
  // ShopCommitmentHeadLink,
  ShopCommitmentHeadTitle,
  ShopCommitmentItemContainer,
  ShopCommitmentItemDescription,
  ShopCommitmentItemImage,
  ShopCommitmentItemImageContainer,
  ShopCommitmentItemTitle,
  ShopCommitmentSection,
} from './styles';

interface IProps {
  commitments: ShopCommitmentsResponse[];
}

export default function ShopCommitment({commitments}: IProps) {
  const strings = useStrings();
  // const route = useRoute<Route<string, {id: string}>>();
  // const requestId = route.params.id;

  return (
    <ShopCommitmentSection>
      <ShopCommitmentHeadContainer>
        <ShopCommitmentHeadTitle>
          {strings.shop_detail_commitment_title}
        </ShopCommitmentHeadTitle>
        {/* {commitments.length > 3 && <ShopCommitmentHeadLink to={`/${requestId}/commitments`}>
          {strings.shop_see_all_button}
        </ShopCommitmentHeadLink>} */}
      </ShopCommitmentHeadContainer>
      <ShopCommitmentContainer horizontal>
        {commitments.map((commitment, index) => (
          <ShopCommitmentItemContainer
            key={commitment.id}
            // to={`${requestId}/commitment/${commitment.id}`}
            lastItem={commitments.length === index + 1}>
            <ShopCommitmentItemImageContainer>
              <ShopCommitmentItemImage
                source={{uri: commitment.imageData.imageUrl}}
              />
            </ShopCommitmentItemImageContainer>
            <ShopCommitmentItemTitle>{commitment.name}</ShopCommitmentItemTitle>
            <ShopCommitmentItemDescription numberOfLines={3}>
              {commitment.description}
            </ShopCommitmentItemDescription>
          </ShopCommitmentItemContainer>
        ))}
      </ShopCommitmentContainer>
    </ShopCommitmentSection>
  );
}
