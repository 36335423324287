import {AxiosInstance} from 'axios';
import {RequestCategory} from './models/responses/categories';
import {RequestCategoryType} from './models/responses/category-type';
import {CalendarRequestResponse, RequestResponse} from './models/responses';
import {RequestCreateRequest} from './models/requests/create';
import {DefaultResponse} from '../models/response/default';
import {ListResponse} from '../models/response/list';
import {CalendarRequestsQuery, RequestsQuery} from './models/requests';
import {DetailRequestResponse} from './models/responses/detail';
import {RequestChatMessage} from './models/responses/chat';
import {RequestChatQuery, RequestUploadFile} from './models/requests/chat';
import {RequestSendMessageRequest} from './models/requests/send';
import {TransferResponse} from './models/responses/transfer';
import {UpdateTransferRequest} from './models/requests/update-transfer';

export default class RequestApi {
  constructor(private axiosInstance: AxiosInstance) {}

  getRequests(query: RequestsQuery): Promise<ListResponse<RequestResponse>> {
    return this.axiosInstance.get('service-request', {
      params: query,
    });
  }

  getRequestsByDate(
    query: CalendarRequestsQuery,
  ): Promise<ListResponse<CalendarRequestResponse>> {
    return this.axiosInstance.get('service-request/by-dates', {
      params: query,
    });
  }

  getDetailRequest(
    id: string,
  ): Promise<DefaultResponse<DetailRequestResponse>> {
    return this.axiosInstance.get(`service-request/${id}`);
  }

  getDetailRequestChat({
    id,
    ...query
  }: RequestChatQuery): Promise<ListResponse<RequestChatMessage>> {
    return this.axiosInstance.get(`service-request/${id}/message`, {
      params: query,
    });
  }

  sendRequestMessage({
    id,
    ...request
  }: RequestSendMessageRequest): Promise<ListResponse<RequestChatMessage>> {
    return this.axiosInstance.post(`service-request/${id}/message`, request);
  }

  getTransferRequest(id: string): Promise<DefaultResponse<TransferResponse>> {
    return this.axiosInstance.get(`transfer-request/${id}`);
  }

  updateTransferRequest(
    id: string,
    request: UpdateTransferRequest,
  ): Promise<DefaultResponse<TransferResponse>> {
    return this.axiosInstance.put(`transfer-request/${id}`, request);
  }

  getRequestCategories(): Promise<ListResponse<RequestCategory>> {
    return this.axiosInstance.get('service-request/type');
  }

  getRequestCategoryType(
    id: string,
  ): Promise<DefaultResponse<RequestCategoryType>> {
    return this.axiosInstance.get(`service-request/type/${id}`);
  }

  createRequest(
    request: RequestCreateRequest,
  ): Promise<DefaultResponse<RequestResponse>> {
    return this.axiosInstance.post(`service-request`, request);
  }

  uploadFile(request: RequestUploadFile): Promise<DefaultResponse<string>> {
    return this.axiosInstance.post(`file/upload`, request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
}
