import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const CP = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      fillRule="evenodd"
      d="M3.29 3.29l13.707-1.957 13.67 13.728-15.63 15.606L1.334 16.98 3.291 3.29zm7.214 8.698a2.77 2.77 0 001.71-2.555 2.764 2.764 0 00-2.769-2.766 2.772 2.772 0 00-2.558 1.706 2.762 2.762 0 001.498 3.614 2.772 2.772 0 002.12 0zm11.297 5.805c.392.403.683.672 1.002.911l-1.075 1.106a8.473 8.473 0 00-.878-1.023l-3.927-4.043a9.362 9.362 0 00-.995-.905c.327-.306.559-.53.944-.926l1.517-1.562c.704-.725 1.365-1.061 2.062-1.061.588-.008 1.176.284 1.698.822.552.568.835 1.203.85 1.875 0 .733-.327 1.413-1.024 2.13l-1.386 1.428 1.212 1.248zm-4-4.118l1.953 2.01 1.386-1.427c.668-.688.676-1.427.037-2.085-.29-.299-.61-.463-.937-.47-.348-.016-.668.149-1.052.545L17.8 13.675zm.057 7.347c-.058-.313-.153-.53-.428-.934l1.22-.882c.384.68.536 1.107.602 1.622.094.95-.32 1.958-1.19 2.855-.792.814-1.663 1.218-2.564 1.203-1.038-.023-2.141-.575-3.07-1.532-.959-.986-1.496-2.167-1.496-3.288-.007-.904.4-1.831 1.125-2.578.567-.583 1.104-.912 1.743-1.076.53-.127 1.06-.12 1.56.022.24.067.422.15.785.344l-.777 1.278c-.421-.225-.625-.3-.937-.322-.544-.052-1.06.165-1.51.628-1.03 1.061-.85 2.533.487 3.908 1.328 1.368 2.795 1.562 3.811.516.508-.523.748-1.173.639-1.764z"
      clipRule="evenodd"
    />
  </Svg>
);

export default CP;
