import React from 'react';
import {
  DetailRequestChatCommonContainer,
  DetailRequestChatCommonIcon,
  DetailRequestChatCommonInfoBubble,
  DetailRequestChatCommonInfoContainer,
  DetailRequestChatCommonInfoType,
} from './styles';
import {RequestChatMessageSystem} from '../../../../../../../../api/request/models/responses/chat';

export default function DetailRequestChatSystem({
  message,
  type,
}: {
  message: RequestChatMessageSystem;
  type: string;
}) {
  return (
    <DetailRequestChatCommonContainer>
      <DetailRequestChatCommonInfoType>{type}</DetailRequestChatCommonInfoType>
      <DetailRequestChatCommonInfoContainer>
        <DetailRequestChatCommonIcon />
        <DetailRequestChatCommonInfoBubble>
          {message.messageText}
        </DetailRequestChatCommonInfoBubble>
      </DetailRequestChatCommonInfoContainer>
    </DetailRequestChatCommonContainer>
  );
}
