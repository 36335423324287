import React, {useCallback, useEffect, useRef, useState} from 'react';
import {JSEncrypt} from 'jsencrypt';
import Config from 'react-native-config';
import {
  RegisterBackButton,
  RegisterButtons,
  RegisterCheckbox,
  RegisterCheckboxText,
  RegisterContainer,
  RegisterContent,
  RegisterEye,
  RegisterEyeOff,
  RegisterInput,
  RegisterInputErrorText,
  RegisterInputs,
  RegisterNextButton,
  RegisterRules,
  RegisterSteps,
  RegisterStepTitle,
  RegisterTermOfService,
  RegisterTermOfServiceText,
} from './styles';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {AppLayout} from '../../uikit/AppLayout';
import AppHeader from '../../uikit/AppHeader';
import {useStrings} from '../../utils/providers/strings';
import {BackHandler, ScrollView, TouchableWithoutFeedback} from 'react-native';
import useRouter from '../../utils/use-router';
import {useAppDispatch, useRequestSelector} from '../../store/store';
import {useToast} from 'react-native-toast-notifications';
import {
  Control,
  Controller,
  useForm,
  FieldPath,
  FieldValues,
  Validate,
  FieldPathValue,
} from 'react-hook-form';
import {formatWithMask} from 'react-native-mask-input';
import {registerRequestAction} from './store/action';
import {AccessoryLoadingIndicator} from '../../uikit/AccessoryLoadingIndicator';
import {FieldServerError} from '../../api/models/field-server-error';
import {RegisterRequest} from '../../api/user/models/requests/register';
import validatePassword from '../../utils/validate-password';
import validateEmail from '../../utils/validate-email';
import {postalInfoRequestAction} from '../app/store/action';
import useDebounce from '../../utils/use-debounce';

interface RegisterFirstForm {
  clientId: string;
  firstName: string;
  lastName: string;
  seiFurigana: string;
  meiFurigana: string;
  phone: string;
  email: string;
}

interface RegisterSecondForm {
  postCode: string;
  prefectures: string;
  municipalities: string;
  streetAddress: string;
  buildingName: string;
  companyName: string;
}

interface RegisterThirdForm {
  password: string;
  repeatPassword: string;
  acceptTermOfService: boolean;
}

export default function RegisterScreen() {
  const toast = useToast();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const crypto = new JSEncrypt();
  const registerRequest = useRequestSelector(
    store => store.register.registerRequest,
  );
  const postalinfoRequest = useRequestSelector(
    store => store.app.postalinfoRequest,
  );

  const inputsRef = useRef<ScrollView | null>(null);
  const [screenStep, setScreenStep] = useState(RegisterScreenStepType.FIRST);

  const {
    control: firstControl,
    handleSubmit: firstHandleSubmit,
    setError: firstSetError,
    getValues: firstGetValues,
  } = useForm<RegisterFirstForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      seiFurigana: '',
      meiFurigana: '',
      phone: '',
      email: '',
    },
  });

  const {
    control: secondControl,
    handleSubmit: secondHandleSubmit,
    setError: secondSetError,
    getValues: secondGetValues,
    watch: secondWatch,
    setValue: setSecondValue,
  } = useForm<RegisterSecondForm>({
    defaultValues: {
      postCode: '',
      prefectures: '',
      municipalities: '',
      streetAddress: '',
      buildingName: '',
      companyName: '',
    },
  });

  const {
    control: thirdControl,
    handleSubmit: thirdHandleSubmit,
    setError: thirdSetError,
    watch: thirdWatch,
  } = useForm<RegisterThirdForm>({
    defaultValues: {
      password: '',
      repeatPassword: '',
      acceptTermOfService: false,
    },
  });

  const watchPostcode = useDebounce(secondWatch('postCode'), 500);

  useEffect(() => {
    if (watchPostcode.length === 7) {
      crypto.setPublicKey(Config.POSTCODE_PUBLIC_KEY);
      const encrypted = crypto.encrypt(watchPostcode);

      dispatch(
        postalInfoRequestAction.request({
          encryptPostalCode: encrypted || '',
        }),
      );
    }
  }, [dispatch, watchPostcode]);

  useEffect(() => {
    if (postalinfoRequest.error) {
      toast.show(postalinfoRequest.error.message);
      return;
    }

    if (postalinfoRequest.data) {
      const postalDataFields = postalinfoRequest?.data;
      setSecondValue('prefectures', postalDataFields?.pref);
      setSecondValue('municipalities', postalDataFields?.city);
      setSecondValue('streetAddress', postalDataFields?.town);
    }
  }, [postalinfoRequest]);

  useEffect(() => {
    return function () {
      dispatch(registerRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!registerRequest.error) return;

    if (registerRequest.error instanceof FieldServerError) {
      const errorFields = (registerRequest.error as FieldServerError).fields;

      if (errorFields.length) {
        errorFields.forEach(errorField => {
          switch (errorField.field as keyof RegisterRequest) {
            case 'firstName':
            case 'lastName':
            case 'seiFurigana':
            case 'meiFurigana':
            case 'phone':
            case 'email':
              firstSetError(errorField.field as keyof RegisterFirstForm, {
                type: 'custom',
                message: errorField.message,
              });
              break;

            case 'postCode':
            case 'prefectures':
            case 'municipalities':
            case 'streetAddress':
            case 'buildingName':
            case 'companyName':
              secondSetError(errorField.field as keyof RegisterSecondForm, {
                type: 'custom',
                message: errorField.message,
              });
              break;

            case 'password':
              thirdSetError(errorField.field as keyof RegisterThirdForm, {
                type: 'custom',
                message: errorField.message,
              });
              break;
          }
        });
      }
    }
    toast.show(registerRequest.error.message);
  }, [
    toast,
    registerRequest.error,
    firstSetError,
    secondSetError,
    thirdSetError,
  ]);

  const onBackPressed = useCallback((): boolean => {
    if (registerRequest.isLoading) return true;

    switch (screenStep) {
      case RegisterScreenStepType.FIRST:
        router.back('/login');
        return true;
      case RegisterScreenStepType.SECOND:
        setScreenStep(RegisterScreenStepType.FIRST);
        inputsRef.current?.scrollTo();
        return true;
      case RegisterScreenStepType.THIRD:
        setScreenStep(RegisterScreenStepType.SECOND);
        inputsRef.current?.scrollTo();
        return true;
    }

    return false;
  }, [registerRequest.isLoading, router, screenStep]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBackPressed,
    );

    return () => backHandler.remove();
  }, [onBackPressed]);

  const onNextFirstPressed = (form: RegisterFirstForm) => {
    setScreenStep(RegisterScreenStepType.SECOND);
    inputsRef.current?.scrollTo();
  };
  const onNextSecondPressed = (form: RegisterSecondForm) => {
    setScreenStep(RegisterScreenStepType.THIRD);
    inputsRef.current?.scrollTo();
  };
  const onNextThirdPressed = ({
    repeatPassword,
    acceptTermOfService,
    ...form
  }: RegisterThirdForm) => {
    const firstValues = firstGetValues();
    firstValues.phone = firstValues.phone.replace(/ /g, '');

    dispatch(
      registerRequestAction.request({
        ...firstValues,
        ...secondGetValues(),
        ...form,
      }),
    );
  };

  return (
    <>
      <AppWhiteStatusBar />
      <AppLayout>
        <RegisterContainer>
          <RegisterContent>
            <AppHeader
              title={strings.register_title}
              onBackPressed={onBackPressed}
            />
            <RegisterSteps>
              {strings.register_steps_fn(
                screenStep,
                RegisterScreenStepType.THIRD,
              )}
            </RegisterSteps>
            {screenStep !== RegisterScreenStepType.THIRD && (
              <RegisterStepTitle>
                {(() => {
                  switch (screenStep) {
                    case RegisterScreenStepType.FIRST:
                      return strings.register_first_step_title;
                    case RegisterScreenStepType.SECOND:
                      return strings.register_second_step_title;
                  }
                })()}
              </RegisterStepTitle>
            )}
            <RegisterInputs ref={inputsRef}>
              {(() => {
                switch (screenStep) {
                  case RegisterScreenStepType.FIRST:
                    return (
                      <>
                        <RegisterScreenInput
                          key="lastName"
                          name="lastName"
                          control={firstControl}
                          label={strings.register_last_name_label}
                          placeholder={strings.register_last_name_placeholder}
                          required={strings.register_last_name_required}
                          disabled={registerRequest.isLoading}
                        />
                        <RegisterScreenInput
                          key="firstName"
                          name="firstName"
                          control={firstControl}
                          label={strings.register_first_name_label}
                          placeholder={strings.register_first_name_placeholder}
                          required={strings.register_first_name_required}
                          disabled={registerRequest.isLoading}
                        />
                        <RegisterScreenInput
                          key="seiFurigana"
                          name="seiFurigana"
                          control={firstControl}
                          label={strings.register_sei_furigana_label}
                          placeholder={
                            strings.register_sei_furigana_placeholder
                          }
                          required={strings.register_sei_furigana_required}
                          disabled={registerRequest.isLoading}
                        />
                        <RegisterScreenInput
                          key="meiFurigana"
                          name="meiFurigana"
                          control={firstControl}
                          label={strings.register_mei_furigana_label}
                          placeholder={
                            strings.register_mei_furigana_placeholder
                          }
                          required={strings.register_mei_furigana_required}
                          disabled={registerRequest.isLoading}
                        />
                        <RegisterScreenPhoneInput
                          key="phone"
                          name="phone"
                          control={firstControl}
                          label={strings.register_phone_label}
                          placeholder={strings.register_phone_placeholder}
                          required={strings.register_phone_required}
                          disabled={registerRequest.isLoading}
                          validate={value => {
                            const phoneCodes = ['7', '8', '9'];
                            const isMobilePhone = phoneCodes.includes(
                              value?.[1],
                            );
                            return value.length < (isMobilePhone ? 13 : 12)
                              ? strings.register_phone_not_valid
                              : true;
                          }}
                        />
                        <RegisterScreenInput
                          key="email"
                          name="email"
                          control={firstControl}
                          label={strings.register_email_label}
                          placeholder={strings.register_email_placeholder}
                          required={strings.register_email_required}
                          disabled={registerRequest.isLoading}
                          keyboardType="email-address"
                          validate={value => validateEmail(value, strings)}
                        />
                      </>
                    );
                  case RegisterScreenStepType.SECOND:
                    return (
                      <>
                        <RegisterScreenInput
                          key="postCode"
                          name="postCode"
                          control={secondControl}
                          label={strings.register_post_code_label}
                          placeholder={strings.register_post_code_placeholder}
                          required={strings.register_post_code_required}
                          disabled={registerRequest.isLoading}
                          keyboardType="number-pad"
                          maxLength={7}
                        />
                        <RegisterScreenInput
                          key="prefectures"
                          name="prefectures"
                          control={secondControl}
                          label={strings.register_prefectures_label}
                          placeholder={strings.register_prefectures_placeholder}
                          required={strings.register_prefectures_required}
                          disabled={
                            registerRequest.isLoading ||
                            postalinfoRequest.isLoading
                          }
                          isLoading={postalinfoRequest.isLoading}
                        />
                        <RegisterScreenInput
                          key="municipalities"
                          name="municipalities"
                          control={secondControl}
                          label={strings.register_municipalities_label}
                          placeholder={
                            strings.register_municipalities_placeholder
                          }
                          required={strings.register_municipalities_required}
                          disabled={
                            registerRequest.isLoading ||
                            postalinfoRequest.isLoading
                          }
                          isLoading={postalinfoRequest.isLoading}
                        />
                        <RegisterScreenInput
                          key="streetAddress"
                          name="streetAddress"
                          control={secondControl}
                          label={strings.register_street_address_label}
                          placeholder={
                            strings.register_street_address_placeholder
                          }
                          required={strings.register_street_address_required}
                          disabled={
                            registerRequest.isLoading ||
                            postalinfoRequest.isLoading
                          }
                          isLoading={postalinfoRequest.isLoading}
                        />
                        <RegisterScreenInput
                          key="buildingName"
                          name="buildingName"
                          control={secondControl}
                          label={strings.register_building_name_label}
                          placeholder={
                            strings.register_building_name_placeholder
                          }
                          disabled={registerRequest.isLoading}
                        />
                        <RegisterScreenInput
                          key="companyName"
                          name="companyName"
                          control={secondControl}
                          label={strings.register_company_name_label}
                          placeholder={
                            strings.register_company_name_placeholder
                          }
                          disabled={registerRequest.isLoading}
                        />
                      </>
                    );
                  case RegisterScreenStepType.THIRD:
                    return (
                      <>
                        <RegisterScreenPasswordInput
                          key="password"
                          name="password"
                          control={thirdControl}
                          label={strings.register_password_label}
                          placeholder={strings.register_password_placeholder}
                          required={strings.register_password_required}
                          disabled={registerRequest.isLoading}
                          validate={value =>
                            validatePassword(value as string, strings)
                          }
                        />
                        <RegisterRules>
                          {strings.app_password_rules_prefix}{' '}
                          {strings.app_password_rules_8_char}
                          {', '}
                          {strings.app_password_rules_lower_case}
                          {', '}
                          {strings.app_password_rules_capital}
                          {', '}
                          {strings.app_password_rules_number}
                          {', '}
                          {strings.app_password_rules_special_character}
                        </RegisterRules>
                        <RegisterScreenPasswordInput
                          key="repeatPassword"
                          name="repeatPassword"
                          control={thirdControl}
                          label={strings.register_repeat_password_label}
                          placeholder={
                            strings.register_repeat_password_placeholder
                          }
                          validate={value =>
                            value === thirdWatch('password') ||
                            strings.register_repeat_password_repeat_error
                          }
                          required={strings.register_repeat_password_required}
                          disabled={registerRequest.isLoading}
                        />
                        <Controller
                          control={thirdControl}
                          rules={{
                            required: strings.register_term_of_service_required,
                          }}
                          render={({
                            field: {onChange, onBlur, value},
                            fieldState: {error},
                          }) => (
                            <>
                              <RegisterCheckbox
                                onBlur={onBlur}
                                checked={value}
                                onChange={onChange}
                                disabled={registerRequest.isLoading}>
                                {evaProps => (
                                  <>
                                    <RegisterCheckboxText {...evaProps}>
                                      {strings.register_term_of_service_prefix}
                                    </RegisterCheckboxText>
                                    <RegisterTermOfService to="/term-of-service">
                                      <RegisterTermOfServiceText>
                                        {strings.register_term_of_service}
                                      </RegisterTermOfServiceText>
                                    </RegisterTermOfService>
                                    <RegisterCheckboxText
                                      // eslint-disable-next-line react-native/no-inline-styles
                                      style={{marginLeft: 4}}>
                                      &
                                    </RegisterCheckboxText>
                                    <RegisterTermOfService to="/term-of-use">
                                      <RegisterTermOfServiceText>
                                        {strings.register_term_of_use}
                                      </RegisterTermOfServiceText>
                                    </RegisterTermOfService>
                                  </>
                                )}
                              </RegisterCheckbox>
                              {error && (
                                <RegisterInputErrorText>
                                  {error.message}
                                </RegisterInputErrorText>
                              )}
                            </>
                          )}
                          name="acceptTermOfService"
                        />
                      </>
                    );
                }
              })()}
            </RegisterInputs>
            <RegisterButtons>
              <RegisterBackButton
                disabled={
                  screenStep === RegisterScreenStepType.FIRST ||
                  registerRequest.isLoading
                }
                onPress={onBackPressed}>
                {strings.register_back_button.toUpperCase()}
              </RegisterBackButton>
              <RegisterNextButton
                disabled={registerRequest.isLoading}
                accessoryRight={
                  registerRequest.isLoading
                    ? AccessoryLoadingIndicator
                    : undefined
                }
                onPress={(() => {
                  switch (screenStep) {
                    case RegisterScreenStepType.FIRST:
                      return firstHandleSubmit(onNextFirstPressed);
                    case RegisterScreenStepType.SECOND:
                      return secondHandleSubmit(onNextSecondPressed);
                    case RegisterScreenStepType.THIRD:
                      return thirdHandleSubmit(onNextThirdPressed);
                  }
                })()}>
                {(() => {
                  switch (screenStep) {
                    case RegisterScreenStepType.FIRST:
                    case RegisterScreenStepType.SECOND:
                      return strings.register_next_button.toUpperCase();
                    case RegisterScreenStepType.THIRD:
                      return strings.register_button.toUpperCase();
                  }
                })()}
              </RegisterNextButton>
            </RegisterButtons>
          </RegisterContent>
        </RegisterContainer>
      </AppLayout>
    </>
  );
}

function RegisterScreenInput<Form extends FieldValues>({
  control,
  required,
  validate,
  name,
  label,
  placeholder,
  disabled,
  maxLength,
  isLoading,
  keyboardType,
}: {
  control: Control<Form>;
  required?: string;
  validate?: Validate<FieldPathValue<Form, FieldPath<Form>>>;
  name: FieldPath<Form>;
  label: string;
  placeholder: string;
  disabled: boolean;
  maxLength?: number;
  isLoading?: boolean;
  keyboardType?:
    | 'default'
    | 'number-pad'
    | 'decimal-pad'
    | 'numeric'
    | 'email-address'
    | 'phone-pad';
}) {
  return (
    <Controller
      control={control}
      rules={{
        required,
        validate,
      }}
      render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
        <>
          <RegisterInput
            status={error ? 'danger' : 'basic'}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            maxLength={maxLength}
            keyboardType={keyboardType}
            accessoryRight={
              isLoading ? <AccessoryLoadingIndicator /> : undefined
            }
          />
          {error && (
            <RegisterInputErrorText>{error.message}</RegisterInputErrorText>
          )}
        </>
      )}
      name={name}
    />
  );
}

function RegisterScreenPhoneInput<Form extends FieldValues>({
  control,
  required,
  validate,
  name,
  label,
  placeholder,
  disabled,
}: {
  control: Control<Form>;
  required?: string;
  validate?: Validate<FieldPathValue<Form, FieldPath<Form>>>;
  name: FieldPath<Form>;
  label: string;
  placeholder: string;
  disabled: boolean;
}) {
  return (
    <Controller
      control={control}
      rules={{
        required,
        validate,
      }}
      render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
        const mobilePhoneMask = [
          '0',
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];
        const landingPhoneMask = [
          '0',
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];

        const phoneCodes = ['7', '8', '9'];
        const isMobilePhone = phoneCodes.includes(value?.[1]);

        const {masked} = formatWithMask({
          text: value,
          mask: isMobilePhone ? mobilePhoneMask : landingPhoneMask,
        });

        return (
          <>
            <RegisterInput
              status={error ? 'danger' : 'basic'}
              onBlur={onBlur}
              onChangeText={onChange}
              value={masked}
              placeholder={placeholder}
              label={label}
              disabled={disabled}
              maxLength={isMobilePhone ? 13 : 12}
              keyboardType="number-pad"
            />

            {error && (
              <RegisterInputErrorText>{error.message}</RegisterInputErrorText>
            )}
          </>
        );
      }}
      name={name}
    />
  );
}

function RegisterScreenPasswordInput<Form extends FieldValues>({
  control,
  required,
  name,
  label,
  placeholder,
  validate,
  disabled,
}: {
  control: Control<Form>;
  required?: string;
  name: FieldPath<Form>;
  label: string;
  placeholder: string;
  validate?: Validate<FieldPathValue<Form, FieldPath<Form>>>;
  disabled: boolean;
}) {
  const [securePassEntry, setSecurePassEntry] = useState(true);

  const toggleSecurePassEntry = useCallback(() => {
    setSecurePassEntry(!securePassEntry);
  }, [securePassEntry]);

  return (
    <Controller
      control={control}
      rules={{
        required,
        validate,
      }}
      render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
        <>
          <RegisterInput
            status={error ? 'danger' : 'basic'}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            accessoryRight={props => {
              return (
                <TouchableWithoutFeedback onPress={toggleSecurePassEntry}>
                  {securePassEntry ? (
                    <RegisterEye key="eye" {...props} />
                  ) : (
                    <RegisterEyeOff key="eye-off" {...props} />
                  )}
                </TouchableWithoutFeedback>
              );
            }}
            secureTextEntry={securePassEntry}
          />
          {error && (
            <RegisterInputErrorText>{error.message}</RegisterInputErrorText>
          )}
        </>
      )}
      name={name}
    />
  );
}

enum RegisterScreenStepType {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}
