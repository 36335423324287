import React from 'react';
import {useJwtUserSelector} from '../../../../../store/store';
import {useStrings} from '../../../../../utils/providers/strings';
import {
  SplitPaymentSuccessDetailsTitle,
  SplitPaymentSuccessIconContainer,
  SplitPaymentSuccessTitle,
  SplitPaymentFriendItemContainer,
  SplitPaymentFriendsContainer,
  SplitPaymentInfoContainer,
  SplitPaymentInfoItemContainer,
  SplitPaymentInfoItemText,
  SplitPaymentInfoItemTitle,
  SplitPaymentInfoRequestInfoTitleMobile,
  SplitPaymentMainContainer,
  SplitPaymentSuccessPngIcon,
} from './styles';
import {SplitPaymentDetailResponse} from '../../../../../api/split-payments/models/responses/detail';

interface IProps {
  data: SplitPaymentDetailResponse;
}

export default function SplitPaymentPayed({data}: IProps) {
  const jwtUser = useJwtUserSelector();
  const strings = useStrings();
  const isSplitPaymentAuthor = jwtUser.id === data.author?.id;

  return (
    <>
      <SplitPaymentMainContainer>
        <SplitPaymentSuccessIconContainer>
          <SplitPaymentSuccessPngIcon />
        </SplitPaymentSuccessIconContainer>
        <SplitPaymentSuccessTitle>
          {strings.qr_payment_success_title}
        </SplitPaymentSuccessTitle>
        <SplitPaymentSuccessDetailsTitle>
          {strings.qr_payment_success_details_title}
        </SplitPaymentSuccessDetailsTitle>

        <SplitPaymentInfoContainer>
          {!isSplitPaymentAuthor && (
            <SplitPaymentInfoItemContainer>
              <SplitPaymentInfoItemTitle>
                {strings.split_payment_friend_creator_payment_title}
              </SplitPaymentInfoItemTitle>
              <SplitPaymentInfoItemText>
                {`${data.author?.firstName} ${data.author?.lastName} (ID: ${data.author?.clientId})`}
              </SplitPaymentInfoItemText>
            </SplitPaymentInfoItemContainer>
          )}

          <SplitPaymentInfoItemContainer>
            <SplitPaymentInfoItemTitle>
              {strings.split_payment_detail_created_date_title}
            </SplitPaymentInfoItemTitle>
            <SplitPaymentInfoItemText>
              {strings.app_date_time_string(data.createdAt)}
            </SplitPaymentInfoItemText>
          </SplitPaymentInfoItemContainer>

          <SplitPaymentInfoItemContainer last={!isSplitPaymentAuthor}>
            <SplitPaymentInfoItemTitle>
              {strings.split_payment_detail_payment_amount_title}
            </SplitPaymentInfoItemTitle>
            <SplitPaymentInfoItemText>
              {`${
                isSplitPaymentAuthor ? data.totalAmount : data.requestedAmount
              } CP`}
            </SplitPaymentInfoItemText>
          </SplitPaymentInfoItemContainer>

          {isSplitPaymentAuthor && (
            <SplitPaymentInfoItemContainer last>
              <SplitPaymentInfoItemTitle>
                {strings.split_payment_detail_you_pay_title}
              </SplitPaymentInfoItemTitle>
              <SplitPaymentInfoItemText>
                {`${data.requestedAmount} CP`}
              </SplitPaymentInfoItemText>
            </SplitPaymentInfoItemContainer>
          )}
        </SplitPaymentInfoContainer>

        {isSplitPaymentAuthor && data?.friends && (
          <SplitPaymentFriendsContainer>
            <SplitPaymentInfoRequestInfoTitleMobile>
              {strings.split_payment_detail_friends_list_title}
            </SplitPaymentInfoRequestInfoTitleMobile>

            {data.friends?.map(({id, firstName, lastName, amount}) => (
              <SplitPaymentFriendItemContainer key={id}>
                <SplitPaymentInfoItemTitle>
                  {`${firstName} ${lastName}`}
                </SplitPaymentInfoItemTitle>
                <SplitPaymentInfoItemText>
                  {`${amount} CP`}
                </SplitPaymentInfoItemText>
              </SplitPaymentFriendItemContainer>
            ))}
          </SplitPaymentFriendsContainer>
        )}
      </SplitPaymentMainContainer>
    </>
  );
}
