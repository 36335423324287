import {all, call, put, takeLatest} from 'redux-saga/effects';
import api from '../../../api';
import {
  ConciergePointsBuyByBtcResponse,
  ConciergePointsBuyByBankTransferResponse,
  ConciergePointsBuyByCybersourceResponse,
  ConciergePointsBuyResponse,
} from '../../../api/concierge-points/models/response/buy';
import {PaymentInfoResponse} from '../../../api/concierge-points/models/response/status';
import {DefaultResponse} from '../../../api/models/response/default';
import {
  buyPointsByBtcRequestAction,
  buyPointsCybersourceRequestAction,
  buyPointsByBankTransferRequestAction,
  buyPointsRequestAction,
  paymentStatusRequestAction,
} from './action';

const buyPointsRequest = function* (
  action: ReturnType<typeof buyPointsRequestAction.request>,
) {
  try {
    const response: DefaultResponse<ConciergePointsBuyResponse> = yield call(
      [api, api.conciergePoints.buyPoints],
      action.payload,
    );
    yield put(buyPointsRequestAction.success(response.data));
  } catch (error) {
    yield put(buyPointsRequestAction.failure(error as Error));
  }
};

const buyPointsByCybersourceRequest = function* (
  action: ReturnType<typeof buyPointsCybersourceRequestAction.request>,
) {
  try {
    const response: DefaultResponse<ConciergePointsBuyByCybersourceResponse> =
      yield call(
        [api, api.conciergePoints.buyPointsByCybersource],
        action.payload,
      );
    yield put(buyPointsCybersourceRequestAction.success(response.data));
  } catch (error) {
    yield put(buyPointsCybersourceRequestAction.failure(error as Error));
  }
};

const buyPointsByBtcRequest = function* (
  action: ReturnType<typeof buyPointsByBtcRequestAction.request>,
) {
  try {
    const response: DefaultResponse<ConciergePointsBuyByBtcResponse> =
      yield call([api, api.conciergePoints.buyPointsByBtc], action.payload);
    yield put(buyPointsByBtcRequestAction.success(response.data));
  } catch (error) {
    yield put(buyPointsByBtcRequestAction.failure(error as Error));
  }
};

const paymentStatusRequest = function* (
  action: ReturnType<typeof paymentStatusRequestAction.request>,
) {
  try {
    const response: DefaultResponse<PaymentInfoResponse> = yield call(
      [api, api.conciergePoints.paymentInfo],
      action.payload,
    );
    yield put(paymentStatusRequestAction.success(response.data));
  } catch (error) {
    yield put(paymentStatusRequestAction.failure(error as Error));
  }
};

const buyPointsByBankTransferRequest = function* (
  action: ReturnType<typeof buyPointsByBankTransferRequestAction.request>,
) {
  try {
    const response: DefaultResponse<ConciergePointsBuyByBankTransferResponse> =
      yield call(
        [api, api.conciergePoints.buyPointsByBankTransfer],
        action.payload,
      );
    yield put(buyPointsByBankTransferRequestAction.success(response.data));
  } catch (error) {
    yield put(buyPointsByBankTransferRequestAction.failure(error as Error));
  }
};

export const conciergePointsSaga = function* () {
  // yield all([takeLatest(buyPointsRequestAction.request, buyPointsRequest)]);
  yield all([
    takeLatest(
      buyPointsCybersourceRequestAction.request,
      buyPointsByCybersourceRequest,
    ),
    takeLatest(buyPointsRequestAction.request, buyPointsRequest),
    takeLatest(buyPointsByBtcRequestAction.request, buyPointsByBtcRequest),
    takeLatest(paymentStatusRequestAction.request, paymentStatusRequest),
    takeLatest(
      buyPointsByBankTransferRequestAction.request,
      buyPointsByBankTransferRequest,
    ),
  ]);
};
