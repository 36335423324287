import React from 'react';
import {
  RequestCategoryTypeField,
  RequestCategoryTypeFieldExtraDataRadioGroup,
} from '../../../../../../../api/request/models/responses/category-type';
import {NewRequestQuestionFieldErrorText} from '../styles';
import {
  NewRequestQuestionFieldRadioItem,
  NewRequestQuestionFieldRadioGroup as NewRequestQuestionFieldRadioGroupComponent,
  NewRequestQuestionFieldRadioGroupContainer,
  NewRequestQuestionFieldRadioGroupTitle,
} from './styles';
import {Controller, Control, FormState} from 'react-hook-form';
import {
  NewRequestQuestionFieldRadioGroupState,
  NewRequestQuestionForm,
} from '../../../form';

export default function NewRequestQuestionFieldRadioGroup({
  field,
  control,
  state,
}: {
  field: RequestCategoryTypeField;
  control: Control<NewRequestQuestionForm>;
  state: FormState<NewRequestQuestionForm>;
}) {
  const extraData =
    field.extraData as RequestCategoryTypeFieldExtraDataRadioGroup;
  return (
    <NewRequestQuestionFieldRadioGroupContainer>
      <NewRequestQuestionFieldRadioGroupTitle>
        {field.title}
      </NewRequestQuestionFieldRadioGroupTitle>
      <Controller
        control={control}
        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
          const radioGroupValue =
            value as NewRequestQuestionFieldRadioGroupState;
          return (
            <NewRequestQuestionFieldRadioGroupComponent
              selectedIndex={
                radioGroupValue !== undefined && extraData
                  ? extraData.findIndex(
                      radio => radio.id === radioGroupValue.id,
                    )
                  : undefined
              }
              onChange={index => onChange(extraData?.[index])}>
              {extraData?.map((item, position) => {
                return (
                  <NewRequestQuestionFieldRadioItem
                    last={position === extraData.length - 1}
                    key={item.id}
                    onBlur={onBlur}>
                    {item.value}
                  </NewRequestQuestionFieldRadioItem>
                );
              })}
            </NewRequestQuestionFieldRadioGroupComponent>
          );
        }}
        name={field.id}
      />
      {state.errors[field.id] && (
        <NewRequestQuestionFieldErrorText>
          {state.errors[field.id]?.message as string}
        </NewRequestQuestionFieldErrorText>
      )}
    </NewRequestQuestionFieldRadioGroupContainer>
  );
}
