import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {ListResponse} from '../../../api/models/response/list';
import {PaginationRequest} from '../../../utils/redux/create-pagination-request-reducer';
import {Transaction} from '../../../api/transaction/models/responses';

export const getTransactionListRequestAction = createAsyncAction<
  PaginationRequest,
  [PaginationRequest, ListResponse<Transaction>],
  Error
>('GET_TRANSACTION_LIST_REQUEST');
