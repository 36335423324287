import React, {useCallback, useEffect} from 'react';
import {
  CommonMainNavigationMenuItemContainer,
  MainNavigationContainer,
  MainNavigationInfoContainer,
  MainNavigationInfoError,
  MainNavigationInfoId,
  MainNavigationInfoName,
  MainNavigationInfoNameContainer,
  MainNavigationInfoSpinner,
  MainNavigationLogoutButton,
  MainNavigationMenu,
  MainNavigationMenuItemCalendarIcon,
  MainNavigationMenuItemFirstContainer,
  MainNavigationMenuItemHomeIcon,
  MainNavigationMenuItemLastContainer,
  MainNavigationMenuItemName,
  MainNavigationMenuItemRequestsIcon,
  MainNavigationMenuItemShopsIcon,
  MainNavigationPointsContainer,
} from './styles';

import {InfoNavigationLinksDesktop} from '../info-navigation-link';

import {useStrings} from '../../../../utils/providers/strings';
import {ParamListBase, TabNavigationState} from '@react-navigation/native';
import {useAppDispatch, useRequestSelector} from '../../../../store/store';
import {userRequestAction} from '../../../user/store/action';
import {useTheme} from 'styled-components/native';

export default function MainNavigationDesktop({
  state,
}: {
  state: TabNavigationState<ParamListBase>;
}) {
  const theme = useTheme();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const userRequest = useRequestSelector(store => store.user.userRequest);

  const onUserRequest = useCallback(() => {
    dispatch(userRequestAction.request());
  }, [dispatch]);

  useEffect(() => {
    if (
      theme.isDesktop &&
      !userRequest.data &&
      !userRequest.error &&
      !userRequest.isLoading
    ) {
      onUserRequest();
    }
  }, [onUserRequest, theme.isDesktop, userRequest]);

  const isHomeActive = state.index === 0;
  const isRequestsActive = state.index === 1;
  const isCalendarActive = state.index === 2;
  const isShopsActive = state.index === 3;

  return (
    <MainNavigationContainer>
      <MainNavigationInfoContainer>
        {userRequest.data ? (
          <>
            <MainNavigationInfoNameContainer>
              <MainNavigationInfoName>
                {userRequest.data.firstName}
              </MainNavigationInfoName>
              <MainNavigationLogoutButton />
            </MainNavigationInfoNameContainer>
            <MainNavigationInfoId>{`${strings.main_navigation_id_prefix} ${userRequest.data.clientId}`}</MainNavigationInfoId>
          </>
        ) : userRequest.isLoading ? (
          <MainNavigationInfoSpinner />
        ) : userRequest.error ? (
          <MainNavigationInfoError
            error={userRequest.error.message}
            retry={onUserRequest}
          />
        ) : null}
      </MainNavigationInfoContainer>

      {isShopsActive && <MainNavigationPointsContainer />}

      <MainNavigationMenu>
        <MainNavigationMenuItemFirstContainer to="/" active={isHomeActive}>
          <MainNavigationMenuItemHomeIcon active={isHomeActive} />
          <MainNavigationMenuItemName active={isHomeActive}>
            {strings.main_navigator_home}
          </MainNavigationMenuItemName>
        </MainNavigationMenuItemFirstContainer>
        <CommonMainNavigationMenuItemContainer
          to="/requests"
          active={isRequestsActive}>
          <MainNavigationMenuItemRequestsIcon active={isRequestsActive} />
          <MainNavigationMenuItemName active={isRequestsActive}>
            {strings.main_navigator_requests}
          </MainNavigationMenuItemName>
        </CommonMainNavigationMenuItemContainer>
        <CommonMainNavigationMenuItemContainer
          to="/calendar"
          active={isCalendarActive}>
          <MainNavigationMenuItemCalendarIcon active={isCalendarActive} />
          <MainNavigationMenuItemName active={isCalendarActive}>
            {strings.main_navigator_calendar}
          </MainNavigationMenuItemName>
        </CommonMainNavigationMenuItemContainer>
        <MainNavigationMenuItemLastContainer to="/shops" active={isShopsActive}>
          <MainNavigationMenuItemShopsIcon active={isShopsActive} />
          <MainNavigationMenuItemName active={isShopsActive}>
            {strings.main_navigator_shops}
          </MainNavigationMenuItemName>
        </MainNavigationMenuItemLastContainer>
      </MainNavigationMenu>
      <InfoNavigationLinksDesktop />
    </MainNavigationContainer>
  );
}
