import {Strings} from '../resources/strings';
import {ValidateResult} from 'react-hook-form';

export default function validatePassword(
  value: string,
  strings: Strings,
): ValidateResult {
  if (!value.match(/[a-z]/))
    return `${strings.app_password_rules_prefix} ${strings.app_password_rules_lower_case}`;

  if (!value.match(/[A-Z]/))
    return `${strings.app_password_rules_prefix} ${strings.app_password_rules_capital}`;

  if (!value.match(/[0-9]/))
    return `${strings.app_password_rules_prefix} ${strings.app_password_rules_number}`;

  if (!value.match(/\W|_/))
    return `${strings.app_password_rules_prefix} ${strings.app_password_rules_special_character}`;

  if (value.length < 8)
    return `${strings.app_password_rules_prefix} ${strings.app_password_rules_8_char}`;

  return true;
}
