import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import HomeScreen from './screen';
import SettingsScreen from '../settings/screen';
import ProfileScreen from '../profile/screen';
import StandardPointsWithdrawalScreen from '../standard-points/withdrawal/screen';
import {useJwtUserSelector} from '../../store/store';
import {UserRole} from '../../api/user/models/responses/user';
import WithdrawalListScreen from '../standard-points/withdrawal-list/screen';
import TransactionListScreen from '../transaction-list/screen';
import {Platform} from 'react-native';
import QrPaymentReaderScreen from '../qr-payment/reader/screen';
import QrPaymentScreen from '../qr-payment/screen';
import ConciergePointsBuyNavigator from '../concierge-points/buy/navigator';
import StandardPointsExchangeScreen from '../standard-points/exchange/screen';
import ExchangeListScreen from '../standard-points/exchange-list/screen';
import FriendListScreen from '../friend-list/screen';
import FriendNewScreen from '../friend-list/new/screen';
import SplitPaymentsNavigator from '../split-payment-list/navigator';

const Stack = createNativeStackNavigator();

export default function HomeNavigator() {
  const jwtUser = useJwtUserSelector();
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="Home" component={HomeScreen} />
      <Stack.Screen name="Settings" component={SettingsScreen} />
      <Stack.Screen
        name="ConciergePointsBuyNavigator"
        component={ConciergePointsBuyNavigator}
      />
      <Stack.Screen name="Profile" component={ProfileScreen} />
      {jwtUser.role === UserRole.MEMBER && (
        <>
          <Stack.Screen
            name="StandardPointsWithdrawal"
            component={StandardPointsWithdrawalScreen}
          />
          <Stack.Screen
            name="WithdrawalList"
            component={WithdrawalListScreen}
          />
          <Stack.Screen
            name="StandardPointsExchange"
            component={StandardPointsExchangeScreen}
          />
          <Stack.Screen name="ExchangeList" component={ExchangeListScreen} />
        </>
      )}
      <Stack.Screen name="TransactionList" component={TransactionListScreen} />
      <Stack.Screen name="FriendList" component={FriendListScreen} />
      <Stack.Screen name="FriendNew" component={FriendNewScreen} />
      <Stack.Screen
        name="SplitPaymentsNavigator"
        component={SplitPaymentsNavigator}
      />
      {(Platform.OS === 'ios' || Platform.OS === 'android') && (
        <>
          <Stack.Screen
            name="QrPaymentReader"
            component={QrPaymentReaderScreen}
          />
          <Stack.Screen name="QrPayment" component={QrPaymentScreen} />
        </>
      )}
    </Stack.Navigator>
  );
}
