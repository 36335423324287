import React, {useEffect, useState} from 'react';
import {TextStyle, View} from 'react-native';
import {useForm} from 'react-hook-form';
import {useTheme} from 'styled-components/native';

import {useStrings} from '../../utils/providers/strings';
import {
  ContactUsForm as ContactUsFormValues,
  contactUsInitialFormValues,
} from './lib/consts';

import {AccessoryLoadingIndicator} from '../../uikit/AccessoryLoadingIndicator';
import {PointsContainerPoints} from '../../uikit/Points/container';
import {AppLayout} from '../../uikit/AppLayout';
import CallFAB from '../../uikit/CallFAB';
import {ContactUsForm, ContactUsSuccessModal} from './ui';

import {
  AppTransparentHeader,
  ContactUsContactUsButton,
  ContactUsContainer,
  ContactUsContent,
  ContactUsContentWrapper,
  ContactUsText,
} from './styles';
import api from '../../api';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';

const ContactUsScreen = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const strings = useStrings();
  const theme = useTheme();

  const {control, handleSubmit, setError, reset, watch} = useForm({
    shouldFocusError: true,
    defaultValues: contactUsInitialFormValues,
  });

  const onModalClose = () => {
    reset();
    setIsModalOpen(false);
  };

  /** Send contact us form request */
  const onSubmit = async (data: ContactUsFormValues) => {
    setIsLoading(true);

    try {
      await api.contactUs.sendContactUsForm(data);
      setIsModalOpen(true);
    } catch (err: any) {
      err?.fields?.forEach(({field, message}) => {
        setError(field as keyof ContactUsFormValues, {message});
      });
    }

    setIsLoading(false);
  };

  const titleStyles: TextStyle = theme.isDesktop
    ? {
        fontWeight: '600',
        fontSize: 28,
        lineHeight: 36,
      }
    : {
        fontSize: 16,
      };

  useEffect(() => {
    // reset form on component unmount
    return () => reset();
  }, []);

  return (
    <>
      <ContactUsSuccessModal visible={isModalOpen} close={onModalClose} />
      <AppWhiteStatusBar />
      <ContactUsContainer>
        <ContactUsContentWrapper>
          <AppTransparentHeader
            title={strings.contact_us_title}
            backNoHistoryRoute={theme.isDesktop ? '/' : '/my-page'}
            titleStyle={titleStyles}
          />
          <ContactUsContent>
            <View style={{paddingBottom: 60}}>
              <ContactUsText>{strings.contact_us_text}</ContactUsText>
              <ContactUsForm
                isLoading={isLoading}
                control={control}
                watch={watch}
              />
              <ContactUsContactUsButton
                accessoryRight={
                  isLoading ? AccessoryLoadingIndicator : undefined
                }
                disabled={isLoading}
                onPress={handleSubmit(onSubmit)}>
                {strings.contact_us_submit_button.toUpperCase()}
              </ContactUsContactUsButton>
            </View>
          </ContactUsContent>
          <AppLayout>{!theme.isDesktop && <CallFAB />}</AppLayout>
        </ContactUsContentWrapper>
        {theme.isDesktop && <PointsContainerPoints />}
      </ContactUsContainer>
    </>
  );
};

export default ContactUsScreen;
