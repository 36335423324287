import createSagaMiddleware, {Task} from 'redux-saga';
import {configureStore, createListenerMiddleware} from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import rootSaga from './saga';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {initState, RequestState} from 'scl/src/redux/create-request-reducer';
import {Reducer} from 'redux';
import {
  initPaginationState,
  PaginationRequestState,
} from '../utils/redux/create-pagination-request-reducer';
import {
  MultipleRequestKey,
  MultipleRequestState,
} from 'scl/src/redux/create-multiple-request-reducer';
import {useStrings} from '../utils/providers/strings';
import {SET_STRINGS_KEY} from '../features/app/store/action';
import {IInfo} from '../api/info/models/info';

export interface SagaStore extends Store {
  sagaTask: Task;
}

export function makeStore(
  reducer: Reducer<ReturnType<typeof rootReducer>> = rootReducer,
) {
  const sagaMiddleware = createSagaMiddleware();
  const listenerMiddlewareInstance = createListenerMiddleware({
    onError: () => console.error,
  });

  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [SET_STRINGS_KEY],
          ignoredPaths: ['app.strings'],
        },
      })
        .prepend(listenerMiddlewareInstance.middleware)
        .concat(sagaMiddleware),
  });

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

type Store = ReturnType<typeof makeStore>;

export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useRequestSelector<Data, ErrorType extends Error = Error>(
  select: (state: RootState) => RequestState<Data, ErrorType> | null,
) {
  const data = useAppSelector(select);

  return data ?? initState<Data>();
}

export const useInfoSelector = (
  select: (state: RootState) => RequestState<IInfo[], Error> | null,
) => {
  const data = useAppSelector(select);
  return data ?? initState<IInfo[]>();
};

export function useMultipleRequestSelector<
  Data,
  ErrorType extends Error = Error,
  Key extends MultipleRequestKey = string,
>(
  select: (state: RootState) => MultipleRequestState<Data, ErrorType>,
  id: Key,
) {
  const data = useAppSelector(select);

  return data[id] ?? initState<Data, ErrorType>();
}

export function usePaginationRequestSelector<Data>(
  select: (state: RootState) => PaginationRequestState<Data> | null,
) {
  const data = useAppSelector(select);

  return data ?? initPaginationState<Data>();
}

export function useJwtUserSelector() {
  const strings = useStrings();
  const jwtUser = useAppSelector(store => store.user.jwtUser);
  if (!jwtUser) throw new Error(strings.app_error_no_user);
  return jwtUser;
}
