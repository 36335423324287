import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Spinner, Text} from '@ui-kitten/components';
import Refresh from '../../../assets/icons/Refresh';
import {AppCard} from '../../AppCard';

export const PointsContainer = styled(AppCard)<{withSp: boolean}>`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  height: ${props => (props.withSp ? '145px' : '104px')};

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: flex-start;
      margin-right: 128px;
      margin-left: ${props.theme.dimens.normalSpacer};
      width: 288px;
    `}
`;

export const PointsTitleContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PointsTitle = styled(Text)`
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const PointsRefreshContainer = styled.TouchableOpacity``;

export const PointsRefreshIcon = styled(Refresh)``;

export const PointsRefreshLoader = styled(Spinner)``;

export const PointsInfoContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const PointsText = styled(Text).attrs({
  category: 'h1',
})``;

export const PointsSuffix = styled(Text).attrs({
  category: 'p1',
})`
  margin-left: ${props => props.theme.dimens.smallestSpacer};
`;

export const PointsSpContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: ${props => props.theme.dimens.smallSpacer};
  padding-top: ${props => props.theme.dimens.normalSpacer};
  border-top-width: 1px;
  border-top-color: ${props => props.theme.colors['color-basic-200']};
`;

export const PointsSpText = styled(Text).attrs({})`
  font-size: 16px;
`;

export const PointsSpSuffix = styled(Text).attrs({
  category: 'p1',
})`
  font-size: 12px;
  margin-left: ${props => props.theme.dimens.smallestSpacer};
`;
