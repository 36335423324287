import {uiKittenColors} from './ui-kitten-colors';

export const light = {
  primary: uiKittenColors['color-primary-500'],
};

export type ThemeColors = typeof light;

export const colors = {
  white: '#FFFFFF',
};

export type Colors = typeof colors & typeof uiKittenColors;
