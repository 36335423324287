import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';

export const NewRequestQuestionFieldContainer = styled.View`
  margin-top: ${props => props.theme.dimens.normalSpacer};
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;

export const NewRequestQuestionFieldTitle = styled(Text)`
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
`;

export const NewRequestQuestionFieldErrorText = styled(Text).attrs({
  status: 'danger',
})`
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};
`;
