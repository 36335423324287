import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const EmptyListScreen = (props: SvgProps) => (
  <Svg width={192} height={192} viewBox="0 0 192 192" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.769 146.171c.717-.256 1.471-.596 2.052-1.09.691-.585.972-1.34 1.161-2.134.242-1.02.339-2.107.632-3.129.109-.379.318-.522.408-.586a.997.997 0 01.672-.188.98.98 0 01.838.546c.033.062.076.156.105.284.021.095.034.389.057.511.055.299.102.598.146.899.145 1.002.229 1.853.688 2.773.623 1.249 1.247 2.013 2.094 2.352.819.327 1.798.266 3.049.009.119-.029.237-.054.353-.074a1.034 1.034 0 01.398 2.027c-.114.025-.226.049-.337.071-1.691.42-3.647 1.919-4.785 3.231-.35.405-.863 1.536-1.387 2.258-.386.532-.82.883-1.185 1.007-.244.084-.45.071-.62.029a1.05 1.05 0 01-.609-.409 1.16 1.16 0 01-.204-.474 3.48 3.48 0 01-.019-.452c-.107-.368-.238-.728-.333-1.099-.227-.887-.672-1.448-1.201-2.19a4.134 4.134 0 00-1.804-1.478c-.102-.025-.919-.226-1.208-.341-.422-.169-.623-.452-.696-.605a1.168 1.168 0 01-.112-.673c.037-.278.161-.517.382-.709.137-.119.341-.235.615-.292.211-.044.771-.07.85-.074zm4.796-1.445c.038.085.078.17.121.256.913 1.83 1.934 2.851 3.174 3.347l.042.016a13.876 13.876 0 00-2.156 1.972c-.236.273-.549.84-.887 1.421-.307-1.003-.81-1.711-1.442-2.598-.482-.676-.988-1.186-1.61-1.601a7.037 7.037 0 001.343-.879 5.118 5.118 0 001.415-1.934z"
      fill="#B8481F"
    />
    <Path
      d="M7.449 80.479a3.731 3.731 0 01-3.724 3.738A3.731 3.731 0 010 80.479a3.731 3.731 0 013.724-3.738 3.731 3.731 0 013.725 3.738z"
      fill="#B8481F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.93 46.983c-.966-3.344-2.87-5.96-5.786-7.85a.751.751 0 01-.223-1.033.736.736 0 011.023-.226c3.228 2.092 5.339 4.987 6.409 8.69a.75.75 0 01-.504.927.739.739 0 01-.92-.508z"
      fill="#1F1B16"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.234 42.444c.575.683 1.09 1.42 1.53 2.203.195.35.539 1.09.592 1.727.06.677-.169 1.249-.756 1.55-.78.399-1.402.357-1.882.116-.462-.232-.824-.688-1.052-1.299-.436-1.168-.37-2.953-.142-3.971a14.372 14.372 0 00-2.007-1.735c-5.366-3.837-13.236-2.422-19.292 3.316-2.12 2.01-3.14 6.229-3.67 10.781-.827 7.088-.355 15.005-.29 17.325.015.573-.039.913-.068.98a.708.708 0 01-.558.454.917.917 0 01-.607-.087.96.96 0 01-.478-.629 2.638 2.638 0 01-.05-.668c.006-.178.041-.372.006-.499-.231-.814-.51-1.61-.783-2.41-.91-2.694-2.086-5.217-3.394-7.738-4.153-8.009-8.17-16.471-14.978-22.573a.753.753 0 01-.063-1.056.736.736 0 011.047-.063c6.945 6.222 11.072 14.83 15.308 22.998 1.111 2.144 2.13 4.291 2.979 6.537-.086-3.973-.054-9.472.667-14.338.64-4.327 1.858-8.151 3.918-10.105 6.634-6.286 15.287-7.65 21.162-3.445.629.45 1.227.945 1.785 1.482 3.074-5.604 9.654-7.85 15.47-5.17a.751.751 0 01.368.992.741.741 0 01-.984.37c-5.241-2.415-11.184-.26-13.778 4.955zm-.43 1.876c.24.343.462.697.67 1.064.11.197.3.576.38.946a1.7 1.7 0 01.02.286c-.204.102-.364.15-.495.084-.163-.08-.246-.272-.326-.486-.201-.537-.267-1.249-.249-1.894z"
      fill="#1F1B16"
    />
    <Path
      d="M56.624 68.313c0-5.76 4.602-10.43 10.279-10.43H181.72c5.677 0 10.279 4.67 10.279 10.43V96.43c0 5.76-4.602 10.43-10.279 10.43H66.903c-5.677 0-10.28-4.67-10.28-10.43V68.313z"
      fill="#CEC5BD"
    />
    <Path
      d="M112.506 77.955c0-1.774 1.417-3.212 3.165-3.212h39.32c1.748 0 3.165 1.438 3.165 3.212 0 1.774-1.417 3.212-3.165 3.212h-39.32c-1.748 0-3.165-1.438-3.165-3.212z"
      fill="#1F1B16"
      fillOpacity={0.4}
    />
    <Path
      d="M68.43 87.59c0-1.773 1.417-3.211 3.165-3.211h83.626c1.748 0 3.165 1.438 3.165 3.211 0 1.774-1.417 3.212-3.165 3.212H71.595c-1.748 0-3.165-1.438-3.165-3.212z"
      fill="#F5EFE6"
    />
    <Path
      d="M182.555 82.773c0 4.434-3.524 8.029-7.871 8.029s-7.87-3.595-7.87-8.03c0-4.434 3.523-8.029 7.87-8.029 4.347 0 7.871 3.595 7.871 8.03z"
      fill="#1F1B16"
      fillOpacity={0.4}
    />
    <Path
      d="M0 109.957c0-6.144 4.909-11.126 10.964-11.126h113.448c6.055 0 10.964 4.982 10.964 11.126v26.726c0 6.145-4.909 11.127-10.964 11.127H10.964C4.91 147.81 0 142.828 0 136.683v-26.726z"
      fill="#fff"
    />
    <Path
      d="M33.844 118.904c0-1.773 1.417-3.211 3.165-3.211h39.32c1.748 0 3.165 1.438 3.165 3.211 0 1.774-1.417 3.212-3.165 3.212H37.01c-1.748 0-3.165-1.438-3.165-3.212z"
      fill="#DC9B2D"
    />
    <Path
      d="M33.844 128.54c0-1.774 1.417-3.212 3.165-3.212h83.626c1.748 0 3.165 1.438 3.165 3.212 0 1.773-1.417 3.211-3.165 3.211H37.009c-1.748 0-3.165-1.438-3.165-3.211z"
      fill="#F5EFE6"
    />
    <Path
      d="M27.548 123.722c0 4.434-3.524 8.029-7.871 8.029s-7.87-3.595-7.87-8.029c0-4.435 3.523-8.029 7.87-8.029 4.347 0 7.87 3.594 7.87 8.029z"
      fill="#DC9B2D"
    />
  </Svg>
);

export default EmptyListScreen;
