import styled from 'styled-components/native';

import {css} from 'scl/src/styled-components/css';

import StarOutline from '../../../../../../assets/icons/StarOutline';
import Star from '../../../../../../assets/icons/Star';

import AppRouteView from '../../../../../../uikit/AppRouteView';

export const ShopReviewItemContainer = styled(AppRouteView)`
  display: flex;
  background: white;
  border-radius: 4px;
  padding: 16px;
  margin-right: 0;
  margin-bottom: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-basis: calc(33.33% - 12px);
      margin-right: 6px;
      margin-left: 6px;
      margin-bottom: 12px;
    `}
`;

export const ShopReviewItemAuthor = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 8px;
`;

export const ShopReviewItemDescription = styled.Text`
  font-size: 14px;
  line-height: 20px;
  color: #8f8880;
  max-height: 60px;
  overflow: hidden;
  margin-top: 4px;
`;

export const ShopStarContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const ShopStarIcon = styled(Star)`
  max-width: 15px;
  max-height: 15px;
`;

export const ShopStarOutlineIcon = styled(StarOutline)`
  max-width: 15px;
  max-height: 15px;
`;
