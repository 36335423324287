import React, {PropsWithChildren, useEffect} from 'react';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {Provider} from 'react-redux';
import {
  SafeAreaProvider,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import {NavigationContainer, LinkingOptions} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {ToastProvider} from 'react-native-toast-notifications';
import {settingsRequestAction} from './features/app/store/action';
import {store} from './store';
import ThemeProvider from './utils/providers/theme';
import StringsProvider from './utils/providers/strings';
import hideSplash from './utils/hide-splash';
import LoginScreen from './features/login/screen';
import {useAppDispatch, useAppSelector} from './store/store';
import MainNavigator from './features/main/navigator';
import RestoreScreen from './features/restore/screen';
import {useTheme} from 'styled-components/native';
import {AppSafeAreaView} from './uikit/AppSafeAreaView';
import RegisterScreen from './features/register/screen';
import TermOfServiceScreen from './features/term-of-service/screen';
import RestoreConfirmScreen from './features/restore-confirm/screen';
import {BottomSheetModalProvider} from '@gorhom/bottom-sheet';
import TermOfUseScreen from './features/term-of-use/screen';

const Stack = createNativeStackNavigator();

const AppWrapper = () => {
  const jwtUser = useAppSelector(state => state.user.jwtUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (jwtUser) {
      dispatch(settingsRequestAction.request());
    }
  }, [dispatch, jwtUser]);

  const linking: LinkingOptions<object> = {
    prefixes: ['DeeplinkProjectname://', 'http://localhost:3000'],
    config: {
      screens: {
        //UnAuth
        Login: 'login',
        SignUp: 'sign-up',
        Restore: 'restore',
        RestoreConfirm: 'restore/:token',
        TermOfService: 'term-of-service',
        TermOfUse: 'term-of-use',

        //Auth
        Main: {
          screens: {
            HomeNavigator: {
              screens: {
                Home: '',
                Settings: 'settings',
                ConciergePointsBuyNavigator: {
                  screens: {
                    ConciergePointsBuy: 'cp',
                    ConciergePointsBuyByVirtualpay: 'cp/virtualpay',
                    ConciergePointsBuyByCybersource: 'cp/cybersource',
                    ConciergePointsBuyByBTC: 'cp/btc',
                    ConciergePointsBuyByBankTransfer: 'cp/transfer',
                    ConciergePointsBuySuccess: 'cp-success',
                    ConciergePointsBuyByCybersourceSuccess:
                      'cybersource-success',
                    ConciergePointsBuyByBTCSuccess: 'btc-success',
                  },
                },
                Profile: 'profile',
                StandardPointsWithdrawal: 'withdrawal',
                WithdrawalList: 'withdrawals',
                TransactionList: 'transactions',
                QrPaymentReader: 'qr-reader',
                QrPayment: 'qr-payment',
                StandardPointsExchange: 'exchange',
                ExchangeList: 'exchanges',
                FriendNew: 'friends/new',
                FriendList: 'friends',
                SplitPaymentsNavigator: {
                  screens: {
                    SplitPaymentList: 'split-payments',
                    SplitPaymentDetail: 'split-payments/:id',
                  },
                },
              },
            },
            RequestsNavigator: {
              screens: {
                Requests: 'requests',
                NewRequest: 'create-request',
                DetailRequest: 'requests/:id',
              },
            },
            RequestsCalendarNavigator: {
              screens: {
                RequestsCalendar: 'calendar',
              },
            },
            ShopsNavigator: {
              screens: {
                Shops: 'shops',
                DetailShop: 'shops/:id',
                RecentlyViewedShop: 'shops/recently-viewed',
                FavoritesShop: 'shops/favorites',
                AllReviewsShop: 'shops/reviews',
                ReviewsShop: 'shops/:id/reviews',
                DetailReviewShop: 'shops/reviews/:id',
                AddReviewShop: 'shops/:id/reviews/add',
                ShopsByCategory: 'shops/category/:id',
              },
            },
            MyPageNavigator: {
              screens: {
                MyPage: 'my-page',
                OperatingCompany: 'operating-company',
                FAQ: 'faq',
                PrivacyPolicy: 'privacy-policy',
                Info: 'info',
                DetailInfo: 'info/:id',
                ContactUs: 'contact-us',
              },
            },
          },
        },
      },
    },
  };

  return (
    <>
      <AppSafeAreaView>
        <NavigationContainer linking={linking}>
          <Stack.Navigator screenOptions={{headerShown: false}}>
            {jwtUser ? (
              <>
                <Stack.Screen
                  name="Main"
                  component={MainNavigator}
                  options={{title: 'Main'}}
                />
              </>
            ) : (
              <>
                <Stack.Screen
                  name="Login"
                  component={LoginScreen}
                  options={{title: 'Login'}}
                />
                <Stack.Screen
                  name="SignUp"
                  component={RegisterScreen}
                  options={{title: 'SignUp'}}
                />
                <Stack.Screen
                  name="Restore"
                  component={RestoreScreen}
                  options={{title: 'Restore'}}
                />
                <Stack.Screen
                  name="RestoreConfirm"
                  component={RestoreConfirmScreen}
                  options={{title: 'Restore Confirm'}}
                />
              </>
            )}
            <Stack.Screen
              name="TermOfService"
              component={TermOfServiceScreen}
              options={{title: 'TermOfService'}}
            />
            <Stack.Screen
              name="TermOfUse"
              component={TermOfUseScreen}
              options={{title: 'TermOfUse'}}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </AppSafeAreaView>
    </>
  );
};

const ToastProviderWrapper = (props: PropsWithChildren) => {
  const theme = useTheme();
  const insets = useSafeAreaInsets();
  return (
    <ToastProvider
      type="danger"
      swipeEnabled={true}
      placement="top"
      offset={insets.top}
      dangerColor={theme.colors['color-danger-600']}>
      {props.children}
    </ToastProvider>
  );
};

export function CommonApp(): React.ReactElement {
  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <ThemeProvider>
          <StringsProvider onLoad={hideSplash}>
            <ToastProviderWrapper>
              <GestureHandlerRootView style={{flex: 1}}>
                <BottomSheetModalProvider>
                  <AppWrapper />
                </BottomSheetModalProvider>
              </GestureHandlerRootView>
            </ToastProviderWrapper>
          </StringsProvider>
        </ThemeProvider>
      </Provider>
    </SafeAreaProvider>
  );
}
