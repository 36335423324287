import {AxiosInstance} from 'axios';
import {IInfo} from './models/info';
import {ListResponse} from '../models/response/list';

export default class InfoApi {
  constructor(private axiosInstance: AxiosInstance) {}

  /** Get information posts list */
  getInfoList() {
    return this.axiosInstance.get<ListResponse<IInfo>>('information');
  }

  /** Get detailed information post  */
  getInfoPost(postId: number) {
    return this.axiosInstance.get<IInfo>(`information/${postId}`);
  }
}
