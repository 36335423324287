import React, {useCallback, useEffect, useState} from 'react';
import {ListRenderItemInfo} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useTheme} from 'styled-components/native';
import {
  AppTransparentHeader,
  CategoryListItemContainer,
  ScreenListEmptyContainer,
  ScreenListEmptyIcon,
  ScreenListEmptyTitle,
  ScreenListLoading,
  ScreenListLoadingContainer,
  ShopRecentlyCardBadgesContainer,
  ShopRecentlyCardImage,
  ShopRecentlyCardLocationBadge,
  ShopRecentlyCardTagBadge,
  ShopRecentlyCardTitle,
  ShopRecentlyCardTitleContainer,
  ShopRecentlyClearHistoryButton,
  ShopRecentlyViewedContainerContent,
  ShopRecentlyViewedList,
  ShopRecentlyViewedListContainer,
} from './styles';
import {useFocusEffect} from '@react-navigation/native';
import {useStrings} from '../../../utils/providers/strings';
import {
  useAppDispatch,
  usePaginationRequestSelector,
  useRequestSelector,
} from '../../../store/store';
import {REQUESTS_LIMIT} from '../../requests/store/saga';
import AppStatusBar from '../../../uikit/AppStatusBar';
import {AppLayout} from '../../../uikit/AppLayout';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import AppRefreshControl from '../../../uikit/AppRefreshControl';
import {ShopsContainerContainer} from '../styles';
import {REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT} from '../store/saga';
import {ShopItem} from '../../../api/shops/models/responses/shop-categories';
import {PaginationRequest} from '../../../utils/redux/create-pagination-request-reducer';
import {
  deleteRecentlyViewedRequestAction,
  getShopRecentlyViewedListRequestAction,
} from '../store/action';
import {RequestsListEmptyInfo} from '../../requests/styles';
import AppModal from '../../../uikit/AppModal';
import {
  AppModalButton,
  AppModalCancel,
  AppModalTitle,
} from '../../../uikit/AppModal/styles';

export default function RecentlyViewedShopScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const shopRecentlyViewedListRequest = usePaginationRequestSelector(
    store => store.shops.shopRecentlyViewedListRequest,
  );

  const deleteRecentlyViewedRequest = useRequestSelector(
    store => store.shops.deleteRecentlyViewedRequest,
  );

  const isEmptyList = !shopRecentlyViewedListRequest.data?.items.length;

  useEffect(() => {
    return function () {
      dispatch(getShopRecentlyViewedListRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      shopRecentlyViewedListRequest.error &&
      shopRecentlyViewedListRequest.data?.items.length
    ) {
      toast.show(shopRecentlyViewedListRequest.error.message);
    }
  }, [shopRecentlyViewedListRequest]);

  useEffect(() => {
    if (deleteRecentlyViewedRequest.error && deleteRecentlyViewedRequest.data) {
      toast.show(deleteRecentlyViewedRequest.error.message);
    }
  }, [deleteRecentlyViewedRequest]);

  const onFirstPage = useCallback(() => {
    const request: PaginationRequest = {
      limit: REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT,
      offset: 0,
    };

    dispatch(getShopRecentlyViewedListRequestAction.request(request));
  }, [dispatch]);

  const onInit = useCallback(() => {
    dispatch(getShopRecentlyViewedListRequestAction.clean());
    onFirstPage();
  }, [dispatch, onFirstPage]);

  useFocusEffect(onInit);

  const onNextPage = () => {
    if (
      shopRecentlyViewedListRequest.data?.isLastPage ||
      shopRecentlyViewedListRequest.isLoading
    )
      return;

    const request: PaginationRequest = {
      limit: REQUESTS_LIMIT,
      offset: shopRecentlyViewedListRequest.data?.items.length || 0,
    };

    dispatch(getShopRecentlyViewedListRequestAction.request(request));
  };

  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onClearRecentlyViewed = () => {
    const request: PaginationRequest = {
      limit: REQUESTS_SHOP_RECENTLY_VIEWED_LIMIT,
      offset: 0,
    };

    dispatch(deleteRecentlyViewedRequestAction.request(request));
    handleModal();
  };

  const renderShopCardItem = ({item}: ListRenderItemInfo<ShopItem>) => {
    return (
      <CategoryListItemContainer to={`/shops/${item.id}`}>
        <ShopRecentlyCardBadgesContainer>
          {item.prefecture && (
            <ShopRecentlyCardLocationBadge numberOfLines={1}>
              {item.prefecture}
            </ShopRecentlyCardLocationBadge>
          )}

          {item.tag && (
            <ShopRecentlyCardTagBadge numberOfLines={1}>
              {item.tag}
            </ShopRecentlyCardTagBadge>
          )}
        </ShopRecentlyCardBadgesContainer>
        <ShopRecentlyCardTitleContainer>
          <ShopRecentlyCardTitle>{item.name}</ShopRecentlyCardTitle>
        </ShopRecentlyCardTitleContainer>
        <ShopRecentlyCardImage source={{uri: item.images[0].imageUrl}} />
      </CategoryListItemContainer>
    );
  };

  const renderScreenListLoading = () => {
    return (
      <ScreenListLoadingContainer>
        <ScreenListLoading />
      </ScreenListLoadingContainer>
    );
  };

  const renderScreenListEmpty = () => {
    return (
      <ScreenListEmptyContainer>
        <ScreenListEmptyIcon />
        <ScreenListEmptyTitle>
          {strings.shop_no_recently_viewed_title}
        </ScreenListEmptyTitle>
        <RequestsListEmptyInfo>
          {strings.shop_recently_try_later_text}
        </RequestsListEmptyInfo>
      </ScreenListEmptyContainer>
    );
  };

  return (
    <>
      <AppStatusBar />
      <ShopsContainerContainer>
        <ShopRecentlyViewedContainerContent>
          <AppLayout>
            {isEmptyList && shopRecentlyViewedListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && shopRecentlyViewedListRequest.error ? (
              <AppError
                error={shopRecentlyViewedListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              <>
                <AppTransparentHeader
                  title={`${strings.shop_recently_viewed_title} (${shopRecentlyViewedListRequest.data?.totalCount})`}
                  backNoHistoryRoute="/shops"
                  rightButton={
                    <ShopRecentlyClearHistoryButton onPress={handleModal}>
                      {strings.shop_recently_clear_history_button.toUpperCase()}
                    </ShopRecentlyClearHistoryButton>
                  }
                />
                {shopRecentlyViewedListRequest.data && (
                  <ShopRecentlyViewedListContainer>
                    <ShopRecentlyViewedList
                      numColumns={theme.isDesktop ? 4 : 1}
                      refreshControl={
                        <AppRefreshControl
                          onRefresh={onFirstPage}
                          refreshing={
                            shopRecentlyViewedListRequest.isFirstLoading
                          }
                        />
                      }
                      data={shopRecentlyViewedListRequest.data.items}
                      renderItem={renderShopCardItem}
                      keyExtractor={item => String(item.id)}
                      onEndReached={onNextPage}
                      ListFooterComponent={
                        shopRecentlyViewedListRequest.isLoading
                          ? renderScreenListLoading
                          : null
                      }
                      ListEmptyComponent={renderScreenListEmpty}
                    />
                  </ShopRecentlyViewedListContainer>
                )}
              </>
            )}
          </AppLayout>
        </ShopRecentlyViewedContainerContent>
      </ShopsContainerContainer>

      <AppModal visible={isModalOpen} onBackdropPress={handleModal}>
        <AppModalTitle>
          {strings.shop_recently_clear_history_modal_title}
        </AppModalTitle>
        <AppModalButton onPress={onClearRecentlyViewed}>
          {strings.shop_recently_clear_history_modal_positive_button.toUpperCase()}
        </AppModalButton>
        <AppModalCancel onPress={handleModal}>
          {strings.shop_recently_clear_history_modal_negative_button}
        </AppModalCancel>
      </AppModal>
    </>
  );
}
