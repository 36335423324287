import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath} from 'react-native-svg';

const Eye = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path d="M1.181 12c.94-5.12 5.427-9 10.82-9 5.391 0 9.877 3.88 10.818 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9Zm10.82 5A5 5 0 1 0 12 7a5 5 0 0 0 0 10Zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Eye;
