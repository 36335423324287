import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import ArrowBack from '../../../assets/icons/ArrowBack';
import {ComponentProps, ComponentType} from 'react';

export const AppHeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  height: 56px;
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 0;
      padding-left: 0;
      background-color: transparent;
    `}
`;

export const AppHeaderBackButtonContainer = styled.TouchableOpacity`
  margin-right: ${props => props.theme.dimens.smallSpacer};
`;

export const AppHeaderBackButtonIcon = styled(ArrowBack)``;

export const AppHeaderTitle = styled(
  Text as ComponentType<
    ComponentProps<typeof Text> & {withBigDesktop: boolean}
  >,
).attrs(props => ({
  category: props.withBigDesktop && props.theme.isDesktop ? 'h1' : 's1',
}))`
  flex: 1;
`;
