import React from 'react';
import {
  DetailRequestChatCommonContainer,
  DetailRequestChatCommonIcon,
  DetailRequestChatCommonImage,
  DetailRequestChatCommonImageButton,
  DetailRequestChatCommonInfoBubble,
  DetailRequestChatCommonInfoContainer,
  DetailRequestChatCommonInfoType,
  DetailRequestChatCommonMessageContainer,
} from './styles';
import {
  RequestChatMessageContentType,
  RequestChatMessageNormal,
} from '../../../../../../../../api/request/models/responses/chat';
import DetailRequestSound from '../../sound';
import renderMessageWithUrls from '../message-with-url';

export default function DetailRequestChatCommon({
  onAction,
  message,
  type,
}: {
  onAction: (imageUrl: string) => void;
  message: RequestChatMessageNormal;
  type: string;
}) {
  return (
    <DetailRequestChatCommonContainer>
      <DetailRequestChatCommonInfoType>{type}</DetailRequestChatCommonInfoType>
      <DetailRequestChatCommonInfoContainer>
        <DetailRequestChatCommonIcon />

        <DetailRequestChatCommonMessageContainer>
          {message.messageText ? (
            <DetailRequestChatCommonInfoBubble>
              {renderMessageWithUrls(message.messageText)}
            </DetailRequestChatCommonInfoBubble>
          ) : null}

          {message.messageContentType ===
          RequestChatMessageContentType.IMAGE ? (
            <DetailRequestChatCommonImageButton
              onPress={() => onAction(message.attachments?.[0]?.attachmentUrl)}>
              <DetailRequestChatCommonImage
                source={{uri: message.attachments?.[0]?.attachmentUrl}}
              />
            </DetailRequestChatCommonImageButton>
          ) : null}

          {message.messageContentType ===
          RequestChatMessageContentType.AUDIO ? (
            <DetailRequestSound
              audioUrl={message.attachments?.[0]?.attachmentUrl}
            />
          ) : null}
        </DetailRequestChatCommonMessageContainer>
      </DetailRequestChatCommonInfoContainer>
    </DetailRequestChatCommonContainer>
  );
}
