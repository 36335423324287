import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

import MyPageScreen from './screen';
import FAQScreen from '../faq/sreen';
import OperatingCompanyScreen from '../operating-company/screen';
import InfoScreen from '../info/screen';
import {DetailInfoScreen} from '../info/ui';
import PrivacyPolicyScreen from '../privacy-policy/screen';
import ContactUsScreen from '../contact-us/sreen';

const Stack = createNativeStackNavigator();

const MyPageNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="MyPage" component={MyPageScreen} />
      <Stack.Screen name="FAQ" component={FAQScreen} />
      <Stack.Screen
        name="OperatingCompany"
        component={OperatingCompanyScreen}
      />
      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
      <Stack.Screen name="Info" component={InfoScreen} />
      <Stack.Screen name="DetailInfo" component={DetailInfoScreen} />
      <Stack.Screen name="ContactUs" component={ContactUsScreen} />
    </Stack.Navigator>
  );
};

export default MyPageNavigator;
