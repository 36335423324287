import React from 'react';
import RNLocalize from 'react-native-localize';
import {getLocale, Locales, Strings} from '../../resources/strings';
import {useAppDispatch, useAppSelector} from '../../store/store';
import {
  setLocalizationAction,
  setStringsAction,
} from '../../features/app/store/action';

export function useStrings(): Strings {
  const strings = useAppSelector(store => store.app.strings);

  if (!strings) throw Error('Strings must be initialized');

  return strings;
}

//TODO Продумать решение над plurals
export default function StringsProvider({
  children,
  onLoad,
}: {
  children: React.ReactElement;
  onLoad: () => void;
}): React.ReactElement | null {
  const dispatch = useAppDispatch();
  const strings = useAppSelector(store => store.app.strings);

  async function nextLoadStrings() {
    const locale = getLocale();
    dispatch(setLocalizationAction(locale));
    return loadStrings(locale);
  }

  async function loadStrings(locale: Locales = getLocale()) {
    return (() => {
      switch (locale) {
        case Locales.JA:
          return import('../../resources/strings/ja');
        case Locales.EN:
        default:
          return import('../../resources/strings/en');
      }
    })()
      .then(data => {
        dispatch(setStringsAction(data.strings));
      })
      .catch(error => {
        console.error('Failed to load string resources', error);
      });
  }

  RNLocalize.addEventListener('change', nextLoadStrings);

  loadStrings().then(() => {
    onLoad();
  });

  //TODO Продумать глобальную загрузку вместе с юзером итд
  if (!strings) return null;

  return children;
}
