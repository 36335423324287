import {ComponentProps, ComponentType} from 'react';
import {ImageProps, ImageStyle} from 'react-native';
import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {CheckBox} from '@ui-kitten/components';
import SelectDate from '../../../../../../../../assets/icons/SelectDate';
import {AppDatepicker} from '../../../../../../../../uikit/AppDatepicker';

export const NewRequestQuestionFieldDateInput = styled(AppDatepicker)``;

export const NewRequestQuestionFieldDateIcon = styled<
  ComponentType<ImageProps & ComponentProps<typeof SelectDate>>
>(SelectDate).attrs(props => ({
  fill: (props.style as ImageStyle)?.tintColor,
}))``;

export const NewRequestQuestionFieldDateFixedCheckbox = styled(CheckBox)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
`;
