import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import AppRouteView from '../../../../../uikit/AppRouteView';

export const ShopsSliderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 26px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const ShopSlide = styled(AppRouteView).attrs({activeOpacity: 1})`
  display: flex;
  flex-direction: column-reverse;
  height: 200px;
  background-color: #e9e0d8;

  ${props =>
    props.theme.isDesktop &&
    css`
      min-height: 268px;
      flex-basis: 50%;

      flex-direction: row;
      align-items: flex-end;

      user-drag: none;
      -o-user-drag: none;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    `}
`;

export const ShopSlideContentContainer = styled.View`
  padding: 6px 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      display: flex;
      flex-basis: 50%;
      height: 100%;
      padding: 16px;
      justify-content: space-between;
    `}
`;

export const ShopSlideBadgesContainer = styled.View`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-left: 8px;

  ${props =>
    props.theme.isDesktop &&
    css`
      position: relative;
      display: flex;
      flex-direction: row;
      margin: 0;
    `}
`;

export const ShopSlideTitle = styled.Text`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: ${props => props.theme.colors['color-basic-900']};

  ${props =>
    props.theme.isDesktop &&
    css`
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
    `}
`;

export const ShopSlideLocationBadge = styled.Text`
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  border-radius: 10px;
  overflow: hidden;
  max-width: 120px;
  padding: 2px 8px;
  margin-right: 6px;
  background-color: ${props => props.theme.colors['color-primary-500']};
  color: ${props => props.theme.colors['color-basic-900']};

  ${props =>
    props.theme.isDesktop &&
    css`
      background-color: ${props.theme.colors['color-primary-400']};
    `}
`;

export const ShopSlideTagBadge = styled(ShopSlideLocationBadge)`
  background-color: ${props => props.theme.colors.white};
`;

export const ShopSlideImageContainer = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-basis: 50%;
      width: 100%;
      height: 100%;
    `}
`;

export const ShopSlideImage = styled.Image`
  flex: 1;
`;

export const ShopsSliderDotsContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 16px;
    `}
`;

export const ShopsSliderDot = styled.TouchableOpacity<{isActive?: boolean}>`
  width: 8px;
  height: 8px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50px;
  background-color: ${props =>
    props.isActive
      ? props.theme.colors['color-primary-500']
      : props.theme.colors['color-primary-300']};
`;
