import {createAsyncAction} from 'scl/src/redux/create-async-action';
import {ListResponse} from '../../../api/models/response/list';
import {IInfo} from '../../../api/info/models/info';

/** Get information posts list action */
const getInfoRequestAction = createAsyncAction<
  void,
  ListResponse<IInfo>,
  Error
>('GET_INFO');

/** Get detailed information post action  */
const getInfoPostRequestAction = createAsyncAction<number, IInfo, Error>(
  'GET_INFO_POST',
);

export const infoActions = {
  fetchList: getInfoRequestAction,
  fetchPost: getInfoPostRequestAction,
};
