import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Save = (props: SvgProps) => (
  <Svg height={24} width={24} viewBox="0 0 48 48" fill="#AEA69E" {...props}>
    <Path d="M42 13.85V39q0 1.2-.9 2.1-.9.9-2.1.9H9q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h25.15Zm-3 1.35L32.8 9H9v30h30ZM24 35.75q2.15 0 3.675-1.525T29.2 30.55q0-2.15-1.525-3.675T24 25.35q-2.15 0-3.675 1.525T18.8 30.55q0 2.15 1.525 3.675T24 35.75ZM11.65 18.8h17.9v-7.15h-17.9ZM9 15.2V39 9Z" />
  </Svg>
);

export default Save;
