import React, {useMemo} from 'react';
import {
  Controller,
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormSetError,
  UseFormWatch,
} from 'react-hook-form';

import {useStrings} from '../../../../../../utils/providers/strings';

import {onNumberChangeText} from 'scl/src/react-native/input/on-number-change-text';
import {AccessoryLoadingIndicator} from '../../../../../../uikit/AccessoryLoadingIndicator';
import {ConciergePointsBuyByBankTransferForm} from '../../screen';
import {
  useAppDispatch,
  useRequestSelector,
} from '../../../../../../store/store';

import {AppLoader} from '../../../../../../uikit/AppLoader';
import {AppError} from '../../../../../../uikit/AppError';
import {SettingId} from '../../../../../../api/settings/models/settingsResponse';
import {settingsRequestAction} from '../../../../../app/store/action';
import useConvertRate from '../../../../../../utils/use-convert-rate';
import useMinimalAmount from '../../../../../../utils/use-minimal-amount';
import {AppButtonGroup} from '../../../../../../uikit/AppButtonGroup';
import {AppButton} from '../../../../../../uikit/AppButtons';

import {
  ConciergePointsAddAmountButtons,
  ConciergePointsBuyByBankTransferButton,
  ConciergePointsBuyByBankTransferContainer,
  ConciergePointsBuyByBankTransferCourseTitle,
  ConciergePointsBuyByBankTransferHeader,
  ConciergePointsBuyByBankTransferInput,
  ConciergePointsBuyByBankTransferInputError,
  ConciergePointsBuyByBankTransferKeyboardAvoidingView,
} from './styles';
import {
  ConciergePointsAmountAfterChargeContainer,
  ConciergePointsAmountAfterChargePoints,
  ConciergePointsAmountAfterChargeSuffix,
  ConciergePointsAmountAfterChargeTitle,
} from '../../../styles';

import {CurrencyTypes} from '../../../../../../api/user/models/responses/user-profile';

export default function ConciergePointsBuyByBankTransferInputs({
  isLoading,
  control,
  errors,
  onBuy,
  watch,
  setFieldValue,
  setError,
}: {
  isLoading: boolean;
  control: Control<ConciergePointsBuyByBankTransferForm>;
  errors: FieldErrors<ConciergePointsBuyByBankTransferForm>;
  onBuy: () => void;
  watch: UseFormWatch<ConciergePointsBuyByBankTransferForm>;
  setFieldValue: UseFormSetValue<ConciergePointsBuyByBankTransferForm>;
  setError: UseFormSetError<ConciergePointsBuyByBankTransferForm>;
}) {
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const settingsRequest = useRequestSelector(
    store => store.app.settingsRequest,
  );

  const getUserProfileRequest = useRequestSelector(
    store => store.user.getUserProfileRequest,
  );

  const cpPoints = useMemo<number>(() => {
    return (
      getUserProfileRequest.data?.wallets?.find(
        wallet => wallet.currency === CurrencyTypes.CP,
      )?.amount || 0
    );
  }, [getUserProfileRequest.data]);

  const chargedCpPoints = watch('amount') || 0;

  const convertRate = useConvertRate();

  const minimalAmount: number = useMinimalAmount(
    SettingId.CP_MINIMAL_AMOUNT_TO_BANK_TRANSFER,
  );

  /** Add value to CP`s amount on button click */
  const handleAddAmount = (value: number) => () => {
    const prevValue = watch('amount') || 0;
    setFieldValue('amount', String(+prevValue + value));
    setError('amount', {message: ''});
  };

  return (
    <ConciergePointsBuyByBankTransferKeyboardAvoidingView>
      {settingsRequest.isLoading ? (
        <AppLoader />
      ) : settingsRequest.error ? (
        <AppError
          error={settingsRequest.error.message}
          retry={() => dispatch(settingsRequestAction.request())}
        />
      ) : (
        settingsRequest.data && (
          <>
            <ConciergePointsBuyByBankTransferContainer>
              <ConciergePointsBuyByBankTransferHeader>
                {strings.concierge_points_buy_points_title}
              </ConciergePointsBuyByBankTransferHeader>
              <Controller
                control={control}
                rules={{
                  required: strings.concierge_points_buy_points_required,
                  validate: value =>
                    Number(value) >= minimalAmount ||
                    strings.app_minimal_value_fn(minimalAmount),
                }}
                render={({
                  field: {onChange, onBlur, value},
                  fieldState: {error},
                }) => (
                  <>
                    <ConciergePointsBuyByBankTransferInput
                      status={error ? 'danger' : 'basic'}
                      onBlur={onBlur}
                      onChangeText={onNumberChangeText(onChange)}
                      value={value}
                      placeholder={
                        strings.concierge_points_buy_points_placeholder
                      }
                    />
                    <ConciergePointsBuyByBankTransferCourseTitle>
                      {`${strings.app_number(Number(value)) || 0} CP = ${
                        strings.app_number(convertRate * Number(value)) || 0
                      } JPY`}
                    </ConciergePointsBuyByBankTransferCourseTitle>
                  </>
                )}
                name="amount"
              />
              {errors.amount && (
                <ConciergePointsBuyByBankTransferInputError>
                  {errors.amount.message}
                </ConciergePointsBuyByBankTransferInputError>
              )}
              <ConciergePointsAddAmountButtons>
                <AppButtonGroup>
                  <AppButton onPress={handleAddAmount(500)}>
                    {`+${strings.app_number(500)}`}
                  </AppButton>
                  <AppButton onPress={handleAddAmount(1000)}>
                    {`+${strings.app_number(1000)}`}
                  </AppButton>
                  <AppButton onPress={handleAddAmount(3000)}>
                    {`+${strings.app_number(3000)}`}
                  </AppButton>
                  <AppButton onPress={handleAddAmount(5000)}>
                    {`+${strings.app_number(5000)}`}
                  </AppButton>
                  <AppButton onPress={handleAddAmount(10000)}>
                    {`+${strings.app_number(10000)}`}
                  </AppButton>
                  <AppButton onPress={handleAddAmount(25000)}>
                    {`+${strings.app_number(25000)}`}
                  </AppButton>
                </AppButtonGroup>
              </ConciergePointsAddAmountButtons>

              <ConciergePointsAmountAfterChargeContainer>
                <ConciergePointsAmountAfterChargeTitle>
                  {strings.concierge_points_balance_after_charge}
                </ConciergePointsAmountAfterChargeTitle>
                <ConciergePointsAmountAfterChargePoints>
                  {strings.app_number(cpPoints + Number(chargedCpPoints))}
                </ConciergePointsAmountAfterChargePoints>
                <ConciergePointsAmountAfterChargeSuffix>
                  {strings.points_suffix.toUpperCase()}
                </ConciergePointsAmountAfterChargeSuffix>
              </ConciergePointsAmountAfterChargeContainer>
            </ConciergePointsBuyByBankTransferContainer>

            <ConciergePointsBuyByBankTransferButton
              disabled={isLoading}
              accessoryRight={isLoading ? AccessoryLoadingIndicator : undefined}
              onPress={onBuy}>
              {strings.concierge_points_buy_button.toUpperCase()}
            </ConciergePointsBuyByBankTransferButton>
          </>
        )
      )}
    </ConciergePointsBuyByBankTransferKeyboardAvoidingView>
  );
}
