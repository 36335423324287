import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import ConciergePointsBuyByCybersourceScreen from './by-cybersource/screen';
import ConciergePointsBuyScreen from './screen';
import ConciergePointsBuyByVirtualpayScreen from './by-virtualpay/screen';
import ConciergePointsBuyByBtcScreen from './by-btc/screen';
import ConciergePointsBuyByBTCSuccess from './success-btc/screen';
import ConciergePointsBuyByCybersourceSuccessScreen from './success-cybersource/screen';
import ConciergePointsBuyByVirtualpaySuccessScreen from './success-virtualpay/screen';
import ConciergePointsBuyByBankTransferScreen from './by-bank-transfer/screen';
import {useRequestSelector} from '../../../store/store';
import {SettingId} from '../../../api/settings/models/settingsResponse';

const Stack = createNativeStackNavigator();

export default function ConciergePointsBuyNavigator() {
  const settingsRequest = useRequestSelector(
    store => store.app.settingsRequest,
  );

  const isPaymentActive = (settingId: SettingId) => {
    const switcherValue = settingsRequest.data?.find(
      item => item.id === settingId,
    )?.value;

    return switcherValue === 'true' ? true : false;
  };

  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen
        name="ConciergePointsBuy"
        component={ConciergePointsBuyScreen}
      />
      {isPaymentActive(SettingId.CREDIT_CARD_SWITCHER) && (
        <>
          <Stack.Screen
            name="ConciergePointsBuyByVirtualpay"
            component={ConciergePointsBuyByVirtualpayScreen}
          />
          <Stack.Screen
            name="ConciergePointsBuyByCybersource"
            component={ConciergePointsBuyByCybersourceScreen}
          />
        </>
      )}
      {isPaymentActive(SettingId.BTC_PAY_SWITCHER) && (
        <Stack.Screen
          name="ConciergePointsBuyByBTC"
          component={ConciergePointsBuyByBtcScreen}
        />
      )}
      {isPaymentActive(SettingId.BANK_TRANSFER_SWITCHER) && (
        <Stack.Screen
          name="ConciergePointsBuyByBankTransfer"
          component={ConciergePointsBuyByBankTransferScreen}
        />
      )}
      <Stack.Screen
        name="ConciergePointsBuySuccess"
        component={ConciergePointsBuyByVirtualpaySuccessScreen}
      />
      <Stack.Screen
        name="ConciergePointsBuyByCybersourceSuccess"
        component={ConciergePointsBuyByCybersourceSuccessScreen}
      />
      <Stack.Screen
        name="ConciergePointsBuyByBTCSuccess"
        component={ConciergePointsBuyByBTCSuccess}
      />
    </Stack.Navigator>
  );
}
