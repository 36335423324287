import React from 'react';
import {TextStyle, View} from 'react-native';
import {useTheme} from 'styled-components/native';

import {useStrings} from '../../utils/providers/strings';
import useRouter from '../../utils/use-router';

import {PointsContainerPoints} from '../../uikit/Points/container';
import {AppWhiteStatusBar} from '../../uikit/AppStatusBar';
import {InfoListItem} from '../../uikit/InfoList';
import {AppLayout} from '../../uikit/AppLayout';
import Collapse from '../../uikit/Collapse';
import CallFAB from '../../uikit/CallFAB';

import {
  AppTransparentHeader,
  FAQAccordionGroup,
  FAQContactUsButton,
  FAQContainer,
  FAQContent,
  FAQContentWrapper,
  FAQSubtitle,
} from './styles';

export default function FAQScreen() {
  const strings = useStrings();
  const router = useRouter();
  const theme = useTheme();

  const titleStyles: TextStyle = theme.isDesktop
    ? {
        fontWeight: '600',
        fontSize: 28,
        lineHeight: 36,
      }
    : {
        fontSize: 16,
      };

  return (
    <>
      <AppWhiteStatusBar />
      <FAQContainer>
        <FAQContentWrapper>
          <AppTransparentHeader
            title={strings.faq_title}
            backNoHistoryRoute={theme.isDesktop ? '/' : '/my-page'}
            titleStyle={titleStyles}
          />
          <FAQContent>
            <View style={{paddingBottom: 60}}>
              <FAQSubtitle>{strings.faq_subtitle_1}</FAQSubtitle>

              <FAQAccordionGroup>
                <Collapse title={strings.faq_question_1_title}>
                  {strings.faq_question_1_text}
                </Collapse>
                <Collapse title={strings.faq_question_2_title}>
                  {strings.faq_question_2_text}
                </Collapse>
                <Collapse title={strings.faq_question_3_title}>
                  {strings.faq_question_3_text}
                </Collapse>
              </FAQAccordionGroup>

              <FAQSubtitle>{strings.faq_subtitle_2}</FAQSubtitle>
              <FAQAccordionGroup>
                <Collapse title={strings.faq_question_4_title}>
                  {strings.faq_question_4_text}
                </Collapse>
                <Collapse title={strings.faq_question_5_title}>
                  {strings.faq_question_5_text}
                </Collapse>
              </FAQAccordionGroup>

              <FAQSubtitle>{strings.faq_contact_us_subtitle}</FAQSubtitle>
              <InfoListItem
                title={strings.faq_contact_us_phone_label}
                value={strings.faq_contact_us_phone_value}
              />

              <FAQContactUsButton onPress={() => router.replace('/contact-us')}>
                {strings.faq_contact_us_button_label}
              </FAQContactUsButton>
            </View>
          </FAQContent>
          <AppLayout>{!theme.isDesktop && <CallFAB />}</AppLayout>
        </FAQContentWrapper>
        {theme.isDesktop && <PointsContainerPoints />}
      </FAQContainer>
    </>
  );
}
