import storage from '@react-native-async-storage/async-storage';
import {SettingsResponse} from '../api/settings/models/settingsResponse';

const JWT_TOKEN_KEY = 'keys:jwt';
const REFRESH_TOKEN_KEY = 'keys:refresh';
const SETTINGS_KEY = 'keys:settings';

export function getJWTToken(): Promise<string | null> {
  return storage.getItem(JWT_TOKEN_KEY);
}

export function saveJWTToken(token: string | null): Promise<void> {
  return token === null
    ? storage.removeItem(JWT_TOKEN_KEY)
    : storage.setItem(JWT_TOKEN_KEY, token);
}

export function getRefreshToken(): Promise<string | null> {
  return storage.getItem(REFRESH_TOKEN_KEY);
}

export function saveRefreshToken(token: string | null): Promise<void> {
  return token === null
    ? storage.removeItem(REFRESH_TOKEN_KEY)
    : storage.setItem(REFRESH_TOKEN_KEY, token);
}

export function saveTokens(
  jwtToken: string | null,
  refreshToken: string | null,
): Promise<[void, void]> {
  return Promise.all([saveJWTToken(jwtToken), saveRefreshToken(refreshToken)]);
}

export function getSettings(): Promise<SettingsResponse[] | null> {
  return storage.getItem(SETTINGS_KEY).then(item => {
    if (item === null) return null;

    try {
      return JSON.parse(item);
    } catch (e) {
      return null;
    }
  });
}

export function saveSettings(token: SettingsResponse[] | null): Promise<void> {
  return token === null
    ? storage.removeItem(SETTINGS_KEY)
    : storage.setItem(SETTINGS_KEY, JSON.stringify(token));
}
