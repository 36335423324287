import React from 'react';
import {ListRenderItemInfo, Platform} from 'react-native';
import {useTheme} from 'styled-components/native';
import {
  NewRequestCategoriesRouteItem,
  NewRequestCategoriesRouteItemContainer,
  NewRequestCategoriesRouteItemIcon,
  NewRequestCategoriesRouteItemName,
  NewRequestCategoriesRoutes,
} from './styles';
import {RequestCategory} from '../../../../api/request/models/responses/categories';
import {SvgUri} from 'react-native-svg';

export default function NewRequestCategories({
  categories,
  onSelect,
}: {
  categories: RequestCategory[];
  onSelect: (category: RequestCategory) => void;
}) {
  const theme = useTheme();

  function renderNewRequestCategoriesRouteItem({
    item,
  }: ListRenderItemInfo<RequestCategory>) {
    return (
      <NewRequestCategoriesRouteItemContainer>
        <NewRequestCategoriesRouteItem onPress={() => onSelect(item)}>
          {Platform.OS === 'ios' || Platform.OS === 'android' ? (
            <SvgUri width={80} height={80} uri={item.imageUrl} />
          ) : (
            <NewRequestCategoriesRouteItemIcon source={{uri: item.imageUrl}} />
          )}

          <NewRequestCategoriesRouteItemName>
            {item.name}
          </NewRequestCategoriesRouteItemName>
        </NewRequestCategoriesRouteItem>
      </NewRequestCategoriesRouteItemContainer>
    );
  }

  return (
    <NewRequestCategoriesRoutes
      key={
        theme.isDesktop
          ? 'NewRequestCategoriesRoutesDesktop'
          : 'NewRequestCategoriesRoutes'
      }
      numColumns={theme.isDesktop ? 3 : 2}
      data={categories}
      renderItem={renderNewRequestCategoriesRouteItem}
    />
  );
}
