import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';

export const ConciergePointsBuyByBankTransferPaymentContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${props => props.theme.dimens.smallSpacer};
  padding: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      flex: none;
      margin-top: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
      padding: 24px;
    `}
`;

export const ConciergePointsBuyByBankTransferPaymentHeader = styled(Text).attrs(
  {
    category: 'h6',
  },
)`
  margin-bottom: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-bottom: 20px;
    `}
`;

export const ConciergePointsBuyByBankTransferPaymentTitle = styled(Text)`
  margin-top: ${props => props.theme.dimens.normalSpacer};
  font-weight: bold;
  font-size: 16px;
`;

export const ConciergePointsBuyByBankTransferPaymentInfo = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const ConciergePointsBuyByBankTransferPaymentInfoCode = styled(Text)`
  font-weight: bold;
`;
