import {AxiosInstance} from 'axios';
import {ListResponse} from '../models/response/list';
import {FriendRequest, SearchFriendRequest} from './models/requests/friend';
import {Friend, SearchFriend} from './models/responses/friend';
import {AddFriendRequest} from './models/requests/add';
import {DefaultResponse} from '../models/response/default';
import {UpdateFriendRequest} from './models/requests/update';

export default class FriendApi {
  constructor(private axiosInstance: AxiosInstance) {}

  getFriendList(query: FriendRequest): Promise<ListResponse<Friend>> {
    return this.axiosInstance.get('user/friends', {
      params: query,
    });
  }

  addFriend(request: AddFriendRequest): Promise<DefaultResponse<Friend>> {
    return this.axiosInstance.post('user/friends', request);
  }

  updateFriend(
    id: string,
    request: UpdateFriendRequest,
  ): Promise<DefaultResponse<Friend>> {
    return this.axiosInstance.put(`user/friends/${id}`, request);
  }

  deleteFriend(id: string): Promise<DefaultResponse<void>> {
    return this.axiosInstance.delete(`user/friends/${id}`);
  }

  searchFriendByPhone(
    query: SearchFriendRequest,
  ): Promise<DefaultResponse<SearchFriend>> {
    return this.axiosInstance.get('user/friends/find-by-phone', {
      params: query,
    });
  }
}
