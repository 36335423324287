import React, {useState} from 'react';
import {useStrings} from '../../../../../utils/providers/strings';
import {TouchableOpacity} from 'react-native';
import Search from '../../../../../assets/icons/Search';
import {ShopsSearchByCategoryRequestParams} from '../../../../../api/shops/models/shop-search';
import {useAppDispatch} from '../../../../../store/store';
import {searchShopsByCategoryRequestAction} from '../../../store/action';
import {ShopsSearchInput} from './styles';
import Close from '../../../../../assets/icons/Close';
import {Route, useRoute} from '@react-navigation/native';

type Props = {
  mode: 'main' | 'search';
  initialSearchValue?: string;
  onSearch: (searchValue: string) => void;
};

const ShopsSearchByCategory = ({
  mode,
  initialSearchValue = '',
  onSearch,
}: Props) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const dispatch = useAppDispatch();
  const strings = useStrings();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  const onClearHandler = () => {
    setSearchValue('');
    if (mode === 'search') {
      onSearch('');
    }
  };

  const searchShops = () => {
    onSearch(searchValue);

    const params: ShopsSearchByCategoryRequestParams = {
      limit: 24,
      offset: 0,
      query: searchValue,
      categoryId: requestId,
    };

    dispatch(searchShopsByCategoryRequestAction.request(params));
  };

  const renderClearIcon = () => (
    <TouchableOpacity onPress={onClearHandler}>
      <Close />
    </TouchableOpacity>
  );

  const renderSearchIcon = () => (
    <TouchableOpacity onPress={searchShops}>
      <Search />
    </TouchableOpacity>
  );

  return (
    <ShopsSearchInput
      placeholder={strings.shop_search_placeholder}
      accessoryLeft={
        searchValue || mode === 'search' ? renderClearIcon : undefined
      }
      accessoryRight={renderSearchIcon}
      onChangeText={setSearchValue}
      value={searchValue}
      onSubmitEditing={searchShops}
    />
  );
};

export {ShopsSearchByCategory};
