import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList, Text} from 'react-native';
import {Spinner} from '@ui-kitten/components';
import AppRouteView from '../../../../uikit/AppRouteView';
import AppHeader from '../../../../uikit/AppHeader';
import EmptyListScreen from '../../../../assets/icons/EmptyListScreen';
import {ShopReviewsResponse} from '../../../../api/shops/models/responses/shop-reviews';
import Star from '../../../../assets/icons/Star';
import StarOutline from '../../../../assets/icons/StarOutline';

export const ShopReviewsViewedContainerContent = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-top: 48px;
      padding-left: 24px;
      padding-right: 111px;
    `}
`;

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  padding: 0 16px;
  margin-bottom: 20px;
`;

export const ShopReviewsViewedListContainer = styled.View`
  flex: 1;
  padding: 0 10px;

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-right: 0;
    `}
`;

export const ShopReviewsViewedList = styled(FlatList<ShopReviewsResponse>)`
  display: flex;
  flex: 1;
`;

export const ScreenListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const ScreenListLoading = styled(Spinner)``;

export const ScreenListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const ScreenListEmptyIcon = styled(EmptyListScreen)``;

export const ScreenListEmptyTitle = styled(Text)`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const ScreenListEmptyInfo = styled(Text)``;

export const ShopReviewItemContainer = styled(AppRouteView)`
  display: flex;
  background: white;
  border-radius: 4px;
  padding: 16px;
  margin-right: 0;
  margin-bottom: 12px;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-basis: calc(33.33% - 12px);
      margin-right: 6px;
      margin-left: 6px;
      margin-bottom: 12px;
    `}
`;

export const ShopReviewItemAuthor = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 8px;
`;

export const ShopReviewItemDescription = styled.Text`
  font-size: 14px;
  line-height: 20px;
  color: #8f8880;
  max-height: 60px;
  overflow: hidden;
  margin-top: 4px;
`;

export const ShopHeadContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ShopStarContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const ShopStarIcon = styled(Star)`
  max-width: 15px;
  max-height: 15px;
`;

export const ShopHeadTitle = styled.Text`
  color: ${props => props.theme.colors['color-basic-500']};
`;

export const ShopStarOutlineIcon = styled(StarOutline)`
  max-width: 15px;
  max-height: 15px;
`;
