import {combineReducers} from 'redux';
import {
  createShopFavoriteRequestAction,
  createShopReviewRequestAction,
  deleteRecentlyViewedRequestAction,
  deleteShopFavoriteRequestAction,
  getAllReviewsListRequestAction,
  getDetailShopRequestAction,
  getDetailShopReviewRequestAction,
  getShopByCategoryListRequestAction,
  getShopCategoriesListRequestAction,
  getShopCommitmentsListRequestAction,
  getShopFavoritesListRequestAction,
  getShopProductsListRequestAction,
  getShopRecentlyViewedListRequestAction,
  getShopReviewsListRequestAction,
  getShopsFlatCategoriesListAction,
  searchShopsByCategoryRequestAction,
  searchShopsRequestAction,
} from './action';
import {
  addPaginationRequestCase,
  PaginationRequest,
  PaginationRequestState,
} from '../../../utils/redux/create-pagination-request-reducer';
import {createReducer} from '@reduxjs/toolkit';
import {
  ListResponse,
  ListWithCategoryNameResponse,
} from '../../../api/models/response/list';
import {
  ShopCategoriesResponse,
  ShopCategory,
  ShopItem,
} from '../../../api/shops/models/responses/shop-categories';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {ShopDetailResponse} from '../../../api/shops/models/responses/shop-detail';
import {ShopProductsResponse} from '../../../api/shops/models/responses/shop-products';
import {ShopCommitmentsResponse} from '../../../api/shops/models/responses/shop-commitments';
import {ShopReviewsResponse} from '../../../api/shops/models/responses/shop-reviews';
import {ReviewsRequest, ShopsFlatCategoriesRequest} from '../../../api/shops';
import {
  ShopSearchItem,
  ShopsSearchByCategoryRequestParams,
  ShopsSearchRequestParams,
} from '../../../api/shops/models/shop-search';

export interface ShopCategoriesList {
  items: ShopCategoriesResponse[];
  totalCount: number;
  isLastPage: boolean;
}

export interface ShopFlatCageoriesList {
  items: ShopCategory[];
  totalCount: number;
  isLastPage: boolean;
}

export interface ShopReviewsList {
  items: ShopReviewsResponse[];
  totalCount: number;
  isLastPage: boolean;
}

export interface ShopItemsList {
  items: ShopItem[];
  totalCount: number;
  isLastPage: boolean;
  categoryName?: string;
}

export interface ShopSearchItemsList {
  items: ShopSearchItem[];
  totalCount: number;
  isLastPage: boolean;
}

export const shopReducer = combineReducers<{
  shopCategoriesListRequest: PaginationRequestState<ShopCategoriesList> | null;
  shopFlatCategoriesListRequest: PaginationRequestState<ShopFlatCageoriesList> | null;
  searchShopsRequest: PaginationRequestState<ShopSearchItemsList> | null;
  shopDetailRequest: RequestState<ShopDetailResponse> | null;
  shopProductsRequest: RequestState<ShopProductsResponse[]> | null;
  shopCommitmentsRequest: RequestState<ShopCommitmentsResponse[]> | null;
  allReviewsListRequest: PaginationRequestState<ShopReviewsList> | null;
  shopReviewsListRequest: PaginationRequestState<ShopReviewsList> | null;
  shopDetailReviewRequest: RequestState<ShopReviewsResponse> | null;
  createShopReviewRequest: RequestState<null> | null;
  shopRecentlyViewedListRequest: PaginationRequestState<ShopItemsList> | null;
  deleteRecentlyViewedRequest: RequestState<null> | null;
  shopFavoritesListRequest: PaginationRequestState<ShopItemsList> | null;
  createShopFavoriteRequest: RequestState<null> | null;
  deleteShopFavoriteRequest: RequestState<null> | null;
  shopByCategoryListRequest: PaginationRequestState<ShopItemsList> | null;
  searchShopsByCategoryRequest: PaginationRequestState<ShopSearchItemsList> | null;
}>({
  shopCategoriesListRequest:
    createReducer<PaginationRequestState<ShopCategoriesList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<ShopCategoriesResponse>],
          ShopCategoriesList
        >(builder, getShopCategoriesListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
  shopFlatCategoriesListRequest:
    createReducer<PaginationRequestState<ShopFlatCageoriesList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          ShopsFlatCategoriesRequest,
          [ShopsFlatCategoriesRequest, ListResponse<ShopCategory>],
          ShopFlatCageoriesList
        >(builder, getShopsFlatCategoriesListAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
  searchShopsRequest:
    createReducer<PaginationRequestState<ShopSearchItemsList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          ShopsSearchRequestParams,
          [ShopsSearchRequestParams, ListResponse<ShopSearchItem>],
          ShopSearchItemsList
        >(builder, searchShopsRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
  shopDetailRequest: createRequestReducer(getDetailShopRequestAction),
  shopProductsRequest: createRequestReducer(getShopProductsListRequestAction),
  shopCommitmentsRequest: createRequestReducer(
    getShopCommitmentsListRequestAction,
  ),
  allReviewsListRequest:
    createReducer<PaginationRequestState<ShopReviewsList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<ShopReviewsResponse>],
          ShopReviewsList
        >(builder, getAllReviewsListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
  shopReviewsListRequest:
    createReducer<PaginationRequestState<ShopReviewsList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          ReviewsRequest,
          [ReviewsRequest, ListResponse<ShopReviewsResponse>],
          ShopReviewsList
        >(builder, getShopReviewsListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
  shopDetailReviewRequest: createRequestReducer(
    getDetailShopReviewRequestAction,
  ),
  createShopReviewRequest: createRequestReducer(createShopReviewRequestAction),
  shopRecentlyViewedListRequest:
    createReducer<PaginationRequestState<ShopItemsList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<ShopItem>],
          ShopItemsList
        >(
          builder,
          getShopRecentlyViewedListRequestAction,
          (prevData, newData) => {
            const [query, response] = newData;
            const items = [
              ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
              ...response.data,
            ];
            return {
              items,
              totalCount: response.totalCount,
              isLastPage: items.length >= response.totalCount,
            };
          },
        );
      },
    ),
  deleteRecentlyViewedRequest: createRequestReducer(
    deleteRecentlyViewedRequestAction,
  ),
  shopFavoritesListRequest:
    createReducer<PaginationRequestState<ShopItemsList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          PaginationRequest,
          [PaginationRequest, ListResponse<ShopItem>],
          ShopItemsList
        >(builder, getShopFavoritesListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
  createShopFavoriteRequest: createRequestReducer(
    createShopFavoriteRequestAction,
  ),
  deleteShopFavoriteRequest: createRequestReducer(
    deleteShopFavoriteRequestAction,
  ),
  shopByCategoryListRequest:
    createReducer<PaginationRequestState<ShopItemsList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          ReviewsRequest,
          [ReviewsRequest, ListWithCategoryNameResponse<ShopItem>],
          ShopItemsList
        >(builder, getShopByCategoryListRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
            categoryName: response.categoryName,
          };
        });
      },
    ),
  searchShopsByCategoryRequest:
    createReducer<PaginationRequestState<ShopSearchItemsList> | null>(
      null,
      builder => {
        addPaginationRequestCase<
          ShopsSearchByCategoryRequestParams,
          [ShopsSearchByCategoryRequestParams, ListResponse<ShopSearchItem>],
          ShopSearchItemsList
        >(builder, searchShopsByCategoryRequestAction, (prevData, newData) => {
          const [query, response] = newData;
          const items = [
            ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
            ...response.data,
          ];
          return {
            items,
            totalCount: response.totalCount,
            isLastPage: items.length >= response.totalCount,
          };
        });
      },
    ),
});
