import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {
  createRequestRequestAction,
  getRequestCategoriesRequestAction,
  getRequestCategoryTypeRequestAction,
} from './action';
import {RequestCategory} from '../../../api/request/models/responses/categories';
import {RequestCategoryType} from '../../../api/request/models/responses/category-type';
import {RequestResponse} from '../../../api/request/models/responses';

export const newRequestReducer = combineReducers<{
  getRequestCategoriesRequest: RequestState<RequestCategory[]> | null;
  getRequestCategoryTypeRequest: RequestState<RequestCategoryType> | null;
  createRequestRequest: RequestState<RequestResponse> | null;
}>({
  getRequestCategoriesRequest: createRequestReducer(
    getRequestCategoriesRequestAction,
  ),
  getRequestCategoryTypeRequest: createRequestReducer(
    getRequestCategoryTypeRequestAction,
  ),
  createRequestRequest: createRequestReducer(createRequestRequestAction),
});
