import {Text} from '@ui-kitten/components';
import styled from 'styled-components/native';

export const UserMobileContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${props => props.theme.dimens.normalSpacer};
`;

export const UserMobileInfoContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const UserMobile = styled(Text).attrs({
  category: 'h6',
})`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
`;

export const UserIdContainerMobile = styled.View`
  display: flex;
  flex-direction: row;
`;

export const UserIdPrefixMobile = styled(Text)`
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const UserIdMobile = styled(Text)`
  color: ${props => props.theme.colors['color-basic-900']};
`;
