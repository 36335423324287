import React from 'react';

import {useStrings} from '../../../../utils/providers/strings';

import AppModal from '../../../../uikit/AppModal';

import {ContactUsSuccesModalButton, ContactUsSuccessModalText} from './styles';

type Props = {
  visible: boolean;
  close: () => void;
};

const SuccessModal = ({visible, close}: Props) => {
  const strings = useStrings();

  return (
    <AppModal visible={visible} onBackdropPress={close} style={{maxWidth: 264}}>
      <ContactUsSuccessModalText>
        {strings.contact_us_submit_success}
      </ContactUsSuccessModalText>
      <ContactUsSuccesModalButton onPress={close}>
        OK
      </ContactUsSuccesModalButton>
    </AppModal>
  );
};

export {SuccessModal};
