import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useTheme} from 'styled-components/native';

import {useStrings} from '../../../../utils/providers/strings';
import {
  getShopsFlatCategoriesListAction,
  searchShopsRequestAction,
} from '../../store/action';
import {useAppSelector} from '../../../../store/store';

import {ShopsSearchRequestParams} from '../../../../api/shops/models/shop-search';
import TouchableStripe from '../../../../uikit/TouchableStripe';
import {AppLoader} from '../../../../uikit/AppLoader';
import ShopCard from '../ShopCard';

import {
  LoadMoreButton,
  LoadMoreButtonLoaderContainer,
  LoadMoreButtonWrapper,
  ShopsSearchResultsCategories,
  ShopsSearchResultsContent,
  ShopsSearchResultsShops,
} from './styles';
import {Spinner} from '@ui-kitten/components';
import {EmptyList} from '../../../../uikit/EmptyList';

const SearchResults = ({searchValue}: {searchValue?: string}) => {
  const shopsSearch = useAppSelector(store => store.shops.searchShopsRequest);
  const flatCategories = useAppSelector(
    store => store.shops.shopFlatCategoriesListRequest,
  );
  const dispatch = useDispatch();
  const strings = useStrings();
  const theme = useTheme();

  const loadMoreCategories = () => {
    if (
      !flatCategories ||
      flatCategories.data?.isLastPage ||
      flatCategories.isLoading
    )
      return;

    const params: ShopsSearchRequestParams = {
      limit: 6,
      offset: flatCategories.data?.items.length || 0,
      query: searchValue,
    };

    dispatch(getShopsFlatCategoriesListAction.request(params));
  };

  const loadMoreShops = () => {
    if (!shopsSearch || shopsSearch.data?.isLastPage || shopsSearch.isLoading)
      return;

    const request: ShopsSearchRequestParams = {
      limit: 20,
      offset: shopsSearch.data?.items.length || 0,
      query: searchValue,
    };

    dispatch(searchShopsRequestAction.request(request));
  };

  const CategoriesList = useCallback(() => {
    const isLoading = flatCategories?.isLoading;
    const totalCount = flatCategories?.data?.totalCount || 0;
    const categories = flatCategories?.data?.items || [];

    if (!categories.length) return null;

    return (
      <>
        <ShopsSearchResultsCategories>
          {categories?.map(category => (
            <TouchableStripe
              key={`${category.name}-${category.id}`}
              title={category.name}
              to={`/shops/category/${category.id}`}
            />
          ))}
        </ShopsSearchResultsCategories>
        {totalCount > categories.length && (
          <LoadMoreButtonWrapper>
            <LoadMoreButton
              onPress={loadMoreCategories}
              accessoryLeft={
                isLoading ? (
                  <LoadMoreButtonLoaderContainer>
                    <Spinner size="small" />
                  </LoadMoreButtonLoaderContainer>
                ) : undefined
              }
              appearance="ghost"
              status="primary">
              {strings.shop_more_button}
            </LoadMoreButton>
          </LoadMoreButtonWrapper>
        )}
      </>
    );
  }, [flatCategories?.isLoading, flatCategories?.data]);

  const renderEmptyListMessage = () => (
    <EmptyList
      title={strings.shop_no_shops_title}
      description={strings.shop_recently_try_later_text}
    />
  );

  return (
    <>
      <ShopsSearchResultsContent>
        {shopsSearch?.data && (
          <ShopsSearchResultsShops
            numColumns={theme.isDesktop ? 4 : 1}
            data={shopsSearch?.data?.items || []}
            keyExtractor={item => String(item.id)}
            onEndReached={loadMoreShops}
            ListHeaderComponent={<CategoriesList />}
            ListFooterComponent={shopsSearch.isLoading ? <AppLoader /> : null}
            ListEmptyComponent={
              !flatCategories?.data?.items.length
                ? renderEmptyListMessage
                : null
            }
            renderItem={render => <ShopCard shop={render.item} />}
          />
        )}
      </ShopsSearchResultsContent>
    </>
  );
};

export {SearchResults};
