import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';

const Delete = (props: SvgProps) => (
  <Svg width={24} height={24} fill="#AEA69E" viewBox="0 0 24 24" {...props}>
    <Path d="M17 6h5v2h-2v14H4V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3Zm-8 5v6h2v-6H9Zm4 0v6h2v-6h-2ZM9 4v2h6V4H9Z" />
  </Svg>
);

export default Delete;
