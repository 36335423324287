import {all, call, put, takeLatest} from 'redux-saga/effects';
import {registerRequestAction} from './action';
import api from '../../../api';
import {saveTokens} from '../../../storage';
import {setJWTUserAction} from '../../user/store/action';
import {LoginResponse} from '../../../api/user/models/responses/login';
import {DefaultResponse} from '../../../api/models/response/default';
import {getUserFromJwt} from '../../../api/user/get-user-from-jwt';

const registerRequest = function* (
  action: ReturnType<typeof registerRequestAction.request>,
) {
  try {
    const response: DefaultResponse<LoginResponse> = yield call(
      [api, api.user.register],
      action.payload,
    );

    const user = getUserFromJwt(response.data.accessToken);

    yield call(
      saveTokens,
      response.data.accessToken,
      response.data.refreshToken,
    );

    yield put(setJWTUserAction(user));

    yield put(registerRequestAction.success(response.data));
  } catch (error) {
    yield put(registerRequestAction.failure(error as Error));
  }
};

export const registerSaga = function* () {
  yield all([takeLatest(registerRequestAction.request, registerRequest)]);
};
