import React, {useCallback, useEffect, useState} from 'react';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../uikit/Points/container';
import {useTheme} from 'styled-components/native';
import {useAppDispatch, usePaginationRequestSelector} from '../../store/store';
import {AppLoader} from '../../uikit/AppLoader';
import {AppError} from '../../uikit/AppError';
import {
  FriendList,
  FriendListAddRoute,
  FriendListAddRouteDesktop,
  FriendListAddRouteIcon,
  FriendListEmptyContainer,
  FriendListEmptyCreateNewLink,
  FriendListEmptyIcon,
  FriendListEmptyTitle,
  FriendListLoading,
  FriendListLoadingContainer,
  FriendListSearch,
} from './styles';
import {ListRenderItemInfo, TouchableOpacity} from 'react-native';
import {useStrings} from '../../utils/providers/strings';
import {useToast} from 'react-native-toast-notifications';
import AppRefreshControl from '../../uikit/AppRefreshControl';
import AppStatusBar from '../../uikit/AppStatusBar';
import {
  acceptFriendRequestAction,
  deleteFriendRequestAction,
  getFriendListRequestAction,
  rejectFriendRequestAction,
} from './store/action';
import {FRIEND_LIST_LIMIT} from './store/saga';
import {AppTransparentHeader} from '../../uikit/AppHeader';
import {ScreenLayoutWithHeader} from '../../uikit/ScreenLayout';
import {Friend, FriendStatus} from '../../api/friend/models/responses/friend';
import AppModal from '../../uikit/AppModal';
import {
  AppModalButton,
  AppModalCancel,
  AppModalTitle,
} from '../../uikit/AppModal/styles';
import FriendListItem, {FriendListItemBottomSheet} from './components/item';
import useDebounce from '../../utils/use-debounce';
import {FriendRequest} from '../../api/friend/models/requests/friend';
import {useFocusEffect} from '@react-navigation/native';
import Close from '../../assets/icons/Close';

export default function FriendListScreen() {
  const theme = useTheme();
  const toast = useToast();
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const friendListRequest = usePaginationRequestSelector(
    store => store.friendList.friendListRequest,
  );
  const isEmptyList = !friendListRequest.data?.items.length;

  const [moreModalFriend, setMoreModalFriend] = useState<Friend | null>(null);
  const [rejectModalFriend, setRejectModalFriend] = useState<Friend | null>(
    null,
  );
  const [deleteModalFriend, setDeleteModalFriend] = useState<Friend | null>(
    null,
  );

  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryThrottle = useDebounce(searchQuery, 500);

  useEffect(() => {
    return function () {
      dispatch(getFriendListRequestAction.clean());
      dispatch(acceptFriendRequestAction.clean());
      dispatch(rejectFriendRequestAction.clean());
      dispatch(deleteFriendRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (friendListRequest.error && friendListRequest.data?.items.length) {
      toast.show(friendListRequest.error.message);
    }
  }, [friendListRequest, toast]);

  const onFirstPage = useCallback(() => {
    const request: FriendRequest = {
      limit: FRIEND_LIST_LIMIT,
      offset: 0,
    };
    if (searchQueryThrottle) request.query = searchQueryThrottle;

    dispatch(getFriendListRequestAction.request(request));
  }, [dispatch, searchQueryThrottle]);

  useFocusEffect(onFirstPage);

  const onNextPage = () => {
    if (friendListRequest.data?.isLastPage || friendListRequest.isLoading)
      return;

    const request: FriendRequest = {
      limit: FRIEND_LIST_LIMIT,
      offset: friendListRequest.data?.items.length || 0,
    };
    if (searchQueryThrottle) request.query = searchQueryThrottle;

    dispatch(getFriendListRequestAction.request(request));
  };

  const renderFriendItem = ({item}: ListRenderItemInfo<Friend>) => {
    return (
      <FriendListItem
        item={item}
        openedFriend={moreModalFriend}
        onCloseMore={() => setMoreModalFriend(null)}
        onAccept={item =>
          dispatch(acceptFriendRequestAction.request([item.friend.id, void 0]))
        }
        onReject={item => setRejectModalFriend(item)}
        onMore={item => setMoreModalFriend(item)}
        onDelete={item => setDeleteModalFriend(item)}
      />
    );
  };

  const renderFriendListLoading = () => {
    return (
      <FriendListLoadingContainer>
        <FriendListLoading />
      </FriendListLoadingContainer>
    );
  };

  const renderFriendListEmpty = () => {
    return (
      <FriendListEmptyContainer>
        <FriendListEmptyIcon />
        <FriendListEmptyTitle>
          {strings.friend_list_empty_title}
        </FriendListEmptyTitle>
        <FriendListEmptyCreateNewLink to="/friends/new">
          {strings.friend_list_new_button.toUpperCase()}
        </FriendListEmptyCreateNewLink>
      </FriendListEmptyContainer>
    );
  };

  const renderClearIcon = () => (
    <TouchableOpacity onPress={() => setSearchQuery('')}>
      <Close />
    </TouchableOpacity>
  );

  return (
    <>
      <AppStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppTransparentHeader
              title={strings.friend_list_title}
              backNoHistoryRoute="/"
              rightButton={
                theme.isDesktop ? (
                  <FriendListAddRouteDesktop to="/friends/new">
                    {strings.friend_list_new_button.toUpperCase()}
                  </FriendListAddRouteDesktop>
                ) : (
                  <FriendListAddRoute to="/friends/new">
                    <FriendListAddRouteIcon />
                  </FriendListAddRoute>
                )
              }
            />
            <FriendListSearch
              value={searchQuery}
              onChangeText={setSearchQuery}
              placeholder={strings.friend_list_search_placeholder}
              accessoryLeft={searchQuery ? renderClearIcon : undefined}
            />
            {isEmptyList && friendListRequest.isLoading ? (
              <AppLoader />
            ) : isEmptyList && friendListRequest.error ? (
              <AppError
                error={friendListRequest.error.message}
                retry={onFirstPage}
              />
            ) : (
              friendListRequest.data && (
                <FriendList
                  refreshControl={
                    <AppRefreshControl
                      onRefresh={onFirstPage}
                      refreshing={friendListRequest.isFirstLoading}
                    />
                  }
                  data={friendListRequest.data.items}
                  renderItem={renderFriendItem}
                  keyExtractor={item => String(item.friend.id)}
                  onEndReached={onNextPage}
                  ListFooterComponent={
                    friendListRequest.isLoading ? renderFriendListLoading : null
                  }
                  ListEmptyComponent={renderFriendListEmpty}
                />
              )
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>

      <AppModal
        visible={rejectModalFriend !== null}
        onBackdropPress={() => setRejectModalFriend(null)}>
        {rejectModalFriend !== null && (
          <>
            <AppModalTitle>
              {strings.friend_list_reject_modal_title}
            </AppModalTitle>
            <AppModalButton
              onPress={() => {
                dispatch(
                  rejectFriendRequestAction.request([
                    rejectModalFriend?.friend?.id,
                    void 0,
                  ]),
                );
                setRejectModalFriend(null);
              }}>
              {strings.friend_list_reject_modal_positive_button.toUpperCase()}
            </AppModalButton>
            <AppModalCancel onPress={() => setRejectModalFriend(null)}>
              {strings.friend_list_reject_modal_negative_button.toUpperCase()}
            </AppModalCancel>
          </>
        )}
      </AppModal>

      <AppModal
        visible={deleteModalFriend !== null}
        onBackdropPress={() => setDeleteModalFriend(null)}>
        {deleteModalFriend !== null && (
          <>
            <AppModalTitle>
              {deleteModalFriend.status === FriendStatus.APPROVED
                ? strings.friend_list_delete_modal_title
                : strings.friend_list_delete_own_request_modal_title}
            </AppModalTitle>
            <AppModalButton
              onPress={() => {
                dispatch(
                  deleteFriendRequestAction.request([
                    deleteModalFriend?.friend?.id,
                    void 0,
                  ]),
                );
                setDeleteModalFriend(null);
              }}>
              {strings.friend_list_delete_modal_positive_button.toUpperCase()}
            </AppModalButton>
            <AppModalCancel onPress={() => setDeleteModalFriend(null)}>
              {strings.friend_list_delete_modal_negative_button.toUpperCase()}
            </AppModalCancel>
          </>
        )}
      </AppModal>
      <FriendListItemBottomSheet
        openedFriend={moreModalFriend}
        onCloseMore={() => setMoreModalFriend(null)}
        onDelete={item => setDeleteModalFriend(item)}
      />
    </>
  );
}
