import {all, call, put, takeLatest, throttle} from 'redux-saga/effects';
import api from '../../../api';
import {DefaultResponse} from '../../../api/models/response/default';
import {
  qrNormalPaymentRequestAction,
  qrSearchFriendListRequestAction,
  qrSplitPaymentRequestAction,
} from './action';
import {Transaction} from '../../../api/transaction/models/responses';
import {getUserProfileRequestAction} from '../../user/store/action';
import {ListResponse} from '../../../api/models/response/list';
import {
  Friend,
  FriendStatus,
} from '../../../api/friend/models/responses/friend';
import {SplitPaymentDetailResponse} from '../../../api/split-payments/models/responses/detail';
import {FriendRequest} from '../../../api/friend/models/requests/friend';

export const QR_FRIEND_LIST_LIMIT = 10;

const qrNormalPaymentRequest = function* (
  action: ReturnType<typeof qrNormalPaymentRequestAction.request>,
) {
  try {
    const response: DefaultResponse<Transaction> = yield call(
      [api, api.transaction.qrNormalPay],
      action.payload,
    );
    yield put(qrNormalPaymentRequestAction.success(response.data));
    yield put(getUserProfileRequestAction.request());
  } catch (error) {
    yield put(qrNormalPaymentRequestAction.failure(error as Error));
  }
};

const qrSplitPaymentRequest = function* (
  action: ReturnType<typeof qrSplitPaymentRequestAction.request>,
) {
  try {
    const response: DefaultResponse<SplitPaymentDetailResponse> = yield call(
      [api, api.transaction.qrSplitPay],
      action.payload,
    );
    yield put(qrSplitPaymentRequestAction.success(response.data));
  } catch (error) {
    yield put(qrSplitPaymentRequestAction.failure(error as Error));
  }
};

const qrSearchFriendListRequest = function* (
  action: ReturnType<typeof qrSearchFriendListRequestAction.request>,
) {
  try {
    const response: ListResponse<Friend> = yield call(
      [api, api.friend.getFriendList],
      {...action.payload, status: FriendStatus.APPROVED} as FriendRequest,
    );
    yield put(
      qrSearchFriendListRequestAction.success([action.payload, response]),
    );
  } catch (error) {
    yield put(qrSearchFriendListRequestAction.failure(error as Error));
  }
};

export const qrPaymentSaga = function* () {
  yield all([
    takeLatest(qrNormalPaymentRequestAction.request, qrNormalPaymentRequest),
    takeLatest(qrSplitPaymentRequestAction.request, qrSplitPaymentRequest),
    throttle(
      500,
      qrSearchFriendListRequestAction.request,
      qrSearchFriendListRequest,
    ),
  ]);
};
