import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {AppLayout} from './AppLayout';

const CommonScreenLayout = css`
  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 20px;
      padding-right: 0;
      padding-top: 48px;
    `}
`;

export const ScreenLayoutWithHeader = styled(AppLayout)`
  ${CommonScreenLayout};
`;

export const ScreenLayoutWithHeaderContent = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding: 0;
    `}
`;

export const ScreenLayout = styled(AppLayout)`
  padding: ${props => props.theme.dimens.normalSpacer};

  ${CommonScreenLayout};
`;
