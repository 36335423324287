import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from 'styled-components/native';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import {useStrings} from '../../../utils/providers/strings';
import {
  acceptTransferRequestAction,
  getDetailRequestChatRequestAction,
  getDetailRequestRequestAction,
  getTransferRequestAction,
  rejectTransferRequestAction,
  sendRequestMessageRequestAction,
  updateDetailRequestChatRequestAction,
} from '../store/action';
import {useFocusEffect, useRoute} from '@react-navigation/native';
import {Route} from '@react-navigation/routers';
import {
  DetailRequestChatButtonMobile,
  DetailRequestChatButtonMobileContainer,
  DetailRequestDesktopChatContainer,
  DetailRequestDesktopContainer,
  DetailRequestLayout,
  DetailRequestTitleBackButtonContainer,
  DetailRequestTitleBackButtonIcon,
  DetailRequestTitleContainer,
  DetailRequestTitleText,
} from './styles';
import DetailRequestInfo from './components/info';
import DetailRequestChat from './components/chat';
import useRouter from '../../../utils/use-router';
import {BackHandler} from 'react-native';
import {REQUEST_CHAT_LIMIT} from '../store/saga';
import AppStatusBar, {AppWhiteStatusBar} from '../../../uikit/AppStatusBar';
import useEndlessRepeater from 'scl/src/hooks/use-endless-repeater';

export default function DetailRequestScreen() {
  const theme = useTheme();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEndlessRepeater(10000, () => {
    dispatch(updateDetailRequestChatRequestAction.request(requestId));
  });

  const onInit = useCallback(() => {
    dispatch(getDetailRequestRequestAction.request(requestId));
    dispatch(
      getDetailRequestChatRequestAction.request({
        id: requestId,
        limit: REQUEST_CHAT_LIMIT,
        offset: 0,
      }),
    );

    return function () {
      dispatch(getDetailRequestRequestAction.clean());
      dispatch(getDetailRequestChatRequestAction.clean());
      dispatch(updateDetailRequestChatRequestAction.clean());
      dispatch(sendRequestMessageRequestAction.clean());
      dispatch(getTransferRequestAction.clean());
      dispatch(acceptTransferRequestAction.clean());
      dispatch(rejectTransferRequestAction.clean());
    };
  }, [dispatch, requestId]);

  useFocusEffect(onInit);

  const onBack = useCallback((): boolean => {
    if (!theme.isDesktop && isChatOpen) {
      setIsChatOpen(false);
      return true;
    }

    router.back('/requests');
    return true;
  }, [isChatOpen, router, theme.isDesktop]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      onBack,
    );

    return () => backHandler.remove();
  }, [onBack]);

  return (
    <>
      {isChatOpen ? <AppWhiteStatusBar /> : <AppStatusBar />}
      <DetailRequestLayout>
        {theme.isDesktop ? (
          <>
            <DetailRequestTitle key="title" onBack={onBack} />
            <DetailRequestDesktopContainer>
              <DetailRequestInfo key="info" requestId={requestId} />
              <DetailRequestDesktopChatContainer>
                <DetailRequestChat
                  key="chat"
                  requestId={requestId}
                  onBack={onBack}
                />
              </DetailRequestDesktopChatContainer>
            </DetailRequestDesktopContainer>
          </>
        ) : isChatOpen ? (
          <DetailRequestChat key="chat" requestId={requestId} onBack={onBack} />
        ) : (
          <>
            <DetailRequestTitle key="title" onBack={onBack} />
            <DetailRequestInfo key="info" requestId={requestId} />
            <DetailRequestChatButtonMobileContainer>
              <DetailRequestChatButtonMobile
                onPress={() => setIsChatOpen(true)}>
                {strings.detail_request_chat_button.toUpperCase()}
              </DetailRequestChatButtonMobile>
            </DetailRequestChatButtonMobileContainer>
          </>
        )}
      </DetailRequestLayout>
    </>
  );
}

const DetailRequestTitle = ({onBack}: {onBack: () => void}) => {
  const request = useRequestSelector(store => store.requests.request);

  return (
    <DetailRequestTitleContainer>
      <DetailRequestTitleBackButtonContainer onPress={onBack}>
        <DetailRequestTitleBackButtonIcon />
      </DetailRequestTitleBackButtonContainer>
      <DetailRequestTitleText>{request.data?.title}</DetailRequestTitleText>
    </DetailRequestTitleContainer>
  );
};
