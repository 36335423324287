import {AxiosInstance} from 'axios';
import {
  ListResponse,
  ListWithCategoryNameResponse,
} from '../models/response/list';
import {PaginationRequest} from '../../utils/redux/create-pagination-request-reducer';
import {
  ShopCategoriesResponse,
  ShopCategory,
  ShopItem,
} from './models/responses/shop-categories';
import {DefaultResponse} from '../models/response/default';
import {ShopDetailResponse} from './models/responses/shop-detail';
import {ShopProductsResponse} from './models/responses/shop-products';
import {ShopCommitmentsResponse} from './models/responses/shop-commitments';
import {ShopReviewsResponse} from './models/responses/shop-reviews';
import {
  ShopsSearchByCategoryRequestParams,
  ShopsSearchRequestParams,
} from './models/shop-search';

export interface ShopIdRequest {
  shopId: number;
}

export interface ReviewsRequest extends PaginationRequest {
  shopId?: number;
}

export interface CreateReviewRequest {
  shopId: number;
  rate: number;
  description: string;
}

export interface ShopsFlatCategoriesRequest extends PaginationRequest {
  name?: string;
}

export default class ShopsApi {
  constructor(private axiosInstance: AxiosInstance) {}

  getShopsCategoriesList(
    query: PaginationRequest,
  ): Promise<ListResponse<ShopCategoriesResponse>> {
    return this.axiosInstance.get(`shop/categories`, {
      params: query,
    });
  }

  getFlatCategoriesList(
    params: ShopsFlatCategoriesRequest,
  ): Promise<ShopCategory[]> {
    return this.axiosInstance.get('category', {params});
  }

  searchShops(params: ShopsSearchRequestParams) {
    return this.axiosInstance.get('shop/for-user', {params});
  }

  getShopDetailRequest(
    id: string,
  ): Promise<DefaultResponse<ShopDetailResponse>> {
    return this.axiosInstance.get(`shop/${id}`);
  }

  getShopsProductsList(
    query: ShopIdRequest,
  ): Promise<ListResponse<ShopProductsResponse>> {
    return this.axiosInstance.get(`product`, {params: query});
  }

  getShopsCommitmentsList(
    query: ShopIdRequest,
  ): Promise<ListResponse<ShopCommitmentsResponse>> {
    return this.axiosInstance.get(`commitment`, {params: query});
  }

  getAllReviewsList(
    query: PaginationRequest,
  ): Promise<ListResponse<ShopReviewsResponse>> {
    return this.axiosInstance.get(`review`, {
      params: query,
    });
  }

  getShopsReviewsList(
    query: ReviewsRequest,
  ): Promise<ListResponse<ShopReviewsResponse>> {
    return this.axiosInstance.get(`shop/${query.shopId}/reviews`, {
      params: query,
    });
  }

  getShopDetailReview(
    id: string,
  ): Promise<DefaultResponse<ShopReviewsResponse>> {
    return this.axiosInstance.get(`review/${id}`);
  }

  createShopReview(
    request: CreateReviewRequest,
  ): Promise<DefaultResponse<void>> {
    return this.axiosInstance.post(`review`, request);
  }

  getRecentlyViewedList(
    query: PaginationRequest,
  ): Promise<ListResponse<ShopItem>> {
    return this.axiosInstance.get(`history`, {
      params: query,
    });
  }

  deleteRecentlyViewed(): Promise<DefaultResponse<void>> {
    return this.axiosInstance.delete(`history`);
  }

  getFavoritesList(query: PaginationRequest): Promise<ListResponse<ShopItem>> {
    return this.axiosInstance.get(`favorites`, {
      params: query,
    });
  }

  createShopFavorite(request: ShopIdRequest): Promise<DefaultResponse<void>> {
    return this.axiosInstance.post(`favorites`, request);
  }

  deleteShopFavorite(request: ShopIdRequest): Promise<DefaultResponse<void>> {
    return this.axiosInstance.delete(`favorites`, {data: request});
  }

  getShopsByCategoryList(
    query: ReviewsRequest,
  ): Promise<ListWithCategoryNameResponse<ShopItem>> {
    return this.axiosInstance.get(`shop/byCategory/${query.shopId}`, {
      params: query,
    });
  }

  searchShopsByCategory(params: ShopsSearchByCategoryRequestParams) {
    return this.axiosInstance.get(`shop/byCategory/${params.categoryId}`, {
      params,
    });
  }
}
