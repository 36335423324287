import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const SP = (props: SvgProps) => (
  <Svg width={32} height={32} fill="none" {...props}>
    <Path
      fill="#DC9B2D"
      fillRule="evenodd"
      d="M25.333 8H6.667v21.333h18.666V8zM9.513 25.067c-.085-.222-.123-.405-.18-.8l1.362-.145c.037.434.094.627.264.848.274.367.766.569 1.38.569.946 0 1.513-.434 1.513-1.157 0-.395-.18-.723-.473-.886-.271-.148-.335-.163-1.489-.436l-.155-.037c-.71-.164-1.088-.318-1.428-.588-.482-.385-.728-.915-.728-1.561 0-1.34 1.059-2.207 2.685-2.207 1.078 0 1.89.337 2.354.973.236.318.35.607.463 1.118l-1.352.164c-.057-.328-.123-.492-.283-.684-.246-.299-.662-.463-1.182-.463-.823 0-1.38.424-1.38 1.05 0 .405.208.685.643.878.208.087.283.106 1.332.347.747.164 1.201.347 1.541.588.54.405.785.935.785 1.658 0 .684-.208 1.243-.596 1.638-.5.492-1.247.733-2.259.733-.775 0-1.531-.193-1.985-.502-.378-.26-.652-.626-.832-1.098zm9.144.274c0 .52.019.886.075 1.253h-1.399c.057-.386.076-.723.076-1.244v-5.385c0-.463-.019-.81-.076-1.244.407.02.7.03 1.2.03h1.977c.917 0 1.56.22 2.013.683.388.386.586.964.586 1.658 0 .733-.217 1.33-.633 1.774-.463.472-1.106.694-2.014.694h-1.805v1.78zm0-5.483v2.593h1.805c.87 0 1.343-.472 1.343-1.32 0-.386-.104-.704-.312-.925-.218-.241-.53-.348-1.03-.348h-1.806z"
      clipRule="evenodd"
    />
    <Path
      fill="#DC9B2D"
      d="M30.667 2.667H1.333V16h5.334v-2.667H4v-8h24v8h-2.667V16h5.334V2.667z"
    />
  </Svg>
);

export default SP;
