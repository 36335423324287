import React from 'react';
import {AppWhiteStatusBar} from '../../../../uikit/AppStatusBar';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../../uikit/ScreenLayout';
import AppHeader from '../../../../uikit/AppHeader';
import {useTheme} from 'styled-components/native';
import {useStrings} from '../../../../utils/providers/strings';
import {useRoute} from '@react-navigation/native';
import {
  ConciergePointsBuyErrorButton,
  ConciergePointsBuyErrorIcon,
  ConciergePointsBuySuccessButton,
  ConciergePointsBuySuccessContainer,
  ConciergePointsBuySuccessIcon,
  ConciergePointsBuySuccessMessage,
} from './styles';
import useRouter from '../../../../utils/use-router';
import {useAppDispatch} from '../../../../store/store';
import {getUserProfileRequestAction} from '../../../user/store/action';

interface ConciergePointsBuySuccessParams {
  result: string;
}

const PAYMENT_SUCCESS_KEY = '0';
const PAYMENT_ERROR_NO_MONEY_KEY = '51';

export default function ConciergePointsBuyByVirtualpaySuccessScreen() {
  const theme = useTheme();
  const route = useRoute();
  const router = useRouter();
  const strings = useStrings();
  const dispatch = useAppDispatch();
  const params = route.params as ConciergePointsBuySuccessParams | undefined;

  const isSuccess = params?.result === PAYMENT_SUCCESS_KEY;

  const onSuccessClick = () => {
    dispatch(getUserProfileRequestAction.request());
    router.push('/');
  };

  const onErrorClick = () => {
    router.back('/cp');
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={
                isSuccess
                  ? strings.concierge_points_buy_success_title
                  : strings.concierge_points_buy_error_title
              }
            />
            <ConciergePointsBuySuccessContainer>
              {isSuccess ? (
                <ConciergePointsBuySuccessIcon />
              ) : (
                <ConciergePointsBuyErrorIcon />
              )}
              <ConciergePointsBuySuccessMessage>
                {isSuccess
                  ? strings.concierge_points_buy_success_message
                  : params?.result === PAYMENT_ERROR_NO_MONEY_KEY
                  ? strings.concierge_points_buy_error_no_money_message
                  : strings.concierge_points_buy_error_message}
              </ConciergePointsBuySuccessMessage>
              {isSuccess ? (
                <ConciergePointsBuySuccessButton onPress={onSuccessClick}>
                  {strings.concierge_points_buy_success_button}
                </ConciergePointsBuySuccessButton>
              ) : (
                <ConciergePointsBuyErrorButton onPress={onErrorClick}>
                  {strings.concierge_points_buy_error_button}
                </ConciergePointsBuyErrorButton>
              )}
            </ConciergePointsBuySuccessContainer>
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
    </>
  );
}
