import React from 'react';
import {
  RequestCategoryTypeField,
  RequestCategoryTypeFieldType,
  RequestCategoryTypeScreen,
} from '../../../../api/request/models/responses/category-type';
import {ListRenderItemInfo} from 'react-native';
import {
  NewRequestQuestionBackButton,
  NewRequestQuestionButtons,
  NewRequestQuestionContainer,
  NewRequestQuestionHeader,
  NewRequestQuestionInputsContainer,
  NewRequestQuestionList,
  NewRequestQuestionNextButton,
} from './styles';
import {useStrings} from '../../../../utils/providers/strings';
import NewRequestQuestionFieldText from './components/fields/text';
import {useForm} from 'react-hook-form';
import NewRequestQuestionFieldDate from './components/fields/date';
import {
  defaultNewRequestQuestionValues,
  NewRequestQuestionForm,
  validateNewRequestQuestionFields,
} from './form';
import NewRequestQuestionFieldRadioGroup from './components/fields/radio-group';
import NewRequestQuestionFieldSingleSelection from './components/fields/single-selection';
import NewRequestQuestionFieldNumber from './components/fields/number';
import NewRequestQuestionFieldCheckBox from './components/fields/check-box';
import NewRequestQuestionFieldStepper from './components/fields/stepper';
import NewRequestQuestionFieldTime from './components/fields/time';
import NewRequestQuestionFieldAttachImage from './components/fields/attach-image';

export default function NewRequestQuestion({
  screen,
  screenPosition,
  onAnswer,
  onBack,
  questionsCount,
  answers,
}: {
  screen: RequestCategoryTypeScreen;
  screenPosition: number;
  onAnswer: (answer: NewRequestQuestionForm, screenPosition: number) => void;
  onBack: () => void;
  questionsCount: number;
  answers: NewRequestQuestionForm | undefined;
}) {
  const strings = useStrings();

  const {control, handleSubmit, formState} = useForm<NewRequestQuestionForm>({
    mode: 'onBlur',
    resolver: values => {
      const errors = validateNewRequestQuestionFields(strings, screen, values);

      return {
        values: Object.keys(errors).length ? {} : values,
        errors,
      };
    },
    defaultValues: answers ? answers : defaultNewRequestQuestionValues(screen),
  });

  function renderNewRequestQuestionItem({
    item,
  }: ListRenderItemInfo<RequestCategoryTypeField>) {
    switch (item.type) {
      case RequestCategoryTypeFieldType.INPUT:
        return (
          <NewRequestQuestionFieldText
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.DATE:
        return (
          <NewRequestQuestionFieldDate
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.TIME:
        return (
          <NewRequestQuestionFieldTime
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.RADIO_GROUP:
        return (
          <NewRequestQuestionFieldRadioGroup
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.SINGLE_SELECTION:
        return (
          <NewRequestQuestionFieldSingleSelection
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.INPUT_NUMBER:
        return (
          <NewRequestQuestionFieldNumber
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.CHECKBOX:
        return (
          <NewRequestQuestionFieldCheckBox
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.STEPPER:
        return (
          <NewRequestQuestionFieldStepper
            control={control}
            state={formState}
            field={item}
          />
        );
      case RequestCategoryTypeFieldType.ATTACHMENT_IMAGE:
        return (
          <NewRequestQuestionFieldAttachImage
            control={control}
            state={formState}
            field={item}
          />
        );
    }
    return null;
  }

  const onNext = handleSubmit(answers => onAnswer(answers, screenPosition));

  return (
    <NewRequestQuestionContainer>
      <NewRequestQuestionInputsContainer>
        <NewRequestQuestionHeader>
          {strings.new_request_question_title_fn(
            screenPosition + 1,
            questionsCount + 1,
          )}
        </NewRequestQuestionHeader>
        <NewRequestQuestionList
          data={screen.fields}
          renderItem={renderNewRequestQuestionItem}
          keyExtractor={item => item.id}
          removeClippedSubviews={false}
          contentContainerStyle={{paddingBottom: 16}}
        />
      </NewRequestQuestionInputsContainer>
      <NewRequestQuestionButtons>
        <NewRequestQuestionBackButton onPress={onBack}>
          {strings.new_request_question_back_button.toUpperCase()}
        </NewRequestQuestionBackButton>
        <NewRequestQuestionNextButton onPress={onNext}>
          {strings.new_request_question_next_button.toUpperCase()}
        </NewRequestQuestionNextButton>
      </NewRequestQuestionButtons>
    </NewRequestQuestionContainer>
  );
}
