import React from 'react';
import {ExternalLinkContainer, ExternalLinkText} from './styles';
import {Linking} from 'react-native';

export const ExternalLink = ({
  to,
  children,
  size,
}: {
  to: string;
  children: string;
  size?: number;
}) => {
  return (
    <ExternalLinkContainer onPress={() => Linking.openURL(to)}>
      <ExternalLinkText size={size}>{children}</ExternalLinkText>
    </ExternalLinkContainer>
  );
};
