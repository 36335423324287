import React, {PropsWithChildren} from 'react';
import {useLinkProps} from '@react-navigation/native';
import {To} from '@react-navigation/native/lib/typescript/src/useLinkTo';
import {TouchableOpacity, TouchableOpacityProps} from 'react-native';

export default function AppRouteView({
  to,
  children,
  ...rest
}: PropsWithChildren<TouchableOpacityProps> & {to: To}) {
  const props = useLinkProps({to});

  return (
    <TouchableOpacity {...rest} {...props}>
      {children}
    </TouchableOpacity>
  );
}
