import React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {ColorValue, StatusBar, StatusBarStyle, View} from 'react-native';
import {useTheme} from 'styled-components/native';

export default function AppStatusBar({
  backgroundColor,
  barStyle = 'dark-content',
}: {
  backgroundColor?: ColorValue;
  barStyle?: StatusBarStyle;
}) {
  const theme = useTheme();
  const insets = useSafeAreaInsets();

  if (!backgroundColor) {
    backgroundColor = theme.colors['color-basic-100'];
  }

  return (
    <View style={{height: insets.top, backgroundColor}}>
      <StatusBar
        backgroundColor={backgroundColor}
        barStyle={barStyle}
        animated
      />
    </View>
  );
}

export function AppWhiteStatusBar() {
  return <AppStatusBar backgroundColor={'#FFFFFF'} />;
}
