import React from 'react';

export default function DetailRequestSound({
  audioUrl,
  my,
}: {
  audioUrl: string;
  my?: boolean;
}) {
  return (
    <audio controls>
      <source src={audioUrl} />
    </audio>
  );
}
