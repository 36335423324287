import {useCallback, useEffect, useRef, useState} from 'react';

export default function useCountdown(tickMs: number, intervalMs: number) {
  const [countdown, setCountdown] = useState(intervalMs);

  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const stopCountdown = useCallback(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      stopCountdown();
    };
  }, [stopCountdown]);

  const resetCountdown = useCallback(() => {
    setCountdown(intervalMs);
  }, [intervalMs]);

  const startCountdown = useCallback(() => {
    stopCountdown();

    intervalRef.current = setInterval(() => {
      setCountdown(prevState => {
        const newCountdown = prevState - tickMs;
        const nextCountdown = newCountdown - tickMs;

        if (newCountdown >= 0) {
          return newCountdown;
        }
        if (nextCountdown <= 0) {
          stopCountdown();
        }

        return prevState;
      });
    }, tickMs);
  }, [stopCountdown, tickMs]);

  return {
    countdown,
    startCountdown,
    stopCountdown,
    resetCountdown,
  };
}
