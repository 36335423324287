import {
  CSSObject,
  DefaultTheme,
  FlattenInterpolation,
  Interpolation,
  InterpolationFunction,
  ThemeProps,
} from 'styled-components';

export function css(
  strings:
    | TemplateStringsArray
    | CSSObject
    | InterpolationFunction<ThemeProps<DefaultTheme>>,
  ...interpolations: Array<Interpolation<ThemeProps<DefaultTheme>>>
): FlattenInterpolation<ThemeProps<DefaultTheme>> {
  return interpolations.reduce(
    (agg, arg, index) => {
      return [...agg, arg, strings[index + 1]];
    },
    [strings[0]],
  );
}
