import {useEffect} from 'react';

export default function ConciergePointsPaymentByBtc({
  checkoutUrl,
}: {
  checkoutUrl: string;
}) {
  useEffect(() => {
    if (checkoutUrl) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.location.replace(checkoutUrl);
    }
  }, [checkoutUrl]);

  return null;
}
