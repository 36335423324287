import {
  createReducer,
  ActionReducerMapBuilder,
  PayloadAction,
  Draft,
} from '@reduxjs/toolkit';
import {
  data,
  initState,
  loading,
  error,
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {AnyAsyncAction} from 'scl/src/redux/create-async-action';

export default function createPaginationRequestReducer<
  RequestType extends PaginationRequest,
  DataType,
  NewDataType = DataType,
  ErrorType extends Error = Error,
>(
  action: AnyAsyncAction<RequestType, DataType, ErrorType>,
  initValue?: NewDataType,
  mergeData: (
    prevData: NewDataType | undefined,
    newData: DataType,
  ) => NewDataType = (_, newData) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newData,
) {
  return createReducer<PaginationRequestState<NewDataType, ErrorType> | null>(
    initValue !== undefined
      ? {...data(null, initValue), isFirstLoading: false}
      : null,
    builder => {
      addPaginationRequestCase(builder, action, mergeData);
    },
  );
}

export function addPaginationRequestCase<
  RequestType extends PaginationRequest,
  DataType,
  NewDataType = DataType,
  ErrorType extends Error = Error,
>(
  builder: ActionReducerMapBuilder<PaginationRequestState<
    NewDataType,
    ErrorType
  > | null>,
  action: AnyAsyncAction<RequestType, DataType, ErrorType>,
  mergeData: (
    prevData: NewDataType | undefined,
    newData: DataType,
  ) => NewDataType = (_, newData) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newData,
) {
  builder.addCase(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    action.request,
    (state, action: PayloadAction<RequestType>) => ({
      ...loading(state),
      isFirstLoading: action.payload.offset === 0,
    }),
  );
  builder.addCase(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    action.success,
    (
      state: PaginationRequestState<NewDataType> | null,
      action: PayloadAction<DataType>,
    ) => ({
      ...data(state, mergeData(state?.data, action.payload)),
      isFirstLoading: false,
    }),
  );
  builder.addCase(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    action.failure,
    (state, action: PayloadAction<Draft<ErrorType>>) => ({
      ...error(state, action.payload),
      isFirstLoading: false,
    }),
  );
  builder.addCase(action.clean, (state, action) => null);
}

export interface PaginationRequest {
  limit: number;
  offset: number;
}

export interface PaginationRequestState<
  DataType,
  ErrorType extends Error = Error,
> extends RequestState<DataType, ErrorType> {
  isFirstLoading: boolean;
}

export function initPaginationState<
  DataType = any,
  ErrorType extends Error = Error,
>(): PaginationRequestState<DataType, ErrorType> {
  return {
    ...initState<DataType, ErrorType>(),
    isFirstLoading: false,
  };
}
