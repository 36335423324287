import React from 'react';

import type {ShopItem} from '../../../../api/shops/models/responses/shop-categories';
import type {ShopSearchItem} from '../../../../api/shops/models/shop-search';

import {
  ShopCardBadgesContainer,
  ShopCardContainer,
  ShopCardContent,
  ShopCardImage,
  ShopCardLocationBadge,
  ShopCardTagBadge,
  ShopCardTitle,
  ShopCardTitleContainer,
} from './styles';

type Props = {
  shop: ShopItem | ShopSearchItem;
};

const ShopCard = ({shop}: Props) => {
  return (
    <ShopCardContainer to={`/shops/${shop.id}`}>
      <ShopCardContent>
        <ShopCardBadgesContainer>
          {shop.prefecture && (
            <ShopCardLocationBadge numberOfLines={1}>
              {shop.prefecture}
            </ShopCardLocationBadge>
          )}
          {shop.tag && (
            <ShopCardTagBadge numberOfLines={1}>{shop.tag}</ShopCardTagBadge>
          )}
        </ShopCardBadgesContainer>
        <ShopCardTitleContainer>
          <ShopCardTitle>{shop.name}</ShopCardTitle>
        </ShopCardTitleContainer>
        <ShopCardImage source={{uri: shop.images[0].imageUrl}} />
      </ShopCardContent>
    </ShopCardContainer>
  );
};

export default ShopCard;
