import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {AppButton, GhostButton} from '../../../../../uikit/AppButtons';
import {BottomSheetView} from '@gorhom/bottom-sheet';
import {Spinner, Text} from '@ui-kitten/components';
import Done from '../../../../../assets/icons/Done';
import Close from '../../../../../assets/icons/Close';
import More from '../../../../../assets/icons/More';

export const FriendListItemContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const FriendListItemInfo = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FriendListItemInfoName = styled(Text)`
  font-weight: 300;
  font-size: 16px;
`;
export const FriendListItemInfoId = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-weight: 300;
  font-size: 14px;
  color: ${props => props.theme.colors['color-basic-800']};
`;

export const FriendListItemInfoStatus = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-size: 12px;
  font-weight: bold;
`;

export const FriendListItemApproveButton = styled.TouchableOpacity``;
export const FriendListItemApproveButtonIcon = styled(Done)``;

export const FriendListItemDeclineButton = styled.TouchableOpacity`
  margin-left: ${props => props.theme.dimens.smallestSpacer};
`;
export const FriendListItemDeclineButtonIcon = styled(Close)``;

export const FriendListItemMoreButton = styled.TouchableOpacity``;
export const FriendListItemMoreButtonIcon = styled(More)``;

export const FriendListItemProgress = styled(Spinner)``;

export const FriendListItemDeclineProgress = styled(Spinner)`
  margin-left: ${props => props.theme.dimens.smallestSpacer};
`;

export const FriendListItemBottomSheetView = styled(BottomSheetView)`
  display: flex;
  flex-direction: column;
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};
`;

export const FriendListItemBottomSheetButton = styled(AppButton)``;

export const FriendListItemBottomSheetCancelButton = styled(GhostButton)``;
