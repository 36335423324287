import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import Attach from '../../../../../../../../assets/icons/Attach';
import {GhostButton} from '../../../../../../../../uikit/AppButtons';

export const DetailRequestUploadButton = styled.TouchableOpacity`
  margin-right: ${props => props.theme.dimens.smallestSpacer};
  margin-top: 3px;
  padding: ${props => props.theme.dimens.smallestSpacer};
`;

export const DetailRequestChatAttachIcon = styled(Attach).attrs(props => ({
  stroke: props.theme.colors['color-basic-500'],
}))``;

export const AttachModalCancel = styled(GhostButton)`
  flex: 1;
`;

export const AttachModalButton = styled.TouchableOpacity`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.dimens.smallSpacer};
`;

export const AttachModalButtonText = styled.Text`
  color: black;
  font-size: 18px;
  padding: ${props => props.theme.dimens.smallSpacer};
`;

export const AttachModalButtonsContainer = styled.View`
  display: flex;
  flex-direction: column;
`;
