import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';
import {AppButton} from '../../../../../uikit/AppButtons';
import Success from '../../../../../assets/images/Success.png';
import Error from '../../../../../assets/images/Error.png';

export const ConciergePointsBuySuccessContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.dimens.smallSpacer};
`;

export const ConciergePointsBuySuccessIcon = styled.Image.attrs({
  source: Success,
})`
  width: 192px;
  height: 192px;
`;

export const ConciergePointsBuyErrorIcon = styled.Image.attrs({
  source: Error,
})`
  width: 192px;
  height: 192px;
`;

export const ConciergePointsBuySuccessMessage = styled(Text).attrs({
  category: 's1',
})`
  text-align: center;
`;

export const ConciergePointsBuySuccessButton = styled(AppButton)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  align-self: stretch;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
    `}
`;

export const ConciergePointsBuyErrorButton = styled(AppButton)`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  align-self: stretch;

  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
    `}
`;
