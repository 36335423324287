import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const StarWithSmile = (props: SvgProps) => (
  <Svg width={32} height={32} viewBox="0 0 32 32" fill="#DC9B2D" {...props}>
    <Path d="M16.667 1.333l5.43 7.975 9.236 2.72-5.879 7.65.276 9.655-9.063-3.248-9.064 3.248.276-9.656L2 12.028l9.236-2.72 5.43-7.975zm-2.57 14.833h-2.57a5.17 5.17 0 001.46 3.603 5.13 5.13 0 007.176.18 5.168 5.168 0 001.638-3.525l.006-.258h-2.57a2.585 2.585 0 01-.715 1.797 2.566 2.566 0 01-3.572.135 2.584 2.584 0 01-.847-1.738l-.006-.194z" />
  </Svg>
);

export default StarWithSmile;
