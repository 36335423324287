import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Spinner, Text} from '@ui-kitten/components';
import {AppInput} from '../../../uikit/AppInput';
import Save from '../../../assets/icons/Save';
import Edit from '../../../assets/icons/Edit';
import {
  AppAutocomplete,
  AppAutocompleteItem,
} from '../../../uikit/AppAutocomplete';
import {AppButton, GhostButton, OutlineButton} from '../../../uikit/AppButtons';
import {ImageStyle, View} from 'react-native';
import React from 'react';

export const ProfileContainer = styled.ScrollView`
  margin-top: ${props => props.theme.dimens.smallSpacer};
  padding-bottom: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: ${props.theme.dimens.bigSpacer};
      margin-bottom: ${props.theme.dimens.bigSpacer};
      background-color: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;

export const ProfileInputGroupTitle = styled(Text).attrs({
  category: 'h6',
})`
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const ProfileInputTitle = styled(Text)`
  margin-bottom: ${props => props.theme.dimens.normalSpacer};
  margin-top: ${props => props.theme.dimens.normalSpacer};
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const ProfileInput = styled(AppInput)`
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.disabled &&
    css`
      background-color: transparent;
      border-color: ${props.theme.colors['color-basic-200']};
    `}
`;

export const ProfileAutocomplete = styled(AppAutocomplete)`
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.disabled &&
    css`
      background-color: transparent;
      border-color: ${props.theme.colors['color-basic-200']};
    `}
`;

export const ProfileAutocompleteItem = styled(AppAutocompleteItem)``;

export const ProfileInputErrorText = styled(Text).attrs({
  status: 'danger',
})`
  margin-left: ${props => props.theme.dimens.normalSpacer};
  margin-right: ${props => props.theme.dimens.normalSpacer};
`;

export const ProfileSaveSpinnerContainer = styled.View`
  padding: ${props => props.theme.dimens.smallSpacer};
`;

export const ProfileSaveSpinner = styled(Spinner)``;

export const ProfileDeleteButton = styled(OutlineButton).attrs({
  status: 'danger',
})`
  background-color: transparent;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      width: fit-content;
    `}
`;

export const DeleteModalTitle = styled(Text)`
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
`;

export const DeleteModalContent = styled(Text)`
  flex: 1;
  line-height: 22px;
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-bottom: ${props => props.theme.dimens.normalSpacer};
`;

export const DeleteModalButtonsContainer = styled(View)`
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.isDesktop &&
    css`
      flex-direction: row;
    `}
`;

export const DeleteModalDelete = styled(OutlineButton).attrs({
  status: 'danger',
})`
  background-color: transparent;
  flex: 1;
  margin-bottom: 8px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-right: 16px;
      margin-bottom: 0;
    `}
`;

export const DeleteModalCancel = styled(GhostButton)`
  flex: 1;
`;

export const EditModalTitle = styled(Text)`
  flex: 1;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  padding-bottom: ${props => props.theme.dimens.normalSpacer};
  text-align: center;
`;

export const ProfileEditButton = styled(AppButton).attrs(props => ({
  appearance: props.theme.isDesktop ? 'outline' : 'ghost',
  status: 'danger',
  accessoryLeft: iconProps => (
    <Edit
      {...iconProps}
      style={[iconProps?.style, {marginRight: -6, marginLeft: 0}]}
      fill={props.theme.colors['color-danger-500']}
      width={(iconProps?.style as ImageStyle)?.width}
      height={(iconProps?.style as ImageStyle)?.height}
    />
  ),
}))`
  background-color: transparent;
`;

export const ProfileSaveButton = styled(AppButton).attrs(props => ({
  appearance: props.theme.isDesktop ? 'outline' : 'ghost',
  status: 'danger',
  accessoryLeft: iconProps => (
    <Save
      {...iconProps}
      style={[iconProps?.style, {marginRight: -6, marginLeft: 0}]}
      fill={props.theme.colors['color-danger-500']}
      width={(iconProps?.style as ImageStyle)?.width}
      height={(iconProps?.style as ImageStyle)?.height}
    />
  ),
}))`
  background-color: transparent;
`;
