import {useEffect} from 'react';

export default function useEndlessRepeater(
  intervalMs: number,
  callback: () => void,
  onCleanCallback?: () => void,
) {
  useEffect(
    () => {
      const intervalId = setInterval(() => {
        callback();
      }, intervalMs);

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        onCleanCallback?.();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intervalMs],
  );
}
