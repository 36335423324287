import React from 'react';
import {
  ShopAddReviewButton,
  ShopAddReviewContainer,
  ShopAddReviewDescription,
  ShopAddReviewEmptyIcon,
  ShopAddReviewTitle,
  ShopReviewContainer,
  ShopReviewHeadContainer,
  ShopReviewHeadLink,
  ShopReviewHeadTitle,
  ShopReviewItemAuthor,
  ShopReviewItemContainer,
  ShopReviewItemDescription,
  ShopReviewSection,
  ShopStarContainer,
  ShopStarIcon,
  ShopStarOutlineIcon,
} from './styles';
import {useStrings} from '../../../../../utils/providers/strings';
import {Route, useRoute} from '@react-navigation/native';
import {ShopReviewsList} from '../../../store/reducer';

interface IProps {
  data?: ShopReviewsList;
}

export default function ShopReview({data}: IProps) {
  const strings = useStrings();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  return (
    <ShopReviewSection>
      <ShopReviewHeadContainer>
        <ShopReviewHeadTitle>
          {strings.shop_detail_reviews_title}
        </ShopReviewHeadTitle>
        {!!data?.items.length && (
          <ShopReviewHeadLink to={`/shops/${requestId}/reviews`}>
            {strings.shop_see_all_button}
          </ShopReviewHeadLink>
        )}
      </ShopReviewHeadContainer>
      {data?.items.length ? (
        <ShopReviewContainer horizontal>
          {data?.items.map((review, index) => (
            <ShopReviewItemContainer
              key={review.id}
              to={`/shops/reviews/${review.id}`}
              lastItem={data.items.length === index + 1}>
              <ShopStarContainer>
                {[...Array(5)].map((_, idx) => {
                  return idx + 1 <= review.rate ? (
                    <ShopStarIcon key={idx} />
                  ) : (
                    <ShopStarOutlineIcon key={idx} />
                  );
                })}
              </ShopStarContainer>
              <ShopReviewItemAuthor>
                {`${review?.author?.firstName} ${review?.author?.lastName}`}
              </ShopReviewItemAuthor>
              <ShopReviewItemDescription>
                {review.description}
              </ShopReviewItemDescription>
            </ShopReviewItemContainer>
          ))}
        </ShopReviewContainer>
      ) : (
        <ShopAddReviewContainer>
          <ShopAddReviewEmptyIcon width={64} height={64} />
          <ShopAddReviewTitle>
            {strings.shop_no_reviews_title}
          </ShopAddReviewTitle>
          <ShopAddReviewDescription>
            {strings.shop_detail_want_to_add_new_review_message}
          </ShopAddReviewDescription>
          <ShopAddReviewButton to={`/shops/${requestId}/reviews/add`}>
            {strings.shop_detail_add_new_review_title.toUpperCase()}
          </ShopAddReviewButton>
        </ShopAddReviewContainer>
      )}
    </ShopReviewSection>
  );
}
