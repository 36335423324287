import styled from 'styled-components/native';
import {ShopSearchItem} from '../../../../../api/shops/models/shop-search';
import {FlatList} from 'react-native';
import {AppButton} from '../../../../../uikit/AppButtons';

export const ShopsSearchResultsContent = styled.View`
  flex: 1;
  flex-direction: column;
`;

export const ShopsSearchResultsCategories = styled.View`
  width: 100%;
  padding-bottom: 16px;
  ${props => (props.theme.isDesktop ? 'padding-right: 10px;' : '')}
`;

export const ShopsSearchResultsShops = styled(FlatList<ShopSearchItem>)`
  width: ${props => (props.theme.isDesktop ? 'calc(100% + 16px)' : '100%')};
  padding: 8px 16px;
`;

export const LoadMoreButtonWrapper = styled.View`
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`;

export const LoadMoreButton = styled(AppButton)`
  margin-top: -8px;
  height: 47px;
  margin-bottom: 16px;
  margin-right: 10px;
`;

export const LoadMoreButtonLoaderContainer = styled.View`
  justify-content: center;
  align-items: center;
`;
