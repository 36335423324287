import styled from 'styled-components/native';
import {AppButton} from '../../../uikit/AppButtons';
import AppHeader from '../../../uikit/AppHeader';

export const AppTransparentHeader = styled(AppHeader)`
  background-color: transparent;
  margin-bottom: 20px;
`;

export const FAQContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: ${props => (props.theme.isDesktop ? 'row' : 'column')};
  background-color: ${props => props.theme.colors['color-basic-100']};
`;

export const FAQContentWrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  ${props => (props.theme.isDesktop ? 'padding: 48px 24px 0 48px;' : '')};
`;

export const FAQContent = styled.ScrollView`
  padding-bottom: 60px;
`;

export const FAQAccordionGroup = styled.View`
  margin-bottom: 24px;
`;

export const FAQSubtitle = styled.Text`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors['color-basic-900']};

  margin: 0 16px 16px;
`;

export const FAQContactUsButton = styled(AppButton)`
  color: ${props => props.theme.colors['color-basic-900']};
  text-transform: uppercase;
  text-align: center;

  display: flex;
  max-width: 288px;
  width: 100%;
  margin: 16px auto 0;
`;
