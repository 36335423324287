import {RequestStatus} from '../responses';
import type {PaginationRequest} from '../../../../utils/redux/create-pagination-request-reducer';

export interface RequestsQuery extends PaginationRequest {
  status?: RequestStatus;
}

export type RequestsStatusTypes = RequestStatus | typeof RequestsStatusTypeAll;
export const RequestsStatusTypeAll = 'ALL';

export interface CalendarRequestsQuery {
  dateStart: string;
  dateEnd: string;
}
