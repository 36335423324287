import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';

export const ShopMainInfoContainer = styled.View`
  margin-top: 24px;

  ${props =>
    props.theme.isDesktop &&
    css`
      margin-top: 28px;
    `}
`;

export const ShopTextContainer = styled.View`
  display: flex;
  background: white;
  padding: 8px 16px;
  border-color: #e8e8e8;
  border-bottom-width: 1px;
`;

export const ShopTextHeading = styled.Text`
  color: ${props => props.theme.colors['color-basic-900']};
  font-size: 16px;
  margin-top: 2px;
`;

export const ShopTextDescription = styled.Text`
  font-size: 14px;
  line-height: 20px;
  color: #8f8880;
`;

export const ShopMoreButton = styled.Text`
  color: ${props => props.theme.colors['color-primary-500']};
  font-weight: 600;
`;
