import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {
  qrNormalPaymentRequestAction,
  qrSearchFriendListRequestAction,
  qrSplitPaymentRequestAction,
} from './action';
import {Transaction} from '../../../api/transaction/models/responses';
import {Friend} from '../../../api/friend/models/responses/friend';
import createPaginationRequestReducer, {
  PaginationRequestState,
} from '../../../utils/redux/create-pagination-request-reducer';
import {FriendRequest} from '../../../api/friend/models/requests/friend';
import {ListResponse} from '../../../api/models/response/list';
import {SplitPaymentResponse} from '../../../api/split-payments/models/responses/split-payment';

export interface QrFriendList {
  items: Friend[];
  totalCount: number;
  isLastPage: boolean;
}

export const qrPaymentReducer = combineReducers<{
  qrNormalPaymentRequest: RequestState<Transaction> | null;
  qrSplitPaymentRequest: RequestState<SplitPaymentResponse> | null;
  qrSearchFriendListRequest: PaginationRequestState<QrFriendList> | null;
}>({
  qrNormalPaymentRequest: createRequestReducer(qrNormalPaymentRequestAction),
  qrSplitPaymentRequest: createRequestReducer(qrSplitPaymentRequestAction),
  qrSearchFriendListRequest: createPaginationRequestReducer<
    FriendRequest,
    [FriendRequest, ListResponse<Friend>],
    QrFriendList
  >(qrSearchFriendListRequestAction, undefined, (prevData, newData) => {
    const [query, response] = newData;
    const items = [
      ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
      ...response.data,
    ];
    return {
      items,
      totalCount: response.totalCount,
      isLastPage: items.length >= response.totalCount,
    };
  }),
});
