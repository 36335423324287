import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const StarOutline = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox="0 0 40 40" fill="#AEA69E" {...props}>
    <Path d="M20 30.433l-11.755 6.58L10.87 23.8.98 14.653l13.378-1.587L20 .833l5.643 12.233 13.379 1.587L29.13 23.8l2.625 13.213L20 30.433zm0-3.82l7.078 3.962-1.581-7.955 5.955-5.509-8.055-.955L20 8.791l-3.396 7.367-8.055.953 5.955 5.509-1.582 7.955L20 26.613z" />
  </Svg>
);

export default StarOutline;
