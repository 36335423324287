import {combineReducers} from 'redux';
import createRequestReducer, {
  RequestState,
} from 'scl/src/redux/create-request-reducer';
import {restoreRequestAction} from './action';
import {RestoreResponse} from '../../../api/user/models/responses/restore';

export const restoreReducer = combineReducers<{
  restoreRequest: RequestState<RestoreResponse> | null;
}>({
  restoreRequest: createRequestReducer(restoreRequestAction),
});
