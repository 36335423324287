import styled from 'styled-components/native';
import Points from './index';

export const PointsContainerContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors['color-basic-100']};
`;

export const PointsContainerContent = styled.View`
  flex: 1;
`;

export const PointsContainerPoints = styled(Points)`
  margin-top: 48px;
`;
