import {Strings} from '../../../../resources/strings';
import {EvaStatus} from '@ui-kitten/components/devsupport';
import {SplitPaymentResponse} from './split-payment';

export interface SplitPaymentDetailResponse extends SplitPaymentResponse {
  friends: SplitPaymentDetailFriend[];
}

export interface SplitPaymentDetailFriend {
  id: number;
  clientId: string;
  firstName: string;
  lastName: string;
  status: FriendStatus;
  amount: number;
}

export enum FriendStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  PAYED = 'PAYED',
}

export function getSplitPaymentFriendStatusText(
  strings: Strings,
  status: FriendStatus,
): string {
  switch (status) {
    case FriendStatus.PENDING:
      return strings.split_payment_friend_status_pending;
    case FriendStatus.APPROVED:
      return strings.split_payment_friend_status_approved;
    case FriendStatus.CANCELLED:
      return strings.split_payment_friend_status_cancelled;
    case FriendStatus.REJECTED:
      return strings.split_payment_friend_status_reject;
    case FriendStatus.PAYED:
      return strings.split_payment_friend_status_payed;
  }
}

export function getSplitPaymentFriendStatusType(
  status: FriendStatus,
): EvaStatus {
  switch (status) {
    case FriendStatus.PENDING:
      return 'warning';
    case FriendStatus.APPROVED:
      return 'success';
    case FriendStatus.CANCELLED:
      return 'danger';
    case FriendStatus.REJECTED:
      return 'danger';
    case FriendStatus.PAYED:
      return 'info';
  }
}
