import React from 'react';

import type {ShopReviewsResponse} from '../../../../../api/shops/models/responses/shop-reviews';

import {
  ShopReviewItemAuthor,
  ShopReviewItemContainer,
  ShopReviewItemDescription,
  ShopStarContainer,
  ShopStarIcon,
  ShopStarOutlineIcon,
} from './styles';

type Props = {
  review: ShopReviewsResponse;
};

const ReviewCard = ({review}: Props) => {
  return (
    <ShopReviewItemContainer to={`/shops/reviews/${review.id}`}>
      <ShopStarContainer>
        {[...Array(5)].map((_, index) => {
          return index + 1 <= review.rate ? (
            <ShopStarIcon key={index} />
          ) : (
            <ShopStarOutlineIcon key={index} />
          );
        })}
      </ShopStarContainer>
      <ShopReviewItemAuthor>
        {`${review.author.firstName} ${review.author.lastName}`}
      </ShopReviewItemAuthor>
      <ShopReviewItemDescription>
        {review.description}
      </ShopReviewItemDescription>
    </ShopReviewItemContainer>
  );
};

export {ReviewCard};
