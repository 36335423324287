import React, {useEffect, useState} from 'react';
import {ViewProps} from 'react-native';
import {useTheme} from 'styled-components/native';

import {
  CollapseContainer,
  CollapseHeader,
  CollapseTitle,
  CollapseBody,
  CollapseIcon,
  CollapseBodyText,
} from './styles';

type Props = {
  title: string;
  initialIsOpen?: boolean;
} & ViewProps;

const Collapse = React.memo(
  ({title, initialIsOpen = false, children, ...restProps}: Props) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(initialIsOpen);

    /** For outer component control */
    useEffect(() => {
      if (isOpen !== initialIsOpen) setIsOpen(initialIsOpen);
    }, [initialIsOpen]);

    return (
      <CollapseContainer {...restProps}>
        <CollapseHeader open={isOpen} onPress={() => setIsOpen(prev => !prev)}>
          <CollapseTitle>{title}</CollapseTitle>
          <CollapseIcon
            open={isOpen}
            color={isOpen ? theme.colors['color-primary-500'] : ''}
          />
        </CollapseHeader>

        {isOpen ? (
          <CollapseBody>
            {typeof children === 'string' ? (
              <CollapseBodyText>{children}</CollapseBodyText>
            ) : (
              children
            )}
          </CollapseBody>
        ) : null}
      </CollapseContainer>
    );
  },
);

export default Collapse;
