import styled from 'styled-components/native';
import {AppError} from '../../../uikit/AppError';
import {AppLoader} from '../../../uikit/AppLoader';
import UserMobileInfo from '../../../uikit/UserMobileInfo';

export * from './index.common';

export const MyPageUserMobileInfo = styled(UserMobileInfo)`
  padding: 0 16px;
`;

export const MyPageUserInfoLoader = styled(AppLoader)`
  align-self: center;
  flex: none;
  height: 43px;
  display: flex;
  margin-bottom: 16px;
`;

export const MyPageUserInfoError = styled(AppError)`
  align-self: center;
  flex: none;
  padding: ${props => props.theme.dimens.normalSpacer};
`;
