import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {FlatList} from 'react-native';
import {Spinner, Text} from '@ui-kitten/components';
import EmptySplitPaymentList from '../../../assets/icons/EmptyRequests';
import {AppLink} from '../../../uikit/AppButtons';
import {SplitPaymentResponse} from '../../../api/split-payments/models/responses/split-payment';
import AppRouteView from '../../../uikit/AppRouteView';
import ArrowRight from '../../../assets/icons/ArrowRight';

export const SplitPaymentList = styled(FlatList<SplitPaymentResponse>)`
  flex: 1;
  padding-top: ${props => props.theme.dimens.smallSpacer};
  padding-left: ${props => props.theme.dimens.smallSpacer};
  padding-right: ${props => props.theme.dimens.smallSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: ${props.theme.dimens.smallSpacer};
    `}
`;

export const SplitPaymentListLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => props.theme.dimens.smallSpacer};
`;

export const SplitPaymentListLoading = styled(Spinner)``;

export const SplitPaymentListEmptyContainer = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.dimens.bigSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      justify-content: flex-start;
    `}
`;

export const SplitPaymentListEmptyIcon = styled(EmptySplitPaymentList)``;

export const SplitPaymentListEmptyInfo = styled(Text)``;

export const SplitPaymentListEmptyCreateNewLink = styled(AppLink)`
  align-self: stretch;
  margin-top: ${props => props.theme.dimens.normalSpacer};
  ${props =>
    props.theme.isDesktop &&
    css`
      align-self: center;
      min-width: 240px;
    `}
`;

export const SplitPaymentListItemContainer = styled(AppRouteView)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.dimens.normalSpacer};
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  margin-bottom: ${props => props.theme.dimens.smallestSpacer};
`;

export const SplitPaymentListItemInfoContainer = styled.View`
  display: flex;
  flex-direction: column;
`;

export const SplitPaymentListItemIcon = styled(ArrowRight).attrs(props => ({
  fill: props.theme.colors['color-basic-500'],
}))``;

export const SplitPaymentListItemInfoTitle = styled(Text)``;

export const SplitPaymentListItemInfoStatus = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  font-size: 12px;
  font-weight: bold;
`;

export const SplitPaymentListItemInfoAuthor = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  color: ${props => props.theme.colors['color-basic-800']};
  font-size: 14px;
`;

export const SplitPaymentListItemInfoDate = styled(Text)`
  margin-top: ${props => props.theme.dimens.smallestSpacer};
  color: ${props => props.theme.colors['color-basic-800']};
  font-size: 14px;
`;
