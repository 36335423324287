import {combineReducers} from 'redux';
import {
  acceptFriendRequestAction,
  addFriendRequestAction,
  deleteFriendRequestAction,
  getFriendListRequestAction,
  rejectFriendRequestAction,
  searchFriendRequestAction,
} from './action';
import {
  addPaginationRequestCase,
  PaginationRequestState,
} from '../../../utils/redux/create-pagination-request-reducer';
import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {ListResponse} from '../../../api/models/response/list';
import {
  Friend,
  SearchFriend,
} from '../../../api/friend/models/responses/friend';
import {FriendRequest} from '../../../api/friend/models/requests/friend';
import createMultipleRequestReducer, {
  MultipleRequestState,
} from 'scl/src/redux/create-multiple-request-reducer';
import createRequestReducer, {
  addRequestCase,
  RequestState,
} from 'scl/src/redux/create-request-reducer';

export interface FriendList {
  items: Friend[];
  totalCount: number;
  isLastPage: boolean;
}

export const friendListReducer = combineReducers<{
  friendListRequest: PaginationRequestState<FriendList> | null;
  addFriendRequest: RequestState<Friend> | null;
  acceptFriendRequest: MultipleRequestState<Friend>;
  rejectFriendRequest: MultipleRequestState<object>;
  deleteFriendRequest: MultipleRequestState<object>;
  searchFriendRequest: RequestState<SearchFriend | null> | null;
}>({
  friendListRequest: createReducer<PaginationRequestState<FriendList> | null>(
    null,
    builder => {
      addPaginationRequestCase<
        FriendRequest,
        [FriendRequest, ListResponse<Friend>],
        FriendList
      >(builder, getFriendListRequestAction, (prevData, newData) => {
        const [query, response] = newData;
        const items = [
          ...(prevData?.items && query.offset !== 0 ? prevData?.items : []),
          ...response.data,
        ];
        return {
          items,
          totalCount: response.totalCount,
          isLastPage: items.length >= response.totalCount,
        };
      });

      const addNewFriend = (
        state: PaginationRequestState<FriendList> | null,
        action: PayloadAction<Friend>,
      ) => {
        if (!state || !state.data) return state;

        const newItems = [action.payload, ...state.data.items];

        return {
          ...state,
          data: {
            ...state.data,
            items: newItems,
            totalCount: state.data.totalCount + 1,
          },
        };
      };

      builder.addCase(addFriendRequestAction.success, addNewFriend);

      const updateFriend = (
        state: PaginationRequestState<FriendList> | null,
        {payload: [id, friend]}: PayloadAction<[number, Friend]>,
      ) => {
        if (!state || !state.data) return state;

        const newItems = [...state.data.items];

        const friendPosition = newItems.findIndex(
          findFriend => findFriend.friend.id === id,
        );

        if (friendPosition < 0) {
          return state;
        }

        newItems[friendPosition] = friend;

        return {
          ...state,
          data: {
            ...state.data,
            items: newItems,
          },
        };
      };

      builder.addCase(acceptFriendRequestAction.success, updateFriend);

      const deleteFriend = (
        state: PaginationRequestState<FriendList> | null,
        {payload: [id]}: PayloadAction<[number, object]>,
      ) => {
        if (!state || !state.data) return state;

        const newItems = [...state.data.items];

        const friendPosition = newItems.findIndex(
          findFriend => findFriend.friend.id === id,
        );

        if (friendPosition < 0) {
          return state;
        }

        newItems.splice(friendPosition, 1);

        return {
          ...state,
          data: {
            ...state.data,
            items: newItems,
            totalCount: state.data.totalCount - 1,
          },
        };
      };

      builder.addCase(rejectFriendRequestAction.success, deleteFriend);
      builder.addCase(deleteFriendRequestAction.success, deleteFriend);
    },
  ),
  addFriendRequest: createRequestReducer(addFriendRequestAction),
  acceptFriendRequest: createMultipleRequestReducer(acceptFriendRequestAction),
  rejectFriendRequest: createMultipleRequestReducer(rejectFriendRequestAction),
  deleteFriendRequest: createMultipleRequestReducer(deleteFriendRequestAction),
  searchFriendRequest: createReducer<RequestState<SearchFriend | null> | null>(
    null,
    builder => {
      addRequestCase(builder, searchFriendRequestAction);

      builder.addCase(addFriendRequestAction.success, (state, action) => {
        if (
          !state ||
          !state.data ||
          state.data.friend.id !== action.payload.friend.id
        )
          return state;

        return {
          ...state,
          data: {
            friend: action.payload.friend,
            status: action.payload.status,
            createdAt: action.payload.createdAt,
            updatedAt: action.payload.updatedAt,
          },
        };
      });
    },
  ),
});
