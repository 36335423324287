import styled from 'styled-components/native';
import {css} from 'scl/src/styled-components/css';
import {Text} from '@ui-kitten/components';

export const TermOfUseContainer = styled.View`
  flex: 1;

  ${props =>
    props.theme.isDesktop &&
    css`
      max-width: 1024px;
      align-self: center;
      margin-top: 5%;
      margin-bottom: 5%;
      padding-top: 40px;
      padding-bottom: 48px;
      padding-left: 40px;
      padding-right: 40px;
      background: ${props.theme.colors.white};
      border: 1px solid ${props.theme.colors['color-basic-400']};
      border-radius: 4px;
    `}
`;

export const TermOfUseScrollView = styled.ScrollView`
  flex: 1;
  padding-top: ${props => props.theme.dimens.normalSpacer};
  padding-left: ${props => props.theme.dimens.normalSpacer};
  padding-right: ${props => props.theme.dimens.normalSpacer};

  ${props =>
    props.theme.isDesktop &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
`;

export const TermOfUseText = styled(Text)`
  flex: 1;
`;
