import React, {useCallback, useEffect} from 'react';
import {useTheme} from 'styled-components/native';
import {useAppDispatch, useRequestSelector} from '../../../store/store';
import {useFocusEffect, useRoute} from '@react-navigation/native';
import {Route} from '@react-navigation/routers';
import {AppWhiteStatusBar} from '../../../uikit/AppStatusBar';
import {
  PointsContainerContainer,
  PointsContainerContent,
  PointsContainerPoints,
} from '../../../uikit/Points/container';
import {ScreenLayoutWithHeader} from '../../../uikit/ScreenLayout';
import AppHeader from '../../../uikit/AppHeader';
import {AppLoader} from '../../../uikit/AppLoader';
import {AppError} from '../../../uikit/AppError';
import {
  acceptSplitPaymentRequestAction,
  getSplitPaymentDetailRequestAction,
  rejectSplitPaymentRequestAction,
} from '../store/action';
import SplitPaymentInfo from './components/info/screen';
import SplitPaymentPayed from './components/payment/screen';
import {SplitPaymentStatus} from '../../../api/split-payments/models/responses/split-payment';
import {useToast} from 'react-native-toast-notifications';
import {useStrings} from '../../../utils/providers/strings';
import SplitPaymentModal from './components/modal';

export default function SplitPaymentDetailScreen() {
  const theme = useTheme();
  const strings = useStrings();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const route = useRoute<Route<string, {id: string}>>();
  const requestId = route.params.id;

  const splitPaymentRequest = useRequestSelector(
    store => store.splitPayments.splitPaymentRequest,
  );

  const splitPaymentAcceptRequest = useRequestSelector(
    store => store.splitPayments.splitPaymentAcceptRequest,
  );

  const splitPaymentRejectRequest = useRequestSelector(
    store => store.splitPayments.splitPaymentRejectRequest,
  );

  useEffect(() => {
    return function () {
      dispatch(getSplitPaymentDetailRequestAction.clean());
      dispatch(acceptSplitPaymentRequestAction.clean());
      dispatch(rejectSplitPaymentRequestAction.clean());
    };
  }, [dispatch]);

  useEffect(() => {
    if (splitPaymentRequest.error) {
      toast.show(splitPaymentRequest.error.message);
    }
  }, [splitPaymentRequest.error, toast]);

  useEffect(() => {
    if (splitPaymentRejectRequest.error) {
      toast.show(splitPaymentRejectRequest.error.message);
    }
  }, [splitPaymentRejectRequest.error, toast]);

  const onSplitPaymentRequest = useCallback(() => {
    dispatch(getSplitPaymentDetailRequestAction.request(requestId));
  }, [dispatch, requestId]);

  useFocusEffect(onSplitPaymentRequest);

  const onPay = () => {
    dispatch(
      acceptSplitPaymentRequestAction.request([
        requestId,
        {status: SplitPaymentStatus.PAYED},
      ]),
    );
  };

  const onApprove = () => {
    dispatch(
      acceptSplitPaymentRequestAction.request([
        requestId,
        {status: SplitPaymentStatus.APPROVED},
      ]),
    );
  };

  const onReject = () => {
    dispatch(
      rejectSplitPaymentRequestAction.request([
        requestId,
        {status: SplitPaymentStatus.REJECTED},
      ]),
    );
  };

  const onCloseModal = () => {
    dispatch(acceptSplitPaymentRequestAction.clean());
  };

  return (
    <>
      <AppWhiteStatusBar />
      <PointsContainerContainer>
        <PointsContainerContent>
          <ScreenLayoutWithHeader>
            <AppHeader
              title={splitPaymentRequest.data?.name || ''}
              backNoHistoryRoute="/split-payments"
            />
            {splitPaymentRequest.isLoading ? (
              <AppLoader />
            ) : splitPaymentRequest.error ? (
              <AppError
                error={splitPaymentRequest.error.message}
                retry={onSplitPaymentRequest}
              />
            ) : (
              splitPaymentRequest.data &&
              (splitPaymentRequest.data?.status === SplitPaymentStatus.PAYED ? (
                <SplitPaymentPayed data={splitPaymentRequest.data} />
              ) : (
                <SplitPaymentInfo
                  data={splitPaymentRequest.data}
                  isLoading={splitPaymentRequest.isLoading}
                  onPay={onPay}
                  onApprove={onApprove}
                  onReject={onReject}
                />
              ))
            )}
          </ScreenLayoutWithHeader>
        </PointsContainerContent>
        {theme.isDesktop && <PointsContainerPoints />}
      </PointsContainerContainer>
      <SplitPaymentModal
        title={strings.split_payment_detail_approved_modal_title_fn(
          splitPaymentRequest.data?.requestedAmount || 0,
        )}
        route="/"
        isOpen={
          !!splitPaymentAcceptRequest.data &&
          splitPaymentAcceptRequest.data.status === SplitPaymentStatus.APPROVED
        }
        onClose={onCloseModal}
      />

      {/* TODO: Maybe need added check error number */}
      <SplitPaymentModal
        title={splitPaymentAcceptRequest.error?.message || ''}
        route="/"
        isOpen={!!splitPaymentAcceptRequest.error}
        onClose={onCloseModal}
      />
    </>
  );
}
