import {all, call, put, takeLatest} from 'redux-saga/effects';
import type {AxiosResponse} from 'axios';

import type {ListResponse} from '../../../api/models/response/list';
import type {IInfo} from '../../../api/info/models/info';
import api from '../../../api';
import {infoActions} from './action';

/** Fetch list of information posts */
const getInfoListRequest = function* (
  action: ReturnType<typeof infoActions.fetchList.request>,
) {
  try {
    const response: AxiosResponse<ListResponse<IInfo>> = yield call([
      api,
      api.info.getInfoList,
    ]);

    yield put(infoActions.fetchList.success(response.data));
  } catch (error) {
    yield put(infoActions.fetchList.failure(error as Error));
  }
};

const getInfoPostRequest = function* (
  action: ReturnType<typeof infoActions.fetchPost.request>,
) {
  try {
    const response: AxiosResponse<IInfo> = yield call(
      [api, api.info.getInfoPost],
      action.payload,
    );

    yield put(infoActions.fetchPost.success(response.data));
  } catch (error) {
    yield put(infoActions.fetchPost.failure(error as Error));
  }
};

export const infoPostsSaga = function* () {
  yield all([
    takeLatest(infoActions.fetchList.request, getInfoListRequest),
    takeLatest(infoActions.fetchPost.request, getInfoPostRequest),
  ]);
};
