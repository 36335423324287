import React from 'react';
import {useAppDispatch} from '../../store/store';
import {AppLogoutButton as AppLogoutButtonComponent} from './styles';
import {useStrings} from '../../utils/providers/strings';
import {logoutRequestAction} from '../../features/user/store/action';
import {ViewStyle} from 'react-native';

export default function AppLogoutButton({style}: {style?: ViewStyle}) {
  const strings = useStrings();
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(logoutRequestAction.request());
  };

  return (
    <AppLogoutButtonComponent style={style} onPress={onLogout}>
      {strings.app_logout_button}
    </AppLogoutButtonComponent>
  );
}
